<div *ngIf="store">
  <div class="position-relative coverPhoto" style="background-image: url('{{store?.banner?.url}}');">
    <div class="logo" style="background-image: url('{{store?.logo.url}}');">
    </div>
  </div>
  <div class="d-flex justify-content-center flex-column align-items-center">
    <p-rating [(ngModel)]="store.rating" class="py-3" [cancel]="false" [stars]="5" [readonly]="true"></p-rating>
    <div class="d-flex">
      <img src="../../../../../assets/images/svg/greenPin.svg" class="mr-2 iconPin" alt="">
      <span class="f18 avenirMedium black">{{store?.region?.name}}</span>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end">
  <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4" (click)="display=true">Ajouter une
    photo</button>
</div>
<div class="row ">
  <div *ngFor="let gallery of galeries" class="box col-md-3 pb-2 pr-2">
    <div class="content"
      style="background-image:linear-gradient(360deg, rgba(0, 170, 49, 0.2) 41.83%, rgba(196, 196, 196, 0) 100%), url('{{gallery.photo.url}}');">
      <button class="btn p-0 deleteIcon"><img src="../../../../../assets/images/svg/delete..svg"
          (click)="deletePhoto(gallery)" alt=""></button>
      <span class="text-white f18 avenirMedium position-absolute text">{{gallery.title}}</span>
    </div>
  </div>
</div>
<p-dialog [showHeader]="false" [(visible)]="display" [modal]="true" [style]="{width: '592px'}" [draggable]="false"
  [resizable]="false">
  <form [formGroup]="photoForm" autocomplete="off">
    <div class="mb-4">
      <span class="avenirMedium f35 fw85 main-color">Ajouter une photo</span>
    </div>
    <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title" placeholder="Title"
      formControlName="title" [ngClass]="{'is-invalid': submit&&f['title'].errors}">
    <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description" placeholder="Description"
      formControlName="description" [ngClass]="{'is-invalid': submit&&f['description'].errors}">
    <div class="d-flex align-items-center ">
      <label class="customUpload flex-column p-5 mr-3"
        [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
        <input type="file" (change)="showPreviewImage($event)" />
        <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
      </label>
      <span class="f18 avenirRegular black">Ajouter une image </span>
    </div>
    <div class="d-flex justify-content-end pt-3">
      <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4" (click)="display=false">Annuler </button>
      <button class="btn green-back avenirMedium f16  text-white px-4" (click)="addPhoto()">Ajouter</button>
    </div>
  </form>
</p-dialog>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="'la photos '+this.photo?.title">
</app-confirmation>