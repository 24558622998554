import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  display: boolean = true
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.display = false
    }, 10000000000000000000000);
  }

}
