<div *ngIf="isLoading;else notYetLoaded">
    <form [formGroup]="searchForm">
      <div class="d-flex pb-4">
        <div class="pr-3">
         <p-dropdown optionValue="id" [options]="deliveryStatus" optionValue="value" formControlName="delivered" optionLabel="name" placeholder="Choisir un statut " [showClear]="false"></p-dropdown>
        </div>
        <div class="pr-3">
          <p-dropdown optionValue="id" [options]="type" optionValue="value" formControlName="type" optionLabel="label" placeholder="Choisir un statut " [showClear]="false"></p-dropdown>
         </div>
         <div class="pr-3">
          <p-calendar inputId="basic" dateFormat="dd/mm/yy" placeholder="JJ/MM/AA" appendTo="body"
            class="avenirMedium whiteBorder" formControlName="created">
          </p-calendar>
         </div>
         <button class="text-white btn avenirRegular f18 green-back filterBtn mr-3 br25 px-4" (click)="filter()">Filtrer</button>
         <button class="text-white btn avenirRegular f16 main-color white-back filterBtn br25"
             (click)="reset()">Réintianiliser</button>
     </div>
    </form>
    <table class="table table-borderless">
        <thead>
            <tr>
                <th>Descriptions</th>
                <th>Type</th>
                <th>Points</th>
                <th>Date</th>
                <th>Opérations</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let notification of transactionPoints?.data">
                <td class="avenirMedium f18 black">
                    {{notification.description}}
                </td>
                <td class="avenirMedium f18 black">{{notification.type}}</td>
                <td class="avenirMedium f18 black">{{notification.points}}</td>
                <td class="avenirMedium f18 black">{{notification.created_at.date|date:'dd/MM/yyyy'}}</td>
                <td class="avenirMedium f18 black">
                    <button class="btn p-0 mr-2" (click)="editTp(notification)">
                        <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                    </button>
                    <button class="btn p-0" (click)="showModal(notification)">
                        <img src="../../../assets/images/svg/Icon - view.svg" alt="">
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class=" w-100 ">
        <div class="d-flex justify-content-end">
            <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
                [showFirstLastIcon]="true" [totalRecords]="transactionPoints?.meta.pagination.total_pages" #paginator
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
</div>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [style]="{width: '675px'}" [draggable]="false"
    [resizable]="false">
    <div class="pb-3 pt-4">
        <span class="main-color f30 fw85 avenirRegular">Informations sur la transaction</span>
    </div>
    <div class="d-flex flex-column">
        <div class="avenirRegular f20 black pb-3">
            <span class="fw85"> Nom : </span>
            <span> {{transactionPoint?.client?.name}} </span>
        </div>

        <div class="avenirRegular f20 black pb-3">
            <span class="fw85"> Genre : </span>
            <span> {{transactionPoint?.client?.gender==="h"?"Male":"Female"}} </span>
        </div>
        <div class="avenirRegular f20 black pb-3">
            <span class="fw85"> Adresse : </span>
            <span> {{transactionPoint?.client?.address}} </span>
        </div>
        <div class="avenirRegular f20 black pb-3">
            <span class="fw85"> Numéro de téléphone : </span>
            <span>{{transactionPoint?.client?.phone}} </span>
        </div>
        <div class="avenirRegular f20 black pb-3">
            <span class="fw85"> Email : </span>
            <span>{{transactionPoint?.client?.email}} </span>
        </div>
        <div>
            <span class="avenirRegular f20 black pb-3 fw85"> Status: </span>
            <span class="px-4 br20 active avenirRegular f18"> {{transactionPoint?.delivered===1?"Livrée":"Non Livrée"}}
            </span>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn avenirRegular f16 br5 green-back text-white px-4 "
            (click)="displayModal=false">Retour</button>
    </div>
</p-dialog>
<p-dialog [showHeader]="false" [(visible)]="modificationModal" [modal]="true" [style]="{width: '675px'}"
    [draggable]="false" [resizable]="false">
    <div class="pb-3 pt-4">
        <span class="main-color f30 fw85 avenirRegular">Modification</span>
    </div>
    <form [formGroup]="tpDeliveryStatus">
        <div class="d-flex flex-column">
            <div class="pb-3">
                <p-dropdown [options]="deliveryStatus" formControlName="delivered" optionValue="value"
                    optionLabel="name" placeholder="Status de transaction" [showClear]="false"
                    [ngClass]="{'is-invalid': submit && f['delivered'].errors}"></p-dropdown>
            </div>

            <div class="avenirRegular f20 black pb-3">
                <textarea class="form-control mdStl avenirMedium f18" placeholder="Commentaire"
                    formControlName="comment" id="message" rows="4"
                    [ngClass]="{'is-invalid': submit && f['comment'].errors}"></textarea>
                    <p class="deepGrey f12 avenirRegular">Commentaire doit avoir au mois 10 caractères</p>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end">
        <div class="d-flex justify-content-end pt-3">
            <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4"
                (click)="modificationModal=false">Annuler </button>
            <button class="btn green-back avenirMedium f18 text-white px-4"
                (click)="editTransactionPoint()">Modifier</button>
        </div>
    </div>
</p-dialog>
