<div class="pt-6 pb-3 px-3">
  <div class="d-flex justify-content-between  align-items-center">
    <div class="">
      <span class="avenirRegular f30 black fw85 mt-4">Gestion des Produits </span>
    </div>
    <div>
      <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="reset()">Annuler</button>
      <button class="btn green-back  avenirMedium f18 text-white px-4"
        (click)="manageItem()">{{edit?"Modifier":"Envoyer"}}</button>
    </div>
  </div>
  <form [formGroup]="itemForm" autocomplete="off" class=" pt-6">
    <div class="d-flex">
      <label class="customUpload flex-column p-5" [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
        <input type="file" (change)="showPreviewImage($event)" />
        <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
      </label>
      <div class="px-3 w-100">
        <div class="row pb-3">
          <div class="col-md-8">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-3" formControlName="name" id="name"
              placeholder="Nom du item" [ngClass]="{'is-invalid': submit&&f['name'].errors}">
          </div>
          <div class="col-md-2">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-3" formControlName="price" id="name"
              placeholder="Prix" [ngClass]="{'is-invalid': submit&&f['price'].errors}">
          </div>
          <div class="col-md-2">
            <p-dropdown [options]="unities" optionValue="value" optionLabel="name"
              [ngClass]="{'is-invalid': submit&&f['unit'].errors}" placeholder="Unité de mesure"
              formControlName="unit" [showClear]="false" class="mb-3">
            </p-dropdown>
          </div>
        </div>
        <div class="row pb-3" *ngIf="itemForm.get('unit')?.value==='kg'">
          <div class="col-md-4">
            <p-dropdown [options]="step_quantities" optionValue="value" optionLabel="label"
              placeholder="Quantité de pas" formControlName="step_quantity" [showClear]="false" class="mb-3">
            </p-dropdown>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <span class="f18 avenirRegular black pr-4">Quantité flexible:</span>
            <p-inputSwitch formControlName="flexible_quantity" class="pr-4" (onChange)="switchValue()">
            </p-inputSwitch>
          </div>
        </div>
        <div class="row pb-3" *ngIf="itemForm.get('unit')?.value==='ukg'">
          <div class="col-md-3">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-3" formControlName="avg_weight" id="name"
              placeholder="Poids moyen" [ngClass]="{'is-invalid': submit&&f['avg_weight'].errors}">
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-md-8">
            <textarea name="description" id="description" class="form-control mdStl avenirMedium f18 mb-3"
              formControlName="description" id="name" placeholder="Description du item"
              [ngClass]="{'is-invalid': submit&&f['description'].errors}" rows="3"></textarea>
          </div>
          <div class="col-md-4">
            <textarea name="barcode" id="barcode" class="form-control mdStl avenirMedium f18 mb-3"
              formControlName="barcode" id="name" placeholder="Code-barre du item"
              [ngClass]="{'is-invalid': submit&&f['barcode'].errors}" rows="3"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="options.length>0">
      <div class="mt-4">
        <span class="avenirRegular f30 black fw85 mt-4">Options</span>
      </div>
      <div *ngFor="let option of options">
        <div class="d-flex py-4 align-items-center">
          <span class="avenirArabic f20 black fw85">{{option.group.name}}
            ({{option.group.min}},{{option.group.max}})</span>
          <button class="btn main-color fw85 f16" (click)="selectAll(option)">Tout sélectionner</button>
        </div>
        <div class="d-flex flex-wrap">
          <div *ngFor="let item of option.options" class="d-flex mr-3 my-2 align-items-center">
            <p-checkbox name="group1" [value]="item.option.id" inputId="{{item.option.id}}" [(ngModel)]="item.checked"
              [ngModelOptions]="{standalone:true}" class="mr-2" [binary]="true"></p-checkbox>
            <label class="greyColor f16 avenirArabic" for="{{item.option.id}}">{{item.option.name}}</label>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-4">
        <span class="avenirRegular f30 black fw85 mt-4">Disponibilité</span>
      </div>
      <div class="d-flex">
        <div class="d-flex mr-3 my-2 align-items-center">
          <p-radioButton name="availability" value="all" formControlName="availability" inputId="all"></p-radioButton>
          <label class="greyColor f16 avenirRegular ml-1" for="all">Tout</label>
        </div>
        <div class="d-flex mr-3 my-2 align-items-center">
          <p-radioButton name="availability" value="breakfast" formControlName="availability"
            inputId="breakfast"></p-radioButton>
          <label class="greyColor f16 avenirRegular ml-1" for="breakfast">Petit-déjeuner</label>
        </div>
        <div class="d-flex mr-3 my-2 align-items-center">
          <p-radioButton name="availability" value="lunch" formControlName="availability"
            inputId="lunch"></p-radioButton>
          <label class="greyColor f16 avenirRegular ml-1" for="lunch">Déjeuner</label>
        </div>
        <div class="d-flex mr-3 my-2 align-items-center">
          <p-radioButton name="availability" value="dinner" formControlName="availability"
            inputId="dinner"></p-radioButton>
          <label class="greyColor f16 avenirRegular ml-1" for="dinner">Dîner</label>
        </div>
        <div class="d-flex mr-3 my-2 align-items-center">
          <p-radioButton name="availability" value="lunch_and_dinner" formControlName="availability"
            inputId="lunch_and_dinner"></p-radioButton>
          <label class="greyColor f16 avenirRegular ml-1" for="lunch_and_dinner">Déjeuner et dîner</label>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center mt-5">
      <span class="f18 avenirRegular black pr-4">Promo</span>
      <p-inputSwitch formControlName="promo" class="pr-4" (onChange)="switchValue()"></p-inputSwitch>
      <p-calendar inputId="basic" formControlName="promo_until" appendTo="body" dateFormat="dd/mm/yy"
        [ngClass]="{'is-invalid': submit&&f['promo_until'].errors}" class="avenirMedium"></p-calendar>
    </div>
  </form>
</div>
<p-dialog [showHeader]="false" *ngIf="item" [(visible)]="available_at_modal" [modal]="true" [style]="{width: '675px'}"
  [draggable]="false" [resizable]="false">
  <div class="">
    <span class="avenirRegular f35 fw85 main-color">Date de disponibilité à nouveau </span>
  </div>
  <div class="row no-gutters align-items-center mt-4 ">
    <div class="d-flex">
      <div class="flex align-items-center mr-2">
          <p-radioButton name="one_hour" value="one_hour" [(ngModel)]="item.available_at" inputId="one_hour"></p-radioButton>
          <label for="one_hour" class="ml-1 avenirRegular black">1 Heure</label>
      </div>

      <div class="flex align-items-center mr-2">
          <p-radioButton name="three_hours" value="three_hours" [(ngModel)]="item.available_at" inputId="three_hours"></p-radioButton>
          <label for="three_hours" class="ml-1 avenirRegular black">3 Heure</label>
      </div>

      <div class="flex align-items-center mr-2">
          <p-radioButton name="tomorrow" value="tomorrow" [(ngModel)]="item.available_at" inputId="tomorrow"></p-radioButton>
          <label for="tomorrow" class="ml-1 avenirRegular black">Demain</label>
      </div>

      <div class="flex align-items-center mr-2">
          <p-radioButton name="never" value="never" [(ngModel)]="item.available_at" inputId="never"></p-radioButton>
          <label for="never" class="ml-1 avenirRegular black">Indéfiniment</label>
      </div>
  </div>
    <div class="w-100 mt-3">
      <p-calendar dateFormat="dd/mm/yy" [showTime]="true" class="pr-3 w-100" appendTo="body" hourFormat="24" [(ngModel)]="item.available_at"> </p-calendar>
    </div>
  </div>
  <div class="d-flex justify-content-end  align-items-center mt-4">
    <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="available_at_modal=false">Annuler
    </button>
    <button class="btn green-back  avenirRegular f16 text-white px-4" (click)="save_availibility()">Modifier</button>
  </div>
</p-dialog>
