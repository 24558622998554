<div *ngIf="isLoading;else notYetLoaded">
    <div class="avenirRegular f16 deedivrey">
        <div>
            Cette page contient les numéro de téléhpone à contacter par les opérateurs du hubs
        pour les besoins des opérations.
        </div>
        <div class="mt-2">
            Tous les utilisateurs sont affichées par défaut.
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-md-9 pr-4">
            <p-table *ngIf="contacts?.length>0;else noContacts" [value]="contacts" [reorderableColumns]="true" responsiveLayout="scroll">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width:3rem"></th>
                        <th pReorderableColumn>Nom de contact</th>
                        <th pReorderableColumn>Téléphone</th>
                        <th pReorderableColumn>Note</th>
                        <th pReorderableColumn>Opérations</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                    <tr [pReorderableRow]="index">
                        <td>
                            <img src="../../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index"
                                alt="">
                        </td>
                        <td class="f16 avenirRegular black">
                            {{rowData.label}}
                        </td>
                        <td class="f16 avenirRegular greyColor">{{rowData.phone}}</td>
                        <td class="f18 avenirRegular black">{{rowData.note}}</td>
                        <td>
                            <button class="btn p-0 mr-2" (click)="editContact(rowData)">
                                <img src="../../../../../assets/images/svg/Icon - pen.svg" alt="">
                            </button>
                            <button class="btn p-0" (click)="delete_contact(rowData)">
                                <img src="../../../../../assets/images/svg/delete..svg" alt="">
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-md-3 p-3 white-back">
            <div class="py-3">
                <span class="main-color f18 fw60 avenirRegular">Ajouter un numéro de contact</span>
            </div>
            <form autocomplete="off" [formGroup]="contactForm">
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" formControlName="label" id="name"
                        [ngClass]="{'is-invalid': f['label'].errors&&submit}" placeholder="Nom du contact">
                </div>
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="phone"
                        [ngClass]="{'is-invalid': f['phone'].errors&&submit}" placeholder="Téléphone du contact">
                </div>
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Note" formControlName="note">
                </div>
                <div class="d-flex justify-content-end align-items-center">
                    <button class="btn p-0 f16 fw60 avenirRegular main-color" (click)="manageContact()">Enregistrer</button>
                </div>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="contact?.label+' contact'"></app-confirmation>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<ng-template #noContacts>
    <div class="pt-5">
        <p class="text-center black  avenirBlack f18 ">Cet établissement n'a pas encore des contacts appuyez sur "Ajouter un numéro de contact" pour en ajouter un</p>
    </div>
</ng-template>
