import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { OrderDetailsComponent } from '../../sharedComponents/order-details/order-details.component';
import { HomeComponent } from '../../home/home.component';
import { TicketsComponent } from '../tickets.component';
@Component({
  selector: 'app-client-tickets',
  templateUrl: './client-tickets.component.html',
  styleUrls: ['./client-tickets.component.scss']
})
export class ClientTicketsComponent implements OnInit {
  client_tickets: any
  page: any
  is_loaded: boolean = false
  types: any
  searchForm!: FormGroup;
  client_ticket_form!: FormGroup
  stores: any[] = []
  types_array: any[] = []
  display: boolean = false
  client_ticket: any
  status: any[] = [{ label: 'Ouvert', value: 1 }, { label: 'Fermé', value: 0 }]
  discounts: any[] = []
  submit: boolean = false
  @ViewChild('order_details') order_details!: OrderDetailsComponent

  constructor(private home:HomeComponent,private tickets:TicketsComponent,private http_service: HttpService, private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private messageService: MessageService) {
  }
  ngOnInit(): void {
    this.initializeForm()
    this.client_ticket_form = this.fb.group({
      discount: new FormControl("", [Validators.maxLength(32)]),
      discount_value: new FormControl("", [this.ConditionallyRequiredValidator]),
      backoffice_close: new FormControl("",),
      update: new FormControl("", [Validators.maxLength(1024),]),
      update_type: new FormControl("backoffice_update"),
    }, {
      validators: [this.ConditionallyRequiredValidator]
    })
    this.client_ticket_form.get('discount')?.valueChanges.subscribe(value => {
      this.client_ticket_form.get('discount_value')?.updateValueAndValidity();
    });
    this.client_ticket_form.get('update')?.valueChanges.subscribe(value => {
      this.client_ticket_form.get('update_type')?.updateValueAndValidity();
    });
    this.get_stores(1)
    this.route.queryParamMap.subscribe(param => {
      this.page = param.get('page') ? Number(param.get('page')) : 1
      if (param.get('type') || param.get('store') || param.get('backoffice')) {
        this.searchForm.patchValue({
          type: param?.get('type') ? param?.get('type') : "",
          store: param?.get('store') ? JSON.parse(param?.get('store') || '') : "",
          backoffice: param?.get('backoffice') ? JSON.parse(param?.get('backoffice') || '') : "",
        })
      }
      let url: any = 'client_tickets?order_by=created&filters=backoffice:' + this.searchForm.value.backoffice + ';'
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""
      param.get('store') ? url += 'store:' + param.get('store') + ";" : ""
      console.log(this.searchForm.value);
      this.get_client_tickets_info()
      this.get_client_tickets(url)
    })
  }
  get f() { return this.client_ticket_form.controls }
  initializeForm() {
    this.searchForm = this.fb.group({
      type: new FormControl("",),
      store: new FormControl("",),
      backoffice: new FormControl(1),
    });
  }
  get_client_tickets(url: any) {
    this.http_service.get(url + "&page=" + this.page).subscribe((data) => {
      if (data?.status == 200) {
        this.client_tickets = data.body
        this.is_loaded = true
        this.client_tickets.data.forEach((element: any) => {
          element.type = this.getType(element.type, element.reason)
        });
      }
    })
  }
  get_client_tickets_info() {
    if (this.types_array.length === 0) {
      this.http_service.get('client_tickets_info').subscribe((data) => {
        if (data?.status == 200) {
          this.types = data.body
          Object.keys(this.types.types).forEach((element: any) => {
            this.types_array.push({ label: this.types.types[element].name, value: element })
          })
          Object.keys(this.types.discounts).forEach(element => {
            this.discounts.push({ label: this.types?.discounts[element], value: element })
          });
        }
      })
    }
  }
  get_stores(page: any) {
    if (this.stores.length == 0) {
      this.http_service.get('stores?page=' + page).subscribe((data) => {
        if (data?.status == 200) {

          data.body.data.forEach((element: any) => {
            this.stores.push({ label: element.name, value: element.id })
          });

          if (data.body.meta.pagination.total_pages > page) {
            this.get_stores(page + 1)
          }
        }
      })
    }
  }
  getType(type: string, reason: any) {
    return { name: this.types.types[type]?.name, color: this.types.types[type]?.color, reasons: this.getReason(this.types.types[type], reason) }
  }
  getReason(type: any, reason: string) {
    return { name: type?.reasons[reason] }
  }
  filter() {
    let search = this.searchForm.value
    search = { ...search, page: 1 }
    this.router.navigate(['/tickets/client-tickets'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.initializeForm()
    this.router.navigate(['/tickets/client-tickets']);
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/tickets/client-tickets'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  edit_client_ticket(client_ticket: any) {
    this.display = true
    this.client_ticket = client_ticket
    this.client_ticket_form.patchValue({
      discount: this.client_ticket.discount,
      discount_value: this.client_ticket.discount_value,
      backoffice_close: this.client_ticket.notify_backoffice === 1 ? true : false,
      update: this.client_ticket.update,
    })
  }
  manage_client_ticket() {
    if (this.client_ticket_form.invalid) {
      this.submit = true
      return
    }
    this.client_ticket_form.value.backoffice_close = this.client_ticket_form.value.backoffice_close ? 0 : 1
    this.http_service.post('client_ticket/' + this.client_ticket.id, this.client_ticket_form.value).subscribe((data) => {
      if (data?.status == 200) {
        this.display = false
        this.home.get_store_tickets_count()
        this.tickets.get_client_tickets_count()
        Object.assign(this.client_ticket, data.body.data)
        this.client_ticket.type = this.getType(this.client_ticket.type, this.client_ticket.reason)
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Requête cloturé avec succès' });
      }
    })
  }
  showSidbar(client_ticket: any) {
    this.order_details.display = true
    this.client_ticket = client_ticket
    this.client_ticket.order.items.forEach((element: any) => {
      element.data.options = Object.values(element.data.options)
    });
  }
  ConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('discount').value === "value") {
      console.log(formControl);
      return Validators.required(formControl);
    }
    return null;
  }
}
