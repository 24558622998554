<div id="container" >
  <svg viewBox="0 0 100 100">
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0" dy="0" stdDeviation="1.5" flood-color="#00AA31" />
      </filter>
    </defs>
    <circle id="spinner"
      style="fill:transparent;stroke:#00AA31 ;stroke-width: 7px;stroke-linecap: round;filter:url(#shadow);" cx="50"
      cy="50" r="45" />
  </svg>
</div>