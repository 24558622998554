<div class="d-flex justify-content-end mb-3">
  <button class="btn avenirHeavy f20 br25 green-back text-white px-4 mr-2" [routerLink]="['manage-categories']">Gérer
    les catégories</button>
  <button class="btn avenirHeavy f20 br25 green-back text-white px-4"[routerLink]="['manage-reward/'+0]">Créer une
    récompense</button>
</div>
<div class="row">
  <div class="col-12">
    <form [formGroup]="searchForm">
      <div class="row no-gutters ">
        <div class="col-md-2 pr-3">
          <input type="text" class="form-control avenirMedium f18 black custom-control h-100" formControlName="name"
            placeholder="Rechercher">
        </div>
        <div class="col-md-2 pr-3">
          <p-dropdown [options]="regions" optionValue="id" optionLabel="name" placeholder="Région" [showClear]="false"
            formControlName="region"></p-dropdown>
        </div>
        <div class="col-md-2 pr-3">
          <p-dropdown [options]="categories" optionValue="id" optionLabel="name"
            placeholder="Catégorie de la récomponse" [showClear]="false" formControlName="category"></p-dropdown>
        </div>
        <div class="col-md-2 pr-2">
          <p-dropdown formControlName="type" appendTo="body" [options]='type' optionValue="value" optionLabel="label"
            placeholder="Type de récompense" [showClear]="false">
          </p-dropdown>
        </div>
        <div class="col-md-2 px-2 d-flex align-items-center justify-content-start">
          <p-dropdown formControlName="active" appendTo="body" [options]='activeList' optionValue="value"
            optionLabel="label" placeholder="Active" [showClear]="false">
          </p-dropdown>
        </div>
        <div class="col-md-1 pr-1">
          <button class="text-white btn avenirRegular f18 green-back w-100 filterBtn"
            (click)="filter()">Filtrer</button>
        </div>
        <div class="col-md-1 ">
          <button class="w-100 text-white btn avenirRegular f16 main-color white-back filterBtn"
            (click)="reset()">Réintianiliser</button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="table-responsive">
  <p-table [value]="rewards?.data" [reorderableColumns]="true" responsiveLayout="scroll">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width:3rem"></th>
        <th style="width: 5%" class="text-center"></th>
        <th style="width: 25%" class="text-start">Nom</th>
        <th style="width: 10%" class="text-center">Regions</th>
        <th style="width: 10%" class="text-center">Utilisations</th>
        <th style="width: 10%" class="text-center">Points</th>
        <th style="width: 10%" class="text-center">Type</th>
        <th style="width: 10%" class="text-center">Valeur</th>
        <th style="width: 10%" class="text-center">Active</th>
        <th style="width: 10%" class="text-center">Opération</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <img src="../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index" alt=""
            (dragend)="changeSectionOrder()">
        </td>
        <td style="width: 5%" class="text-center">
          <img *ngIf="rowData.image;else templateName" class="rewardImg" [src]="rowData.image" alt="">
          <ng-template #templateName>
            <img class="rewardImg" src="../../../../assets/images/png/Frame 1322.png" alt="">
          </ng-template>
        </td>
        <td style="width: 35%" class="avenirRegular f18 black">
          <div class="d-inline-grid lineHeight text-start">
            <span>{{rowData.name}}</span>
            <span class="avenirArabic">{{rowData?.translations?.name?.ar || ""}}</span>
            <span><small> {{rowData.starts_at?.date | date:"dd/MM/yyyy"}} - {{rowData.expires_at?.date |
                date:"dd/MM/yyyy"}}</small></span>
          </div>
        </td>
        <td style="width: 10%" class="avenirRegular f18 black text-center">
          <div class="d-inline-grid lineHeight">
            <span>{{rowData?.region?.name || "Toutes"}}</span>
          </div>
        </td>
        <td style="width: 10%" class="avenirRegular f18 black text-center">
          <div class="d-inline-grid lineHeight">
            <span>{{rowData?.uses}}</span>
          </div>
        </td>
        <td style="width: 10%" class="avenirRegular f18 fw85 text-center">
          <span class="main-color ">{{rowData.points}} </span>
          <span class="black">PTS</span>
        </td>
        <td style="width: 10%" class="avenirRegular f18 black text-center">{{rowData.type}}</td>
        <td style="width: 10%" class="avenirRegular f18 fw85 text-center">
          <span class="main-color ">{{rowData.value}} </span>
          <span *ngIf="rowData.type == 'value_promo'"
            class="deepGrey">{{appconfig.config.langs[0].currency_symbol}}</span>
          <span *ngIf="rowData.type == 'percent_promo'" class="deepGrey">%</span>
        </td>
        <td style="width: 10%" class="avenirRegular f18 black">
          <div class="d-inline-grid lineHeight d-flex align-items-center justify-content-center">
            <p-inputSwitch [ngModel]="rowData.active === 1" (ngModelChange)="rowData.active = $event ? 1 : 0"
              [ngModelOptions]="{standalone:true}" class="d-flex justify-content-center"
              (onChange)="changeAvailability(rowData)">
            </p-inputSwitch>
          </div>
        </td>
        <td style="width: 10%" class="text-center">
          <div class="d-flex align-items-center">
            <button class="btn p-0 mr-2" [routerLink]="['manage-reward/'+rowData.id]" routerLinkActive="router-link-active" >
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
            <button class="btn p-0 d-flex align-items-center " (click)="editFiles(rowData)">
              <i class="pi pi-images mr-1"></i> <span class="avenirRegular f12 main-color">({{rowData.files.length}})</span>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class=" w-100 ">
    <div class="d-flex justify-content-end">
      <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
        [showFirstLastIcon]="true" [totalRecords]="rewards?.meta.pagination.total_pages" #paginator
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<app-manage-files #manageFiles (onConfirm)="onConfirm($event)" [reward]="reward"></app-manage-files>
