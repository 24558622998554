import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { HttpService } from '../../services/http.service';
import { style } from "../../../app/_helpers/map_style";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { HomeComponent } from '../home/home.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SharedService } from 'src/app/services/shared.service';
declare var google: any;
@Component({
    selector: 'app-regions',
    templateUrl: './regions.component.html',
    styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {
    items!: MenuItem[];
    displayModal: boolean = false;
    options: any;
    infoWindow: any;
    regions: any[] = []
    Storepin = '../../../assets/images/svg/greenPin.svg'
    displaySidebar: boolean = false;
    smsPhones: any
    region: any
    regionForm!: FormGroup;
    SMSForm!: FormGroup;
    display: boolean = false;
    categories: any
    checked: boolean = true
    isLoading: boolean = false;
    overlays!: any[];
    cords: any = { lat: 36.9055073, lng: 7.7544866 }
    edit: boolean = false;
    submit: boolean = false
    configLangs: any[] = [];

    constructor(
        private fb: FormBuilder, 
        private httpService: HttpService, 
        private messageService: MessageService, 
        private home: HomeComponent,
        private appConfig: AppConfigService,
        private sharedService: SharedService
    ) { }
    
    ngOnInit(): void {
        this.configLangs = this.appConfig.configLangs;

        this.getRegions()
        this.inializeForms()
        this.setupCategories()
        this.infoWindow = new google.maps.InfoWindow();
        if (this.home?.user?.type === "superadmin") {
            this.getPhonesStatus()
        }
        this.items = [{
            items: [{
                label: 'Liste des SMS globale',
                routerLink: 'phoneList'
            }, {
                label: 'Status des SMS',
                command: () => {
                    this.displaySideBar();
                }
            }
            ]
        },];
        this.options = {
            center: this.cords,
            zoom: 10,
            styles: style.style
        };
        this.overlays = [
            new google.maps.Marker(
                {
                    position: this.cords, icon: this.Storepin,
                    title: this.region?.name, draggable: true,
                },
            ),
        ];
    }
    get f() { return this.regionForm.controls }
    getRegions() {
        this.httpService.get("regions?order_by=weight").subscribe(res => {
            if (res?.status == 200) {
                this.isLoading = true
                res.body.data.forEach((element: any) => {
                    this.regions.push(element)
                });
            }
        })
    }
    addRegion() {
        this.displayModal = true;
        this.region = null
        this.edit = false
        this.inializeForms()
        this.setupCategories()
    }
    hideDialog() {
        this.displayModal = false
    }
    displaySideBar() {
        if (this.home?.user?.type === "superadmin") {
            this.displaySidebar = !this.displaySidebar
            this.getPhonesStatus()
        }

    }
    getPhonesStatus() {
        this.httpService.get("sms_phones/status").subscribe(res => {
            if (res?.status === 200) {
                this.smsPhones = res.body.phones
            }
        })
    }
    inializeForms() {
        this.regionForm = this.fb.group({
            name: new FormControl("", Validators.required),
            active: new FormControl(false, Validators.required),
            location: new FormControl("", Validators.required),
            categories: new FormControl("", Validators.required),
            default_store_order_rate: new FormControl("", [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]),
            default_order_rate: new FormControl("", [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]),
            service_fees: new FormControl(),
            translations: new FormArray([])
        });
        this.SMSForm = this.fb.group({
            from: new FormControl("", Validators.required),
            text: new FormControl("", Validators.required),
            to: new FormControl("", [Validators.required, Validators.pattern("^(0)(5|6|7)(4|5|6|7|8|9)[0-9]{7}$")]),
        });
    }
    setupCategories() {
        this.categories = [{ name: "Food", value: "food", checked: false, fees: null }, { name: "Fresh", value: "fresh", checked: false, fees: null }, { name: "Express", value: "express", checked: false, fees: null }, { name: "Healthy", value: "healthy", checked: false, fees: null }]
        let categories = this.region?.categories.split(",")
        this.categories.forEach((element: any) => {
            categories?.forEach((category: any) => {
                if (element.value === category) {
                    element.checked = true
                    element.fees = this.region.service_fees[category]
                }
            });
        });
    }
    editRegion(region: any) {
        this.displayModal = true
        this.region = region
        this.edit = true
        this.setupCategories()
        this.regionForm.patchValue({
            name: region.name,
            active: region.active,
            default_store_order_rate: region.default_store_order_rate * 100,
            default_order_rate: region.default_order_rate * 100,
            location: region.location,
        })
        let cordsText: string = region.location.substring(6, region.location.length - 1);
        let cords = cordsText.split(" ");
        this.options = {
            center: { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) },
            zoom: 10,
            styles: style.style
        };

        this.overlays = [
            new google.maps.Marker(
                {
                    position: { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) },
                    title: region.name, draggable: true, icon: this.Storepin
                }
            ),
        ];
    }
    manageRegion() {
        let services_fees: any = {}
        let categories = ""
        this.submit = true
        this.categories.forEach((element: any) => {
            if (element.checked) {
                categories = categories.concat(",", element.value)
            }
            services_fees = { ...services_fees, ...{ [element.value]: element.fees } }
        });
        this.regionForm.patchValue({
            categories: categories.substring(1),
            service_fees: services_fees
        })
        if (this.regionForm.invalid) {
            return
        }
        this.regionForm.value.default_order_rate = this.regionForm.value.default_order_rate / 100
        this.regionForm.value.default_store_order_rate = this.regionForm.value.default_store_order_rate / 100
        if (this.edit) {
            this.httpService.post("region/" + this.region.id, this.regionForm.value).subscribe(res => {
                if (res?.status === 200) {
                    this.displayModal = false
                    Object.assign(this.region, res.body.data)
                    this.messageService.add({ severity: 'success', summary: 'Opération terminée', detail: 'La région a été modifié avec succès' });
                }
            })
        } else {
            this.httpService.post("region", this.regionForm.value).subscribe(res => {
                if (res?.status === 200) {
                    this.displayModal = false
                    this.regions.unshift(res.body.data)
                    this.messageService.add({ severity: 'success', summary: 'Opération terminée', detail: 'La région a été ajouté avec succès' });
                }
            })
        }
    }
    testSMS(sms: any) {
        this.display = true
        this.SMSForm.patchValue({
            from: sms.number,
        })
    }
    sendSMS() {
        if (this.SMSForm.invalid) {
            return
        }
        this.httpService.post("sms_phones/send", this.SMSForm.value).subscribe(res => {
            if (res?.status === 200) {
                this.display = false
            }
        })
    }
    setCenter(map: any) {
        let cordsText: string = this.region?.location.substring(6, this.region.location.length - 1)
        let cords = cordsText?.split(" ") || ["36.9055073", "7.7544866"]
        map.setCenter(new google.maps.LatLng(parseFloat(cords[0]), parseFloat(cords[1])));
    }
    mapDRagEnd(event: any) {
        this.regionForm.patchValue({
            location: "POINT(" + event.overlay.position.lat() + " " + event.overlay.position.lng() + ")"
        })
    }

    onTranslationsChange(form: FormGroup, key: string, lang: string, event: any){
        var element: FormArray = form.get('translations') as FormArray;
        element = this.sharedService.onTranslationsChange(form, key, lang, event);
    }

    patchTranslationsData(form: FormGroup, data: any){
        var element: FormArray = form.get('translations') as FormArray;
        element = this.sharedService.patchTranslationsData(form, data);
      }
}
