import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {
  loading: boolean = false;
  regions: any
  displayModal: boolean = false;
  region: any
  productForm!: FormGroup;
  deletedServices: any = []
  submit: boolean = false;
  constructor(private httpService: HttpService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.Get_regions();
    this.productForm = this.fb.group({
      store_phones: this.fb.array([
        this.addPhoneFormGroup()
      ]),
      delivery_phones: this.fb.array([
        this.addPhoneFormGroup()
      ]),
      sms_phones: this.fb.array([
        this.addPhoneFormGroup()
      ]),
      client_phones: this.fb.array([
        this.addPhoneFormGroup()
      ]),
    })


  }
  addPhoneFormGroup(): FormGroup {
    return this.fb.group({
      phone: new FormControl("", [Validators.required, Validators.pattern("^(0)(5|6|7)(4|5|6|7|8|9)[0-9]{7}$")]),
      exist: new FormControl(false),
      delete: new FormControl(false)
    });
  }
  Get_regions() {
    this.httpService.get('regions').subscribe((data: any) => {
      if (data.status == 200) {
        this.loading = true;
        data.body.data.forEach((element: any) => {
          this.managePhones(element)
        });
        this.regions = data.body.data;
      }
    }

    )
  }
  managePhones(element: any) {
    let client_phones = element.client_phones.split(',').filter(Boolean)
    let delivery_phones = element.delivery_phones.split(',').filter(Boolean)
    let sms_phones = element.sms_phones.split(',').filter(Boolean)
    let store_phones = element.store_phones.split(',').filter(Boolean)
    element.store_phones = store_phones;
    element.client_phones = client_phones;
    element.sms_phones = sms_phones;
    element.delivery_phones = delivery_phones;
    element
  }
  addService() {
    this.displayModal = true;
    this.productForm.reset()
  }
  editService(region: any) {
    this.displayModal = true;
    this.region = region;
    this.productForm.setControl('store_phones', this.setExistingProducts(this.region.store_phones));
    this.productForm.setControl('delivery_phones', this.setExistingProducts(this.region.delivery_phones));
    this.productForm.setControl('sms_phones', this.setExistingProducts(this.region.sms_phones));
    this.productForm.setControl('client_phones', this.setExistingProducts(this.region.client_phones));
  }
  setExistingProducts(skillSets: any): FormArray {
    const formArray = new FormArray([]);
    if (skillSets.length>0) {
      skillSets.forEach((s: any) => {
        formArray.push(this.fb.group({
          phone: s,
          exist: true,
          delete: false
        }));
      });
    }else{
      formArray.push(this.addPhoneFormGroup());
    }

    return formArray;
  }
  get store_phones() {
    return this.productForm.get('store_phones') as FormArray
  }
  get delivery_phones() {
    return this.productForm.get('delivery_phones') as FormArray
  }
  get sms_phones() {
    return this.productForm.get('sms_phones') as FormArray
  }
  get client_phones() {
    return this.productForm.get('client_phones') as FormArray
  }
  cancel() {
    this.displayModal = false;
    this.productForm.reset();
  }
  manageService() {
    this.submit = true;
    // console.log(this.productForm);
    if (this.productForm.invalid) {
      return;
    }
    let store_phones: any = [];
    let client_phones: any = [];
    let sms_phones: any = [];
    let delivery_phones: any = [];
    this.productForm.value.store_phones.forEach((element: any) => {
      if (!element['delete']) {
        store_phones.push(element.phone)
      }
    });
    this.productForm.value.client_phones.forEach((element: any) => {
      if (!element['delete']) {
        client_phones.push(element.phone)
      }
    });
    this.productForm.value.sms_phones.forEach((element: any) => {
      if (!element['delete']) {
        sms_phones.push(element.phone)
      }
    });
    this.productForm.value.delivery_phones.forEach((element: any) => {
      if (!element['delete']) {
        delivery_phones.push(element.phone)
      }
    });
    let data = { store_phones: store_phones.join(','), client_phones: client_phones.join(','), sms_phones: sms_phones.join(','), delivery_phones: delivery_phones.join(',') }
    this.httpService.post('region/' + this.region.id, data).subscribe((data: any) => {
      if (data?.status == 200) {
        this.displayModal = false;
        this.managePhones(data.body.data)
        Object.assign(this.region, data.body.data);

      }
    })
  }
  deleteAssistance(form_control: any, index: number) {
    (<FormArray>this.productForm.get(form_control)).removeAt(index);
    console.log(this.region);

  }
  addPhoneNumber(form_control: any): void {
    (<FormArray>this.productForm.get(form_control)).push(this.addPhoneFormGroup());
  }

}

