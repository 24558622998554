<div *ngIf="loadingItems;else notYetLoaded">
    <div class="d-flex justify-content-between align-items-center pb-3">
        <span class="avenirMedium f36 fw85 main-color">Les sections</span>
        <div class="d-flex align-items-center justify-content-center">
            <button class="btn avenirRegular f20 br25 green-back text-white px-4 mr-3" (click)="addSection()">Ajouter
                une section</button>
            <button class="btn p-0 cloneBtn mr-1" (click)="dispaly=true">
                <img src="../../../../assets/images/png/cloner.png" alt="">
            </button>
            <button class="btn cloneBtn mr-1"  [routerLink]="['/stores/details/'+menu_id+'/statistics']" >
              <i class="pi pi-chart-bar" style="font-size: 1.2rem"></i>
            </button>
            <button class="btn p-0 cloneBtn mr-3" (click)="dispalyBarcode=true" *ngIf="store?.uses_barcode===1">
              <img src="../../../../assets/images/png/barcode.png" alt="" class="barcodeImg">
          </button>
            <button class="btn p-0 deleteBtn" (click)="deleteAll('section')">
                <img src="../../../../../assets/images/svg/trash.svg" alt="">
            </button>
        </div>
    </div>
    <div #content id="content">
        <p-table *ngIf="sections!.length>0;else noSections" [value]="sections" [columns]="colsSections"
            [reorderableColumns]="true"  responsiveLayout="scroll" [scrollHeight]="height">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width:3rem"></th>
                    <th>Image</th>
                    <th>Nom</th>
                    <th>Parent section</th>
                    <th>Opérations</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td>
                        <img src="../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index" alt=""
                            (dragend)="changeSectionOrder()">
                    </td>
                    <td><img *ngIf="rowData?.image?.url;else noImg" src="{{rowData.image.url}}" class="img" alt=""> </td>
                    <td>{{rowData.name}} </td>
                    <td>{{rowData.parent}} </td>
                    <td>
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="btn p-0 mr-2" [routerLink]="['/stores/details/'+menu_id+'/section/'+rowData.id]"
                                routerLinkActive="router-link-active">
                                <img src="../../../../../assets/images/svg/Vector 8.svg" alt="">
                            </button>
                            <button class="btn p-0 mr-2" (click)="editSection(rowData)">
                                <img src="../../../../../assets/images/svg/Icon - pen.svg" alt="">
                            </button>
                            <button class="btn p-0 mr-2" (click)="deleteSection(rowData)">
                                <img src="../../../../../assets/images/svg/delete..svg" alt="">
                            </button>
                            <button class="btn p-0 mr-2" (click)="deactivate_all_products(rowData)">
                              <img src="../../../../../assets/images/svg/Eye off.svg" alt="" class="eye_off">
                          </button>
                          <button class="btn p-0" (click)="activate_all_products(rowData)">
                            <img src="../../../../../assets/images/svg/Eye.svg" alt="" class="eye_off">
                        </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <span *ngIf="store?.global_store_name" class="f12 red-color avenirRegular">Attention: ceci est un
        menu global de store {{store?.global_store_name}} qui ne peut pas être modifié</span>
</div>
<div *ngIf="loadingOptions;else notYetLoaded">
    <div class="d-flex justify-content-between align-items-center mt-5">
        <span class="avenirMedium f36 fw85 main-color">Les options</span>
        <div class="d-flex align-items-center justify-content-center">
            <button class="btn avenirRegular f20 br25 green-back text-white px-4 mr-3" (click)="addOption()">Ajouter une
                option</button>
            <button class="btn p-0 deleteBtn" (click)="deleteAll('option')">
                <img src="../../../../../assets/images/svg/trash.svg" alt="">
            </button>
        </div>
    </div>
    <p-table *ngIf="options?.length>0;else noOptions" [value]="options" [columns]="colsOptions"
        [reorderableColumns]="true" class="pt-3" [scrollHeight]="height">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width:3rem"></th>
                <th *ngFor="let col of columns" pReorderableColumn>
                    {{col.header}}
                </th>
                <th>
                    Opérations
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pReorderableRow]="index">
                <td>
                    <img src="../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index" alt=""
                        (dragend)="changeOptionOrder()">
                </td>
                <td *ngFor="let col of columns">
                    {{rowData[col.field]}}
                </td>
                <td>
                    <div class="d-flex align-items-center justify-content-center">
                        <button class="btn p-0 mr-4" (click)="editOptions(rowData)">
                            <img src="../../../../../assets/images/svg/Icon - pen.svg" alt="">
                        </button>
                        <button class="btn p-0" (click)="deleteOption(rowData)">
                            <img src="../../../../../assets/images/svg/delete..svg" alt="">
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="pt-3">
    <span class="ma main-color f24 avenirRegular">{{count_items}} produits ajoutés avec succés</span>
    <div class="d-flex" *ngIf="items_without_img.length===0;else noImgs">
        <img src="../../../../../assets/images/svg/check.svg" alt="" class="mr-2">
        <span class="avenirMedium f18 deepGrey">Tous les produits ont des images</span>
    </div>
    <ng-template #noImgs>
        <div class="d-flex">
            <img src="../../../../../assets/images/svg/triangle.svg" alt="">
            <div>
                <span class="avenirMedium f18 deepGrey">{{items_without_img.length}} produits sans images: </span>
                <ul
                    [ngClass]="{'cc-2': items_without_img.length>=6,'cc-3': items_without_img.length>=12,'cc-4': items_without_img.length>=16}">
                    <li class="avenirMedium f18 deepGrey" *ngFor="let item of items_without_img"><a
                            [routerLink]="['/stores/details/'+menu_id+'/section/'+item.item.section_id]">{{item?.item?.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
</div>
<ng-template #noSections>
    <div class="pt-3">
        <p class="text-center black  avenirBlack f18">Cet établissement n'a pas encore des section appuyez sur "Ajouter
            une section" pour en ajouter une</p>
    </div>
</ng-template>
<ng-template #noOptions>
    <div class="pt-3">
        <p class="text-center black  avenirBlack f18">Cet établissement n'a pas encore des options appuyez sur "Ajouter
            une option" pour en ajouter une</p>
    </div>
</ng-template>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="displaysecTionModal" [modal]="true" [style]="{width: '592px'}"
    [draggable]="false" [resizable]="false">
    <form class="" [formGroup]="sectionForm" autocomplete="off">
        <div class="mb-3">
            <span class="avenirMedium f35 fw85 main-color"> {{editScton?'Modifier la section':'Ajouter une section'}}
            </span>
        </div>
        <div class="d-flex align-items-center justify-content-center mb-4">
            <label class="customUpload flex-column p-5 mr-3"
                [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
                <input type="file" (change)="showPreviewImage($event)" />
                <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
            </label>
            <span class="f18 avenirRegular black">Ajouter une image </span>
        </div>
        <input type="text" class="form-control mdStl avenirMedium f18 mb-4" id="name"
            placeholder="Entrez le nom de section" formControlName="name">
        <p-dropdown [options]="editScton?sections_without_parent:sections" optionValue="id" appendTo="body"
            formControlName="parent_id" optionLabel="name" placeholder="Parent section" [showClear]="false">
        </p-dropdown>
        <div class="d-flex justify-content-end pt-3">
            <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4"
                (click)="displaysecTionModal=false">Annuler </button>
            <button class="btn green-back avenirMedium f16  text-white px-4" (click)="manageSection()">Ajouter</button>
        </div>
    </form>
</p-dialog>
<p-dialog [showHeader]="false" [(visible)]="displayOptionModal" [modal]="true" [style]="{width: '660px'}"
    [draggable]="false" [resizable]="false">
    <form autocomplete="off" [formGroup]="optionForm" autocomplete="off">
        <div class="mb-4">
            <span class="avenirMedium f35 fw85 main-color">{{editOption?"Modifier l'option "+option.name:"Ajouter une option"}}</span>
        </div>
        <div class="form-group mb-5">
            <input type="text" class="form-control mdStl avenirMedium f18" id="name"
                placeholder="Entrez le nom de l'option" formControlName="name"
                [ngClass]="{'is-invalid':submitOption&&optioncontrols['name'].errors}">
        </div>
        <span class=" greyColor f18 avenirRegular">Définir la quantité maximale et minimale du produit</span>
        <div class="row no-gutters mt-4">
            <div class="col-md-5">
                <div class="row no-gutters">
                    <div class="form-group col-md-6 pr-3">
                        <input type="number" class="form-control mdStl avenirMedium f18" id="name" placeholder="Min"
                            formControlName="min" [ngClass]="{'is-invalid':submitOption&&optioncontrols['min'].errors}">
                    </div>
                    <div class="form-group col-md-6 pr-3">
                        <input type="number" class="form-control mdStl avenirMedium f18" id="name"
                            [ngClass]="{'is-invalid':submitOption&&optioncontrols['max'].errors}" placeholder="Max"
                            formControlName="max">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
              <p-inputSwitch (onChange)="swich_all_options($event.checked)" ></p-inputSwitch>
            </div>
        </div>
        <form [formGroup]="productForm" autocomplete="off">
            <div formArrayName="products" *ngFor="let fg of formgroup.controls; let i = index;let isLast=last">
                <div [formGroupName]="i" class="row no-gutters mt-4 align-items-center">
                    <div class="col-md-5 pr-3">
                        <input type="text" class="form-control mdStl avenirMedium f18" id="name"
                            placeholder="Nom de produit" formControlName="name"
                            [ngClass]="{'is-invalid':submitOption&& fg.get('name')?.errors}">
                    </div>
                    <div class="col-md-3 pr-2">
                        <input type="number" class="form-control mdStl avenirMedium f18" id="name" placeholder="Prix"
                            formControlName="price" [ngClass]="{'is-invalid': submitOption&& fg.get('price')?.errors}">
                    </div>
                    <div class="col-md-2 d-flex justify-content-center">
                        <p-inputSwitch formControlName="available"></p-inputSwitch>
                    </div>
                    <div class="col-md-2 d-flex justify-content-center">
                        <button class="btn p-0" (click)="deleteProduct(i)">
                            <img src="../../../../../assets/images/svg/delete..svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <div class="clickable main-color f20 avenirMedium mt-3" (click)="addProduct()">Ajouter un produit (+)</div>
        </form>
        <div class="d-flex justify-content-end pt-5">
            <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4"
                (click)="displayOptionModal=false">Annuler </button>
            <button class="btn green-back avenirMedium f16  text-white px-4"
                (click)="manageOption()">{{editOption?"Modifier":"Ajouter"}}</button>
        </div>
    </form>
</p-dialog>
<p-dialog [(visible)]="dispaly" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '800px'}" [showHeader]="false">
    <form autocomplete="off" [formGroup]="template_form" class="pt-4 mb-4">
        <div class="mb-3">
            <span class="avenirMedium f35 fw85 main-color">Cloner un menu</span>
        </div>
        <div class="row no-gutters align-items-center mb-2">
            <div class="col-md-5">
                <label class="avenirMedium f20" for="name"><span class="black">Choisir un template </span></label>
            </div>
            <div class="col-md-7">
                <p-autoComplete formControlName="store_id" [suggestions]="menus" appendTo="body" field="name"  placeholder="Chosir un menu template" (completeMethod)="search($event)"></p-autoComplete>

                <!-- <p-dropdown [options]="menus" optionValue="id" optionLabel="name" (onClear)="clear()" appendTo="body"
                    formControlName="global_menu" placeholder="Chosir un menu template" [showClear]="true"></p-dropdown> -->
            </div>
        </div>
        <span class="red-color f14 avenirRegular"> Attention:J'espère que vous savez ce que vous faites</span>
        <div class="d-flex justify-content-end  align-items-center mt-4">
            <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="dispaly=false">Annuler
            </button>
            <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="apply()">Envoyer</button>
        </div>
    </form>
</p-dialog>
<p-dialog [(visible)]="dispalyBarcode" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '800px'}" [showHeader]="false">
    <form autocomplete="off" [formGroup]="item_bulk_form" class="pt-4 mb-4">
        <div class="mb-3">
            <span class="avenirMedium f35 fw85 main-color">Modifier les prix par Code-barres</span>
        </div>
        <div class="form-group">
          <textarea formControlName="text" class="form-control mdStl black avenirRegular f18" placeholder="Barcode,Quantity,Price" id="Barecode" rows="5"></textarea>
        </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">
            <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="dispalyBarcode=false">Annuler
            </button>
            <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="edit_item_bulk()">Envoyer</button>
        </div>
    </form>
</p-dialog>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="'la section '+this.section?.name">
</app-confirmation>
<app-confirmation #deleteConfirmation (onConfirm)="confirmDelete($event)" [name]="'l\'option '+this.option?.name">
</app-confirmation>
<app-confirmation #deleteAllSections (onConfirm)="ConfirmdeleteAll('section')" [name]="'tous les sections'">
</app-confirmation>
<app-confirmation #deleteAllOptions (onConfirm)="ConfirmdeleteAll('option')" [name]="'tous les options'">
</app-confirmation>
<ng-template #noImg>
    <img src="../../../../../../assets/images/png/Frame 1322.png" alt="" class="img">
</ng-template>
