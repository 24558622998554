import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Output() onConfirm = new EventEmitter();
  @Input() name!:string
  displayModal:boolean=false
  constructor() { }

  ngOnInit(): void {    
  }
  confirm(){
    this.onConfirm.emit(true);
  }
}
