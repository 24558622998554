<div class="d-flex justify-content-end mb-3">
    <button class="btn avenirHeavy f20 br25 green-back text-white px-4" (click)="newReward()">Créer un programme</button>
</div>
<div *ngIf="isLoading;else notYetLoaded">
    <table class="table table-borderless">
        <thead>
            <tr>
                <th></th>
                <th>Nom</th>
                <th>Description</th>
                <th>Points</th>
                <th>Type</th>
                <th>Opération</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let reward of points?.data" class="lineHeight">

                <td>
                    <img *ngIf="reward.image;else templateName" class="rewardImg" [src]="reward.image" alt="">
                    <ng-template #templateName>
                        <img class="rewardImg" src="../../../../assets/images/png/Frame 1322.png" alt="">
                    </ng-template>
                </td>
                <td class="avenirRegular f18 black">
                    <div class="d-inline-grid lineHeight">
                        <span>{{reward.name}}</span><br>
                        <span dir="rtl">{{reward.name_ar}}</span>
                    </div>
                </td>
                <td class="avenirRegular f18 black">
                    <div class="d-inline-grid lineHeight">
                        <span>{{reward.description}}</span><br>
                        <span dir="rtl">{{reward.description_ar}}</span>
                    </div>
                </td>
                <td class="avenirRegular f18 fw85">
                    <span class="main-color ">{{reward.points.toLocaleString()}} </span>
                    <span class="black">PTS</span>
                </td>
                <td class="avenirRegular f18 black">
                    <div [ngSwitch]="reward.type">
                        <div *ngSwitchCase="'signup'">Sign up</div>
                        <div *ngSwitchCase="'instagram'">Suivi sur Instagram</div>
                        <div *ngSwitchCase="'facebook'">J’aime sur Facebook</div>
                        <div *ngSwitchCase="'complete_order'">Completer une commande</div>
                        <div *ngSwitchCase="'share_app'">Partage de l’application</div>
                        <div *ngSwitchCase="'rate_app'">Partager l’application</div>
                        <div *ngSwitchCase="'invite_friend'">Inviter un ami</div>
                        <div *ngSwitchCase="'reward'">Reward</div>
                    </div>
                </td>
                <td>
                    <button class="btn p-0 mr-2" (click)="addReward(reward)">
                        <img src="../../../assets/images/svg/Vector 8.svg" alt="">
                    </button>
                    <button class="btn p-0 " (click)="editReward(reward)">
                        <img src="../../../assets/images/svg/Icon - pen.svg"  alt="">
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class=" w-100 ">
        <div class="d-flex justify-content-end">
            <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
                [showFirstLastIcon]="true" [totalRecords]="points.meta.pagination.total_pages" #paginator
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
</div>
<p-dialog [(visible)]="display" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '800px'}" [showHeader]="false">
    <form autocomplete="off" [formGroup]="point_program_form" class="pt-4">
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="name"><span class="black">Nom </span> <span class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <input type="text" class="form-control mdStl avenirMedium f18" formControlName="name" id="name"
                    placeholder="Ex: 250DA">
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="name_ar"><span class="black">Nom arabe</span> <span class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <input type="text" class="form-control mdStl avenirMedium f18" formControlName="name_ar" id="name_ar" placeholder="الإسم بالعربية" dir="rtl">
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="points"><span class="black">points </span> <span class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <input type="text" class="form-control mdStl avenirMedium f18" id="points" formControlName="points"
                    placeholder="Ex: 90DA">
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="message"><span class="black">Description </span> <span
                        class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <textarea class="form-control mdStl avenirRegular f18" placeholder="Description"
                    formControlName="description" id="message" rows="5"></textarea>
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="message_ar"><span class="black">Description arabe</span> <span
                        class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <textarea class="form-control mdStl avenirRegular f18" placeholder="الوصف بالعربية"
                    formControlName="description_ar" id="message_ar" rows="5" dir="rtl"></textarea>
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20"><span class="black">Type </span> <span class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <p-dropdown formControlName="type" [options]="types" optionValue="value" appendTo="body"
                    optionLabel="label" placeholder="Type de programme" [showClear]="false"></p-dropdown>
            </div>
        </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">
            <!-- <button class="btn avenirMedium f18 text-white mr-3" (click)="deleteModal.displayModal=true"><img src="../../../assets/images/svg/delete..svg" alt=""></button> -->
            <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="display=false">Annuler
            </button>
            <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="manageReward()">Envoyer</button>
        </div>
    </form>
</p-dialog>
<p-dialog [(visible)]="displayReward" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '800px'}" [showHeader]="false">
    <form autocomplete="off" [formGroup]="reward_apply" class="pt-4">
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirMedium f20" for="name"><span class="black">Numéro de téléphone </span> <span class="red-color">
                        *</span></label>
            </div>
            <div class="col-md-9">
                <p-autoComplete placeholder="Ex. 06587985423" formControlName="phones" appendTo="body" field="phone" [suggestions]="clients"(completeMethod)="searchClients($event)"></p-autoComplete> 
            <span *ngIf="point_program?.type!=='reward'" class="avenirRegular f12 red-color">Vous ne pouvez pas ajouter un rewadr à cet programme de point </span>
            </div>
        </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">
            <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="displayReward=false">Annuler
            </button>
            <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="applyReward()">Envoyer</button>
        </div>
    </form>
</p-dialog>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100 ">
        <app-loading></app-loading>
    </div>
</ng-template>