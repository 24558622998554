import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { AppConfigService } from '../../services/app-config.service';
@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  countries: any = []
  country: any
  constructor(private http: HttpClient, private router: Router,private app_config:AppConfigService,private http_service:HttpService) { }

  ngOnInit(): void {
    this.get_countries()
  }
  get_countries() {
    this.countries = new Array()
    localStorage.getItem('country_code')?this.country=localStorage.getItem('country_code'):""
    this.http.get('https://api.foodbeeper.com/i/?l=1', { observe: "response" }).subscribe((res: any) => {
      if (res.status === 200) {
        res.body.forEach((element: any) => {
          this.countries.push(element)
          if (element.country_code === this.country) {
            this.country = element
            console.log(this.country);

          }
        });
      }
    })
  }
  select_country() {
    localStorage.removeItem('country_code')
    localStorage.setItem('country_code',this.country.country_code)
    this.app_config.loadAppConfig().then(()=>{
      this.http_service.endpoint = this.app_config.appConfig.api_url+"/api/";
      localStorage.removeItem('token')
      this.router.navigate(['/signIn'])
    })
  }
  cancel() {
  }
}
