<div *ngIf="is_loading;else splashscreen" class="row no-gutters h-100">
  <div class="col-md-3 col-lg-2">
    <div class="fixed-position">
      <div class="d-flex pt-4 pl-3 align-items-center">
        <img src="../../../assets/images/svg/FoodBeeper logo.svg" alt="" class="logo">
        <div class="d-flex flex-column ml-2">
          <span class="avenirBlack f25 main-color">Wajeez</span>
          <span class="avenirRegular f16 black font-weight-bold text-capitalize">{{user?.username}}</span>
          <span class="avenirRegular f14 grey font-weight-bold">{{config?.country_name}} </span>
        </div>
      </div>
      <ul class="nav flex-column mt-4">
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_view_analytics')">
          <a class="nav-link" [routerLink]="['/analytics']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Analytics.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Analytiques</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'">
          <a class="nav-link" [routerLink]="['/global-analytics']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Union.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Analytiques globale</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_other')">
          <a class="nav-link" [routerLink]="['/users']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-admin.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Utilisateurs</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_delivery_fees')">
          <a class="nav-link" [routerLink]="['/delivery-fees']" routerLinkActive="router-link-active" >
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/payments_black_24dp.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18"> Frais de livraison</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_hubs')">
          <a class="nav-link" [routerLink]="['/regions']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-region.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Régions</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_hubs')">
          <a class="nav-link" [routerLink]="['/hubs']" routerLinkActive="router-link-active" >
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-hub.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Hubs</span>
          </a>
        </li>
        <li class="nav-item mb-10">
          <a class="nav-link" [routerLink]="['/stores']" routerLinkActive="router-link-active"*ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_stores')">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-store.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Établissments</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_orders')">
          <a class="nav-link" [routerLink]="['/orders']" routerLinkActive="router-link-active" >
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-order.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Commandes</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_offers_promos')">
          <a class="nav-link" [routerLink]="['/notifications']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-broadcast.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Broadcast</span>
          </a>
        </li>
        <li class="nav-item mb-10"  *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_offers_promos')">
          <a class="nav-link" [routerLink]="['/banner']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-deals.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Banners</span>
          </a>
        </li>
        <li class="nav-item mb-10"  *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_offers_promos')">
          <a class="nav-link" [routerLink]="['/promo_code']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-promo.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Code promo</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_offers_promos')">
          <a class="nav-link" [routerLink]="['/rewards']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-rewards.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Rewards</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_hubs')">
          <a class="nav-link" [routerLink]="['/transactions']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Group8.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Transaction</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_stores')">
          <a class="nav-link d-flex align-items-center" [routerLink]="['/tickets']"
            routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/TicketSharp.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Requêtes</span>
            <div class="d-flex ml-auto badget">
              <span class="avenirMedium f12">{{st_count}}</span>
            </div>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_offers_promos')">
          <a class="nav-link d-flex align-items-center" [routerLink]="['/stories-management']"
            routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/icon_rounds.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Rounds</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'||user.permissions.includes('can_manage_other')">
          <a class="nav-link" [routerLink]="['/assistance']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-phone-phone.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Service d'assistance</span>
          </a>
        </li>
        <li class="nav-item mb-10" *ngIf="user?.type==='superadmin'">
          <a class="nav-link" [routerLink]="['/settings']" routerLinkActive="router-link-active">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Subtract.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18">Paramètres</span>
          </a>
        </li>
        <li class="nav-item mb-10 clickable"  (click)="confirm()">
          <a class="nav-link">
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/arrow-right-from-bracket-solid.svg#Group_3475"></use>
            </svg>
            <span class="avenirMedium f18 greyColor" >Se déconnetecter </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-9 col-lg-10 light-grey-back">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #splashscreen>
  <splash-screen></splash-screen>
</ng-template>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- <splash-screen></splash-screen> -->
