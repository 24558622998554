<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [style]="{width: '592px'}"
    [draggable]="false" [resizable]="false">
   <div class="">
    <span class="avenirMedium f40 fw85 main-color">Supprimer</span>
    <p class="avenirMedium f20 black pt-3">Êtes-vous sûr de vouloir supprimer {{name}} ?</p>
    <div class="d-flex justify-content-end pt-3">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="displayModal=false">Annuler </button>
        <button class="btn red-back avenirMedium f18 text-white px-4"(click)="confirm()" >Supprimer</button>
    </div>
   </div>
</p-dialog>