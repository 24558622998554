import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HomeComponent } from 'src/app/components/home/home.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-store-transactions',
  templateUrl: './store-transactions.component.html',
  styleUrls: ['./store-transactions.component.scss']
})
export class StoreTransactionsComponent implements OnInit {
  loading: boolean = false
  transactions: any;
  page: any
  gte: any
  lte: any
  display: boolean = false;
  TransactionForm!: FormGroup
  submit: boolean = false;
  store_id: any;
  store: any
  types: any = [{ label: "Collect Sold", value: "collect_sold" }, { label: "Crédit Sold", value: "credit_sold" }]
  constructor(public appconfig: AppConfigService, private datePipe: DatePipe, private messageService: MessageService, private router: Router, private route: ActivatedRoute, private httpService: HttpService, public home: HomeComponent, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(param => {
      this.route.parent?.params.subscribe((params: any) => {
        Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
        this.store_id = params.id
        let url = 'store_transactions?page=' + this.page + '&order_by=-created&filters=store:' + params.id + ";"
        if (param.get('gte') || param.get('lte')) {
          if (param.get('gte')) {
            url += 'created:gte:' + param.get('gte') + ";"
            this.gte = new Date(param.get('gte') || '')
          }
          if (param.get('lte')) {
            url += 'created:lse:' + param.get('lte') + ";"
            this.lte = new Date(param.get('lte') || '')
          }
        }
        this.getTransactions(url)
        this.getStore()
        this.TransactionForm = this.fb.group({
          amount: new FormControl("", [Validators.required, Validators.pattern("^-?([0]{1}\.{1}[0-9]+|[1-9]{1}[0-9]*\.{1}[0-9]+|[0-9]+|0)$"), Validators.min(0)]),
          type: new FormControl("", [Validators.required, Validators.maxLength(32)]),
          comment: new FormControl("", [Validators.maxLength(256)]),
        });
      })
    })
  }
  get f() { return this.TransactionForm.controls; }
  getTransactions(url: any) {
    this.httpService.get(url).subscribe(res => {
      if (res?.status == 200) {
        this.transactions = res.body
        this.loading = true
      }
    })
  }
  getStore() {
    this.httpService.get('store/' + this.store_id).subscribe(res => {
      if (res?.status == 200) {
        this.store = res.body.data
        console.log(this.store);

      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['../transactions'], { queryParams: { page: this.page }, queryParamsHandling: "merge", relativeTo: this.route });
  }
  add_transaction() {
    this.display = true;
    this.TransactionForm.reset();
    this.submit = false;
  }
  addTransaction() {
    this.submit = true;
    if (this.TransactionForm.invalid) { return }
    let form = this.TransactionForm.value
    form.store_id = this.store_id
    form.type === "collect_sold" ? form.amount = -form.amount : ""
    form.key = this.datePipe.transform(new Date(), 'YYYYMMddHHmmss') + this.generateRandomKey(18)
    this.httpService.post('store_transaction', this.TransactionForm.value).subscribe(res => {
      if (res?.status == 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Transaction a été modifié avec succès' });
        this.display = false;
        this.transactions.data.unshift(res.body.data)
      }
    })
  }
  generateRandomKey(length: any) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  filter() {
    let search: any = { gte: this.gte, lte: this.lte }
    let gte: any
    let lte: any
    search?.gte ? gte = new Date(search?.gte) : ""
    search?.lte ? lte = new Date(search?.lte) : ""
    let monthg = gte ? gte.getMonth() + 1 : ""
    let monthl = lte ? lte.getMonth() + 1 : ""
    gte ? gte = gte.getFullYear() + "-" + monthg + "-" + gte.getDate() : ""
    lte ? lte = lte.getFullYear() + "-" + monthl + "-" + lte.getDate() : ""
    search.gte = gte
    search.lte = lte
    search = { ...search, page: 1 }
    this.router.navigate(['../transactions'], { queryParams: search, queryParamsHandling: "merge", relativeTo: this.route });
  }
  resetFilter() {
    this.gte = null
    this.lte = null
    this.router.navigate(['../transactions'], { queryParams: { gte: null, lte: null }, queryParamsHandling: "merge", relativeTo: this.route });
  }
}
