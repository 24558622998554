import { Component, OnInit ,Input,Output,EventEmitter} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-special-opening-hours',
  templateUrl: './special-opening-hours.component.html',
  styleUrls: ['./special-opening-hours.component.scss']
})
export class SpecialOpeningHoursComponent implements OnInit {
  displayModal: boolean = false;
  opening_hours: any = []
  sceduleForm!: FormGroup;
  old_dates!: FormGroup;
  submit: boolean = false
  storeId: any;
  @Input() day: any
  @Output() onConfirm = new EventEmitter();

  constructor(private fb: FormBuilder,private httpService:HttpService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.sceduleForm = this.fb.group({
      date: new FormControl("", [Validators.required,]),
      scheduls: this.fb.array([
        this.addSkillFormGroup()
      ])
    })
    this.old_dates = this.fb.group({
      dates: this.fb.array([
      ])
    })

  }
  
  get controler (){return this.sceduleForm.controls}
  setExistingSkills(skillSets: any): FormArray {
    const formArray = new FormArray([]);
    skillSets.forEach((s: any) => {
      formArray.push(this.fb.group({
        dates: new Date(s.date),
      }));
      console.log(formArray);

    });

    return formArray;
  }
  get f() {
    return this.sceduleForm.get('scheduls') as FormArray;
  }
  get g() {
    return this.old_dates.get('dates') as FormArray;
  }
  addSkillFormGroup(): FormGroup {
    return this.fb.group({
      start_at: new FormControl("",  [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0|3][0]$")]),
      finish_at: new FormControl("",  [Validators.required, Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0|3][0]$")]),

    });
  }

  addCourseClick(): void {
    (<FormArray>this.sceduleForm.get('scheduls')).push(this.addSkillFormGroup());
  }
  deleteDate(index: number) {
    (<FormArray>this.sceduleForm.get('scheduls')).removeAt(index);
  }
  selectHour(event: any, index: number, fc: any) {
    const control: any = this.sceduleForm.get('scheduls');
    let date = new Date(event)
    var minutes = String(date.getMinutes()).padStart(2, "0")
    var hour = date.getHours();
    switch (fc) {
      case "start_at":
        console.log((<FormArray>control?.controls[index]));
        (<FormArray>control?.controls[index].patchValue({
          start_at: hour + ":" + minutes
        }))
        break;
      case "finish_at":
        (<FormArray>control?.controls[index].patchValue({
          finish_at: hour + ":" + minutes
        }))
        break;
      default:
        break;
    }
  }
  manageDate() {
    this.submit = true
    if (!this.sceduleForm.valid) {
      return
    }
    console.log(this.sceduleForm.value);
    let array = new Array();
    this.sceduleForm.value.scheduls.forEach((element: any) => {
      let start_at = new Date("01/01/2022 " + element.start_at).getTime()
      let finish_at = new Date("01/01/2022 " + element.finish_at).getTime()
      array.push(String(new Date(start_at).getHours()).padStart(2, "0") + ":" + String(new Date(start_at).getMinutes()).padStart(2, "0"));
      for (let i = 0; i <= 47; i++) {
        if (start_at !== finish_at) {
          start_at = start_at + 1800000
          array.push(String(new Date(start_at).getHours()).padStart(2, "0") + ":" + String(new Date(start_at).getMinutes()).padStart(2, "0"))
        }
      }
    });
    let date =new Date(this.sceduleForm.value.date) 
    let month = date.getMonth() + 1 
    // date.getFullYear() + "-" + month+ "-" + date.getDate() 
    let form = {day:this.day, date:date.getFullYear() + "-" + month+ "-" + date.getDate() , hours: array.join(";") }
    this.httpService.post("store_opening_hours/" + this.storeId, form).subscribe(data => {
      if (data?.status === 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Modifcation effectuée avec succès' });
        this.displayModal = false
        this.submit = false
        this.sceduleForm.reset()
        this.opening_hours.push(data.body.data)
        this.onConfirm.emit(this.opening_hours);
      }
    })
    console.log(form);

  }
  // confirm(){
  //   this.onConfirm.emit(true);
  // }
}
