import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from "@angular/forms";
import { AppConfigService } from 'src/app/services/app-config.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-usrs',
  templateUrl: './usrs.component.html',
  styleUrls: ['./usrs.component.scss']
})
export class UsrsComponent implements OnInit {
  users: any
  displayModal: boolean = false
  userForm!: FormGroup;
  edit: boolean = false
  user: any
  submit: boolean = false
  store_id: any
  loading: boolean = false
  constructor(private messageService: MessageService, private app_config: AppConfigService, private route: ActivatedRoute, private httpSErvice: HttpService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.route.parent?.params.subscribe((params: any) => {
      this.store_id = params.id
    })
    this.inialiseUserForm()
    this.getUsers()

  }
  getUsers() {
    this.httpSErvice.get("store_users?filters=store:" + this.store_id).subscribe((data: any) => {
      if (data?.status == 200) {
        this.users = data.body.data
        this.loading = true
        this.users.forEach((element: any) => {
          this.adjust_phone(element)
        });
      }
    })
  }
  adjust_phone(element: any) {
    if (element.phone.lastIndexOf(this.app_config.config.phone_int_prefix, 0) === 0) {
      element.phone = element.phone.replace(this.app_config.config.phone_int_prefix, this.app_config.config.phone_local_prefix)
    }
  }
  addUser() {
    this.inialiseUserForm()
    this.displayModal = true
    this.edit = false
  }
  get f() { return this.userForm.controls }
  editUser(user: any) {
    this.displayModal = true
    this.edit = true
    this.user = user
    this.userForm.patchValue({
      name: user.name,
      phone: user.phone,
      active: user.active,
      permissions: {
        can_create_orders: user.permissions.can_create_orders,
        can_edit_menu: user.permissions.can_edit_menu,
        can_edit_store_users: user.permissions.can_edit_store_users,
        can_create_free_items: user.permissions.can_create_free_items,
      }
    })

  }
  manageUser() {
    let form: any = {}
    this.submit = true
    if (this.userForm.invalid) {
      return
    }
    let permissions = this.userForm.value.permissions;
    var keys = Object.keys(permissions);
    var filtered = keys.filter(function (key) {
      return permissions[key]
    });
    form = { ... this.userForm.value }
    form.permissions = filtered.join(',')
    if (this.userForm.value.phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
      if (this.app_config.config.phone_local_prefix === "") {
        form.phone = this.app_config.config.phone_int_prefix + this.userForm.value.phone
      }
      else {
        form.phone = this.userForm.value.phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)
      }
    } else {
      form.phone = this.app_config.config.phone_int_prefix + this.userForm.value.phone
    }
    if (this.edit) {
      this.httpSErvice.post("store_user/" + this.user.id, form).subscribe((data: any) => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Utilisateur a été modifié avec succès' });
          this.displayModal = false
          Object.assign(this.user, data.body.data)
          this.adjust_phone(this.user)
        }
      })
    } else {
      this.httpSErvice.post("store_user", form).subscribe((data: any) => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Utilisateur a été ajouté avec succès' });
          this.displayModal = false
          console.log(data);
          this.adjust_phone(data.body.data)
          this.users.push(data.body.data)
        }
      })
    }
  }
  inialiseUserForm() {
    this.userForm = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.min(1), Validators.max(32)]),
      phone: new FormControl("", [Validators.required]),
      permissions: new FormGroup({
        can_create_orders: new FormControl(false),
        can_edit_menu: new FormControl(false),
        can_edit_store_users: new FormControl(false),
        can_create_free_items: new FormControl(false),
      }),
      active: new FormControl(false),
      store_id: new FormControl(this.store_id),
    });
  }
}
