import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ManageFilesComponent } from '../manage-files/manage-files.component';

@Component({
  selector: 'app-rewards-child',
  templateUrl: './rewards-child.component.html',
  styleUrls: ['./rewards-child.component.scss']
})
export class RewardsChildComponent implements OnInit {
  page: number = 1
  rewards: any
  reward: any
  regions: any
  categories: any
  searchForm!: FormGroup;
  @ViewChild('manageFiles') manageFiles!: ManageFilesComponent;

  type: any = [{ label: "Tous les types", value: "" }, { label: "Bon d'achat", value: "value_promo" }, { label: "Remise pourcentage", value: "percent_promo" }, { label: "Livraison gratuite", value: "free_delivery" }, { label: "Objet physique", value: "physical_object" }, { label: "Cadeau par téléphone", value: "gift_by_phone" }, { label: "Cadeau par email", value: "gift_by_email" }, { label: "Partenariat", value: "partner_promo" }]
  activeList: any = [{ label: "Tous", value: "null" }, { label: "Active", value: 1 }, { label: "Inactive", value: 0 }]

  constructor(
    public appconfig: AppConfigService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      region: new FormControl(""),
      category: new FormControl(""),
      name: new FormControl("",),
      type: new FormControl("",),
      active: new FormControl("",)
    });
    this.route.queryParamMap.subscribe(param => {
      param.get('page') ? this.page = Number(param.get('page')) : this.page = 1;
      let url: any = 'points_rewards/?order_by=-created&page=' + this.page
      if (param.get('hub') || param.get('region') || param.get('type') || param.get('active') || param.get('name') || param.get('category')) {
        url += '&filters='
        this.searchForm.patchValue({
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          category: param?.get('category') ? JSON.parse(param?.get('category') || '') : "",
          type: param.get('type'),
          active: param.get('active'),
          name: param.get('name'),
        })
      }
      if (param.get('active') !== "null") {
        param.get('active') ? url += 'active:' + param.get('active') + ";" : ""
      }
      param.get('name') ? url += 'name:sw:' + param.get('name') + ";" : ""
      param.get('region') ? url += 'region:' + param.get('region') + ";" : ""
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""
      param.get('category') ? url += 'category:' + param.get('category') + ";" : ""

      this.getRewards(url)
      this.getRegions()
      this.getCategories()
    })

  }
  getRewards(url: any) {
    this.httpService.get(url).subscribe(data => {
      if (data?.status == 200) {
        this.rewards = data.body
      }
    })
  }
  getCategories() {
    this.httpService.get("points_rewards_categories").subscribe(data => {
      if (data?.status == 200) {
        this.categories = data.body.data
      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/rewards'], { queryParams: { page: this.page } });
  }

  editFiles(reward: any) {
    this.manageFiles.displayModal = true
    this.reward = reward
    console.log(this.reward);
    this.manageFiles.imgUrls.length = 0
    this.manageFiles.selectedFiles.length = 0
  }

  onConfirm(event: any) {
    console.log(event);

    this.rewards.data.push(event)
  }

  getRegions() {
    this.httpService.get('regions').subscribe((res: any) => {
      if (res?.status == 200) {
        this.regions = res.body.data
        this.regions.unshift({ id: "null", name: "Toutes les régions" })
      }
    })
  }

  filter() {
    let search = this.searchForm.value
    search = { ...search, page: 1 }
    console.log(search);

    this.router.navigate(['/rewards'], { queryParams: search, queryParamsHandling: "merge" });
  }

  reset() {
    this.searchForm.reset()
    this.router.navigate(['/rewards']);
  }

  changeAvailability(reward: any) {
    this.httpService.post('points_reward/' + reward.id, { active: reward.active }).subscribe(data => {
      if (data?.status == 200) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Reward modifié avec succès' });
        Object.assign(reward, data.body.data)
      }
    })
  }
  changeSectionOrder() {
    let order: any = []
    let form: any
    this.rewards.data.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("points_rewards/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
}
