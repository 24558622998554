import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-phone-list',
  templateUrl: './phone-list.component.html',
  styleUrls: ['./phone-list.component.scss']
})
export class PhoneListComponent implements OnInit {
  regions: any
  constructor(private location: Location, private httpService: HttpService) { }

  ngOnInit(): void {
    this.getRegions()
  }
  back() {
    this.location.back()
  }
  getRegions() {
    this.httpService.get('regions').subscribe(res => {
      if (res?.status === 200) {
        // console.log(res);
        this.regions = res.body.data
        res.body.data.forEach((element:any) => {
          if (element.store_phones.length>0) {element.store_phoness = element.store_phones.split(',');}
          if (element.sms_phones.length>0) {element.sms_phoness = element.store_phones.split(',');}
          if (element.delivery_phones.length>0) {element.delivery_phoness = element.store_phones.split(',');}
          if (element.client_phones.length>0) {element.client_phoness = element.store_phones.split(',');}
        });
        console.log(res.body.data);
        
            }
    })
  }
}
