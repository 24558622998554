<div class="px-4">
    <div class="pt-6 pb-3">
        <span class="avenirRegular f72 main-color font-weight-bold">Rewards</span>
    </div>
    <div class="row mb-2">
        <div class="col-12 h-100 white-back br10">
            <ul class="nav">
                <li class="nav-item">
                    <a class="nav-link avenirRegular f18 black" [routerLink]="['rewards']"
                        routerLinkActive="router-link-active">
                        Rewards
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link avenirRegular f18 black" [routerLink]="['points-programs']"
                        routerLinkActive="router-link-active">
                        Programme des points
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link avenirRegular f18 black" [routerLink]="['transaction-points']"
                        routerLinkActive="router-link-active">
                        Points Transactions
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>