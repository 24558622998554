<div *ngIf="loading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Service d'assisstance</span>
    <div class="d-flex justify-content-end">
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="addService()">Ajouter une
        liste</button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless mt-4">
      <thead>
        <tr>
          <th>
            <svg class="icon mr-1">
              <use xlink:href="../../../assets/images/svg/Icon-region.svg#Group_3475"></use>
            </svg>
            Région
          </th>
          <th>
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon-store.svg#Group_3475"></use>
            </svg>
            Etablissements
          </th>
          <th>
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon - courier.svg#Group_3475"></use>
            </svg>
            Livreurs
          </th>
          <th>
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon - user.svg#Group_3475"></use>
            </svg>
            Clients
          </th>
          <th>
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Icon - simcard.svg#Group_3475"></use>
            </svg>
            SMS
          </th>
          <th>
            <svg class="icon mr-2">
              <use xlink:href="../../../assets/images/svg/Subtract.svg#Group_3475"></use>
            </svg>
            Opérations
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let region of regions">
          <td class="avenirRegular f18 black">{{region.name}}</td>
          <td class="avenirRegular f18 black">
            <ul>
              <li *ngFor="let store_phone of region.store_phones">{{store_phone}}</li>
            </ul>
          </td>
          <td class="avenirRegular f18 black">
            <ul>
              <li *ngFor="let store_phone of region.delivery_phones">{{store_phone}}</li>
            </ul>
          </td>
          <td class="avenirRegular f18 black">
            <ul>
              <li *ngFor="let client_phone of region.client_phones">{{client_phone}}</li>
            </ul>
          </td>
          <td class="avenirRegular f18 black">
            <ul>
              <li *ngFor="let sms_phone of region.sms_phones">{{sms_phone}}</li>
            </ul>
          </td>
          <td>
            <button class="btn p-0 mr-2" (click)="editService(region)">
              <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #noStores>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des établissements appuyez sur "Ajouter un
      établissement" pour en insérer un </p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [style]="{width: '675px'}" [draggable]="false"
  [resizable]="false">
  <form class="py-3" [formGroup]="productForm" autocomplete="off">
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20 black" for="name">Établissements </label>
      </div>
      <div class="col-md-9">
        <div class="row no-gutters mb-3" formArrayName="store_phones"
          *ngFor="let fg of store_phones.controls; let i = index;let isLast=last">
          <div class="col-md-12 position-relative" [formGroupName]="i">
            <input type="text" class="form-control mdStl avenirMedium f18" formControlName="phone"
              [ngClass]="{'is-invalid':submit&& fg.get('phone')?.errors}" id="name" placeholder="Etablissements ">
            <button *ngIf="i===0;else deleteTemplate" class="btn p-0 plusBtn" (click)="addPhoneNumber('store_phones')">
              <img src="../../../assets/images/svg/Group 1588.svg" alt="">
            </button>
            <ng-template #deleteTemplate>
              <button class="btn p-0 plusBtn" (click)="deleteAssistance('store_phones',i)">
                <img src="../../../assets/images/svg/Icon - X.svg" alt="">
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20 black" for="name">Livreurs </label>
      </div>
      <div class="col-md-9">
        <div class="row no-gutters mb-3" formArrayName="delivery_phones"
          *ngFor="let fg of delivery_phones.controls; let i = index;let isLast=last">
          <div class="col-md-12 position-relative" [formGroupName]="i">
            <input type="text" class="form-control mdStl avenirMedium f18" formControlName="phone"
              [ngClass]="{'is-invalid':submit&& fg.get('phone')?.errors}" id="name" placeholder="Livreurs ">
            <button *ngIf="i===0;else deleteTemplate" class="btn p-0 plusBtn"
              (click)="addPhoneNumber('delivery_phones')">
              <img src="../../../assets/images/svg/Group 1588.svg" alt="">
            </button>
            <ng-template #deleteTemplate>
              <button class="btn p-0 plusBtn" (click)="deleteAssistance('delivery_phones',i)">
                <img src="../../../assets/images/svg/Icon - X.svg" alt="">
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20 black" for="name">Clients </label>
      </div>
      <div class="col-md-9">
        <div class="row no-gutters mb-3" formArrayName="client_phones"
          *ngFor="let fg of client_phones.controls; let i = index;let isLast=last">
          <div class="col-md-12 position-relative" [formGroupName]="i">
            <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="phone"
              [ngClass]="{'is-invalid':submit&& fg.get('phone')?.errors}" placeholder="Clients ">
            <button *ngIf="i===0;else deleteTemplate" class="btn p-0 plusBtn" (click)="addPhoneNumber('client_phones')">
              <img src="../../../assets/images/svg/Group 1588.svg" alt="">
            </button>
            <ng-template #deleteTemplate>
              <button class="btn p-0 plusBtn" (click)="deleteAssistance('client_phones',i)">
                <img src="../../../assets/images/svg/Icon - X.svg" alt="">
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20 black" for="name">SMS </label>
      </div>
      <div class="col-md-9">
        <div class="row no-gutters mb-3" formArrayName="sms_phones"
          *ngFor="let fg of sms_phones.controls; let i = index;let isLast=last">
          <div class="col-md-12 position-relative" [formGroupName]="i">
            <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="phone"
              [ngClass]="{'is-invalid':submit&& fg.get('phone')?.errors}" placeholder="SMS ">
            <button *ngIf="i===0;else deleteTemplate" class="btn p-0 plusBtn" (click)="addPhoneNumber('sms_phones')">
              <img src="../../../assets/images/svg/Group 1588.svg" alt="">
            </button>
            <ng-template #deleteTemplate>
              <button class="btn p-0 plusBtn" (click)="deleteAssistance('sms_phones',i)">
                <img src="../../../assets/images/svg/Icon - X.svg" alt="">
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pt-3">
      <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="cancel()">Annuler </button>
      <button class="btn green-back avenirRegular f16 text-white px-4" (click)="manageService()">Envoyer</button>
    </div>
  </form>
</p-dialog>