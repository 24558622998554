<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Frais de livraison</span>
    <div class="d-flex justify-content-end"
      *ngIf="home?.user?.profile?.permissions.includes('can_manage_delivery_fees')||home?.user?.type==='superadmin'">
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="addDeliverFee()">Ajouter un prix de
        livraison</button>
    </div>
  </div>
  <div class="table-responsive">
    <table *ngIf="regions.length>0;else noDeliveryFees" class="table table-borderless mb-0">
      <thead>
        <tr>
          <th>Région</th>
          <th>Temps de livraison</th>
          <th>Prix de base</th>
          <th>Prix promotionnel </th>
          <th
            *ngIf="home?.user?.profile?.permissions.includes('can_manage_delivery_fees')||home?.user?.type==='superadmin'">
            Opérations</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let region of regions">
          <ng-container *ngIf="region?.delivery?.length>0">
            <tr>
              <td class="avenirMedium f20 black" [rowSpan]="region.delivery.length+1">{{region.name}}</td>
              <td class="avenirMedium f20 black">{{region.delivery[0].delivery_time}} minutes</td>
              <td class="avenirMedium f20 black">{{region.delivery[0].base_price}} {{home.config.langs[0].currency_symbol}}</td>
              <td class="avenirMedium f20 black">{{region.delivery[0].price}}  {{home.config.langs[0].currency_symbol}}</td>
              <td
                *ngIf="home?.user?.profile?.permissions.includes('can_manage_delivery_fees')||home?.user?.type==='superadmin'"
                class="avenirMedium f20 black">
                <button class="btn p-0 mr-2" (click)="editDeliveryFees(region?.delivery[0])">
                  <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                </button>
              </td>
            </tr>
            <tr *ngFor="let item of region?.delivery ;let index=index; let last =last" [ngClass]="{'is_last': last}">
              <ng-container *ngIf="index!=0">
                <td class="avenirMedium f20 black">{{item.delivery_time}} minutes</td>
                <td class="avenirMedium f20 black">{{item.base_price}}  {{home.config.langs[0].currency_symbol}}</td>
                <td class="avenirMedium f20 black">{{item.price}}  {{home.config.langs[0].currency_symbol}}</td>
                <td
                  *ngIf="home?.user?.profile?.permissions.includes('can_manage_delivery_fees')||home?.user?.type==='superadmin'"
                  class="avenirMedium f20 black">
                  <button (click)="editDeliveryFees(item)" class="btn p-0 mr-2">
                    <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                  </button>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<p-dialog [(visible)]="display" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '800px'}" [showHeader]="false">
  <form autocomplete="off" [formGroup]="deliveryFeeform">
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Prix de base</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control mdStl avenirMedium f18" formControlName="base_price" id="name"
          placeholder="Ex: 250DA">
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Prix promotionnel</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="price"
          placeholder="Ex: 90DA">
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Temp de livraison </span> <span
            class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <p-dropdown [options]="deliveryTimes" formControlName="delivery_time" optionValue="value" optionLabel="name"
          placeholder="Temp de livraison" [showClear]="false"></p-dropdown>
      </div>
    </div>
    <div *ngIf="!edit" class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Région </span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <p-dropdown [options]="regions" formControlName="region_id" optionValue="id" optionLabel="name"
          placeholder="Région" [showClear]="false"></p-dropdown>
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-5">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Date d'expiration</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <p-calendar inputId="basic" formControlName="promo_until" appendTo="body" dateFormat="dd/mm/yy" class="avenirMedium"></p-calendar>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-8 d-flex justify-content-between  align-items-center px-3 py-2 bnr">
        <span class="avenirMedium f18 ursopct black"> Activer</span>
        <p-inputSwitch formControlName="active"></p-inputSwitch>
      </div>
    </div>
    <div class="d-flex justify-content-end  align-items-center mt-4">
      <button class="btn avenirMedium f18 text-white mr-3" (click)="deleteModal.displayModal=true"><img
          src="../../../assets/images/svg/delete..svg" alt=""></button>
      <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="hideDialog()">Annuler </button>
      <button class="btn green-back  avenirMedium f18 text-white px-4"
        (click)="manageDeliveryFees()">{{edit?"Modifier":"Ajouter"}}</button>
    </div>
  </form>
</p-dialog>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]=deliveryFee?.delivery_time></app-confirmation>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noDeliveryFees>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des Frais de livraison appuyez sur "Ajouter un
      prix de livraison" pour le insérer un </p>
  </div>
</ng-template>
