import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardGuard implements CanActivate {
  constructor(private http: HttpService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let permission = route.data['permission'] as string
    return this.check_user_access(permission)

  }
  check_user_access(permission_user: any) {
    return this.http.get('authenticated_user').pipe(map((res: any) => {
      if (res.body.data.type === "superadmin") {
        return true
      } else {
        let permission = res.body.data.profile.permissions.split(',')
        console.log(permission_user);
        console.log(permission);

        if (permission.includes(permission_user)) {
          return true
        } else {
          this.router.navigate(['blank-page'])
          return false;
        }
      }

    }))
  }
}
