import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { HomeComponent } from '../home/home.component';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit {
  page: number = 1
  promoCodes: any
  isLoading: boolean = false
  promo_types: any = [{ value: "percentage", name: 'Poucentage ' }, { value: "value", name: 'Valeur' }, { value: "free_delivery", name: 'Gratuit' }]
  status: any = [{ value: 1, name: 'Active' }, { value: 0, name: 'Inactive' }]
  expiration: any = [{ value: "expired", name: 'Expiré' }, { value: "not_expired", name: 'Non expiré' }, { value: "no_expiration", name: 'Sans expiration' }]
  displayFilter: boolean = false
  searchForm!: FormGroup;
  apply_codeForm!: FormGroup;
  apply_code_modal: boolean = false
  submit: boolean = false
  promo_code: any
  constructor(public home: HomeComponent,
     private httpService: HttpService,
     private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private messageService: MessageService,
      private app_config: AppConfigService
    ) { }
  ngOnInit(): void {
    this.searchForm = this.fb.group({
      expiration: new FormControl("",),
      promo_type: new FormControl(""),
      active: new FormControl("",),
      gte: new FormControl("",),
      lte: new FormControl(""),
      code: new FormControl("",),
      min_price: new FormControl("",),
    });
    this.apply_codeForm = this.fb.group({
      client_phone: new FormControl("", [Validators.required]),
    });
    this.route.queryParamMap.subscribe(param => {
      this.page = param.get('page') ? Number(param.get('page')) : 1
      let url: any = 'promo_codes?order_by=-created&page=' + this.page
      this.searchForm.patchValue({
        active: param?.get('active') ? JSON.parse(param?.get('active') || "") : "",
        expiration: param.get('expiration'),
        promo_type: param.get('promo_type'),
        code: param.get('code'),
        min_price: param.get('min_price'),
        gte: param.get('gte') ? new Date(param.get('gte') || '') : '',
        lte: param.get('lte') ? new Date(param.get('lte') || '') : '',
      })
      if (param.get('expiration') || param.get('promo_type') || param.get('active') || param.get('gte') || param.get('lte') || param.get('code') || param.get('min_price')) {
        url += '&filters='
        if (param.get('expiration')) {
          let current: any = new Date()
          let month = current.getMonth() + 1
          let today = current.getFullYear() + "-" + month + "-" + current.getDate()
          switch (param.get('expiration')) {
            case "not_expired":
              let day = current.getDate() + 1
              let next_day = current.getFullYear() + "-" + month + "-" + day
              url += "expires:gte:" + today + ";created:ls:" + next_day + ";"
              break;
            case "expired":
              url += "expires:lse:" + today + ";"
              break;
            case "no_expiration":
              url += "expires:null;"
              break;
          }
        }
        if (param.get('gte') || param.get('lte')) {
          param.get('gte') ? url += 'created:gte:' + param.get('gte') + ";" : ""
          param.get('lte') ? url += 'created:lse:' + param.get('lte') + ";" : ""
        }
        param.get('active') ? url += 'active:' + param.get('active') + ";" : ""
        param.get('promo_type') ? url += 'type:' + param.get('promo_type') + ";" : ""
        param.get('code') ? url += 'code:' + param.get('code') + ";" : ""
        param.get('min_price') ? url += 'min_price:' + param.get('min_price') + ";" : ""
      }
      this.getPromoCode(url)
    })
  }
  get f() { return this.apply_codeForm.controls; }
  getPromoCode(url: any) {
    this.httpService.get(url).subscribe((data: any) => {
      if (data?.status == 200) {
        this.isLoading = true
        this.promoCodes = data.body
        data.body.data.forEach((element: any) => {
          element.limited_stores = Object.values(element.limit_stores);
          element.limited_regions = Object.values(element.limit_regions);
        });
      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/promo_code'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  collapse() {
    this.displayFilter = !this.displayFilter
    let doc = document.getElementById('filter') as HTMLHeadElement
    doc.style.paddingBottom = '1.5rem'
    this.displayFilter === true ? doc.style.paddingBottom = "1.5rem " : doc.style.paddingBottom = "0.375rem "
    this.displayFilter === false ? doc.style.borderBottomLeftRadius = "0.25rem " : doc.style.borderBottomLeftRadius = "0"
    this.displayFilter === false ? doc.style.borderBottomRightRadius = "0.25rem " : doc.style.borderBottomRightRadius = "0"
  }
  filter() {
    let search = this.searchForm.value
    let gte: any
    let lte: any
    search?.gte ? gte = new Date(search?.gte) : ""
    search?.lte ? lte = new Date(search?.lte) : ""
    let monthg = gte ? gte.getMonth() + 1 : ""
    let monthl = lte ? lte.getMonth() + 1 : ""
    gte ? gte = gte.getFullYear() + "-" + monthg + "-" + gte.getDate() : ""
    lte ? lte = lte.getFullYear() + "-" + monthl + "-" + lte.getDate() : ""
    search.gte = gte
    search.lte = lte
    search = { ...search, page: 1 }
    this.router.navigate(['/promo_code'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.searchForm.reset()
    this.router.navigate(['/promo_code']);
  }
  show_modal(promo_code: any) {
    this.apply_code_modal = true
    this.promo_code = promo_code
  }
  apply_promo_code(form:any) {
    this.submit = true
    if (this.apply_codeForm.invalid) {
      return;
    }
    if (this.apply_codeForm.value.client_phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
      form.client_phone = this.apply_codeForm.value.client_phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)
    }else{
      this.apply_codeForm.value.client_phone = this.app_config.config.phone_int_prefix + this.apply_codeForm.value.client_phone
    }
    this.httpService.post('apply_promo_code/'+this.promo_code.id, form).subscribe((data: any) => {
      if (data?.status == 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Le code promo a été bien appliqué au client ' });
        this.apply_code_modal = false
      }
    })
  }
}
