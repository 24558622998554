<p-dialog [showHeader]="false" class="customModal" [(visible)]="displayModal" [modal]="true" [draggable]="false"
    [resizable]="false" [breakpoints]="{ '1440px': '100vw'}" [style]="{width: '1440px',height:'100%'}"
    (onShow)="setCenter(gmap.getMap(),hub.location)">
    <div class="row no-gutters">
        <div class="col-md-7 position-relative">
            <p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'90vh'}"
                (click)="_handleMapClick($event)" (onMapClick)="handleMapClick($event,gmap.getMap())"
                (onOverlayClick)="handleOverlayClick($event)"></p-gmap>
            <div id="form" class="form">
                <form *ngIf="dispay ||add" [formGroup]="deliveryZoneform" autocomplete="off">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Name"
                        formControlName="name" [ngClass]="{'is-invalid': submit && f['name'].errors}">
                    <div class="d-flex justify-content-end pt-3">
                        <button *ngIf="edit" class="btn p-0 mr-2" (click)="deleteZone()">
                            <img src="../../../../assets/images/svg/delete..svg" alt="">
                        </button>
                        <button class="btn avenirMedium f14 text-white dp-grey-back mr-2"
                            (click)="cancel()">Annuler</button>
                        <button class="btn green-back avenirMedium f14 text-white"
                            (click)="saveZone()">{{edit?"Modifier":"Ajouter"}}</button>
                    </div>
                </form>
            </div>

        </div>
        <div class="col-md-5 d-flex justify-content-center flex-column align-items-end">
            <div class="pr-3">
                <button *ngIf="!edit" class="btn avenirRegular f20 br25 green-back text-white px-4 mr-2"
                    (click)="addZone()">Ajouter une
                    zone</button>
                <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4 br25"
                    (click)="displayModal=false;this.cancel()">Annuler
                </button>
            </div>
            <table class="table table-borderless mt-3">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Disponiblité</th>
                        <th>Couriers</th>
                        <th>Opérations</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let zone of zones;let index=index">
                        <td class="avenirMedium f18 black">{{zone.name}}</td>
                        <td class="avenirMedium f18 black">
                            <button class="btn p-0" (click)="switchAvailability(zone)">
                                <img *ngIf="zone.available;else notAvailable"
                                    src="../../../../assets/images/svg/Group1069.svg" alt="">
                            </button>
                            <ng-template #notAvailable>
                                <img src="../../../../assets/images/svg/Group 1055.svg" alt="">
                            </ng-template>
                        </td>
                        <td class="avenirMedium f16 black">{{zone.delivery_men}}</td>
                        <td class="avenirMedium f18 black">
                            <button class="btn p-0 mr-2" (click)="editZone(zone)">
                                <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="position-absolute editBtns" *ngIf="dispay ||add">
          <button class="btn green-back avenirMedium f14 text-white mr-2" (click)='edit_delivery_zone()'>Modifier les
              zones de livraison </button>
          <button class="btn green-back avenirMedium f14 text-white" (click)="edit_sore_zone()">Modifier les zones des
              stores </button>
      </div>
    </div>
</p-dialog>
