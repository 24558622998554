<div class="px-4">
  <div class="pt-6 pb-3">
      <span class="avenirRegular f72 main-color font-weight-bold">Requêtes</span>
  </div>
  <div class="row mb-2">
      <div class="col-12 h-100 white-back br10">
          <ul class="nav">
              <li class="nav-item">
                  <a class="nav-link avenirRegular f18 black d-flex align-items-start" [routerLink]="['store-tickets']"
                      routerLinkActive="router-link-active">
                      Requêtes Établissments <span class="f10 badge ml-1">{{store_tickets_count}}</span>
                  </a>
              </li>
              <li class="nav-item">
                  <a class="nav-link avenirRegular f18 black d-flex align-items-start" [routerLink]="['client-tickets']"
                      routerLinkActive="router-link-active">
                      Requêtes Clients <span class="f10 badge ml-1">{{client_tickets_count}}</span>
                  </a>
              </li>
          </ul>
      </div>
  </div>
  <div class="row">
      <div class="col-md-12">
          <router-outlet></router-outlet>
      </div>
  </div>
</div>
