<div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Hubs</span>
    <div *ngIf="home?.user?.profile?.permissions.includes('can_manage_hubs')||home?.user?.type==='superadmin'"
        class="d-flex justify-content-end align-items-center">
        <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="addHub()">Ajouter un
            hub</button>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-borderless mt-4 mb-0">
        <thead>
            <tr>
                <th class="centerth">Région</th>
                <th class="centerth">Hub</th>
                <th class="centerth">Zones</th>
                <th class="centerth">Active</th>
                <th class="centerth">Opérations</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let hub of hubs">
                <td class="centertd avenirMedium f18 black">{{hub.region.name}}</td>
                <td class="centertd avenirMedium f18 black">{{hub.name}}</td>
                <td class="centertd avenirMedium f16 main-color " (click)='dispalyZone(hub)'><span
                        class="clickable">Voir les zones</span></td>
                <td class="centertd avenirMedium f18 black">
                    <p-inputSwitch [(ngModel)]="hub.active" [ngModelOptions]="{standalone:true}"
                        class="d-flex justify-content-center" (onChange)="changeAvailability(hub)"></p-inputSwitch>
                </td>
                <td class="centertd avenirMedium f16 black">
                    <button class="btn p-0 mr-2"
                        *ngIf="home?.user?.profile?.permissions.includes('can_manage_hubs')||home?.user?.type==='superadmin'">
                        <img src="../../../assets/images/svg/Icon - pen.svg" (click)="editHub(hub)" alt="">
                    </button>
                    <button class="btn p-0 mr-2" (click)="go_to_hub(hub)" routerLinkActive="router-link-active">
                        <img src="../../../assets/images/svg/Vector.svg" alt="">
                    </button>
                    <button class="btn p-0"[routerLink]="[hub.id]"   routerLinkActive="router-link-active">
                        <i class="pi pi-users" style="color: #acacac;"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="displayModal" [modal]="true" [draggable]="false"
    [resizable]="false" [breakpoints]="{ '1240px': '100vw'}" [style]="{width: '1240px'}"
    (onShow)="setCenter(gmap.getMap())">
    <div class="row no-gutters h-100">
        <div class="col-md-6">
            <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}" #gmap
                (onOverlayDragEnd)="mapDRagEnd($event)"></p-gmap>
        </div>
        <div class="col-md-6 px-3 py-4">
            <form [formGroup]="hubForm" autocomplete="off" class="h-100 d-flex flex-column">
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="name"
                        placeholder="Nom du hub" [ngClass]="{'is-invalid': submit&&f['name'].errors}">
                </div>
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" formControlName="contact_phone"
                        [ngClass]="{'is-invalid': submit&&f['contact_phone'].errors}" id="name" placeholder="Téléphone">
                </div>
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Contact"
                        [ngClass]="{'is-invalid': submit&&f['contact_name'].errors}" formControlName="contact_name">
                </div>
                <div class="form-group mb-5">
                    <input type="number" class="form-control mdStl avenirMedium f18" id="name"
                        placeholder="Réduction du prix de base"
                        [ngClass]="{'is-invalid': submit&&f['base_price_reduction'].errors}"
                        formControlName="base_price_reduction">
                </div>
                <div class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Addresse"
                        formControlName="address" [ngClass]="{'is-invalid': submit&&f['address'].errors}">
                </div>
                <div *ngIf="!edit" class="form-group mb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" formControlName="username" id="name"
                        [ngClass]="{'is-invalid': submit&&f['username'].errors}" placeholder="Nom de l’utilisateur">
                </div>
                <div *ngIf="!edit" class="form-group mb-5">
                    <input type="password" class="form-control mdStl avenirMedium f18" id="name"
                        [ngClass]="{'is-invalid': submit&&f['password'].errors}" placeholder="Mot de passe"
                        formControlName="password">
                </div>
                <div class="form-group mb-4">
                    <p-dropdown appendTo="body" [options]="regions" optionLabel="name" placeholder="Région"
                        [ngClass]="{'is-invalid': submit&&f['region_id'].errors}" formControlName="region_id"
                        optionValue="id" [showClear]="false"></p-dropdown>
                </div>
                <div class="d-flex justify-content-between  align-items-center mb-4 px-3 py-2 bnr">
                    <span class="avenirMedium f18 ursopct black"> Livraison</span>
                    <p-inputSwitch formControlName="delivery"></p-inputSwitch>
                </div>
                <div class="d-flex justify-content-between  align-items-center mb-5 px-3 py-2 bnr">
                    <span class="avenirMedium f18 ursopct black"> Commission de livraison</span>
                    <p-inputSwitch formControlName="delivery_commission"></p-inputSwitch>
                </div>
                <div class="d-flex justify-content-end  align-items-center ">
                    <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
                        (click)="displayModal=false">Annuler</button>
                    <button class="btn green-back px-5 avenirMedium f18 text-white"
                        (click)="manageHub()">{{edit?"Modifier":"Ajouter"}}</button>
                </div>
            </form>
        </div>
    </div>
</p-dialog>
<app-zone #zone></app-zone>