import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { DatePipe, Location } from '@angular/common';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MessageService } from 'primeng/api';
import { ManageItemsComponent } from '../manage-items/manage-items.component';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  section_items: any;
  isLoading: boolean = false;
  item: any;
  store_id: any
  @ViewChild('manage_items') manage_items!: ManageItemsComponent
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent
  constructor(private route: ActivatedRoute, private httpService: HttpService, private location: Location, public appconfig: AppConfigService, private datePipe: DatePipe, private messageService: MessageService) { }
  ngOnInit(): void {
    this.route?.params.subscribe((params: any) => {
      this.store_id = params.id;
      this.getSections(params.id);
    })
  }
  getSections(storeId: any) {
    this.httpService.get("menu/" + storeId + "/sections?menu_items_stats=1").subscribe(data => {
      if (data?.status === 200) {
        this.isLoading = true;
        this.section_items = data.body.data
        this.section_items.forEach((element: any) => {
          console.log(element.section_id);
          this.httpService.get("menu/" + storeId + "/section/" + element.section_id).subscribe(data => {
            if (data?.status === 200) {
              console.log(data.body.data);
              element.section = data.body.data
            }
          })
        });
      }
    })
  }
  back() {
    this.location.back();
  }
  change_availibility(item: any) {
    this.item = item;
    if (!item.available) {
      this.manage_items.available_at_modal = true;
    }
    else {
      this.httpService.post('menu/' + this.store_id + '/item/' + this.item.id, { available: this.item.available }).subscribe(data => {
        if (data?.status === 200) {
          this.manage_items.available_at_modal = false;
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + data.body.data.name + ' a été modifié avec succès' });
        }
      })
    }
  }
  editItem(item: any) {
    this.manage_items.editItem(item)
    this.manage_items.edit = true;
    this.item = item
  }
  deleteItem(item: any) {
    this.item = item
    this.deleteModal.displayModal = true;
  }
}
