import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  userForm!: FormGroup;
  submit:boolean=false
  constructor(private fb: FormBuilder,private httpService:HttpService,private router:Router,public app_config:AppConfigService) { }

  ngOnInit(): void {
    console.log(this.app_config);

    this.userForm = this.fb.group({
      username: new FormControl("",[ Validators.required,]),
      password: new FormControl("", [Validators.required,Validators.minLength(8)]),
    });
  }
  get f (){return this.userForm.controls}
login(){
  this.submit=true
  this.httpService.post('authenticate',this.userForm.value).subscribe(res=>{
   if (res?.status === 200) {
    console.log(res);
    localStorage.setItem('token',res.body.token);
    this.router.navigate(['/']);
   }
  })
}
}
