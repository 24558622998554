import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-store-tickets',
  templateUrl: './store-tickets.component.html',
  styleUrls: ['./store-tickets.component.scss']
})
export class StoreTicketsComponent implements OnInit {
  store_ticket_form!: FormGroup
  submit: boolean = false
  store_id: any
  types: any = [{ label: "Modifier le menu", value: 'update_menu' }, { label: "Modifier le profil", value: 'update_profile' },
  { label: "Modifier les heures d'ouvertures ", value: 'update_hours' },
  { label: "Réclamation établissement", value: 'store_complaint' }, { label: "Réclamation client", value: 'client_complaint' },
  { label: "Injoignable", value: 'unreachable' },
  { label: "Demande", value: 'request' }]
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private http_service: HttpService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.parent?.params.subscribe((params: any) => {
      this.store_ticket_form = this.fb.group({
        type: new FormControl("", Validators.required),
        description: new FormControl("", [Validators.required, Validators.maxLength(256)]),
        store_id: new FormControl(params?.id),
      })
      this.store_id = params?.id
    });
  }
  get f() { return this.store_ticket_form.controls }
  create_store_ticket() {
    this.submit = true
    console.log(this.store_ticket_form.value);
    if (this.store_ticket_form.invalid) {
      return
    }
    this.http_service.post("store_tickets", this.store_ticket_form.value).subscribe(res => {
      if (res?.status === 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'requête a été crée avec succès' });
      }
    })
  }
}
