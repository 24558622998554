<div *ngIf="is_loading;else notYetLoaded">
  <div class="px-3">
    <div class="d-flex align-items-center py-5 justify-content-between">
      <div>
        <button class="btn p-0" (click)="back()">
          <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
        </button>
        <span class="f40 avenirMedium main-color fw85">Staff</span>
      </div>
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="add_staff()">Ajouter un
        staff</button>
    </div>
    <div class="d-flex my-4">
      <div class="form-group mb-0 mr-3">
        <input type="text" class="form-control mdStl avenirRegular f16" id="name" [(ngModel)]="search"
          placeholder="Rechercher par nom ou  par téléphone" autocomplete="off">
      </div>
      <p-dropdown optionValue="value" class="mr-3" [options]="status" [(ngModel)]="active" optionLabel="label"
        placeholder="Statut" [showClear]="false" (onChange)="filter()"></p-dropdown>
      <p-dropdown optionValue="value" class="mr-3" [options]="types" [(ngModel)]="type" optionLabel="label"
        placeholder="Statut" [showClear]="false" (onChange)="filter()"></p-dropdown>
      <button class="text-white btn avenirRegular f16 main-color white-back mr-3 br25"
        (click)="reset()">Réintianiliser</button>
    </div>
  </div>
  <div *ngIf="staff?.data.length>0;else no_staff" class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th></th>
          <th>Nom</th>
          <th>Téléphone</th>
          <th>Nom d'utilisateur</th>
          <th style="width: 12rem">Régions</th>
          <th>Vehicle</th>
          <th>Active</th>
          <th style="width: 1rem">Collectionneur</th>
          <th>Opération</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let staf of staff?.data | searchFilter: search">
          <td>
            <img *ngIf="staf.image;else noImg" src="{{staf.image}}" class="staff_img" alt="">
            <ng-template #noImg>
              <img src="../../../assets/images/png/Frame 1322.png" alt="">
            </ng-template>
          </td>
          <td class="avenirMedium f18 black">
            {{staf.name}}
          </td>
          <td class="avenirMedium f18 black">
            {{staf.phone}}
          </td>
          <td class="avenirMedium f18 black">
            {{staf?.username}}
          </td>
          <td class="avenirMedium f18 black">
            <span *ngFor="let region of staf.regions;let is_last=last ">
              {{region.name}}{{is_last ? '' : ','}}
            </span>
          </td>
          <td class="avenirMedium f18 black">
            <div [ngSwitch]="staf.vehicle">
              <div *ngSwitchCase="'moto'">
                <img src="../../../../assets/images/png/fast-delivery.png" class="staff_vehicle" alt="">
              </div>
              <div *ngSwitchCase="'voiture'">
                <img src="../../../../assets/images/png/car.png" class="staff_vehicle" alt="">

              </div>
              <div *ngSwitchCase="'velo'">
                <img src="../../../../assets/images/png/icons8-bicycle-30.png" class="staff_vehicle" alt="">

              </div>
              <div *ngSwitchDefault></div>
            </div>
          </td>
          <td class="avenirMedium f18 black">
            <p-inputSwitch [(ngModel)]="staf.active" (onChange)="changeStatus(staf)"></p-inputSwitch>
          </td>
          <td class="avenirMedium f18 black">
            <p-inputSwitch [(ngModel)]="staf.collector" (onChange)="changeStatus(staf)"></p-inputSwitch>
          </td>
          <td class="avenirMedium f18 black">
            <button class="btn p-0 mr-2" (click)="edit_staff(staf)">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
            <button *ngIf="staf.type=='hub_agent'" class="btn p-0 mr-2 " (click)="editHubAgent(staf)">
              <img src="../../../assets/images/svg/icons8-services.svg" alt="" class="gearWidth">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="display" [modal]="true" [draggable]="false"
  [resizable]="false" [breakpoints]="{ '124px': '100vw'}" [style]="{width: '800px'}">
  <div class="px-3">
    <form autocomplete="off" [formGroup]="staff_form">
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="phone"><span class="black">Nom</span> <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="name"
            [ngClass]="{'is-invalid': submit&&f['name'].errors}" placeholder="Nom">
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="username"><span class="black">Nom d'utilisateur</span> <span
              class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control mdStl avenirMedium f18" id="username" formControlName="username"
            [ngClass]="{'is-invalid': submit&&f['username'].errors}" placeholder="Nom d'utilisateur">
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="password"><span class="black"> Mot de passe</span> <span
              class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <input type="password" class="form-control mdStl avenirMedium f18" id="password" formControlName="password"
            [ngClass]="{'is-invalid': submit&&f['password'].errors}" placeholder="Mot de passe">
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="confirmpassword"><span class="black">Confirmer le Mot de passe</span>
            <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <input type="password" class="form-control mdStl avenirMedium f18" id="confirmpassword"
            formControlName="confirmpassword" [ngClass]="{'is-invalid': submit&&f['confirmpassword'].errors}"
            placeholder="Mot de passe">
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="phone"><span class="black">Téléphone</span> <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control mdStl avenirMedium f18" id="phone" formControlName="phone"
            [ngClass]="{'is-invalid': submit&&f['phone'].errors}" placeholder="Ex . 0657889125">
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="type"><span class="black">Type</span> <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <p-dropdown optionValue="value" formControlName="type" appendTo="body" [options]="types" optionLabel="label"
            placeholder="Type de staff" [ngClass]="{'is-invalid': submit&& f['type'].errors}" [showClear]="false">
          </p-dropdown>
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4" *ngIf="staff_form.get('type')?.value==='delivery'">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="phone"><span class="black">Véhicule</span> <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <p-dropdown optionValue="value" [ngClass]="{'is-invalid': submit&& f['vehicle'].errors}"
            formControlName="vehicle" appendTo="body" [options]="vehicles" optionLabel="label"
            placeholder="Véhicule de staff" [showClear]="false">
          </p-dropdown>
        </div>
      </div>
      <div class="row no-gutters align-items-center mb-4" *ngIf="staff_form.get('type')?.value==='supervisor'">
        <div class="col-md-3">
          <label class="avenirMedium f20" for="phone"><span class="black">Hubs</span> <span class="red-color">
              *</span></label>
        </div>
        <div class="col-md-9">
          <p-multiSelect class="w-100" [options]="hubs" appendTo="body" [filter]="true" filterBy="name"
            placeholder="Selectionner les hubs" optionLabel="name" optionValue="id" [(ngModel)]="selected_hubs"
            [ngModelOptions]="{standalone: true}" [maxSelectedLabels]="6"
            selectedItemsLabel="{0} hub selectionnée"></p-multiSelect>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <label class="customUpload flex-column p-5 mr-3"
          [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
          <input type="file" (change)="showPreviewImage($event)" />
          <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
        </label>
        <span class="f18 avenirRegular black">Ajouter une image </span>
      </div>

      <div class="d-flex justify-content-end  align-items-center mt-4">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3" (click)="display=false">Annuler</button>
        <button class="btn green-back  avenirMedium f18 text-white" (click)="manage_staff()">Envoyer</button>
      </div>
    </form>
  </div>
</p-dialog>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="permission_dialog" [modal]="true" [draggable]="false"
  [resizable]="false" [breakpoints]="{ '124px': '100vw'}" [style]="{width: '800px'}">
  <div class="px-3">
    <span class="avenirMedium f40 fw85 main-color">Liste des Permissions</span>
    <form autocomplete="off" class="mt-4" [formGroup]="permission_form">
      <div class="mb-3">
        <p-checkbox name="group1" inputId="can_create_delivery_staff" class="mr-2"
          formControlName="can_create_delivery_staff" [binary]="true"></p-checkbox>
        <label class="black f18 avenirRegular" for="can_create_delivery_staff">Creation des livreurs </label>
      </div>
      <div class="mb-3">
        <p-checkbox name="group1" inputId="can_create_delivery_transactions" class="mr-2"
          formControlName="can_create_delivery_transactions" [binary]="true"></p-checkbox>
        <label class="black f18 avenirRegular" for="can_create_delivery_transactions">Fleet Manager</label>
      </div>
      <div class="mb-3">
        <p-checkbox name="group1" inputId="can_edit_delivery_zones" class="mr-2"
          formControlName="can_edit_delivery_zones" [binary]="true"></p-checkbox>
        <label class="black f18 avenirRegular" for="can_edit_delivery_zones">Modification des zones de
          livraison</label>
      </div>
      <div class="d-flex justify-content-end  align-items-center mt-4">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3"
          (click)="permission_dialog=false">Annuler</button>
        <button class="btn green-back  avenirMedium f18 text-white" (click)="manage_permissions()">Envoyer</button>
      </div>
    </form>
  </div>
</p-dialog>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #no_staff>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des livreurs appuyez sur "Ajouter un
      livreur" pour le insérer un </p>
  </div>
</ng-template>
