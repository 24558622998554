import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  store_tickets_count: any
  client_tickets_count: any
  constructor(private http_service: HttpService) { }

  ngOnInit(): void {
    this.get_store_tickets_count()
    this.get_client_tickets_count()
  }
  get_store_tickets_count() {
    this.http_service.get('store_tickets/count').subscribe((res: any) => {
      if (res?.status === 200) {
        this.store_tickets_count = res?.body.count
      }
    })
  }
  get_client_tickets_count() {
    this.http_service.get('client_tickets/count?type=backoffice').subscribe((res: any) => {
      if (res?.status === 200) {
        this.client_tickets_count = res?.body.count
      }
    })
  }
}
