<p-sidebar #sidebar [(visible)]="display" [appendTo]="sidebar" position="right" [style]="{width:'575px'}"
  [showCloseIcon]=false [baseZIndex]="10000" styleClass="disable-scroll">
  <div class="d-flex align-items-center p-3">
    <button class="btn p-0" (click)="display=false">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">Détails de la commande</span>
  </div>
  <div class="p-3">
    <span class="fw85 main-color f25 avenirRegular">Details de la commande</span>
    <div class="px-2 py-4 mt-3 cdr">
      <div class=" row no-gutters pb-3">
        <div class="col-md-4">
          <span class="avenirRegular f18 black">Commande</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 main-color">{{order?.order_number}}</span>
        </div>
        <div class="col-md-4">
          <div class="status"
            [ngClass]="{'yellow_back': order?.status==='in-delivery','light_green_back': order?.status==='completed','light_red_back': order?.status==='rejected'||order?.status==='canceled','light_blue_back': order?.status==='ready'}">
            <span class="avenirRegular f18"
              [ngClass]="{'yellow': order?.status==='in-delivery','main-color': order?.status==='completed','red-color': order?.status==='rejected'||order?.status==='canceled','blue': order?.status==='ready'}">{{order?.status}}</span>
          </div>
        </div>
      </div>
      <div class="row no-gutters pb-3">
        <div class="col-md-4">
          <span class="avenirRegular f18 black">Etablissement</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.store?.name}}</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.store?.phone}}</span>
        </div>
      </div>
      <div class="row no-gutters pb-3">
        <div class="col-md-4">
          <span class="avenirRegular f18 black">Client</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.delivery?.client?.name}}</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.delivery?.client?.phone}}</span>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-4">
          <span class="avenirRegular f18 black">Livreur</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.delivery?.staff?.name}}</span>
        </div>
        <div class="col-md-4">
          <span class="avenirRegular f18 black">{{order?.delivery?.staff?.phone}}</span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="mb-3">
        <span class=" main-color f25 fw85 avenirRegular">Progrès de la commande</span>
      </div>
      <div *ngFor="let status of order?.status_history" class="deepGrey f20 avenirRegular mb-3">
        <div class="d-flex justify-content-between">
          <span>{{status?.status}}</span>
          <span>{{status?.created.date |date :"dd/MM/YYYY HH:mm"}}</span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="mb-3">
        <span class=" main-color f25 fw85 avenirRegular">Commentaire d'état</span>
      </div>
      <div class="deepGrey f20 avenirRegular mb-3">{{order?.status_comment}}</div>
    </div>
    <div>
      <span class="fw85 main-color f25 avenirRegular">Items</span>
      <div class="px-2 py-3 mt-3 cdr">
        <div *ngFor="let item of order?.items" class="my-2">
          <div class="d-flex justify-content-between">
            <span class="black f18 avenirRegular">{{item?.quantity}} x{{item?.title}}</span>
            <span class="fw85 avenirRegular f18 main-color">{{item?.price}}
              {{appconfig.config.langs[0].currency_symbol}} </span>
          </div>
          <div *ngIf="item?.data?.options.length>0" class="d-flex justify-content-between">
            <span class="black f16 avenirRegular">Prix de base:</span>
            <span class="fw85 avenirRegular f16 main-color">{{item?.data?.base_price}}
              {{appconfig.config.langs[0].currency_symbol}} </span>
          </div>
          <div *ngFor="let item of item?.data?.options" class="d-flex justify-content-between">
            <span class="black f16 avenirRegular">{{item?.name}} </span>
            <span class="fw85 avenirRegular f16 main-color">{{item?.price}}
              {{appconfig.config.langs[0].currency_symbol}} </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="mb-3">
          <span class=" main-color f25 fw85 avenirRegular">Commentaire:</span>
        </div>
        <div class="deepGrey f20 avenirRegular mb-3">{{order?.comment || "aucun "}} </div>
      </div>
      <div class="px-2 py-3 mt-3 green-back text-white br5">
        <span class="fw85 text-white f25 avenirRegular">Payment</span>
        <div class="d-flex justify-content-between mb-3 mt-3">
          <span class="f18 avenirRegular">Prix</span>
          <span class="fw85 avenirRegular f18">{{order?.price}} {{appconfig.config.langs[0].currency_symbol}} </span>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <span class="f18 avenirRegular">Remise</span>
          <span class="fw85 avenirRegular f18">{{order?.delivery?.discount}}
            {{appconfig.config.langs[0].currency_symbol}} </span>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <span class="f18 avenirRegular">Livraison</span>
          <span class="fw85 avenirRegular f18">{{order?.delivery?.price}} {{appconfig.config.langs[0].currency_symbol}}
          </span>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <span class="f18 avenirRegular">Total</span>
          <span class="fw85 avenirRegular f18">{{order?.delivery?.full_price}}
            {{appconfig.config.langs[0].currency_symbol}} </span>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
