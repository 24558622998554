<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [breakpoints]="{ '1240px': '100vw'}"
  [style]="{width: '1240px'}" [draggable]="false" [resizable]="false">
  <div class="mb-4">
    <span class="avenirMedium f35 fw85 main-color">Gérer les fichiers média</span>
    <div class="d-flex justify-content-center pb-5">
      <label class="customUpload flex-column p-5 mr-3">
        <input type="file" (change)="showPreviewImage($event)" accept="image/png, image/jpeg, image/jpg, video/mp4"
          multiple />
        <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
      </label>
    </div>
    <div class="row align-items-stretch">
      <div *ngFor="let item of imgUrls" class="col-md-4 pr-2 pb-2 d-flex">
        <img *ngIf="item.type==='image';else video" src="{{item.url}}" alt="" class="w-100 img">
        <ng-template #video>
          <video width="100%" controls>
            <source src="{{item.url}}" type="video/mp4">
          </video>
        </ng-template>

      </div>
    </div>
    <div class="card xl:flex xl:justify-content-center">
      <p-orderList [value]="reward?.files" (onReorder)="updateOrder()" header="List des fichiers media existants" [responsive]="true" >
        <ng-template let-file pTemplate="item">
          <div class="flex flex-wrap p-2 align-items-center gap-3">
            <div class="position-relative">
              <img *ngIf="file.type==='image';else video" src="{{file.media}}" alt="" class="w-100 img">
              <ng-template #video>
                <video width="100%" controls>
                  <source src="{{file.media}}" type="video/mp4">
                </video>
              </ng-template>
              <button class="btn p-0 deleteIcon"><img src="../../../../../assets/images/svg/delete..svg"
                  (click)="deleteFile(file)" alt=""></button>
            </div>
          </div>
        </ng-template>
      </p-orderList>
    </div>
    <div class="d-flex justify-content-end pt-3">
      <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4" (click)="displayModal=false">Annuler
      </button>
      <button class="btn green-back avenirMedium f16  text-white px-4" (click)="manage_files()"
        [disabled]="disabled">Enregistrer</button>
    </div>
  </div>
</p-dialog>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="'le média '+this.file?.id">
