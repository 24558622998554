import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  value: boolean = true
  display: boolean = false;
  checked: boolean = true;
  regions: any = [];
  permissions: any = [];
  Users: any
  user: any
  userForm!: FormGroup;
  editUser: any
  selectedRegions!: any[]
  selectedPermissions!: any[]
  isWorking!: boolean;
  submit: boolean = false
  searchFilter: any = '';
  isLoading: boolean = false;
  active: any
  status: any = [{ label: 'Actif', value: 1 }, { label: 'Inactif', value: 0 }]
  constructor(private app_config: AppConfigService, private messageService: MessageService, private fb: FormBuilder, private router: Router, public route: ActivatedRoute, private httpService: HttpService) {
    this.isWorking = false;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.status) {
        this.active = JSON.parse(params.status)
        this.getUsers()
      }
      else {
        this.active = 1
        this.getUsers()
      }

    })
    this.inializeUserForm()
    this.userForm.get('regions')?.valueChanges
    .subscribe(value => {
      this.userForm.get('all_regions')?.updateValueAndValidity();
    });
    this.getRegions()
    this.permissions = [
      {
        name: "Gestion des hubs", value: "can_manage_hubs",
      },
      {
        name: "Analytics", value: "can_view_analytics",
      },
      {
        name: "Gestion des établissments", value: "can_manage_stores",
      },
      {
        name: "Gestion Frais de livraison", value: "can_manage_delivery_fees",
      },
      {
        name: "Gestion des commandes", value: "can_manage_orders",
      },
      {
        name: "Gestion des offres et promo", value: "can_manage_offers_promos",
      },
      {
        name: "Autre", value: "can_manage_Other",
      },
    ]
  }
  get f() { return this.userForm.controls; }
  toggleBtn() {
    this.value = !this.value
  }
  showDialog() {
    this.display = true;
  }
  hideDialog() {
    this.display = false;
  }
  getUsers() {
    this.httpService.get("staff?filters=type:admin;active:" + this.active).subscribe(data => {
      if (data?.status == 200) {
        this.Users = data.body.data
        this.isLoading = true
        this.Users.forEach((element: any) => {
          element.permisionss = this.adjustPermissions(element)
          if (element?.phone?.lastIndexOf(this.app_config.config.phone_int_prefix, 0) === 0) {
            element.phone = element.phone.replace(this.app_config.config.phone_int_prefix, this.app_config.config.phone_local_prefix)
          }
        });
      }
    })
  }
  inializeUserForm() {
    this.userForm = this.fb.group({
      name: new FormControl("", Validators.required),
      username: new FormControl("", [Validators.required, Validators.pattern("^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._-]+(?<![_.])$")]),
      password: new FormControl("", [Validators.required, Validators.minLength(8)]),
      active: new FormControl(true, Validators.required),
      regions: ["",this.ConditionallyRequiredValidator],
      permissions: ["", Validators.required],
      phone: new FormControl("", [Validators.required]),
      all_regions: new FormControl(false),
      type: new FormControl("admin", Validators.required),
      vehicle: new FormControl("voiture", Validators.required),
    }, {
      validators: [this.ConditionallyRequiredValidator]
    });
  }
  getRegions() {
    this.httpService.get("regions").subscribe(data => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.regions.push({ name: element.name, id: element.id })
        });
      }
    })
  }
  editUsr(User: any) {
    this.userForm.removeControl('vehicle')
    this.userForm.removeControl('password')
    this.userForm.updateValueAndValidity()
    this.selectedRegions = new Array
    this.selectedPermissions = new Array
    this.editUser = true;
    this.user = User
    User.regions.forEach((element: any) => {
      this.selectedRegions.push(element.id)
    });
    User.permisionss.forEach((element: any) => {
      this.selectedPermissions.push(element)
    });
    this.showDialog()
    this.userForm.patchValue({
      name: User.name,
      username: User.username,
      phone: User.phone,
      active: User.active,
      type: User.type,
    })
  }
  addUser() {
    this.submit = false
    this.inializeUserForm()
    this.showDialog()
    this.editUser = null;
    this.userForm.addControl('password', new FormControl("", [Validators.required, Validators.minLength(8)]))
    this.userForm.addControl('vehicle', new FormControl("voiture", Validators.required))
    this.userForm.patchValue({
      type: "admin",
      vehicle: "voiture",
      active: false
    })
  }
  manageUser() {
    this.submit = true
    this.userForm.get('regions')?.updateValueAndValidity();
    if (this.userForm.invalid) {
      return
    }
    if (this.user?.phone === this.userForm.value.phone) {
      delete this.userForm.value.phone
    } else {
      if (this.userForm.value.phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
        if (this.app_config.config.phone_local_prefix === "") {
          this.userForm.value.phone = this.app_config.config.phone_int_prefix + this.userForm.value.phone
        } else {
          this.userForm.value.phone = this.userForm.value.phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)

        }
      } else {
        this.userForm.value.phone = this.app_config.config.phone_int_prefix + this.userForm.value.phone
      }
    }
    if (this.userForm.value.all_regions) {
      delete this.userForm.value.regions
    }
    if (this.editUser) {
      let form = this.userForm.value
      form.permissions ? form.permissions = form?.permissions?.toString() : ''
      this.httpService.post("staff/" + this.user.id, form).subscribe(res => {
        if (res?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Admin  ' + this.user.name + ' a été modifie créé avec succès' })
          Object.assign(this.user, res.body.data)
          this.getUsers()
          this.user.permisionss = this.adjustPermissions(res.body.data)
          this.hideDialog()
        }
      })
    } else {
      this.userForm.value.permissions ? this.userForm.value.permissions = this.userForm.value?.permissions?.toString() : ''
      this.httpService.post("staff", this.userForm.value).subscribe(res => {
        if (res?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Admin a été crée créé avec succès' })
          this.getUsers()
          res.body.data.permisionss = this.adjustPermissions(res.body.data)
          // this.Users.push(res.body.data)
          this.hideDialog()
        }
      })
    }
  }
  select_status() {
    this.isLoading = false
    this.router.navigate(['/users'], { queryParams: { status: this.active } });
  }
  ConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('all_regions').value === false) {
      return Validators.required(formControl);
    }
    return null;
  }
  adjustPermissions(User: any) {

    let permisionss: any[] = []
    if (User.permissions.includes("can_manage_hubs")) {
      permisionss.push("can_manage_hubs")
    }
    if (User.permissions.includes("can_view_analytics")) {
      permisionss.push("can_view_analytics")
    }
    if (User.permissions.includes("can_manage_stores")) {
      permisionss.push("can_manage_stores")
    }
    if (User.permissions.includes("can_manage_delivery_fees")) {
      permisionss.push("can_manage_delivery_fees")
    }
    if (User.permissions.includes("can_manage_orders")) {
      permisionss.push("can_manage_orders")
    }
    if (User.permissions.includes("can_manage_offers_promos")) {
      permisionss.push("can_manage_offers_promos")
    }
    return permisionss
  }
}
