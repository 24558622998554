import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { MessageService } from 'primeng/api';
import { HomeComponent } from '../home/home.component';
@Component({
  selector: 'app-delivery-fees',
  templateUrl: './delivery-fees.component.html',
  styleUrls: ['./delivery-fees.component.scss']
})
export class DeliveryFeesComponent implements OnInit {
  display: boolean = false;
  deliveryTimes: any;
  deliveryFees: any
  deliveryFee: any
  deliveryFeeform!: FormGroup;
  regions: any
  edit: boolean = false
  isLoading: boolean = false;
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  constructor(public home: HomeComponent, private httpService: HttpService, private fb: FormBuilder, private messageService: MessageService) {
    this.deliveryTimes = [
      { name: '5 mins', value: 5 },
      { name: '10 mins', value: 10 },
      { name: '15 mins', value: 15 },
      { name: '20 mins', value: 20 },
      { name: '25 mins', value: 25 },
    ];
  }
  ngOnInit(): void {
    this.getDeliveryFees();
    this.inializedeliveryFeeForm()
  }
  hideDialog() {
    this.display = false;
  }
  getDeliveryFees() {
    this.httpService.get("regions").subscribe(data => {
      if (data?.status == 200) {
        this.regions = data.body.data
        this.isLoading = true
        data.body.data.forEach((element: any) => {
          this.httpService.get('region_delivery_price/' + element.id).subscribe(res => {
            if (res?.status == 200) {
              if (res.body.data.length > 0) {
                element.delivery = res.body.data
              }
            }
          })
        });
      }
    })
  }
  editDeliveryFees(deliveryFee: any) {
    this.display = true;
    this.edit = true
    this.deliveryFee = deliveryFee
    this.deliveryFeeform.patchValue({
      active: deliveryFee.active === 1 ? true : false,
      base_price: deliveryFee.base_price,
      delivery_time: deliveryFee.delivery_time,
      price: deliveryFee.price,
      promo_until: deliveryFee.promo_until?.date ? new Date(deliveryFee.promo_until?.date) : null,
      region_id: deliveryFee.region_id,
    })
  }
  addDeliverFee() {
    this.display = true;
    this.edit = false
    this.deliveryFeeform.reset()
    this.deliveryFeeform.patchValue({
      active: false,
    })
  }
  inializedeliveryFeeForm() {
    this.deliveryFeeform = this.fb.group({
      active: new FormControl("", Validators.required),
      base_price: ["", Validators.required],
      delivery_time: ["", Validators.required],
      price: ["", Validators.required],
      promo_until: [""],
      region_id: ["", Validators.required],
    });
  }
  manageDeliveryFees() {
    if (this.deliveryFeeform.invalid) {
      return
    }
    if (this.edit) {
      this.httpService.post("region_delivery_price", this.deliveryFeeform.value).subscribe(data => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Frais de livraison est modifiée avec succès', });
          Object.assign(this.deliveryFee, data.body.data)
          this.display = false
        }
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'Opération echouée', detail: 'Une erreur s\'est produite' });
      })
    } else {
      this.httpService.post("region_delivery_price", this.deliveryFeeform.value).subscribe(data => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Frais de livraison est ajouté avec succès' });
          this.regions.forEach((element: any) => {
            if (data.body.data.active) {
              if (element.id === data.body.data.region_id) {
                element.delivery.push(data.body.data)
              }
            }
          });
          this.display = false
        }
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'Opération echouée', detail: 'Une erreur s\'est produite' });
      })

    }
  }
  onConfirm(event: any) {
    this.httpService.delete("region_delivery_price/" + this.deliveryFee.id).subscribe(res => {
      if (res?.status === 204) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Frais de livraison est supprimé avec succès' });
        this.display = false
        this.deleteModal.displayModal = false
        for (let index = 0; index < this.regions.length; index++) {
          const element = this.regions[index];
          if (element.id === this.deliveryFee.region_id) {
            for (let index = 0; index < element.delivery.length; index++) {
              const delivery = element.delivery[index];
              if (delivery.id === this.deliveryFee.id) {
                element.delivery.splice(index, 1);
              }
            }
          }
        }
      }
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Opération echouée', detail: 'Une erreur s\'est produite' });
    })
  }
}
