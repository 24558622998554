<div *ngIf="loadingService;else notYetLoaded">
    <div class="px-4">
        <div class="pt-6 pb-3">
            <span class="avenirRegular f72 main-color font-weight-bold">Paramètres
            </span>
        </div>
        <div class="pb-3">
            <span class="f48 avenirRegular black fw85">Services</span>
        </div>
        <div class="d-flex justify-content-end pb-3">
            <button class="btn avenirHeavy f20 br25 green-back text-white px-4" (click)="addService()">Ajouter un
                service</button>
        </div>
    </div>
    <div class="pb-4">
        <p-table *ngIf="services.length > 0; else noItems" [value]="services" [reorderableColumns]="true"
            responsiveLayout="scroll">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3rem"></th>
                    <th style="width: 2%"></th>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Opérations</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td>
                        <img src="../../../../../../assets/images/svg/Group380.svg" (dragend)="changeOrder()"
                            [pReorderableRowHandle]="index" alt="" />
                    </td>
                    <td>
                        <img *ngIf="rowData?.icon?.url; else noImg" src="{{ rowData?.icon?.url }}" class="img" alt="" />
                    </td>
                    <td class="f16 avenirRegular black">
                        {{ rowData.name }}
                    </td>
                    <td class="f16 avenirRegular greyColor">{{ rowData.description }}</td>
                    <td>
                        <button class="btn p-0 mr-2" (click)="editService(rowData)">
                            <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div *ngIf="loadingSpecialities;else notYetLoaded" class="px-4 pb-4">
    <div class="pb-3">
        <span class="f48 avenirRegular black fw85">Spécialités</span>
    </div>
    <div class="d-flex justify-content-end pb-3">
        <button class="btn avenirHeavy f20 br25 green-back text-white px-4" (click)="addSpeciality()">Ajouter une
            spécialité</button>
    </div>
    <div>
        <p-table *ngIf="specialities.length > 0; else noItems" [value]="specialities" [reorderableColumns]="true"
            responsiveLayout="scroll">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3rem"></th>
                    <th style="width: 2%"></th>
                    <th>Nom</th>
                    <th>Catégorie</th>
                    <th>Description</th>
                    <th>Opérations</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td>
                        <img src="../../../../../../assets/images/svg/Group380.svg"
                            (dragend)="changeSpecialitiesOrder()" [pReorderableRowHandle]="index" alt="" />
                    </td>
                    <td>
                        <img *ngIf="rowData?.icon?.url; else noImg" src="{{ rowData?.icon?.url }}" class="img" alt="" />
                    </td>
                    <td class="f16 avenirRegular black">
                        {{ rowData.name }}
                    </td>
                    <td class="f16 avenirRegular black">
                        {{ rowData.category }}
                    </td>
                    <td class="f16 avenirRegular greyColor">{{ rowData.description }}</td>
                    <td>
                        <button class="btn p-0 mr-2" (click)="editSpciality(rowData)">
                            <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div *ngIf="loadingtypes;else notYetLoaded" class="px-4 pb-4">
    <div class="pb-3">
        <span class="f48 avenirRegular black fw85">Types des stores</span>
    </div>
    <div class="d-flex justify-content-end pb-3">
        <button class="btn avenirHeavy f20 br25 green-back text-white px-4" (click)="addType()">Ajouter un type de store</button>
    </div>
    <div>
        <p-table *ngIf="types.length > 0; else noItems" [value]="types" [reorderableColumns]="true"
            responsiveLayout="scroll">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3rem"></th>
                    <th style="width: 2%"></th>
                    <th>Nom</th>
                    <th>Catégorie</th>
                    <th>Description</th>
                    <th>Opérations</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                    <td>
                        <img src="../../../../../../assets/images/svg/Group380.svg"
                            (dragend)="changetypesOrder()" [pReorderableRowHandle]="index" alt="" />
                    </td>
                    <td>
                        <img *ngIf="rowData?.icon?.url; else noImg" src="{{ rowData?.icon?.url }}" class="img" alt="" />
                    </td>
                    <td class="f16 avenirRegular black">
                        {{ rowData.name }}
                    </td>
                    <td class="f16 avenirRegular black">
                        {{ rowData.category }}
                    </td>
                    <td class="f16 avenirRegular greyColor">{{ rowData.description }}</td>
                    <td>
                        <button class="btn p-0 mr-2" (click)="editTypes(rowData)">
                            <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<ng-template #noItems>
    <div class="text-center black avenirBlack f18 p-3">
        Cet section n'a pas encore des produits appuyez sur "Ajouter" pour en
        ajouter un
    </div>
</ng-template>
<ng-template #noImg></ng-template>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="display" [modal]="true" [style]="{width: '656px'}" [draggable]="false"
    [resizable]="false" (onHide)="service_form_tag.reset()">
    <form #service_form_tag [formGroup]="service_form" autocomplete="off">
        <div class="mb-4">
            <span class="avenirMedium f35 fw85 main-color">Ajouter un service</span>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Titre</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title" placeholder="Title"
                formControlName="name" [ngClass]="{'is-invalid': submit&&f['name'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title-{{lang.code}}" [placeholder]="lang.name"
                       dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
                       [value]="service_form.value?.translations | translationsPatchValue: 'name':lang.code"
                       (change)="onTranslationsChange(service_form, 'name', lang.code, $event)">
            </div>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Description</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description" placeholder="Description"
                       formControlName="description" [ngClass]="{'is-invalid': submit&&f['description'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description-{{lang.code}}" [placeholder]="lang.name"
                       dir="auto" [ngClass]="{'is-invalid': submit&&f['description'].errors}"
                       [value]="service_form.value?.translations | translationsPatchValue: 'description':lang.code"
                       (change)="onTranslationsChange(service_form, 'description', lang.code, $event)">
            </div>
        </div>
        <div class="d-flex align-items-center mt-3">
            <label class="customUpload flex-column p-5 mr-3"
                [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
                <input type="file" (change)="showPreviewImage($event,'service')" />
                <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
            </label>
            <span class="f18 avenirRegular black">Ajouter une image </span>
        </div>
        <div class="d-flex justify-content-end pt-3">
            <button class="btn mr-3" (click)="deleteItem('service')"><img src="../../../assets/images/svg/delete..svg" alt=""></button>
            <button type="reset" class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4" (click)="display=false">Annuler
            </button>
            <button class="btn green-back avenirMedium f16  text-white px-4"
                (click)="manageService()">{{editSrvice?"Modifier":"Ajouter"}}</button>
        </div>
    </form>
</p-dialog>
<p-dialog [showHeader]="false" [(visible)]="displaySpeciality" [modal]="true" [style]="{width: '656px'}"
    [draggable]="false" [resizable]="false" (onHide)="speciality_form_tag.reset()">
    <form #speciality_form_tag [formGroup]="speciality_form" autocomplete="off">
        <div class="mb-4">
            <span class="avenirMedium f35 fw85 main-color">{{editSpeciality?"Modifier la spécialité":"Ajouter une spécialité"}} </span>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Titre</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title" placeholder="Title"
                formControlName="name" [ngClass]="{'is-invalid': submitSpeciality&&g['name'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title-{{lang.code}}" [placeholder]="lang.name"
                        dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
                        [value]="speciality_form.value?.translations | translationsPatchValue: 'name':lang.code"
                        (change)="onTranslationsChange(speciality_form, 'name', lang.code, $event)">
            </div>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Description</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description" placeholder="Description"
                formControlName="description" [ngClass]="{'is-invalid': submitSpeciality&&g['description'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description-{{lang.code}}" [placeholder]="lang.name"
                        dir="auto" [ngClass]="{'is-invalid': submit&&f['description'].errors}"
                        [value]="speciality_form.value?.translations | translationsPatchValue: 'description':lang.code"
                        (change)="onTranslationsChange(speciality_form, 'description', lang.code, $event)">
            </div>
        </div>
        <div class="mt-3">
            <p-dropdown [options]="categories" optionValue="value" optionLabel="label" placeholder="Catégorie"
                formControlName="category" [showClear]="false" class="mb-5" [ngClass]="{'is-invalid': submitSpeciality&&g['category'].errors}">
            </p-dropdown>
        </div>
        <div class="d-flex align-items-center mt-5">
            <label class="customUpload flex-column p-5 mr-3"
                [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
                <input type="file" (change)="showPreviewImage($event,'speciality')" />
                <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
            </label>
            <span class="f18 avenirRegular black">Ajouter une image </span>
        </div>
        <div class="d-flex justify-content-end pt-3">
            <button class="btn mr-3" (click)="deleteItem('speciality')"><img src="../../../assets/images/svg/delete..svg" alt=""></button>
            <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4"
                (click)="displaySpeciality=false">Annuler
            </button>
            <button class="btn green-back avenirMedium f16  text-white px-4"
                (click)="manageSpeciality()">{{editSpeciality?"Modifier":"Ajouter"}}</button>
        </div>
    </form>
</p-dialog>
<p-dialog [showHeader]="false" [(visible)]="displayType" [modal]="true" [style]="{width: '656px'}"
    [draggable]="false" [resizable]="false" (onHide)="types_form_tag.reset()">
    <form #types_form_tag [formGroup]="types_form" autocomplete="off">
        <div class="mb-4">
            <span class="avenirMedium f35 fw85 main-color">{{editType?"Modifier le type":"Ajouter un type"}} </span>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Titre</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title" placeholder="Title"
                formControlName="name" [ngClass]="{'is-invalid': submitTypes&&k['name'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="title-{{lang.code}}" [placeholder]="lang.name"
                        dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
                        [value]="types_form.value?.translations | translationsPatchValue: 'name':lang.code"
                        (change)="onTranslationsChange(types_form, 'name', lang.code, $event)">
            </div>
        </div>
        <p-divider align="center">
            <div class="inline-flex align-items-center">
                <b>Description</b>
            </div>
        </p-divider>
        <div class="border border-top-0 p-1">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description" placeholder="Description"
                formControlName="description" [ngClass]="{'is-invalid': submitTypes&&k['description'].errors}">
            <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
                <input type="text" class="form-control mdStl avenirMedium f18 mb-5" id="description-{{lang.code}}" [placeholder]="lang.name"
                        dir="auto" [ngClass]="{'is-invalid': submit&&f['description'].errors}"
                        [value]="types_form.value?.translations | translationsPatchValue: 'description':lang.code"
                        (change)="onTranslationsChange(types_form, 'description', lang.code, $event)">
            </div>
        </div>
        <div class="mt-3">
            <p-dropdown [options]="categories" optionValue="value" optionLabel="label" placeholder="Catégorie"
                formControlName="category" [showClear]="false" class="mb-5" [ngClass]="{'is-invalid': submitTypes&&k['category'].errors}">
            </p-dropdown>
        </div>
        <div class="d-flex align-items-center mt-5">
            <label class="customUpload flex-column p-5 mr-3"
                [ngStyle]="{'background-image':imgUrl  ? 'url('+imgUrl+')' : '' }">
                <input type="file" (change)="showPreviewImage($event,'type')" />
                <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
            </label>
            <span class="f18 avenirRegular black">Ajouter une image </span>
        </div>
        <div class="d-flex justify-content-end pt-3">
            <button class="btn mr-3" (click)="deleteItem('store_type')"><img src="../../../assets/images/svg/delete..svg" alt=""></button>
            <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4"
                (click)="displayType=false">Annuler
            </button>
            <button class="btn green-back avenirMedium f16  text-white px-4"
                (click)="manageType()">{{editType?"Modifier":"Ajouter"}}</button>
        </div>
    </form>
</p-dialog>
<app-confirmation  #deleteModal (onConfirm)="onConfirm($event)" [name]=text></app-confirmation>
