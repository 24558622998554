<div *ngIf="loading;else notYetLoaded">
  <div class="d-flex justify-content-end">
    <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4" (click)="addUser()">Ajouter un
      utilisateur</button>
  </div>
  <div *ngIf="users.length>0;else noUsers" class="table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr class="customTr">
          <th>Utilistateur</th>
          <th>Téléphone</th>
          <th>Permissions</th>
          <th>Opérations</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
          <td class="avenirMedium f18 black">{{user.name}}</td>
          <td class="avenirMedium f18 black">{{user.phone}}</td>
          <td class="avenirMedium f18 black">
            <ul>
              <li *ngIf="user.permissions.can_create_orders">Création des commandes</li>
              <li *ngIf="user.permissions.can_edit_menu">Modifications de menu</li>
              <li *ngIf="user.permissions.can_edit_store_users">Modification des utilisateurs</li>
              <li *ngIf="user.permissions.can_create_free_items">Création des articles gratuits</li>
            </ul>
          </td>
          <td class="avenirMedium f18 black">
            <button class="btn p-0 mr-2" (click)="editUser(user)">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="displayModal" [modal]="true" [draggable]="false"
  [resizable]="false" [breakpoints]="{ '690px': '100vw'}" [style]="{width: '690px'}">
  <div class="pt-2">
    <span class="aven avenirRegular f35 fw85 main-color">Ajouter un utilisateur</span>
    <form autocomplete="off" [formGroup]="userForm" class="pt-3">
      <div class="row no-gutters mb-4 align-items-center">
        <div class="col-md-3">
          <label class="avenirMedium f20 black" for="name">Nom </label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="name" [ngClass]="{'is-invalid': f['name'].errors&&submit}"
            placeholder="Ex. Lokmane">
        </div>
      </div>
      <div class="row no-gutters mb-4 align-items-center">
        <div class="col-md-3">
          <label class="avenirMedium f20 black" for="name">Téléphone </label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="phone"[ngClass]="{'is-invalid': f['phone'].errors&&submit}"
            placeholder="Ex . 0657889125">
        </div>
      </div>
      <div formGroupName="permissions">
        <div class="my-3">
          <span class="black avenirRegular f25 fw85">Permissions</span>
        </div>
        <div class="d-flex align-items-center mb-3">
          <p-checkbox name="group1"  inputId="can_edit_menu"class="mr-2"  formControlName="can_edit_menu"  [binary]="true"></p-checkbox>
          <label class="black f18 avenirRegular" for="can_edit_menu">Modifier le menu</label>
        </div>
        <div class="d-flex align-items-center mb-3">
          <p-checkbox name="group1" class="mr-2" inputId="can_create_orders"
            formControlName="can_create_orders"  [binary]="true">
          </p-checkbox>
          <label class="black f18 avenirRegular" for="can_create_orders">Créer des commandes</label>
        </div>
        <div class="d-flex align-items-center mb-3">
          <p-checkbox name="group1" inputId="can_edit_store_users" class="mr-2" formControlName="can_edit_store_users"  [binary]="true"></p-checkbox>
          <label class="black f18 avenirRegular" for="can_edit_store_users">Modification des utilisateurs</label>
        </div>
        <div class="d-flex align-items-center mb-3">
          <p-checkbox name="group1" inputId="can_create_free_items" class="mr-2" formControlName="can_create_free_items"  [binary]="true"></p-checkbox>
          <label class="black f18 avenirRegular" for="can_create_free_items">Création des articles gratuits</label>
        </div>
      </div>
      <div class="d-flex justify-content-between  align-items-center my-4 px-3 py-2 bnr">
        <span class="avenirMedium f18 ursopct black"> Activer l’utilisateur</span>
        <p-inputSwitch formControlName="active"></p-inputSwitch>
      </div>
      <div class="d-flex justify-content-end  align-items-center mt-auto">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
          (click)="displayModal=false">Annuler</button>
        <button class="btn green-back px-5 avenirMedium f18 text-white" (click)="manageUser()">Envoyer</button>
      </div>
    </form>
  </div>
</p-dialog>
<ng-template #noUsers>
  <div class="pt-3">
      <p class="text-center black  avenirBlack f18">Cet établissement n'a pas encore des utilisateurs appuyez sur
          "Ajouter un utilisateur" pour en insérer un</p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
      <app-loading></app-loading>
  </div>
</ng-template>