import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { HomeComponent } from '../../home/home.component';
import { TicketsComponent } from '../tickets.component';
@Component({
  selector: 'app-stores-tickets',
  templateUrl: './stores-tickets.component.html',
  styleUrls: ['./stores-tickets.component.scss']
})
export class StoresTicketsComponent implements OnInit {
  currentPage: any;
  page: number = 1
  url: any
  store_tickets: any
  regions: any
  is_loaded: boolean = false
  searchForm!: FormGroup;
  status: any = [{ label: "Fermé", value: "closed" }, { label: "Ouvert", value: "open" }]
  types: any = []
  display: boolean = false
  store_ticket: any
  store_ticket_form!: FormGroup
  submit: boolean = false
  constructor(private home:HomeComponent,private tickets:TicketsComponent,private messageService:MessageService,private fb: FormBuilder, private httpService: HttpService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      region: new FormControl(""),
      status: new FormControl("",),
      type: new FormControl("",),
      store: new FormControl("",),
    });
    this.store_ticket_form = this.fb.group({
      status: new FormControl("", Validators.required),
      updates: new FormControl("", [Validators.required, Validators.maxLength(256)]),
    })
    this.route.queryParamMap.subscribe(param => {
      this.page = param.get('page') ? Number(param.get('page')) : 1
      let url: any = 'store_tickets?order_by=-created'
      if (param.get('region') || param.get('type') || param.get('status') || param.get('store')) {
        url += '&filters='
        this.searchForm.patchValue({
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          status: param?.get('status') ? param?.get('status') : "",
          type: param?.get('type') ? param?.get('type') : "",
          store: param?.get('store') ? param?.get('store') : "",
        })
      }
      param.get('region') ? url += 'region:' + param.get('region') + ";" : ""
      param.get('status') ? url += 'status:' + param.get('status') + ";" : ""
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""
      param.get('store') ? url += 'store:' + param.get('store') + ";" : ""
      this.types = new Array();
      this.get_store_tickets(url)
      this.get_regions()
      this.get_types()
    })

  }
  get f(){return this.store_ticket_form.controls}
  get_store_tickets(url: any) {
    this.httpService.get(url + "&page=" + this.page).subscribe((res: any) => {
      if (res?.status == 200) {
        this.is_loaded = true
        this.store_tickets = res?.body
      }
    })

  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/tickets/store-tickets'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });

  }
  get_regions() {
    this.httpService.get('regions').subscribe(res => {
      if (res?.status === 200) {
        this.regions = res.body.data
      }
    })
  }
  get_types() {
    this.httpService.get("store_tickets/types").subscribe(data => {
      if (data?.status === 200) {
        data.body.data.forEach((element: any) => {
          this.types.push({ label: element.name, value: element.key, color: element.color })
        });
      }
    })

  }
  filter() {
    let search = this.searchForm.value
    search = { ...search, page: 1 }
    this.router.navigate(['/tickets/store-tickets'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.searchForm.reset()
    this.router.navigate(['/tickets/store-tickets']);
  }
  open_side_bar(ticket: any) {
    console.log(ticket);
    this.submit = false
    this.store_ticket = ticket
    this.display = true
    this.store_ticket_form.reset()
    this.store_ticket_form.patchValue({
      status: this.store_ticket.status==="open"?false:true,
    })
    console.log(this.store_ticket_form.value);

  }
  edit_store_ticket() {
    this.submit = true
    if (this.store_ticket_form.invalid) {
      return
    }
    let form=this.store_ticket_form.value
    form.status=!form.status?"open":"closed"
    console.log(this.store_ticket_form.value);
    this.httpService.post("store_tickets/" + this.store_ticket.id, form).subscribe(data => {
      if (data?.status === 200) {
        this.home.get_store_tickets_count()
        this.tickets.get_store_tickets_count()
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Requêtes a été modifié avec succès' });
        Object.assign(this.store_ticket, data.body.data)

        this.display = false
      }
    })
  }
}
