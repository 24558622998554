<div class="row no-gutters">
    <div class="col-md-6">
        <div class="form-group mb-4">
            <p-autoComplete class="greyBorder text-white" placeholder="Saisir le numéro de de tépéhone" field="phone"
                [suggestions]="clients" (completeMethod)="searchClients($event)"></p-autoComplete>

        </div>
        <div class="form-group mb-4">
            <textarea class="form-control mdStl avenirRegular f18" placeholder="Message..." id="message"
                rows="3"></textarea>
        </div>
        <div class="form-group mb-5">
            <p-calendar inputId="basic" dateFormat="dd/mm/yy" [showTime]="true" placeholder="JJ/MM/AA" appendTo="body"
                class="avenirMedium whiteBorder">
            </p-calendar>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4">Annuler </button>
            <button class="btn green-back  avenirMedium f16 text-white px-4">Envoyer</button>
        </div>
    </div>
    <div class="col-md-6 d-flex justify-content-center">
        <div class="position-relative">
            <img src="../../../../../assets/images/png/Group 1624 1.png" alt="">
            <div class="position-absolute ctr w-100">
                <div class="noti p-2">
                    <span class="avenirRegular f12 black ">Veuillez nous envoyer votre menu, ainsi que les coordonnées de vos managers.</span>
                    <!-- <div class="d-flex align-items-center justify-content-between px-2 ">
                        <div class="d-flex align-items-center">
                            
                        </div> -->
                     
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>