import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-manage-items',
  templateUrl: './manage-items.component.html',
  styleUrls: ['./manage-items.component.scss']
})
export class ManageItemsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) { }
  itemForm!: FormGroup;
  submit: boolean = false;
  selectedFile: any = null;
  options: any[] = []
  imgUrl: any;
  unities: any = [{ name: "Unité", value: 'u' }, { name: "Measurable (en Kg)", value: 'kg' }, { name: "Unité (en Kg)", value: "ukg" }]
  step_quantities = [{ label: "500 g", value: 0.5 }, { label: "250 g", value: 0.25 }, { label: "100 g", value: 0.1 }]
  available_at_modal: boolean = false;
  edit: boolean = false;
  @Input() storeId!: boolean
  @Input() item: any
  @Input() items: any
  @Input() section_id: any
  ngOnInit(): void {
    this.getOptions(this.storeId)
    this.itemForm = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(64)]),
      description: new FormControl("", Validators.maxLength(256)),
      price: new FormControl("", Validators.required),
      section_id: this.storeId,
      unit: new FormControl("u", Validators.required),
      promo: new FormControl("",),
      promo_until: new FormControl("",),
      step_quantity: new FormControl("",),
      flexible_quantity: new FormControl(0),
      avg_weight: new FormControl("",),
      min_quantity: new FormControl("",),
      availability: new FormControl("all",),
      barcode: new FormControl(""),
    });
  }
  get f() { return this.itemForm.controls }
  reset() {
    this.edit = false;
    this.submit = false;
    this.itemForm.reset();
    this.setUpOptions()
    this.selectedFile = null;
    this.itemForm.patchValue({ availability: "all", unit: "u" })
    this.imgUrl = null
  }
  setUpOptions() {
    this.options.forEach(element => {
      element.checked = false
      element.options.forEach((option: any) => {
        option.checked = false
      });
    });
  }
  manageItem() {
    this.submit = true;
    const fd = new FormData();
    if (this.itemForm.invalid) {
      return;
    }
    let options: any[] = []
    this.options.forEach(element => {
      element.options.forEach((option: any) => {
        if (option.checked) {
          options.push(option.option.id);
        }
      });
    });
    this.selectedFile ? fd.append('image', this.selectedFile) : ""
    fd.append('name', this.itemForm.value.name);
    fd.append('description', this.itemForm.value.description || "null");
    fd.append('price', this.itemForm.value.price);
    fd.append('section_id', this.section_id);
    fd.append('unit', this.itemForm.value.unit);
    fd.append('availability', this.itemForm.value.availability);
    this.itemForm.value.barcode ? fd.append('barcode', this.itemForm.value.barcode) : ''
    this.itemForm?.value?.avg_weight ? fd.append('avg_weight', this.itemForm?.value?.avg_weight) : ""
    this.itemForm.value.step_quantity ? fd.append('step_quantity', this.itemForm.value.step_quantity) : ""
    this.itemForm.value.step_quantity ? fd.append('min_quantity', this.itemForm.value.step_quantity) : ""
    fd.append('promo', this.itemForm.value.promo === true ? "1" : "0");
    this.itemForm.value.flexible_quantity ? fd.append('flexible_quantity', this.itemForm.value.flexible_quantity === true ? "1" : "0") : ""
    this.itemForm.value.promo_until ? fd.append('promo_until', new Date(this.itemForm.value.promo_until.getTime() + Math.abs(this.itemForm.value.promo_until.getTimezoneOffset() * 60000)).toISOString()) : ""
    if (options.length > 0) {
      options.forEach((element: any) => {
        fd.append("options[]", element);
      });
    } else {
      fd.append("options[]", "");
    }
    if (this.edit) {
      this.httpService.post('menu/' + this.storeId + '/item/' + this.item.id, fd).subscribe(data => {
        if (data?.status === 200) {
          this.reset()
          Object.assign(this.item, data.body.data)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + data.body.data.name + ' a été modifié avec succès' });
        }
      })
    } else {
      if (this.section_id) {
        this.httpService.post('menu/' + this.storeId + '/item', fd).subscribe(data => {
          if (data?.status === 200) {
            this.items.push(data.body.data)
            this.reset()
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + data.body.data.name + ' a été ajouté avec succès' });
          }
        })
      }
    }
  }
  switchValue() {
    if (this.itemForm.value.promo === true) {
      this.itemForm.controls["promo_until"].setValidators([Validators.required,])
      this.itemForm.controls['promo_until'].updateValueAndValidity()
    }
    else {
      this.itemForm.controls["promo_until"].clearValidators()
      this.itemForm.controls['promo_until'].updateValueAndValidity()
    }

  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
      event.target.value = ""
    }
  }
  getOptions(store_id: any) {
    this.httpService.get('menu/' + store_id + '/item_options_groups_tree').subscribe(data => {
      if (data?.status === 200) {
        this.options = data.body.data;
        this.setUpOptions()
      }
    })
  }
  selectAll(option: any) {
    const isBelowThreshold = (currentValue: any) => currentValue.checked === true;
    if (option.options.every(isBelowThreshold)) {
      option.checked = false
    } else {
      option.checked = true
    }
    option.options.forEach((element: any) => {
      element.checked = option.checked;
    });
  }
  editItem(item: any) {
    this.reset()
    this.submit = false;
    this.options.forEach(element => {
      element.options.forEach((option: any) => {
        item.options.forEach((item: any) => {
          if (item === option.option.id) {
            option.checked = true;
          }
        });
      });
    });
    this.imgUrl = item.image?.url
    this.itemForm.patchValue({
      name: item.name,
      description: item.description,
      price: item.price,
      section_id: item.section_id,
      unit: item.unit,
      promo: item.promo === 1 ? true : false,
      promo_until: item.promo_until?.date ? new Date(item.promo_until?.date) : null,
      step_quantity: item.step_quantity,
      flexible_quantity: item.flexible_quantity === 1 ? true : false,
      avg_weight: item.avg_weight,
      availability: item.availability,
      barcode: item.barcode,
    });
  }
  save_availibility() {
    let available_at
    switch (this.item.available_at) {
      case "one_hour":
        available_at = this.datePipe.transform(new Date().setHours(new Date().getHours() + 1), 'YYYY-MM-dd HH:mm:ss')
        break;
      case "three_hours":
        available_at = this.datePipe.transform(new Date().setHours(new Date().getHours() + 3), 'YYYY-MM-dd HH:mm:ss')
        break;
      case "tomorrow":
        available_at = this.datePipe.transform(new Date().setDate(new Date().getDate() + 1), 'YYYY-MM-dd HH:mm:ss')
        break;
      case "never":
        available_at = null
        break;
      default:
        available_at = this.datePipe.transform(this.item.available_at, 'YYYY-MM-dd HH:mm:ss')
        break;
    }
    let form = { available: this.item.available, available_at: available_at }
    this.httpService.post('menu/' + this.storeId + '/item/' + this.item.id, form).subscribe(data => {
      if (data?.status === 200) {
        Object.assign(this.item, data.body.data);
        this.available_at_modal = false;
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + data.body.data.name + ' a été modifié avec succès' });
      }
    })
  }

}
