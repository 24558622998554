import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../assets/json/84918-404-error-doodle-animation.json'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
