import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { HttpService } from 'src/app/services/http.service';
import { style } from "../../../../../app/_helpers/map_style";
declare var google: any;

@Component({
  selector: 'app-delivery-zone',
  templateUrl: './delivery-zone.component.html',
  styleUrls: ['./delivery-zone.component.scss']
})
export class DeliveryZoneComponent implements OnInit {
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  options: any;
  overlays: any[] = [];
  infoWindow: any;
  Storepin = '../../../../../assets/images/svg/Group1378.svg'
  store_delivery_zone: any
  store: any
  loading: boolean = false
  delivery_zone: any
  constructor(private httpService: HttpService, private route: ActivatedRoute, private messageService: MessageService) { }
  ngOnInit(): void {
    this.route.parent?.params.subscribe((params: any) => {
      this.getStore(params.id)
    })

    this.infoWindow = new google.maps.InfoWindow();
  }
  getStore(storeId: string) {
    this.httpService.get('store/' + storeId).subscribe((data: any) => {
      if (data?.status === 200) {
        this.store = data.body.data
        this.httpService.get('store_delivery_zones/' + storeId).subscribe((result: any) => {
          if (result?.status === 200) {
            if (result.body.data.length > 0) {
              this.store_delivery_zone = result.body.data
              this.setUpMap(this.store_delivery_zone, false)
              console.log(this.store_delivery_zone);
            }
            else {
              this.get_default_delivery_time(storeId)
            }
          }
        })

      }

    })
  }
  get_default_delivery_time(storeId: any) {
    this.httpService.get('store_default_delivery_zones/' + storeId).subscribe((result: any) => {
      if (result?.status === 200) {
        result.body.zones.forEach((element: any) => {
          let cords = element.polygon.substring(9, element.polygon.length - 2)
          let cord = cords.split(",");
          let array: any[] = [];
          cord.forEach((element: any) => {
            let crd = element.split(" ")
            array.push(crd[1] + " " + crd[0])
          });
          element.delivery_zone = "POLYGON((" + array.toString() + "))"
        });
        this.store_delivery_zone = result.body.zones
        this.setUpMap(this.store_delivery_zone, false)
      }
    })
  }
  changeStatus(deliveryZone: any) {    
    this.httpService.post('store_delivery_zone/' + this.store.id, deliveryZone).subscribe((result: any) => {
      if (result?.status === 200) {
        this.delivery_zone=null
        Object.assign(deliveryZone, result.body.data)
        this.setUpMap(this.store_delivery_zone, false)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Zone de livraison a été modifiée avec succès' });

      }
    })
  }
  
  setUpMap(data: any, editable: boolean) {
    this.overlays = new Array()
    data.forEach((element: any) => {
      switch (element.delivery_time) {
        case 5:
          element.color = '#e7464580'
          break;
        case 10:
          element.color = '#fb77564d'
          break;
        case 15:
          element.color = '#bfe69c80'
          break;
        case 20:
          element.color = '#1ac0c64d'
          break;
        case 25:
          element.color = '#fb77564d'
          break;
      }
      if (element.active) {
        element.active === 1 || true ? element.active = true : element.active = false
        let cords = element.delivery_zone.substring(9, element.delivery_zone.length - 2)
        let cord = cords.split(",");
        let array: any[] = [];
        cord.forEach((element: any) => {
          let crd = element.split(" ")
          array.push({ lat: parseFloat(crd[0]), lng: parseFloat(crd[1]) })
        });
      let tmp = this;
        let polygon=new google.maps.Polygon({ paths: array, strokeOpacity: 0.5, strokeWeight: 0, fillColor: element.color, fillOpacity: 1, editable: editable })
        this.overlays.push(polygon)
        google.maps.event.addListener(polygon.getPath(), 'insert_at', function () {
          tmp.handlePolygonChange(this)
          tmp.handlePolygonChange(this)
        });
        google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
          tmp.handlePolygonChange(this);
          tmp.handlePolygonChange(this)
        });
      }
      let cordsText: string = this.store.location.substring(6, this.store.location.length - 1);
      let cords = cordsText.split(" ");
      let center = { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) }
      this.overlays.push(new google.maps.Marker({ position: center, title: this.store.name, icon: this.Storepin }),)
      this.options = {
        center: center,
        zoom: 12,
        styles: style.style,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP]
        }
      };

    });
    this.loading = true
  }
handlePolygonChange(shape: any) {
  var len = shape.getLength();
  var points
  var paths = new Array
  for (var i = 0; i < len; i++) {
    points = shape.getAt(i).toUrlValue(5).split(',')
    paths.push({
      lat: Number(points[0]),
      lng: Number(points[1])
    })
  }
  var result = [];
  for (var i = 0; i < paths.length; i += 1) {
    result.push(paths[i].lat + ' ' + paths[i].lng,);
  }
  if (paths[0].lat !== paths[length].lat || paths[0].lng !== paths[length].lng) {
    result.push(paths[0].lat + ' ' + paths[0].lng);
  }
  this.delivery_zone.delivery_zone="POLYGON ((" + result.toString() + "))"
}
  delete_all_delivery_zones() {
    this.deleteModal.displayModal = true;
  }
  onConfirm(event: any) {
    this.store_delivery_zone.forEach((element: any) => {
      if (element.id) {
        this.httpService.delete('store_delivery_zone/' + element.id).subscribe((result: any) => {
          if (result?.status === 204) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Zone de livraison a été ajouté avec succès' });
            this.deleteModal.displayModal = false;
            this.store_delivery_zone.length = 0
          }
          this.get_default_delivery_time(this.store.id)
          this.setUpMap(this.store_delivery_zone, false)
        })
      }
    });
  }
  edit_delivery_zone(delivery_zone: any) {
    this.delivery_zone = delivery_zone
    console.log(delivery_zone);

    let store_delivery_zones = this.store_delivery_zone.filter((item: any) => item.id === delivery_zone.id);
    this.setUpMap(store_delivery_zones, true)
  }
}
