import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: any
  search_form!: FormGroup;
  regions!: any[]
  currentPage: any;
  isLoading: boolean = false;
  page: number = 1
  notification: any

  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router,private fb:FormBuilder) { }
  ngOnInit(): void {
    this.search_form = this.fb.group({
      region: new FormControl("",),
      gte: new FormControl("",),
      lte: new FormControl(""),
    });
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      let url: any = 'notification_campaigns?order_by=-created&page=' + this.page
      if ( param.get('region') || param.get('gte') || param.get('lte')) {
        url += '&filters='
        this.search_form.patchValue({
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          gte: param.get('gte') ? new Date(param.get('gte') || '') : '',
          lte: param.get('lte') ? new Date(param.get('lte') || '') : '',
        })
        if (param.get('gte') || param.get('lte')) {
          param.get('gte') ? url += 'send:gte:' + param.get('gte') + ";" : ""
          param.get('lte') ? url += 'send:lse:' + param.get('lte') + ";" : ""
        }
        param.get('region') ? url += 'region:' + param.get('region') + ";" : ""
      }
      this.getNotifications(url)
    })
  }
  getNotifications(url: any) {
    this.httpService.get(url).subscribe((res: any) => {
      if (res?.status == 200) {
        this.notifications = res.body
        this.isLoading = true
        this.httpService.get('regions').subscribe((data: any) => {
          if (data?.status == 200) {
            data.body.data.forEach((region: any) => {
              res.body.data.forEach((notification: any) => {
                if (notification.region_id == region.id) {
                  notification.region = region
                }
              });
            });
            this.regions = data.body.data
          }
        })
      }
    })
  }
  duplicate(id:any){
    this.router.navigate(['/notifications/manage-notification/duplicate-broadcast'], { queryParams: { id: id } });
  }
  delete_notification(notification: any) {
    this.deleteModal.displayModal = true;
    this.notification = notification
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/notifications'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  onConfirm(event: any) {
    this.httpService.delete("notification_campaign/" + this.notification.id).subscribe(res => {
      if (res?.status === 202) {
        this.notifications.data = this.notifications.data.filter((notification: any) => notification.id !== this.notification.id)
        this.deleteModal.displayModal = false;
      }
    })
  }
  reset_filter() {
    this.search_form.reset()
    this.router.navigate(['/notifications'])
  }
  filter() {
    let search = this.search_form.value
    let gte: any
    let lte: any
    search?.gte ? gte = new Date(search?.gte) : ""
    search?.lte ? lte = new Date(search?.lte) : ""
    let monthg = gte ? gte.getMonth() + 1 : ""
    let monthl = lte ? lte.getMonth() + 1 : ""
    gte ? gte = gte.getFullYear() + "-" + monthg + "-" + gte.getDate() : ""
    lte ? lte = lte.getFullYear() + "-" + monthl + "-" + lte.getDate() : ""
    search.gte = gte
    search.lte = lte
    search = { ...search, page: 1 }
    console.log(search);
    this.router.navigate(['/notifications'], { queryParams: search, queryParamsHandling: "merge" });
  }
}
