<div class="white-back w-100" *ngIf="isLoading;else notYetLoaded ">
  <div class="pt-6 pb-4 d-flex align-items-center px-3">
    <button class="btn p-0" [routerLink]="['/orders']">
      <img src="../../../../../../assets/images/svg/Icon-arrow.svg" class="mr-2 backArrow" alt="">
    </button>
    <span class="avenirArabic f30 fw85 black">Statistiques des annulations</span>
  </div>
  <div class="d-flex align-items-center p-3">
    <p-dropdown [options]="stats_duration" [(ngModel)]="stat_duration" placeholder="Durée des statistiques" (onChange)="filter()" [showClear]="false" class="mr-3" ></p-dropdown>
  </div>
  <div class="p-3">
    <div *ngFor="let stat of stats">
      <a class="avenirArabic f24 fw85 black" [routerLink]="['/orders']" [queryParams]="{ region : stat.region_id }" >{{stat.region_name}}</a>
      <div class="d-flex mb-4 mt-2 align-items-center">
        <div class="">
         <div class="d-flex flex-column justify-content-between">
          <span class="avenirArabic f16  black mb-2">{{stat.canceled}}</span>
          <span class="avenirArabic f14 grey">({{stat.cancel_rate}}%)</span>
         </div>
        </div>
        <div class="d-flex pl-3"  [ngStyle]="{width: stat.width+'%'}">
          <a [routerLink]="['/orders']" [queryParams]="{ cancel_reason : item.key,stats_duration:stat_duration,region:stat.region_id }" target="_blank" routerLinkActive="router-link-active"  class="bar_stats d-flex justify-content-center align-items-center" *ngFor="let item of stat.data"
            [ngStyle]="{width: item.rate+'%',backgroundColor:item.color}" pTooltip='{{item.label}}' tooltipPosition="top">
            <span class="avenirRegular f16  text-white">{{item.value}}</span>
        </a>
      </div>
      </div>

</div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #nosection_items>
  <div class="text-center black  avenirBlack f18 p-3">Commandes n'a pas encore des statistiques</div>
</ng-template>
