<div class="white-back w-100" *ngIf="isLoading;else notYetLoaded ">
  <div class="d-flex justify-content-between px-3 pt-6 pb-4 ">
    <div class="d-flex align-items-center ">
      <button class="btn p-0" [routerLink]="['/stories-management']">
        <img src="../../../../../../assets/images/svg/Icon-arrow.svg" class="mr-2 backArrow" alt="">
      </button>
      <span class="avenirArabic f30 fw85 black">Gestion des Partenaires</span>
    </div>
    <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="add_partner()">Ajouter un
      partenaire</button>
  </div>
  <p-table [value]="partners" [reorderableColumns]="true" responsiveLayout="scroll">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th></th>
        <th style="width: 2%"></th>
        <th>Nom</th>
        <th>Opérations</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">

      <tr [pReorderableRow]="index">
        <td>
          <img src="../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index" alt=""
            (dragend)="changeSectionOrder()">
        </td>
        <td class="f16 avenirRegular black">
          <img *ngIf="rowData?.logo; " src="{{ rowData?.logo }}" class="logo_img mr-2" alt="" />
        </td>
        <td class="f16 avenirRegular black">
          {{ rowData.name }}
        </td>
        <td>
          <button class="btn p-0 mr-2" (click)="edit_partner(rowData)">
            <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
          </button>
          <button class="btn p-0" (click)="delete_story_partner(rowData)">
            <img src="../../../../../assets/images/svg/delete..svg" alt="">
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #nosection_items>
  <div class="text-center black  avenirBlack f18 p-3">Pas encore des partenaires appayer sur "ajouter un partenaire"
    pour en insérer</div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="display" [modal]="true" [style]="{width: '592px'}" [draggable]="false"
  [resizable]="false">
  <form class="" [formGroup]="partner_form" autocomplete="off">
    <div class="mb-3">
      <span class="avenirMedium f35 fw85 main-color"> {{edit?'Modifier le partenaire':'Ajouter un partenaire'}}
      </span>
    </div>
    <div class="d-flex align-items-center justify-content-center mb-4">
      <label class="customUpload flex-column p-5 mr-3"
        [ngStyle]="{'background-image':partner_form.get('logo')?.value  ? 'url('+partner_form.get('logo')?.value+')' : '' }">
        <input type="file" (change)="showPreviewImage($event)" accept=".jpeg,.png,.bmp,.gif,.svg" />
        <img src="../../../../../assets/images/svg/greenCamera.svg" class="cameraImg" alt="">
      </label>
      <span class="f18 avenirRegular black">Ajouter une image </span>
    </div>
    <input type="text" class="form-control mdStl avenirMedium f18 mb-4" id="name"
      placeholder="Entrez le nom du partenaire" formControlName="name"
      [ngClass]="{'is-invalid': submit&&f['name'].errors}">
    <!-- <input type="number" class="form-control mdStl avenirMedium f18 mb-4" id="name" placeholder="Entrez le weight"
      formControlName="weight"> -->
    <div class="d-flex justify-content-end pt-3">
      <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4" (click)="display=false">Annuler </button>
      <button class="btn green-back avenirMedium f16  text-white px-4"
        (click)="manage_partner()">{{edit?"Modifier":"Ajouter"}}</button>
    </div>
  </form>
</p-dialog>
<app-confirmation #deleteConfirmation (onConfirm)="confirmDelete($event)" [name]="'Round '+this.partner?.name">
</app-confirmation>
