<div class="p-3">
  <div class="pt-6 pb-5 d-flex align-items-center">
    <button class="btn" (click)="back()">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" alt="">
    </button>
    <span class="main-color f56 avenirHeavy">{{text}}</span>
  </div>
  <div class="row">
    <div class="col-md-2">
      <ul class="nav flex-column p-2 white-back">
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['profile']" routerLinkActive="router-link-active"
            (click)="changeText('profile')">
            <span class="avenirMedium f18">Profile</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['users']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('users')">
            <span class="avenirMedium f18">Utilisateurs</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['contact']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('contact')">
            <span class="avenirMedium f18">Contact</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['menu']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('menu')">
            <span class="avenirMedium f18">Menu</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['delivery-zone']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('delivery-zone')">
            <span class="avenirMedium f18">Zone de livraison</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['gallery']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('gallery')">
            <span class="avenirMedium f18">Galerie</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['opening-hours']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('opening-hours')">
            <span class="avenirMedium f18">Heures d'ouverture</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['sales-promotions']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('sales-promotions')">
            <span class="avenirMedium f18">Promotions</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['store-tickets']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('store-tickets')">
            <span class="avenirMedium f18">Requêtes</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['transactions']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active" (click)="changeText('transactions')">
            <span class="avenirMedium f18">Transactions</span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['/notifications/manage-notification/create-broadcast/7']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active">
            <span class="avenirMedium f18">Notification <span class="pi pi-external-link"></span>
          </span>
          </a>
        </li>
        <li class="nav-item p-2">
          <a class="nav-link" [routerLink]="['/promo_code/manage_promo_code/create_promo_code/7']" [ngClass]="{'disabledAnchor': !edit}"
            routerLinkActive="router-link-active">
            <span class="avenirMedium f18">Code promo <span class="pi pi-external-link"></span>
          </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
