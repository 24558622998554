import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../assets/json/35BHPazK8d.json'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
