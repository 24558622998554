import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  getDirtyValues(form: any) {
    let dirtyValues:any=  {};

    Object.keys(form.controls)
        .forEach(key => {
            const currentControl = form.controls[key];
            if (currentControl.dirty && currentControl.value) {
                console.log(currentControl);
                
                if (currentControl.controls)
                    dirtyValues[key] = this.getDirtyValues(currentControl);
                else
                    dirtyValues[key] = currentControl.value;
            }
        });

    return dirtyValues;
}

    onTranslationsChange(form: FormGroup, key: string, lang: string, event: any) {
        const translations: FormArray = form.get('translations') as FormArray;
        const index = translations.controls.findIndex(x => Object.keys(x.value || {})[0] === key);

        if (event.target.value) {
        if(index !== -1){
            let obj1 = translations.at(index)?.value;
            let obj2 = {
            [key]: {
                [lang]: event.target.value
            }
            }
            let obj3 = {
            [key]:{
                ...obj1[key],
                ...obj2[key]
            }
            }
            translations.at(index).setValue(obj3);
        }else{
            translations.push(new FormControl({
            [key]: {
                [lang]: event.target.value
            }
            }));
        }
        } else {
        if(index !== -1){
            let obj1 = translations.at(index)?.value;
            delete obj1[key][lang];
            if(Object.keys(obj1[key] || {}).length === 0){
            translations.removeAt(index);
            }else{
            translations.at(index).setValue(obj1);
            }
        }
        }

        console.log(translations)
        return translations;
    }

    patchTranslationsData(form: FormGroup, data: any){
        const translations: FormArray = form.get('translations') as FormArray;
        while (translations.length !== 0) {
          translations.removeAt(0)
        }
        if(data?.translations){
          Object.entries(data.translations || []).forEach(([key, value]: any) => {
            translations.push(new FormControl({
              [key]: value
            }));
          });
        }
        return translations;
    }

}
