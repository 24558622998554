import { Component, OnInit, } from "@angular/core";
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { SplashScreenStateService } from 'src/app/services/splash-screen-state.service';

@Component({
  selector: "splash-screen",
  templateUrl: "./splash-screen.component.html",
  styleUrls: ["./splash-screen.component.scss"]
})

export class SplashScreenComponent implements OnInit {
  options: AnimationOptions = {
    path: this.selectOption()
  };
  public opacityChange = 1;
  public splashTransition: any;
  public showSplash = true;
  readonly ANIMATION_DURATION = 1;
  constructor(private splashScreenStateService: SplashScreenStateService) { }
  ngOnInit(): void {
    this.splashScreenStateService.subscribe((res: any) => {
      this.hideSplashAnimation();
    });
  }
  onAnimate(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
  private hideSplashAnimation() {
    this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
    this.opacityChange = 0;
    setTimeout(() => {
      this.showSplash = !this.showSplash;
    });
  }
  selectOption() {
    let array = ['../../assets/json/lottie.json', '../../assets/json/99273-preparing-food.json', '../../assets/json/99276-loading-utensils.json']
    return array[Math.floor(Math.random() * array.length)]
  }
}
