import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contacts: any;
  contactForm!: FormGroup;
  contact: any
  store_id: any
  edit: boolean = false
  submit: boolean = false
  isLoading: boolean = false
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private httpService: HttpService, private messageService: MessageService) { }
  ngOnInit(): void {
    this.route.parent?.params.subscribe((params: any) => {
      this.store_id = params.id
    })
    this.getStore(this.store_id);
    this.contactForm = this.fb.group({
      label: new FormControl("", [Validators.required, Validators.min(1), Validators.max(32)]),
      phone: new FormControl("", [Validators.required]),
      note: new FormControl(""),
    });
  }
  get f() { return this.contactForm.controls }
  getStore(id: any) {
    this.httpService.get("store/" + id).subscribe((data: any) => {
      if (data?.status === 200) {
        this.isLoading = true
        this.contacts = data.body.data.data?.additional_phones;
      }
    })
  }
  editContact(contact: any) {
    this.contactForm.patchValue({
      label: contact.label,
      phone: contact.phone,
      note: contact.note
    })
    this.contact = contact
    this.edit = true
  }
  manageContact() {
    this.submit = true
    if (this.contactForm.invalid) {
      return
    }
    if (this.edit) {
      Object.assign(this.contact, this.contactForm.value)
      let form = { key: "additional_phones", value: this.contacts }
      this.httpService.post('store/' + this.store_id + '/update_data', form).subscribe((data: any) => {
        if (data?.status === 202) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Contact a été modifié avec succès' });
        }
      })
    } else {
      let cntacts = new Array()
      // this.contacts = []
      if (this.contacts?.length > 0) {
        this.contacts.forEach((element: any) => {
          cntacts.push(element)
        });
      }
      cntacts.push(this.contactForm.value)
      let form = { key: "additional_phones", value: cntacts }
      this.httpService.post('store/' + this.store_id + '/update_data', form).subscribe((data: any) => {
        if (data?.status === 202) {
          console.log(this.contacts, cntacts);
          this.edit = true
          this.contact = cntacts[cntacts.length - 1]
          this.contacts = cntacts
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Contact a été ajouté avec succès' });
        }
      })
    }
  }
  onConfirm(event: any) {
    this.contacts = this.contacts.filter((contact: any) => contact.label !== this.contact.label)
    let form = { key: "additional_phones", value: this.contacts }
    this.httpService.post('store/' + this.store_id + '/update_data', form).subscribe((data: any) => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Contact a été supprimé avec succès' });
        this.deleteModal.displayModal = false
      }
    })
    console.log(this.contacts);

  }
  delete_contact(contact: any) {
    this.contact = contact
    this.deleteModal.displayModal = true
  }
}
