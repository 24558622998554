import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// ************************************************************************Components************************************************************************
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HomeComponent } from './components/home/home.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { GlobalAnalyticsComponent } from './components/global-analytics/global-analytics.component';
import { StoresComponent } from './components/stores/stores.component';
import { UsersComponent } from './components/users/users.component';
import { DeliveryFeesComponent } from './components/delivery-fees/delivery-fees.component';
import { RegionsComponent } from './components/regions/regions.component';
import { HubsComponent } from './components/hubs/hubs.component';
import { TestComponent } from './components/test/test.component';
import { PhoneListComponent } from './components/regions/phone-list/phone-list.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { StoreDetailsComponent } from './components/stores/store-details/store-details.component';
import { ProfileComponent } from './components/stores/store-details/profile/profile.component';
import { UsrsComponent } from './components/stores/store-details/usrs/usrs.component';
import { GalleryComponent } from './components/stores/store-details/gallery/gallery.component';
import { DeliveryZoneComponent } from './components/stores/store-details/delivery-zone/delivery-zone.component';
import { MenuComponent } from './components/stores/store-details/menu/menu.component';
import { SectionsComponent } from './components/stores/store-details/menu/sections/sections.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OpeningHoursComponent } from './components/stores/store-details/opening-hours/opening-hours.component';
import { ContactComponent } from './components/stores/store-details/contact/contact.component';
import { SalesPromotionComponent } from './components/stores/store-details/sales-promotion/sales-promotion.component';
import { OrdersComponent } from './components/orders/orders.component';
import { BannerComponent } from './components/banner/banner.component';
import { RewardsComponent } from './components/rewards/rewards.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RewardsChildComponent } from './components/rewards/rewards-child/rewards-child.component';
import { PointsProgramsComponent } from './components/rewards/points-programs/points-programs.component';
import { TransactionPointsComponent } from './components/rewards/transaction-points/transaction-points.component';
import { ManageRewardComponent } from './components/rewards/manage-reward/manage-reward.component';
import { ManagePromoCodeComponent } from './components/promo-code/manage-promo-code/manage-promo-code.component';
import { AssistanceComponent } from './components/assistance/assistance.component';
import { ManageNotificationComponent } from './components/notifications/manage-notification/manage-notification.component';
import { PushComponent } from './components/notifications/manage-notification/push/push.component';
import { EmailComponent } from './components/notifications/manage-notification/email/email.component';
import { SMSComponent } from './components/notifications/manage-notification/sms/sms.component';
import { PageNotFoundComponent } from './components/errors/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './components/errors/permission-denied/permission-denied.component';
import { StoreTicketsComponent } from './components/stores/store-details/store-tickets/store-tickets.component';
import { StoresTicketsComponent } from './components/tickets/stores-tickets/stores-tickets.component';
import { StaffComponent } from './components/hubs/staff/staff.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { StoreTransactionsComponent } from './components/stores/store-details/store-transactions/store-transactions.component';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { StatisticsComponent } from './components/stores/store-details/menu/statistics/statistics.component';
import { CancelledOrdersStatsComponent } from './components/orders/cancelled-orders-stats/cancelled-orders-stats.component';
import { ManageCategoriesComponent } from './components/rewards/manage-categories/manage-categories.component';
// ************************************************************************ Guards************************************************************************
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuardGuard } from './guards/permission-guard.guard';
import { StoriesManagementComponent } from './components/stories-management/stories-management.component';
import { PartnersManagementComponent } from './components/stories-management/partners-management/partners-management.component';
import { ClientTicketsComponent } from './components/tickets/client-tickets/client-tickets.component';
import { TicketsComponent } from './components/tickets/tickets.component';
const   notification_children= [
  { path: "push", component: PushComponent },
  { path: "email", component: EmailComponent },
  { path: "sms", component: SMSComponent },
  { path: '', redirectTo: 'push', pathMatch: 'full' },
]
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "analytics",
        component: AnalyticsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_view_analytics' },
      },
      {
        path: '',
        redirectTo:'analytics',
        pathMatch: 'full'
      },
      {
        path: "global-analytics",
        component: GlobalAnalyticsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_view_analytics' },
      },
      {
        path: "stores",
        component: StoresComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
      },
      {
        path: "banner",
        component: BannerComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
      },
      {
        path: "promo_code",
        component: PromoCodeComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
      },
      {
        path: "promo_code/manage_promo_code/:code",
        component: ManagePromoCodeComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
      },
      {
        path: "promo_code/manage_promo_code/:code/:store_id",
        component: ManagePromoCodeComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
      },

      {
        path: "transactions",
        component: TransactionsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_hubs' },
      },
      {
        path: "assistance",
        component: AssistanceComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_other' },
      },
      {
        path: "settings",
        component: SettingsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_view_analytics' },
      },
      {
        path: "notifications",
        component: NotificationsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
      },
      {
        path: "notifications/manage-notification/:id",
        component: ManageNotificationComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
        children: notification_children
      },
      {
        path: "notifications/manage-notification/:id/:store_id",
        component: ManageNotificationComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
        children: notification_children
      },
      {
        path: "orders",
        component: OrdersComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_orders' },
      },
      {
        path: "orders/orders_stats",
        component: CancelledOrdersStatsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_orders' },
      },

      {
        path: "stores/details/:id",
        component: StoreDetailsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
        children: [
          {
            path: "profile",
            component: ProfileComponent
          },
          {
            path: "create_store",
            component: ProfileComponent
          },
          {
            path: "users",
            component: UsrsComponent,
          },
          {
            path: "gallery",
            component: GalleryComponent
          },
          {
            path: "menu",
            component: MenuComponent
          },
          {
            path: "contact",
            component: ContactComponent
          },
          {
            path: "sales-promotions",
            component: SalesPromotionComponent
          },
          {
            path: "delivery-zone",
            component: DeliveryZoneComponent
          },
          {
            path: "opening-hours",
            component: OpeningHoursComponent
          },
          {
            path: "store-tickets",
            component: StoreTicketsComponent
          },
          {
            path: "transactions",
            component: StoreTransactionsComponent,
          },
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full'
          },
        ],
      },
      {
        path: "stores/details/:id/section/:section_id",
        component: SectionsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
      },
      {
        path: "stores/details/:id/statistics",
        component: StatisticsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
      },
      {
        path: "stores/sections/:id",
        component: SectionsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
      },
      {
        path: "hubs",
        component: HubsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_hubs' },
      },
      {
        path: "hubs/:id",
        component: StaffComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_hubs' },
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_other' },
      },
      {
        path: "stories-management",
        component: StoriesManagementComponent,
      },
      {
        path: "stories-management/partners-management",
        component: PartnersManagementComponent,
      },
      {
        path: "regions",
        component: RegionsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_hubs' },
      },
      {
        path: "regions/phoneList",
        component: PhoneListComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_hubs' },
      },
      {
        path: "test",
        component: TestComponent
      },
      {
        path: "blank-page",
        component: BlankPageComponent
      },
      {
        path: "splash",
        component: SplashScreenComponent
      },
      {
        path: "delivery-fees",
        component: DeliveryFeesComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_delivery_fees' },
      },
      {
        path: "rewards",
        component: RewardsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_offers_promos' },
        children: [
          {
            path: "rewards",
            component: RewardsChildComponent,
          },
          {
            path: "rewards/manage-reward/:id",
            component: ManageRewardComponent,
          },
          {
            path: "rewards/manage-categories",
            component: ManageCategoriesComponent,
          },
          {
            path: "points-programs",
            component: PointsProgramsComponent
          },
          {
            path: "transaction-points",
            component: TransactionPointsComponent
          },

          {
            path: '',
            redirectTo: 'rewards',
            pathMatch: 'full'
          },
        ]
      },
      {
        path: "tickets",
        component: TicketsComponent,
        canActivate: [PermissionGuardGuard],
        data: { permission: 'can_manage_stores' },
        children: [
          {
            path: "client-tickets",
            component: ClientTicketsComponent,
          },
          {
            path: "store-tickets",
            component: StoresTicketsComponent,
          },
          {
            path: '',
            redirectTo: 'store-tickets',
            pathMatch: 'full'
          },
        ]
      },

    ]
  },
  {
    path: "signIn",
    component: SignInComponent,
  },
  {
    path: "countries",
    component: CountrySelectorComponent,
  },
  {
    path: "permission_denied",
    component: PermissionDeniedComponent,
  },
  {
    path: "page_not_found",
    component: PageNotFoundComponent,
  },

  {
    path: '**',
    component: PageNotFoundComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
