import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  store: any
  galeries!: any[]
  selectedFile: any = null;
  imgUrl!: any[];
  display: boolean = false;
  photoForm!: FormGroup;
  submit: boolean = false;
  photo: any;
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;

  constructor(private messageService: MessageService, private route: ActivatedRoute, private httpService: HttpService, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.route.parent?.params.subscribe((url: any) => {
      this.getStore(url.id)
      this.getStoreGallery(url.id)
      this.photoForm = this.fb.group({
        title: new FormControl("", [Validators.minLength(4), Validators.maxLength(64), Validators.required]),
        description: new FormControl("", Validators.maxLength(512)),
        store_id: url.id,
      });
    })
  }
  get f() { return this.photoForm.controls; }
  getStore(storeId: number) {
    this.httpService.get("store/" + storeId).subscribe((data: any) => {
      if (data?.status === 200) {
        this.store = data.body.data
      }
    })
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
    }
  }
  addPhoto() {
    this.submit = true;
    if (this.photoForm.invalid||this.selectedFile===null) {
      return
    }
    const fd=new FormData();
    fd.append('photo',this.selectedFile);
    fd.append('title',this.photoForm.value.title);
    fd.append('description',this.photoForm.value.description);
    fd.append('store_id',this.photoForm.value.store_id);
    this.httpService.post("photo_gallery",fd).subscribe((data: any) => {
      if (data?.status === 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Photo ajouté avec succès' });
        this.galeries.push(data.body.data)
        this.selectedFile = null;
        this.display = false;
      }})
  }
  getStoreGallery(storeId: number) {
    this.httpService.get("photo_galleries?filters=store_id:" + storeId).subscribe((data: any) => {
      if (data?.status === 200) {
        this.galeries = data.body.data
      }
    })
  }
  deletePhoto(photo: number) {
    this.photo = photo;
    this.deleteModal.displayModal = true;
  }
  onConfirm(event: any) {
    this.httpService.delete("photo_gallery/" + this.photo.id).subscribe((data: any) => {
      if (data?.status === 204) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Photo supprimé avec succès' });
        this.galeries = this.galeries.filter((item: any) => item.id !== this.photo.id)
        this.deleteModal.displayModal = false;
      }})
  }
}
