<div class="px-3">
    <div class="d-flex align-items-center py-5">
        <button class="btn p-0" (click)="back()">
            <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
        </button>
        <span class="f40 avenirMedium main-color fw85">Créer un broadcast</span>
    </div>
    <!-- <nav class="nav">
        <a class="nav-link grey-back" [routerLink]="['push']" routerLinkActive="activeLink">
            <div class="d-flex align-items-center">
                <svg class="icon mr-2">
                    <use xlink:href="../../../../assets/images/svg/Icon - bell.svg#Group_3475"></use>
                </svg>
                <span class="avenirRegular f20 greyColor">Push</span>
            </div>
        </a>
        <a class="nav-link grey-back" [routerLink]="['email']" routerLinkActive="activeLink">
            <div class="d-flex align-items-center">
                <svg class="icon mr-2">
                    <use xlink:href="../../../../assets/images/svg/Icon - email.svg#Group_3475"></use>
                </svg>
                <span class="avenirRegular f20 greyColor">Email</span>
            </div>
        </a>
        <a class="nav-link grey-back" [routerLink]="['sms']" routerLinkActive="activeLink">
            <div class="d-flex align-items-center">
                <svg class="icon mr-2">
                    <use xlink:href="../../../../assets/images/svg/Icon - message.svg#Group_3475"></use>
                </svg>
                <span class="avenirRegular f20 greyColor">SMS</span>
            </div>
        </a>
    </nav> -->
</div>
<div class="px-2 pt-5 pb-2">
    <router-outlet></router-outlet>
</div>