import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.scss']
})
export class ManageCategoriesComponent implements OnInit {
  points_rewards_categorys: any
  point_category_modal: boolean = false
  categoryForm!: FormGroup;
  submit: boolean = false
  category: any
  edit = false
  configLangs: any[] = [];
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent

  constructor(private http: HttpService, private fb: FormBuilder, private messageService: MessageService, private appConfig: AppConfigService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.getPointsRewardsCategory()
    this.categoryForm = this.fb.group({
      name: new FormControl("", [Validators.maxLength(256), Validators.required]),
      translations: new FormArray([])
    })
    this.configLangs = this.appConfig.configLangs;
  }
  get f() { return this.categoryForm.controls; }
  getPointsRewardsCategory() {
    this.http.get('points_rewards_categories').subscribe((res: any) => {
      if (res?.status === 200) {
        console.log(res.body);
        this.points_rewards_categorys = res.body
      }
    })
  }
  addTransactionPointCategory() {
    this.point_category_modal = true
    this.categoryForm.reset()
    this.edit = false
  }
  editTransactionPointCategory(category: any) {
    console.log(category);
    this.edit = true
    this.point_category_modal = true
    this.categoryForm.patchValue({
      name: category.name
    })
    this.category = category
    this.patchTranslationsData(this.categoryForm, category)
  }
  delete_category(category: any) {
    this.deleteModal.displayModal = true
    this.category = category
  }
  onConfirm(event: any) {
    this.http.delete("points_rewards_categories/" + this.category.id).subscribe(res => {
      if (res?.status === 204) {
        this.deleteModal.displayModal = false;
        this.points_rewards_categorys.data = this.points_rewards_categorys.data.filter((item: any) => item.id !== this.category.id)
      }
    })
  }
  manage_category() {
    console.log(this.categoryForm.value);
    this.submit = true
    if (this.categoryForm.invalid) {
      return
    }
    const data: any = {
      name: this.categoryForm.value.name
    }
    let translations: any = [...this.categoryForm.value.translations];
    translations?.map((element: any) => {
      Object.keys(element || []).forEach((fst_key: any) => {
        let temp = <any>{}
        Object.keys(element[fst_key] || []).forEach((snd_key: any) => {
          temp[fst_key] = <any>{
            ...temp[fst_key],
            [snd_key]: element[fst_key][snd_key]
          }
          data.translations = {
            ...data.translations,
            ...temp
          }
        })
      })
    })
    if (this.edit) {
      this.http.post('points_rewards_categories/' + this.category.id, data).subscribe((res: any) => {
        if (res?.status === 200) {
          this.point_category_modal = false
          Object.assign(this.category, res.body.data)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Catégorie a été modifié avec succès' });
        }
      })
    } else {
      this.http.post('points_rewards_categories/', data).subscribe((res: any) => {
        if (res?.status === 200) {
          this.point_category_modal = false
          this.points_rewards_categorys.data.push(res.body.data)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Catégorie a été modifié avec succès' });
        }
      })
    }
  }
  onTranslationsChange(form: FormGroup, key: string, lang: string, event: any) {
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.onTranslationsChange(form, key, lang, event);
  }
  patchTranslationsData(form: FormGroup, data: any) {
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.patchTranslationsData(form, data);
  }
  changeOrder() {
    let order: any = []
    let form: any
    this.points_rewards_categorys.data.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.http.post("points_rewards_categories/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
}
