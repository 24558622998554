import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  st_count: any
  is_loading: boolean = false
  user: any
  config: any
  constructor(private appConfig: AppConfigService, private router: Router, private confirmationService: ConfirmationService, private http_service: HttpService) { }
  ngOnInit(): void {
    this.get_store_tickets_count()
    this.get_user()
    this.config = this.appConfig.config
  }
  logout() {
    localStorage.clear()
    this.router.navigate(['/signIn']);
  }
  confirm() {
    this.confirmationService.confirm({
      message: 'Êtes vous sur de vouloir vous déconnecter?',
      accept: () => {
        this.logout()
      }
    });
  }

  get_store_tickets_count() {
    this.http_service.get('store_tickets/count?with_client_tickets=1').subscribe(res => {
      if (res?.status === 200) {
        this.st_count = res.body.count
      }

    })
  }
  get_user() {
    this.http_service.get('authenticated_user').subscribe((res: any) => {
      if (res?.status === 200) {
        this.user = res.body.data
        let permissions= res.body.data.profile?.permissions?.split(',')
        this.user.permissions=permissions
        setTimeout(() => {
          this.is_loading = true
        }, 2000);
      }
    })
  }
}
