<div class="row">
    <div class="col-md-6 mt-4">
        <div class="form-group mb-4">
            <input type="text" class="form-control mdStl black avenirRegular f18" id="title" placeholder="Destinataire">
        </div>
        <div class="form-group mb-4">
            <input type="text" class="form-control mdStl black avenirRegular f18" id="title" placeholder="Sujet">
        </div>
        <div class="form-group mb-4">
            <textarea class="form-control mdStl black avenirRegular f18" placeholder="Contenu" id="message"
            rows="3"></textarea>
        </div>
        <div class="form-group mb-4">
            <p-calendar [showTime]="true" inputId="basic"dateFormat="dd/mm/yy" placeholder="JJ/MM/AA" appendTo="body" class="avenirMedium whiteBorder">
            </p-calendar>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4">Annuler </button>
            <button class="btn green-back  avenirMedium f16 text-white px-4">Envoyer</button>
        </div>
    </div>
    <div class="col-md-6">
        <p class="f16 avenirRegular pb-4 black">Sujet</p>
        <p class="f16 avenirRegular pb-4"><span class="black">Addresse de destinataire</span><span class="greyColor">&#60;example@example.com&gt;
        </span> </p>
        <p class="f16 avenirRegular greyColor">Content</p>
    </div>
</div>
