import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-points-programs',
  templateUrl: './points-programs.component.html',
  styleUrls: ['./points-programs.component.scss']
})
export class PointsProgramsComponent implements OnInit {
  page: number = 1
  points: any
  display: boolean = false;
  point_program_form!: FormGroup;
  reward_apply!: FormGroup;
  isLoading: boolean = false;
  point_program: any
  displayReward: boolean = false;
  clients!: any
  editRewardForm: boolean = false;
  types: any = [{ label: "Sign up", value: "signup" }, { label: "Suivi sur Instagram", value: "instagram" }, { label: "J’aime sur Facebook", value: "facebook" }, { label: "Completer une commande", value: "complete_order" }, { label: "Partage de l’application", value: "share_app" }, { label: "Partager l’application", value: "rate_app" }, { label: "Inviter un ami", value: "invite_friend" }, { label: "Reward", value: "reward" }]
  constructor(private messageService: MessageService, private httpService: HttpService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,) { }
  ngOnInit(): void {
    this.point_program_form = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.maxLength(128)]),
      type: new FormControl("", [Validators.required]),
      points: new FormControl("", [Validators.required, Validators.maxLength(1024)]),
      name_ar: new FormControl("", Validators.maxLength(128)),
      description: new FormControl("", Validators.maxLength(1024)),
      description_ar: new FormControl("", Validators.maxLength(1024)),
      data: new FormArray([]),
    });
    this.reward_apply = this.fb.group({
      phones: new FormControl("", [Validators.required, Validators.maxLength(250)]),
    });

    this.route.queryParamMap.subscribe(param => {
      if (Number(param.get('page'))) {
        this.page = Number(param.get('page'))
      } else {
        this.page = 1;
      }
      this.pointsPrograms(this.page)
    })
  }
  pointsPrograms(page: any) {
    this.httpService.get("points_programs/?page=" + page).subscribe(data => {
      if (data?.status == 200) {
        this.isLoading = true
        this.points = data.body
        console.log(data);
      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/rewards/points-programs'], { queryParams: { page: this.page } });
  }
  manageReward() {
    console.log(this.point_program_form.value);
    if (this.editRewardForm) {
      this.httpService.post("points_program/" + this.point_program.id, this.point_program_form.value).subscribe(data => {
        if (data?.status == 200) {
          this.display = false;
          Object.assign(this.point_program, data.body.data)
        }
      })
    } else {
      this.httpService.post("points_program", this.point_program_form.value).subscribe(data => {
        if (data?.status == 200) {
          this.display = false;
          this.pointsPrograms(this.page)
        }
      })
    }
  }
  addReward(point: any) {
    this.displayReward = true;
    this.point_program = point;
    this.reward_apply.reset();
    console.log(this.point_program);

  }
  searchClients(event: any) {
    this.httpService.get('clients?filters=phone:sw:' + event.query).subscribe((data: any) => {
      if (data?.status == 200) {
        this.clients = data.body.data
      }
    })
  }
  applyReward() {
    if (this.point_program.type === "reward") {
      if (!this.reward_apply.value.phones.phone ) {
        return
      }
      let form = { phones: this.reward_apply.value.phones.phone }
      this.httpService.post("points_program/apply_reward/" + this.point_program.id, form).subscribe(data => {
        if (data?.status == 200) {
          this.displayReward = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Reward appliqué avec succéss' });
        }
      })
    }
  }
  editReward(point: any) {
    this.display = true;
    this.editRewardForm = true;
    this.point_program = point;
    this.point_program_form.patchValue({
      name: point.name,
      type: point.type,
      points: point.points,
      name_ar: point.name_ar,
      description: point.description,
      description_ar: point.description_ar,
      data: point.data,
    });
  }

  newReward() {
    this.display = true;
    this.editRewardForm = false;
    this.point_program_form.reset();
  }
}
