<div class="container_class">
  <div class="px-4 pt-6 pb-3">
    <form [formGroup]="search_form" (keydown.enter)="$event.preventDefault()">
      <span class="avenirRegular f72 main-color font-weight-bold">Établissments</span>
      <div class="d-flex align-items-center my-4">
        <button class="btn btn-sel avenirMedium f18 black d-flex align-items-center" (click)="toggleBtn()">{{value
          ===true ?
          "Nom" : "Téléphone"}}
          <img src="../../../assets/images/svg/arrow_drop_down_black_24dp 1.svg" alt="">
        </button>
        <div class="d-flex position-relative mr-3">
          <input *ngIf="value;else phone" type="text" class="form-control avenirMedium f18 black custom-form-control"
            formControlName="name" placeholder="Rechercher">
          <ng-template #phone>
            <input type="text" class="form-control avenirMedium f18 black custom-form-control" formControlName="phone"
              placeholder="Rechercher">
          </ng-template>
          <i class="pi pi-search customIcon"></i>
        </div>
        <p-dropdown optionValue="value" class="mr-3" [options]="categories" formControlName="category"
          optionLabel="label" placeholder="Catégorie" [showClear]="false"></p-dropdown>
        <p-dropdown optionValue="id" class="mr-3" [options]="regions" formControlName="region" optionLabel="name"
          placeholder="Région" [showClear]="false"></p-dropdown>
        <p-dropdown optionValue="id" class="mr-3" [options]="store_types" formControlName="type" optionLabel="name"
          placeholder="Type" [showClear]="false"></p-dropdown>
        <p-dropdown class="mr-3" [options]="visibility" formControlName="visible" placeholder="Visibilité"
          [showClear]="false"></p-dropdown>
        <p-dropdown optionValue="value" class="mr-3 order_by" [options]="Orderables" formControlName="order_by"
          optionLabel="label" placeholder="Trie" [showClear]="false"></p-dropdown>
        <button class="text-white btn avenirRegular br25 mr-3 f16 green-back px-4 filterBtn" style="height:48px"
          (click)="filter()">Filtrer</button>
        <button class="text-white btn avenirRegular br25  f16 main-color white-back filterBtn" (click)="reset_filter()"
          style="height:48px">Réintianiliser</button>
        <button class="btn avenirRegular f20 br25 green-back text-white ml-auto"
          *ngIf="home?.user?.profile?.permissions.includes('can_manage_stores')||home?.user?.type==='superadmin'"
          [routerLink]="['details/0']"><i class="pi pi-plus" style="font-size: 1rem"></i>
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="loading;else notYetLoaded">
    <div class="table-responsive">
      <table *ngIf="stores.data.length>0;else noStores" class="table table-borderless mt-4">
        <thead>
          <tr>
            <th></th>
            <th>Etablissement</th>
            <th>Téléphone</th>
            <th>Date de création </th>
            <th>Statistiques</th>
            <th>Statut</th>
            <th>Catégorie</th>
            <th>Région</th>
            <th>Solde</th>
            <th>Opérations</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let store of stores.data">
            <td>
              <img *ngIf="store?.logo.url;else noImg" src="{{store?.logo?.url}}" alt="" class="img-table">
              <ng-template #noImg>
                <div class="img-table green-back"></div>
              </ng-template>
            </td>
            <td class="avenirMedium">
              <span class="f18 black d-block">{{store.name}} </span>
              <span class="main-color f18"> {{store.rating}} </span><span class="black f13">
                ({{store.ratings}})</span>
            </td>
            <td class="avenirMedium f16 black">{{store.phone}}</td>
            <td class="avenirMedium f16 black">{{store?.created?.date | date:'dd/MM/yyyy'}}</td>
            <td class="avenirMedium f16 black">
              <div class="">
                <div class="d-flex align-items-center flex-wrap justify-content-between">
                  <p-chart type="line" class="d-flex justify-content-center" [data]="store.stackedOrdersData"
                    [options]="stackedPieOptions" width="65px" height="65px">
                  </p-chart>
                  <a class="f16 avenirArabic main-color text-capitalize"
                    [routerLink]="['details/'+store.id+'/statistics']">
                    <u><i class="pi pi-chart-line"></i></u>
                  </a>
                  <p-chart type="line" class="d-flex justify-content-center" [data]="store.stackedvisitsData"
                    [options]="stackedBarOptions" width="200px" height="100px">
                  </p-chart>
                </div>
                <div class="d-flex mt-1 mb-0 pb-0">
                  <div *ngFor="let item of store.orders_stats.last_orders" class="pieShape"
                    [ngClass]="{'canceled': item.status==='canceled'||item.status==='rejected'||item.status==='closed','completed': item.status==='completed','no_answer': item.status==='no_answer','not_available': item.status==='not_available','other': item.status==='other'}"
                    pTooltip='{{item.order_number}}
                              {{item.comment}}
                              {{item.created_at|date:"dd/MM/yyyy HH:mm"}}' tooltipPosition="top">
                  </div>
                </div>
              </div>
            </td>
            <td class="avenirMedium f16 black">
              <span *ngIf="store.visible;else not_visible">Visible</span>
              <span *ngIf="store.free_delivery">, Livraison Gratuite </span>
              <ng-template #not_visible>Invisible</ng-template>
            </td>
            <td class="avenirMedium f16 black">
              <span class="d-block">{{store.category}}</span>
              <span>({{store.type.name}})</span>
            </td>
            <td class="avenirMedium f18 black">{{store.region.name}}</td>
            <td class="avenirMedium f18 clickable" [routerLink]="['details/'+store.id+'/transactions']"><span
                class="main-color">{{store.money.toLocaleString()}}</span><span
                class="greyColor">{{home.config.langs[0].currency_symbol}} </span> </td>
            <td class="avenirMedium f18 black">
              <div class="d-flex align-items-center">
                <a class="btn p-0 mr-2" [routerLink]="['details/'+store.id]">
                  <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                </a>
                <p-inputSwitch [(ngModel)]="store.visible" (onChange)="changeVisibility(store,$event)"></p-inputSwitch>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class=" w-100 ">
      <div class="d-flex justify-content-end">
        <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
          [showFirstLastIcon]="true" [totalRecords]="stores.meta.pagination.total_pages" #paginator
          (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #noStores>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Aucun résultat correspondant à votre recherche changer les paramètres
      et essayer encore une fois </p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="orders_modal" [modal]="true"
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '800px'}" [draggable]="false"
  [resizable]="false">
  <div class="pb-3 pt-4">
    <span class="main-color f30 fw85 avenirRegular">Informations sur les 10 dernières commandes </span>
  </div>
  <div class="d-flex flex-column">
    <div *ngFor="let item of store?.orders_stats?.last_orders" class="avenirRegular f20 black pb-3">
      <span class="fw85"> Crée le : </span><span>{{item.created_at|date :"dd/MM/YYYY HH:mm"}}</span>,
      <span class="fw85">Statut : </span> <span> {{item?.status}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn avenirRegular f16 br5 green-back text-white px-4 " (click)="orders_modal=false">Retour</button>
  </div>
</p-dialog>
