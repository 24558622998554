import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SMSComponent implements OnInit {
  sms_form!: FormGroup;
  clients!: any

  constructor(private fb: FormBuilder,private httpService:HttpService) { }

  ngOnInit(): void {
    this.sms_form = this.fb.group({
      name: new FormControl("",[Validators.required]),
      title: new FormControl("",[Validators.required]),
      body: new FormControl("",[Validators.required]),
      subtitle: new FormControl("",Validators.maxLength(128)),
      url: new FormControl("",Validators.maxLength(64)),
      large_icon: new FormControl("",Validators.maxLength(256)),
      send_at: new FormControl("",),
      region_id: new FormControl(""),
      hub_id: new FormControl("",),     
    });
  }
  searchClients(event: any) {
    this.httpService.get('clients?filters=phone:sw:'+event.query).subscribe((data: any) => {
      if (data?.status == 200) {
        this.clients = data.body.data
      }
    })
  }
}
