import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit {
  text: any
  edit!: boolean
  constructor(private route: ActivatedRoute, private location: Location, private router: Router) {
    this.changeText(this.route?.snapshot?.firstChild?.routeConfig?.path)
    this.router.events.subscribe((val: any) => {
      this.changeText(this.route?.snapshot?.firstChild?.routeConfig?.path)
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params?.id !== "0") {
        this.edit = true
      }
      else {
        this.edit = false
      }
    })
  }
  changeText(route: any) {
    switch (route) {
      case "users":
        this.text = "Gérer les utilisateurs"
        break;
      case "profile":
        this.text = "Gérer l'Établissement"
        break;
      case "gallery":
        this.text = "Gallery"
        break;
      case "delivery-zone":
        this.text = "Gérer les zones de livraison"
        break;
      case "menu":
        this.text = "Gérer les menus"
        break;
      case "contact":
        this.text = "Gérer les contacts"
        break;
      case "sales-promotions":
        this.text = "Gérer les promotions"
        break;
      case "opening-hours":
        this.text = "Gérer les heures d'ouverture"
        break;
      case "store-tickets":
        this.text = "Créer une requête"
        break;
      case "transactions":
        this.text = "Gérer les transactions"
        break;
    }
  }

  back() {
    this.location.back()
  }

}
