<div class="d-flex align-items-center py-5 px-3">
    <button class="btn p-0" (click)="back()">
        <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">Creation du Promo Code</span>
</div>
<div class="m-3 white-back px-3 py-5 br10">
    <form *ngIf="promoCodeForm" [formGroup]="promoCodeForm" autocomplete="off">
        <div class="row no-gutters">
            <div class="col-md-7">
                <div class="pb-5">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="code"
                        placeholder="Code promo" [ngClass]="{'is-invalid': f['code'].errors&&submit}">
                </div>
                <div class="pb-5">
                    <textarea class="form-control mdStl avenirRegular f18" placeholder="Description"
                        formControlName="description" [ngClass]="{'is-invalid': f['description'].errors&&submit}"
                        id="message" rows="5"></textarea>
                </div>
                <div class="row no-gutters pb-5 align-items-center">
                    <div class="col-md-5 pr-3">
                        <p-dropdown class="mr-4" [options]="promo_types" optionValue="value" optionLabel="name"
                            placeholder="Type de promo" [showClear]="false" formControlName="type"
                            [ngClass]="{'is-invalid': f['type'].errors&&submit}"></p-dropdown>
                    </div>
                    <div class="col-md-7" [ngSwitch]="promoCodeForm.get('type')?.value">
                        <div *ngSwitchCase="'value'">
                            <div class="input-group">
                                <input type="number" class="form-control mdStl avenirRegular f18 black"
                                    placeholder="Remise" aria-describedby="basic-addon2"
                                    [ngClass]="{'is-invalid': f['value'].errors&&submit}" formControlName="value">
                                <div class="input-group-append">
                                    <span class="input-group-text fw85 avenirRegular f18 main-color"
                                        id="basic-addon2">{{appconfig.config.langs[0].currency_symbol}} </span>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'percentage'">
                            <div class="input-group">
                                <input type="number" class="form-control mdStl avenirRegular f18 black"
                                    placeholder="Pourcentage" aria-describedby="basic-addon2"
                                    [ngClass]="{'is-invalid': f['value'].errors&&submit}" formControlName="value">
                                <div class="input-group-append">
                                    <span class="input-group-text fw85 avenirRegular f18 main-color"
                                        id="basic-addon2">%</span>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'free_delivery'">
                            <span class="main-color d-inline-block fw85 px-3 free">FREE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters pb-5">
            <div class="col-md-9">
                <div class="row no-gutters">
                    <div class="col-md-6 pr-5">
                        <div class="input-group">
                            <input type="text" class="form-control mdStl avenirRegular f19 black"
                                placeholder="Nombre d'utilisations" formControlName="uses"
                                [ngClass]="{'is-invalid': f['uses'].errors&&submit}" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text fw85 avenirRegular f18 main-color"
                                    id="basic-addon2">Fois</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control mdStl avenirRegular f18 black"
                                placeholder="Prix minimale" formControlName="min_price"
                                [ngClass]="{'is-invalid': f['min_price'].errors&&submit}"
                                aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text fw85 avenirRegular f18 main-color"
                                    id="basic-addon2">{{appconfig.config.langs[0].currency_symbol}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters pb-5">
            <div class="col-md-9">
                <div class="row no-gutters">
                    <div class="col-md-6 pr-5">
                        <p-calendar inputId="basic" [ngClass]="{'is-invalid': f['expires_at'].errors&&submit}" formControlName="expires_at"
                            dateFormat="dd/mm/yy" appendTo="body" class="avenirMedium" placeholder="Date d'expiration">
                        </p-calendar>
                        <!-- <span *ngIf="edit;else modification" class="f12 avenirRegular main-color">La date d'expiration
                            ne peut pas être changée lors de la modification</span> -->
                        <ng-template #modification>
                            <span class="f12 avenirRegular main-color"> La date d'expiration ne peut pas être changée
                                plus tard</span>
                        </ng-template>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control mdStl avenirRegular f18 black"
                                placeholder="Jours de validité"
                                [ngClass]="{'is-invalid': f['validity_days'].errors&&submit}"
                                formControlName="validity_days" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text fw85 avenirRegular f18 main-color"
                                    id="basic-addon2">Jours</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-md-7">
                <div class="row no-gutters pb-4 align-items-center">
                    <div class="col-md-5 pr-3">
                        <p-multiSelect [options]="regions" appendTo="body"
                            [ngClass]="{'is-invalid': f['limit_regions'].errors&&submit}"
                            formControlName="limit_regions" [showHeader]="true" defaultLabel="Régions"
                            optionLabel="name" [(ngModel)]="selectedRegions" (onPanelHide)="getStores()"></p-multiSelect>
                    </div>
                    <div class="col-md-7">
                      <p-multiSelect *ngIf="stores" [options]="stores" appendTo="body" [filter]="true" filterBy="name"
                      formControlName="limit_stores" placeholder="Etablissement" optionLabel="name"
                      [ngClass]="{'is-invalid': f['limit_stores'].errors&&submit}" optionValue="id"
                      [(ngModel)]="selectedStores"></p-multiSelect>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters pb-3">
            <div class="col-md-7">
                <div class="d-flex justify-content-between  align-items-center px-3 py-2 bnr">
                    <span class="avenirMedium f18 ursopct black">Active le code</span>
                    <p-inputSwitch formControlName="active"></p-inputSwitch>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end">
        <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="back()">Annuler </button>
        <button class="btn green-back  avenirRegular f16 text-white px-4"
            (click)="manage_promo_code()">{{edit?"Modifier":"Créer"}}</button>
    </div>
</div>
