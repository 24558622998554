import { Component, OnInit,Input} from '@angular/core';
import { FormControl } from '@angular/forms';
import '@github/markdown-toolbar-element'

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent implements OnInit {
  @Input() controlId!: string

  constructor() { }

  ngOnInit(): void {

  }

}
