<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-3 pt-6 pb-3">
    <span class="avenirRegular f72 main-color fw85">Analytiques Globale</span>
  </div>
  <div class="p-3 d-flex">
    <p-dropdown [options]="regions" optionValue="id" optionLabel="name" placeholder="Tout les régions" [showClear]="false"
      class="mr-3" (onChange)="filterByRegion()" [(ngModel)]="region"></p-dropdown>
    <p-dropdown [options]="filters" optionValue="value" (onChange)="filterByType()" [(ngModel)]="type" optionLabel="title"
      placeholder="Tout les filtres" [showClear]="false"></p-dropdown>
  </div>
  <div *ngFor="let item of analytics" class="white-back ">
    <p-chart type="bar" [data]="item.chart" [options]="stackedOptions" height="400"></p-chart>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
      <app-loading></app-loading>
  </div>
</ng-template>