<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <form [formGroup]="search_form">
      <span class="avenirRegular f72 main-color font-weight-bold">Broadcast</span>
      <div class="d-flex align-items-center my-4 justify-content-between">
        <div class="d-flex align-items-center ">
          <p-dropdown optionValue="id" class="mr-3" [options]="regions" formControlName="region"
            optionLabel="name" placeholder="Région" [showClear]="false"></p-dropdown>
          <p-calendar inputId="basic" formControlName="gte" placeholder="JJ/MM/AA" appendTo="body"
            class="avenirMedium whiteBorder pr-3" dateFormat="dd/mm/yy">
          </p-calendar>
          <p-calendar inputId="basic" formControlName="lte" placeholder="JJ/MM/AA" appendTo="body"
            class="avenirMedium whiteBorder pr-3" dateFormat="dd/mm/yy">
          </p-calendar>
          <button class="btn avenirRegular f20 br25 green-back text-white px-4 mr-3" (click)="filter()">Filtrer
          </button>
          <button class="text-white btn avenirRegular br25 mr-3 f16 main-color white-back filterBtn"
            (click)="reset_filter()" style="height:48px">Réintianiliser</button>
        </div>
        <button class="btn avenirHeavy f20 br25 green-back text-white px-4"
          [routerLink]="['manage-notification/create-broadcast']" routerLinkActive="router-link-active">Créer une
          notification</button>
      </div>
    </form>
  </div>
  <table class="table table-borderless">
    <thead>
      <tr>
        <th>Nom</th>
        <th>Région</th>
        <th>Hub</th>
        <th>Status</th>
        <th>Ouverture</th>
        <th>Date d’envoie</th>
        <th>Opération</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let notification of notifications.data">
        <td class="avenirMedium f18 black">
          <img *ngIf="notification.large_icon;else noImg" src="{{notification.large_icon}}" class="notificationImg"
            alt="">
          {{notification.body}}
          <ng-template #noImg>
            <img src="../../../assets/images/png/Frame 1322.png" alt="">
          </ng-template>
        </td>
        <td class="avenirMedium f18 black">{{notification.region?.name}}</td>
        <td class="avenirMedium f18 black">{{notification.hub?.name}}</td>
        <td class=""><span class="avenirMedium f16 br20 px-3"
            [ngClass]="{'completed': notification.status,'InProgeress': notification.in_progress}">
            <ng-container [ngSwitch]="notification.status">
              <ng-container *ngSwitchCase="'completed'">Terminé</ng-container>
              <ng-container *ngSwitchDefault>output2</ng-container>
            </ng-container>
          </span> </td>
        <td class="avenirMedium f18 black">{{notification.open_rate}}%</td>
        <td class="avenirMedium "><span class="black f18">{{notification.send_at.date |date:"dd/MM/YYYY " }}</span>
          <span class="grey f16">{{notification.send_at.date |date:"hh:mm" }}</span>
        </td>
        <td class="avenirMedium f18 black">
          <button class="btn p-0 mr-2" [routerLink]="['manage-notification/'+notification.id]">
            <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
          </button>
          <button class="btn p-0 mr-2" (click)="duplicate(notification.id)">
            <img src="../../../assets/images/svg/1608634_clone_icon.svg" alt="" class="cloneIcon">
          </button>
          <button class="btn p-0" (click)="delete_notification(notification)">
            <img src="../../../assets/images/svg/delete..svg" alt="">
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class=" w-100 ">
    <div class="d-flex justify-content-end">
      <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
        [showFirstLastIcon]="true" [totalRecords]="notifications.meta.pagination.total_pages" #paginator
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="notification?.name"></app-confirmation>
