<span class="avenirRegular f35 fw85 main-color">Créer une requête</span>
<div class="row">
  <div class="col-md-8">
    <form autocomplete="off" [formGroup]="store_ticket_form" class="pt-3">
      <div class="row no-gutters mb-4 align-items-center">
        <div class="col-md-3">
          <label class="avenirMedium f20 black" for="name">type </label>
        </div>
        <div class="col-md-9">
          <p-dropdown [options]="types" formControlName="type" optionValue="value" optionLabel="label"
            placeholder="Type" [showClear]="false" [ngClass]="{'is-invalid': submit&&f['type'].errors}">
          </p-dropdown>
        </div>
      </div>
      <div class="row no-gutters mb-4 align-items-center">
        <div class="col-md-3">
          <label class="avenirMedium f20 black" for="name">Description </label>
        </div>
        <div class="col-md-9">
          <textarea class="form-control mdStl black avenirRegular f18" formControlName="description"
            placeholder="Ajouter la description" id="message" rows="5"
            [ngClass]="{'is-invalid': submit&&f['description'].errors}"></textarea>
        </div>
      </div>
      <div class="d-flex justify-content-end  align-items-center mt-auto">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
          (click)="store_ticket_form.reset()">Annuler</button>
        <button class="btn green-back px-5 avenirMedium f18 text-white" (click)="create_store_ticket()">Envoyer</button>
      </div>
    </form>
  </div>
</div>
<div class="pt-3">
  <span class=" avenirRegular f16 main-color clickable underlined" [routerLink]="['/tickets/store-tickets']"
    [queryParams]="{ store : store_id }" routerLinkActive="router-link-active"> Liste des réclamations de
    l'établissement</span>
</div>
