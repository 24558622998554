<div class="white-back" *ngIf="items">
  <div class="d-flex justify-content-end">
    <button class="btn avenirRegular f20 br25 green-back text-white mt-3" (click)="clone_display_Modal=true">Cloner les horaires d'ouverture</button>
</div>
  <div class="row no-gutters pb-4 px-6 pt-2">
    <form [formGroup]="openingHoursForm" class="col-xl-6 col-md-8">
      <div class="my-3" formGroupName="dates">
        <div formArrayName="options" *ngFor="let item of items  ; let index = index"
          class="d-flex justify-content-between">
          <div [formGroupName]="index" class="w-100">
            <div [ngSwitch]="item.day"
              class="d-flex justify-content-between avenirRegular f24 fw85 black mb-3 align-items-center">
              <div *ngSwitchCase="'0'">Dimanche</div>
              <div *ngSwitchCase="'1'">Lundi</div>
              <div *ngSwitchCase="'2'">Mardi</div>
              <div *ngSwitchCase="'3'">Mercredi</div>
              <div *ngSwitchCase="'4'">Jeudi</div>
              <div *ngSwitchCase="'5'">Vendredi</div>
              <div *ngSwitchCase="'6'">Samedi</div>
              <div *ngSwitchDefault>dfdsfsd</div>
              <div class="d-flex align-items-center">
                <button class="btn" (click)="add_special_date(item)">
                  <img src="../../../../../assets/images/svg/Icon - add.svg" alt="" />
                </button>
                <p-inputSwitch formControlName="open" (onChange)="changeDetector(item, index)">
                </p-inputSwitch>
              </div>
            </div>
            <div class="mb-5" formArrayName="openings_hours" *ngFor="let fefr of item.openings_hours; let i = index">
              <div [formGroupName]="i" class="d-flex justiy-content-center mb-2">
                <div class="pr-2">
                  <span class="mr-2 avenirRegular f16 black">De :</span>
                  <p-calendar [showTime]="true" dateFormat="dd/mm/yy" [timeOnly]="true" formControlName="start_at"[defaultDate]="defaultDate"
                    (onSelect)="selectHour($event, index, i, 'start_at')" hourFormat="24" [stepMinute]="30" [ngClass]="{
                      'is-invalid':
                        submit &&
                        f[index].get('openings_hours')?.status === 'INVALID'
                    }">
                  </p-calendar>
                </div>
                <div class="d-flex align-items-center">
                  <span class="mr-2 avenirRegular f16 black">À :</span>
                  <p-calendar dateFormat="dd/mm/yy" [showTime]="true" class="pr-3" [timeOnly]="true" [stepMinute]="30"[defaultDate]="defaultDate2"
                    (onSelect)="selectHour($event, index, i, 'finish_at')" formControlName="finish_at" [ngClass]="{
                      'is-invalid':
                        submit &&
                        f[index].get('openings_hours')?.status === 'INVALID'
                    }" hourFormat="24">
                  </p-calendar>
                  <button *ngIf="i === 0" class="btn p-0 btnIcon plus" (click)="addOpeningHour(index)">
                    <img src="../../../../../assets/images/svg/Icon - add.svg" alt="" />
                  </button>
                  <button *ngIf="i !== 0" class="btn p-0 btnIcon delete" (click)="deleteOpeningHour(index, i)">
                    <img src="../../../../../assets/images/svg/Icon-delete.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-4">
          <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4">
            Annuler
          </button>
          <button class="btn green-back avenirRegular f16 text-white px-4" (click)="manageOpeningHour()">
            Modifier
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-special-opening-hours #special_opening_hours [day]="day"
  (onConfirm)="onConfirm($event)"></app-special-opening-hours>
  <p-dialog [(visible)]="clone_display_Modal" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '800px'}" [showHeader]="false">
  <div autocomplete="off" class="pt-4 mb-4">
      <div class="mb-3">
          <span class="avenirMedium f35 fw85 main-color">Cloner les horaires d'ouverture</span>
      </div>
      <div class="row no-gutters align-items-center mb-2">
          <div class="col-md-5">
              <label class="avenirMedium f20" for="name"><span class="black">Choisir un store </span></label>
          </div>
          <div class="col-md-7">
            <p-autoComplete placeholder="Saisir le nom d'établissments" field="name"
            [suggestions]="stores" (completeMethod)="searchStores($event)" [(ngModel)]="store_id"></p-autoComplete>
            <!-- <p-dropdown [options]="stores" [(ngModel)]="store_id" [filter]="true" optionValue="id" optionLabel="name" appendTo="body" placeholder="Chosir un store" [showClear]="true"></p-dropdown> -->
          </div>
      </div>
      <!-- <span class="red-color f14 avenirRegular"> Attention:J'espère que vous savez ce que vous faites</span> -->
      <div class="d-flex justify-content-end  align-items-center mt-4">
          <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="clone_display_Modal=false">Annuler
          </button>
          <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="choose_store()">Envoyer</button>
      </div>
    </div>
</p-dialog>
