import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../../../confirmation/confirmation.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ManageItemsComponent } from '../manage-items/manage-items.component';
@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
  items: any[] = []
  section: any
  isLoading: boolean = false;
  storeId: any
  sectionId: any
  item: any
  fragment!: any;

  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  @ViewChild('manage_items') manage_items!: ManageItemsComponent;
  @ViewChild('content') elementView!: ElementRef;
  constructor(private elementRef: ElementRef, private router: Router, public appconfig: AppConfigService, private messageService: MessageService, private route: ActivatedRoute, private httpService: HttpService, private location: Location) { }
  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.route.params.subscribe((params: any) => {
      this.storeId = params.id;
      this.sectionId = params.section_id;
      this.getItems(params.id, params.section_id);
      this.getSection(params.id, params.section_id);
    })
  }
  getSection(id: any, section_id: any) {
    this.httpService.get("menu/" + id + "/section/" + section_id + "/").subscribe(data => {
      if (data?.status === 200) {
        this.section = data.body.data;
      }
    })
  }
  getItems(store_id: any, section_id: any) {
    this.httpService.get("menu/" + store_id + "/" + section_id + "/items").subscribe(data => {
      if (data?.status === 200) {
        this.isLoading = true;
        this.items = data.body.data;
      }
    })
  }
  back() {
    this.location.back();
  }
  editItem(item: any) {
    this.scrollTo()
    this.manage_items.editItem(item)
    this.manage_items.edit = true;
    this.item = item
  }
  deleteItem(item: any) {
    this.item = item
    this.deleteModal.displayModal = true;
  }
  changeOrder() {
    let order: any = []
    let form: any
    this.items.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("menu/" + this.storeId + "/items/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
  onConfirm(event: any) {
    this.httpService.delete('menu/' + this.storeId + '/item/' + this.item.id).subscribe(data => {
      if (data?.status === 204) {
        this.deleteModal.displayModal = false
        this.items = this.items.filter((element: any) => element.id != this.item.id)
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + this.item.name + ' a été supprimém avec succès' });
      }
    })
  }
  changeAvailability(item: any) {
    this.item = item;
    if (!item.available) {
      this.manage_items.available_at_modal = true;
    }
    else {
      this.httpService.post('menu/' + this.storeId + '/item/' + this.item.id, { available: this.item.available }).subscribe(data => {
        if (data?.status === 200) {
          this.manage_items.available_at_modal = false;
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Item ' + data.body.data.name + ' a été modifié avec succès' });
        }
      })
    }
  }
  scrollTo() {
    const targetSection = this.elementRef.nativeElement.querySelector('#manage_items');
    console.log(targetSection);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
