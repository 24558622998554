import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { style } from '../../../_helpers/map_style';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
declare var google: any;
@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements OnInit {
  displayModal: boolean = false;
  options: any;
  overlays: any[] = []
  hub: any
  zones: any
  edit: boolean = false;
  stores: any
  map: any
  zone: any
  polygon: any
  polyline: any
  deliveryZoneform!: FormGroup;
  dispay: boolean = false;
  elmnt: any;
  add: boolean = false;
  overlay: boolean = false;
  submit: boolean = false;
  constructor(private httpService: HttpService, private messageService: MessageService, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.options = {
      zoom: 12,
      styles: style.style
    };
    this.inializedeliveryFeeForm()
  }
  switchAvailability(zone: any) {
    this.httpService.post('hub_delivery_zone/' + zone.id, { available: !zone.available }).subscribe(data => {
      Object.assign(zone, data.body.data)
    })
  }
  getDeliveryZones(hub: any) {
    this.hub = hub
    this.httpService.get('hub_delivery_zones/' + this.hub.id).subscribe(data => {
      if (data?.status === 200) {
        this.zones = data.body.data
        this.overlays.length = 0
        this.zones.forEach((element: any) => {
          this.setUpMap(element, false, false)
        });
      }
    })
  }
  _handleMapClick(event: any) {
    // console.log(event);
  }
  handleOverlayClick(event: any) {
    this.overlay = false;

  }
  modalMolygons: any = []
  newPaths: any = []
  handleMapClick($event: any, map: any) {
    this.overlay = true;
    console.log(this.add);
    console.log(this.overlay);
    console.log(this.newPaths.length);

    if (this.add && this.overlay && this.newPaths.length <= 1) {
      this.clearPaths()
      let bounds = new google.maps.LatLngBounds();
      let zonesPath = new google.maps.Polygon({
        paths: this.newPaths,
        strokeOpacity: 0,
        map: this.map,
        editable: false,
        draggable: false
      })
      this.modalMolygons.push(zonesPath)
      let selectedPosition = $event.latLng;
      this.newPaths.push({
        lat: Number(selectedPosition.lat()),
        lng: Number(selectedPosition.lng())
      })
      for (let i = 0; i < this.newPaths.length; i++) {
        bounds.extend(this.newPaths[i]);
      }
      let tmp = this;
      let polygon = new google.maps.Polygon({ paths: this.newPaths, strokeOpacity: 0.5, strokeWeight: 1, editable: true, draggable: true, fillColor: '#1976D2', fillOpacity: 0.35 })
      google.maps.event.addListener(polygon.getPath(), 'insert_at', function () {
        tmp.handlePolygonChange(this, "delivery_zone")
        tmp.handlePolygonChange(this, "stores_zone")
      });
      google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
        tmp.handlePolygonChange(this, "delivery_zone");
        tmp.handlePolygonChange(this, "stores_zone")
      });
      this.modalMolygons.push(polygon)
      // polygon.getPath().push(polygon.getPath().getAt(0));
      polygon.setMap(map)
    }
  }
  handlePolygonChange(shape: any, formname: string) {
    var len = shape.getLength();
    var points
    var paths = new Array
    for (var i = 0; i < len; i++) {
      points = shape.getAt(i).toUrlValue(5).split(',')
      paths.push({
        lat: Number(points[0]),
        lng: Number(points[1])
      })
    }
    var result = [];
    for (var i = 0; i < paths.length; i += 1) {
      result.push(paths[i].lat + ' ' + paths[i].lng,);
    }
    if (paths[0].lat !== paths[length].lat || paths[0].lng !== paths[length].lng) {
      result.push(paths[0].lat + ' ' + paths[0].lng);
    }
    switch (formname) {
      case "delivery_zone":
        this.deliveryZoneform.patchValue({
          delivery_zone: "POLYGON ((" + result.toString() + "))"
        })
        break;

      case "stores_zone":
        this.deliveryZoneform.patchValue({
          stores_zone: "POLYGON ((" + result.toString() + "))"
        })
        break;
    }
  }
  clearPaths() {
    this.modalMolygons.forEach((element: any) => {
      element.setMap(null)
    });
    this.modalMolygons = new Array();
  }
  setUpMap(element: any, editable_polygon: boolean, editable_polyline: boolean) {
    element.color = this.generateColor(element.name);
    element.delivery_men = Object.keys(element.staff).length
    let cordsPolygon = element.zone.substring(9, element.zone.length - 2)
    let cordPolygon = cordsPolygon.split(",");
    let cordsPolyline = element.stores_zone.substring(9, element.stores_zone.length - 2)
    let cordPolyline = cordsPolyline.split(",");
    let array: any[] = [];
    let arrayPolyline: any[] = [];
    for (let index = 0; index < cordPolygon.length - 1; index++) {
      let crd = cordPolygon[index].split(" ")
      array.push({ lat: parseFloat(crd[0]), lng: parseFloat(crd[1]) })
    }
    for (let index = 0; index < cordPolyline.length; index++) {
      let crd = cordPolyline[index].split(" ")
      arrayPolyline.push({ lat: parseFloat(crd[0]), lng: parseFloat(crd[1]) })
    }
    let polygon = new google.maps.Polygon({
      paths: array, strokeOpacity: 0.5, strokeWeight: 0, fillColor: element.color, fillOpacity: 0.5, editable: editable_polygon,
      draggable: editable_polygon,

    })
    let polyline = new google.maps.Polygon({
      paths: arrayPolyline, strokeColor: element.color, geodesic: false, scale: 4, strokeOpacity: 0.5, strokeWeight: 2, editable: editable_polyline, draggable: editable_polyline, fillOpacity: 0
    })
    let tmp = this
    google.maps.event.addListener(polygon.getPath(), 'insert_at', function (x: any) {
      tmp.handlePolygonChange(this, "delivery_zone")
    });
    google.maps.event.addListener(polygon.getPath(), 'dragend', function (x: any) {
      tmp.handlePolygonChange(this, "delivery_zone")
    });
    google.maps.event.addListener(polygon.getPath(), 'remove_at', function (x: any) {
      tmp.handlePolygonChange(this, "delivery_zone")
    });
    google.maps.event.addListener(polygon.getPath(), 'set_at', function (x: any) {
      tmp.handlePolygonChange(this, "delivery_zone")
    });
    google.maps.event.addListener(polyline.getPath(), 'set_at', function (x: any) {
      tmp.handlePolygonChange(this, "stores_zone")
    });
    google.maps.event.addListener(polyline.getPath(), 'remove_at', function (x: any) {
      tmp.handlePolygonChange(this, "stores_zone")
    });
    google.maps.event.addListener(polyline.getPath(), 'insert_at', function (x: any) {
      tmp.handlePolygonChange(this, "stores_zone")
    });
    google.maps.event.addListener(polyline.getPath(), 'dragend', function (x: any) {
      tmp.handlePolygonChange(this, "stores_zone")
    });
    if (editable_polygon) { // to be reconconsidered
      this.polygon = polygon
      this.polyline = polyline
    }
    this.overlays.push(polygon)
    this.overlays.push(polyline)
  }
  setUpMarkers(data: any) {
    data.forEach((element: any) => {
      let cords = element.location.substring(6, element.location.length - 1)
      let cord = cords.split(" ")
      this.overlays.push(new google.maps.Marker({
        position: { lat: parseFloat(cord[0]), lng: parseFloat(cord[1]) },
        icon:
        {
          url: element.logo?.url,
          size: new google.maps.Size(128, 128),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(28, 28),
          stroke: '#000',
          strokeWidth: 5,
          fill: '#000'
        },
        title: element?.name,
        draggable: false,
      }))
    });
  }
  editZone(zone: any) {
    this.overlays.length = 0
    this.edit = true;
    this.dispay = true
    this.registerDragElement()
    this.zone = zone
    this.inializedeliveryFeeForm()
    var bounds = new google.maps.LatLngBounds();
    var polygonCoords: any = [];
    let cordsPolygon = zone.zone.substring(9, zone.zone.length - 2)
    let cordPolygon = cordsPolygon.split(",");
    cordPolygon.forEach((element: any) => {
      let crd = element.split(" ")
      polygonCoords.push({ lat: parseFloat(crd[0]), lng: parseFloat(crd[1]) })
    });
    for (var i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }
    let location = bounds.getCenter().toUrlValue(5)
    this.map.setCenter(new google.maps.LatLng(location.split(",")[0], location.split(",")[1]));
    this.map.setZoom(13);
    console.log(bounds.getCenter().toUrlValue(5));
    this.setUpMap(zone, false, false)
    this.deliveryZoneform.patchValue({
      name: zone.name,
      can_delivery: zone.can_deliver,
      delivery_zone: zone.zone,
      stores_zone: zone.stores_zone,
    })
  }
  edit_delivery_zone() {
    this.overlays.length = 0
    this.setUpMap(this.zone, true, false)
  }
  edit_sore_zone() {
    this.overlays.length = 0
    this.setUpMap(this.zone, false, true)
    this.setUpMarkers(this.stores)

  }
  getStores(hub: any) {
    this.httpService.get('stores?filters=region:' + hub.id + '&all=1').subscribe(data => {
      if (data?.status === 200) {
        this.stores = data.body.data
        this.setUpMarkers(data.body.data)
      }
    })
  }
  setCenter(map: any, location: any) {
    this.map = map
    let cordsText: string = location.substring(6, location.length - 1)
    let cords = cordsText?.split(" ")
    map.setCenter(new google.maps.LatLng(parseFloat(cords[0]), parseFloat(cords[1])));
  }
  generateColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i);
    }
    hash = Math.pow((hash % 799 + 97) * 13, 3);
    const r = hash % 90 + 110;
    hash = Math.floor(hash / 100);
    const g = hash % 90 + 110;
    hash = Math.floor(hash / 100);
    const b = hash % 90 + 110;
    return "rgb(" + r + ',' + g + ',' + b + ')';
  }
  get f() { return this.deliveryZoneform.controls }
  saveZone() {
    this.submit = true
    if (this.deliveryZoneform.invalid) {
      return
    }
    if (this.edit) {
      this.httpService.post('hub_delivery_zone/' + this.zone.id, this.deliveryZoneform.value).subscribe(data => {
        if (data?.status === 200) {
          Object.assign(this.zone, data.body.data)
          this.cancel()
          this.setUpMap(data.body.data, false, false)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'zone est modifié avec succès' });
        }
      })
    } else {
      this.deliveryZoneform.patchValue({
        can_delivery: true,
      })
      this.httpService.post('hub_delivery_zones/' + this.hub.id, this.deliveryZoneform.value).subscribe(data => {
        if (data?.status === 200) {
          this.add = false
          this.cancel()
          this.clearPaths()
          this.overlay = true
          this.newPaths.length = 0
          console.log(this.newPaths.length);
          this.zones.push(data.body.data)
          this.setUpMap(data.body.data, false, false)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'zone est créé avec succès' });
        }
      })
    }
  }
  cancel() {
    this.edit = false;
    this.clearPaths()
    this.overlay = true
    this.dispay = false;
    this.add = false;
    this.overlays.length = 0
    this.zones.forEach((element: any) => {
      this.setUpMap(element, false, false)
    });
    this.setCenter(this.map, this.hub.location)
    this.map.setZoom(12);
    this.elmnt ? this.elmnt.style.padding = '0px' : ''
  }
  deleteZone() {
    this.httpService.delete('hub_delivery_zone/' + this.zone.id).subscribe(data => {
      if (data?.status === 202) {
        this.zones = this.zones.filter((zone: any) => zone.id !== this.zone.id)
        this.overlay = true
        this.dispay = false;
        this.add = false;
        this.overlays.length = 0
        this.edit = false;
        this.setCenter(this.map, this.hub.location)
        this.map.setZoom(12);
        this.elmnt.style.padding = '0px'
        this.zones.forEach((element: any) => {
          this.setUpMap(element, false, false)
        });
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'zone est supprimé avec succès' });
      }
    })
  }
  inializedeliveryFeeForm() {
    this.deliveryZoneform = this.fb.group({
      can_delivery: new FormControl("",),
      delivery_zone: ["", Validators.required],
      name: ["", Validators.required],
      stores_zone: ["", Validators.required],
    });
  }
  addZone() {
    this.edit = false;
    this.add = true
    this.dispay = true
    this.inializedeliveryFeeForm()
    this.registerDragElement()
    this.submit = false
  }

  private registerDragElement() {
    this.elmnt = document.getElementById('form') as HTMLElement;
    this.elmnt.style.padding = '30px';
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const dragMouseDown = (e: any) => {
      e = e || window.event;
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    };
    const elementDrag = (e: any) => {
      e = e || window.event;
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      this.elmnt.style.top = this.elmnt.offsetTop - pos2 + 'px';
      this.elmnt.style.left = this.elmnt.offsetLeft - pos1 + 'px';
    };
    const closeDragElement = () => {
      document.onmouseup = null;
      document.onmousemove = null;
    };
    this.elmnt.onmousedown = dragMouseDown;
  }
}
