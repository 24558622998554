import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewChecked {
  options: any
  sections: any
  colsOptions: any[] =
    [{ field: 'name', header: 'Nom' },
    { field: 'min', header: 'Min' },
    { field: 'max', header: 'Max' },]
  colsSections: any[] =
    [{ field: 'name', header: 'Nom' },
    { field: 'parent', header: 'Parent section' },
    { field: 'image', header: 'Image' }]
  menu_id: any
  selectedFile: any = null;
  imgUrl!: any;
  count_items: number = 0;
  items_without_img: any = [];
  loadingOptions: boolean = false;
  loadingItems: boolean = false;
  editOption: boolean = false;
  editScton: boolean = false;
  displaysecTionModal: boolean = false;
  displayOptionModal: boolean = false;
  sectionForm!: FormGroup;
  optionForm!: FormGroup;
  template_form!: FormGroup
  item_bulk_form!: FormGroup
  section: any
  delete_products_array: any[] = [];
  option: any
  productForm!: FormGroup;
  submitOption: boolean = false;
  store: any
  dispaly: boolean = false
  dispalyBarcode: boolean = false
  menus: any;
  sections_without_parent: any
  height: any
  @ViewChild('content') elementView!: ElementRef;
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  @ViewChild('deleteConfirmation') deleteConfirmation!: ConfirmationComponent;
  @ViewChild('deleteAllSections') deleteAllSections!: ConfirmationComponent;
  @ViewChild('deleteAllOptions') deleteAllOptions!: ConfirmationComponent;
  constructor(private route: ActivatedRoute, private httpService: HttpService, private fb: FormBuilder, private messageService: MessageService, private cdRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.route.parent?.params.subscribe((params: any) => {
      this.menu_id = params.id
      this.getOptions(params.id);
      this.getSections(params.id);
    })
    this.getMenuTree();
    this.inializeForm();
    this.getMenuTemplates()
    this.template_form = this.fb.group({
      store_id: new FormControl(0, Validators.required),
    })
    this.item_bulk_form = this.fb.group({
      text: new FormControl("", Validators.required),
    })
  }
  getOptions(storeId: any) {
    this.httpService.get('menu/' + storeId + "/item_options_groups/").subscribe(data => {
      if (data?.status === 200) {
        this.loadingOptions = true;
        this.options = data.body.data
      }
    })
  }
  getSections(storeId: any) {
    this.httpService.get("menu/" + storeId + "/sections").subscribe(data => {
      if (data?.status === 200) {
        this.sections = data.body.data
        this.loadingItems = true;
        this.parent();
      }
    })
  }
  parent() {
    this.sections.forEach((element: any) => {
      this.sections.forEach((ele: any) => {
        if (element.id == ele.parent_id) {
          ele.parent = element.name
        }
      });
    });
  }
  getMenuTree() {
    this.httpService.get("menu_tree/" + this.menu_id).subscribe(data => {
      if (data?.status === 200) {
        this.count_items = 0;
        this.items_without_img = [];
        this.countItems(data.body.data);
      }
    })
  }
  addSection() {
    this.displaysecTionModal = true;
    this.editScton = false;
    this.sectionForm.reset();
    this.selectedFile = null
    this.imgUrl = null;
  }
  addOption() {
    this.delete_products_array = new Array();
    this.submitOption = false;
    this.displayOptionModal = true;
    this.editOption = false;
    this.productForm.reset();
    this.sectionForm.reset();
    this.optionForm.reset();
    this.productForm.setControl('products', this.fb.array([]));
  }
  editSection(section: any) {
    this.displaysecTionModal = true;
    this.imgUrl = null;
    this.selectedFile = null
    this.editScton = true;
    this.section = section;
    this.sections_without_parent = this.sections.filter((element: any) => element.id != section.id)
    this.sectionForm.patchValue({
      name: section.name,
      parent_id: section.parent_id || '',
    });
  }
  countItems(data: any) {
    data.forEach((element: any) => {
      this.count_items += element.items.length
      let items_without_img = element.items.filter((item: any) => item.item.image.url == null)
      this.items_without_img = this.items_without_img.concat(items_without_img)
      if (element.sub_sections.length > 0) {
        this.countItems(element.sub_sections)
      }
    });
  }
  inializeForm() {
    this.sectionForm = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.min(1), Validators.max(32)]),
      parent_id: new FormControl("",),
    });
    this.optionForm = this.fb.group({
      id: new FormControl("",),
      name: new FormControl("", [Validators.required, Validators.min(1), Validators.max(32)]),
      min: new FormControl("", [Validators.required, Validators.pattern('^[0-9]+$')]),
      max: new FormControl("", [Validators.required, Validators.pattern('^[0-9]+$')]),
    });
    this.productForm = this.fb.group({
      products: this.fb.array([])
    })
  }
  addProductForm(): FormGroup {
    return this.fb.group({
      id: new FormControl("",),
      name: new FormControl("", [Validators.required,Validators.minLength(3), Validators.maxLength(64)]),
      price: new FormControl("", [Validators.required,]),
      available: new FormControl(false, Validators.required),
    });
  }
  set_all_products(products: any, value: any): FormArray {
    const formArray = new FormArray([]);
    products.forEach((p: any) => {

      console.log(p);
      // patch the value of available to the value
      formArray.push(this.fb.group({
        id: p.id,
        name: p.name,
        price: p.price,
        available: value,
      }))

    })
    return formArray;
  }
  setExistingProducts(products: any): FormArray {
    const formArray = new FormArray([]);
    products.forEach((p: any) => {
      formArray.push(this.fb.group({
        id: p.id,
        name: p.name,
        price: p.price,
        available: p.available,
      }));
    });
    return formArray;
  }
  addProduct(): void {
    (<FormArray>this.productForm.get('products')).push(this.addProductForm());
  }
  get formgroup() {
    return this.productForm.get('products') as FormArray
  }
  get optioncontrols() { return this.optionForm.controls; }
  manageSection() {
    const fd = new FormData()
    fd.append("name", this.sectionForm.value.name)
    this.sectionForm.value.parent_id ? fd.append("parent_id", this.sectionForm.value.parent_id) : ""
    this.selectedFile ? fd.append('image', this.selectedFile, this.selectedFile.name) : null
    if (this.editScton) {
      this.httpService.post("menu/" + this.menu_id + "/section/" + this.section.id, fd).subscribe(data => {
        if (data?.status === 200) {
          Object.assign(this.section, data.body.data);
          this.parent();
          this.displaysecTionModal = false;
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Menu a modifié créé avec succès' });
        }
      })

    } else {
      this.httpService.post("menu/" + this.menu_id + "/section/", fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Menu a ajouté créé avec succès' });
          this.sections.push(data.body.data);
          this.displaysecTionModal = false;
          this.parent();
        }
      })
    }

  }
  deleteSection(section: any) {
    this.deleteModal.displayModal = true;
    this.section = section;
  }
  changeSectionOrder() {
    let order: any = []
    let form: any
    this.sections.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("menu/" + this.menu_id + "/sections/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
  changeOptionOrder() {
    let order: any = []
    let form: any
    this.options.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("menu/" + this.menu_id + "/items_options_group/update_order/", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
  manageOption() {
    this.submitOption = true;
    if (this.productForm.invalid || this.optionForm.invalid) {
      return;
    }
    if (this.editOption) {
      this.httpService.post("menu/" + this.menu_id + "/item_options_group/" + this.option.id, this.optionForm.value).subscribe(data => {
        if (data?.status === 200) {
          Object.assign(this.option, data.body.data);
          this.displayOptionModal = false;
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Menu a modifié créé avec succès' });
          this.delete_products_array.forEach(element => {
            if (element.id) {
              this.httpService.delete('menu/' + this.menu_id + '/item_option/' + element.id).subscribe(data => {
                if (data?.status === 200) {
                  this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Produit ' + element.name + ' a été supprimé  avec succès' });
                }
              })
            }
          });
          this.productForm.value.products.forEach((element: any) => {
            if (element.id) {
              this.httpService.post('menu/' + this.menu_id + '/item_option/' + element.id, element).subscribe(data => {
                this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Option a modifié créé avec succès' });
              })
            } else {
              element.group_id = this.option.id;
              this.httpService.post('menu/' + this.menu_id + '/item_option', element).subscribe(data => {
                this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Option a crée créé avec succès' });
              })
              // console.log("product shoudld be created");

            }
          })
        }
      })
    } else {
      this.httpService.post("menu/" + this.menu_id + "/item_options_group", this.optionForm.value).subscribe(data => {
        if (data?.status === 200) {
          this.productForm.value.products.forEach((element: any) => {
            element.group_id = data.body.data.id
            this.httpService.post('menu/' + this.menu_id + '/item_option', element).subscribe(res => {
              if (res?.status === 200) {
                this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'produit' + res.body.data.name + 'est ajouté avec succès' });
              }
            })
          });
          this.options.unshift(data.body.data);
          this.displayOptionModal = false;
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'option' + data.body.data.name + 'est ajouté avec succès' });
        }
      })
    }
  }
  onConfirm(event: any) {
    this.httpService.delete("menu/" + this.menu_id + "/section/" + this.section.id).subscribe(data => {
      if (data?.status === 204) {
        this.sections = this.sections.filter((element: any) => element.id != this.section.id)
        this.deleteModal.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Section ' + this.section.name + ' a été supprimé créé avec succès' });
      }
    }
    )
  }
  confirmDelete(event: any) {
    this.httpService.delete("menu/" + this.menu_id + "/item_options_group/" + this.option.id).subscribe(data => {
      if (data?.status === 204) {
        this.options = this.options.filter((element: any) => element.id != this.option.id)
        this.deleteConfirmation.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Option ' + this.option.name + ' a été supprimé créé avec succès' })
      }
    })

  }
  deleteProduct(index: any) {
    this.delete_products_array.push((<FormArray>this.productForm.get('products'))["value"][index]);
    (<FormArray>this.productForm.get('products')).removeAt(index)
    console.log(this.productForm);
  }
  edit_item_bulk() {
    if (this.item_bulk_form.invalid) {
      return
    }
    this.httpService.post("menu/" + this.menu_id + "/item_bulk_update", this.item_bulk_form.value).subscribe(data => {
      if (data?.status === 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Nombre de produits modifiés' + data.body.updated });
        this.messageService.add({ severity: 'error', summary: 'Opération Terminée', detail: "Nombre d'erreur " + data.body.error_count });
        this.messageService.add({ severity: 'error', summary: 'Opération Terminée', detail: 'Nombre de produits not trouvés ' + data.body.not_found_count });
        this.item_bulk_form.reset();
        this.dispalyBarcode = false;
      }
    })
  }
  deleteOption(option: any) {
    this.deleteConfirmation.displayModal = true;
    this.option = option;
  }
  deleteAll(option: any) {
    switch (option) {
      case "option":
        this.deleteAllOptions.displayModal = true;
        break;
      case "section":
        this.deleteAllSections.displayModal = true;
        break;
    }
  }
  editOptions(option: any) {
    this.delete_products_array = new Array()
    this.editOption = true;
    this.submitOption = false;
    this.option = option;
    this.optionForm.patchValue({
      name: option.name,
      min: option.min,
      max: option.max,
    })
    this.httpService.get('menu/' + this.menu_id + '/' + option.id + '/item_options?order_by=weight').subscribe(res => {
      if (res.status === 200) {
        option.products = res.body.data;
        this.productForm.setControl('products', this.setExistingProducts(option.products));
      }
    })
    this.displayOptionModal = true;

  }
  swich_all_options(value: any) {
    this.option.products.forEach((element: any) => {
      element.available = value
    });
    this.productForm.setControl('products', this.set_all_products(this.option.products, value));
  }
  ConfirmdeleteAll(event: any) {
    console.log(event);
    switch (event) {
      case 'section':
        this.sections?.forEach((element: any) => {
          this.httpService.delete("menu/" + this.menu_id + "/section/" + element.id).subscribe(data => {
            if (data?.status === 204) {
              this.sections = this.sections.filter((element: any) => element.id != element.id)
              this.deleteAllSections.displayModal = false;
              this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Section ' + element.name + ' a été supprimé créé avec succès' });
            }
          })
        });
        break;

      case 'option':
        this.options?.forEach((element: any) => {
          this.httpService.delete("menu/" + this.menu_id + "/item_options_group/" + element.id).subscribe(data => {
            if (data?.status === 204) {
              this.options = this.options.filter((element: any) => element.id != element.id)
              this.deleteAllOptions.displayModal = false;
              this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Option ' + element.name + ' a été supprimé créé avec succès' })
            }
          })
        });
        break;
    }
  }
  apply() {
    this.template_form.patchValue({
      store_id: this.template_form.value.store_id.id
    })
    this.httpService.post('menu/' + this.store.id + "/clone", this.template_form.value).subscribe((data: any) => {
      if (data?.status === 202) {
        this.getSections(this.store.id)
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Template a été appliqué avec succès' });
        this.getMenuTemplates()
        this.dispaly = false
      }
    })
  }
  getMenuTemplates() {
    this.httpService.get("store/" + this.menu_id).subscribe((data: any) => {
      this.store = data.body.data
      if (this.store.global_menu > 0) {
        this.httpService.get("store/" + this.store.global_menu).subscribe((data: any) => {
          this.menus = data.body.data
          this.store.global_store_name = data.body.data.name
        })
      }
    })

  }
  search(event: any) {
    this.httpService.get("search/autocomplete/" + event.query).subscribe(data => {
      this.menus = data.body.data;
    });
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
    }
  }
  activate_all_products(section: any) {
    this.httpService.get('menu/' + this.menu_id + '/' + section.id + '/items').subscribe(data => {
      if (data?.status === 200) {
        data.body.data.forEach((element: any) => {
          this.httpService.post('menu/' + this.menu_id + '/item/' + element.id, { "available": true }).subscribe(data => {
            if (data?.status === 200) {
              this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Produit ' + element.name + ' a été activé avec succès' });
            }
          })
        });

      }
    })

  }
  deactivate_all_products(section: any) {
    this.httpService.get('menu/' + this.menu_id + '/' + section.id + '/items').subscribe(data => {
      if (data?.status === 200) {
        data.body.data.forEach((element: any) => {
          this.httpService.post('menu/' + this.menu_id + '/item/' + element.id, { "available": false, "available_at": null }).subscribe(data => {
            if (data?.status === 200) {
              this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Produit ' + element.name + ' a été désactivé avec succès' });
            }
          })
        });

      }
    })
  }
  ngAfterViewChecked(): void {
    if (this.elementView?.nativeElement.offsetHeight > 750) {
      this.height = 750 + "px";
    }
    this.cdRef.detectChanges();

  }
}
