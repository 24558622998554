import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { Observable, of, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment.staging';
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from 'primeng/api';
import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
   endpoint = environment.endpoint

  constructor(private app_config: AppConfigService,private http: HttpClient, public route: ActivatedRoute, public router: Router, private messageService: MessageService) { 
      this.endpoint = app_config.appConfig.api_url+"/api/";
  }
  get(url: any): Observable<any> {
    return this.http.get(this.endpoint + url, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') }, observe: "response" }).pipe(
      catchError(this.handleError<any>('Get' + url)));
  }
  delete(url: any): Observable<any> {
    return this.http.delete(this.endpoint + url, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') }, observe: "response" }).pipe(
      catchError(this.handleError<any>('delete' + url)));
  }
  post(url: any, form: any): Observable<any> {
    return this.http.post(this.endpoint + url, form, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') }, observe: "response" }).pipe(
      catchError(this.handleError<any>('get centres')));

  }
  patch(url: any, form: any): Observable<any> {
    return this.http.patch(this.endpoint + url, form, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') }, observe: "response" }).pipe(
      catchError(this.handleError<any>('edit student ')));

  }
  put(url: any, form: any): Observable<any> {
    return this.http.put(this.endpoint + url, form, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') }, observe: "response" }).pipe(
      catchError(this.handleError<any>('get centres')));
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.error instanceof ErrorEvent) {
      }
      else {
        switch (error.status) {
          case 0:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: "Veuillez vérifier la connexion internet et essayez encore" });
            break;
          case 400:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: JSON.stringify(error.error.errors) });
            break;
          case 403:
            this.messageService.add({ severity: 'error', summary: 'Une erreur d\'authentification s\'est produite', detail: JSON.stringify(error.error.errors) });
            this.router.navigate(['/permission_denied']);
            // localStorage.removeItem('token');
            break;
          case 422:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: JSON.stringify(error.error.errors) });
            break;
          case 401:
            this.messageService.add({ severity: 'error', summary: 'Une erreur d\'authentification s\'est produite', detail: JSON.stringify(error.error.errors) });
            this.router.navigate(['/signIn']);
            localStorage.removeItem('token');
            break;
          case 404:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: JSON.stringify(error.error.message) });
            break;
          case 500:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: JSON.stringify(error.error.message) });
            break;
          case 504:
            this.messageService.add({ severity: 'error', summary: 'Une erreur s\'est produite', detail: JSON.stringify(error.error.errors) });
            break;
        }
      }
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
