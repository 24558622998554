import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';

@Component({
  selector: 'app-partners-management',
  templateUrl: './partners-management.component.html',
  styleUrls: ['./partners-management.component.scss']
})
export class PartnersManagementComponent implements OnInit {
  isLoading: boolean = false
  display: boolean = false
  partner_form!: FormGroup
  partners: any
  partner: any
  selectedFile: any = null;
  imgUrl: any;
  submit: boolean = false
  edit: boolean = false
  @ViewChild('deleteConfirmation') deleteConfirmation!: ConfirmationComponent

  constructor(
    private httpService: HttpService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.partner_form = this.fb.group({
      name: new FormControl("", [Validators.required, Validators.maxLength(64)]),
      logo: new FormControl("", [Validators.required]),
    })
    this.get_partners()
  }
  get f() { return this.partner_form.controls }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.partner_form.patchValue({
          logo: event.target.result
        })
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
    }
  }
  get_partners() {
    this.httpService.get('stories_partners').subscribe(res => {
      if (res?.status === 200) {
        this.isLoading = true
        this.partners = res.body.data
      }
    })
  }
  edit_partner(partner: any) {
    this.selectedFile = null
    this.imgUrl = null
    this.display = true
    this.edit = true
    this.partner = partner
    this.partner_form.patchValue({
      name: partner.name,
      logo: partner.logo
    })
  }
  add_partner() {
    this.selectedFile = null
    this.imgUrl = null
    this.edit = false
    this.display = true
    this.partner_form.reset()
  }
  manage_partner() {
    this.submit = true
    if (this.partner_form.invalid) {
      return
    }
    const fd = new FormData()
    fd.append("name", this.partner_form.value.name)
    this.selectedFile ? fd.append("logo", this.selectedFile) : ""
    if (this.edit) {
      this.httpService.post('stories_partner/' + this.partner.id, fd).subscribe(res => {
        if (res?.status === 200) {
          console.log(res.body);
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Partenaire modifié avec succès', });
          Object.assign(this.partner, res.body.data)
          this.display = false
        }
      })
    } else {
      this.httpService.post('stories_partner/', fd).subscribe(res => {
        if (res?.status === 200) {
          console.log(res.body);
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Partenaire crée avec succès', });
          this.partners.push(res.body.data)
          this.display = false
        }
      })
    }
  }
  delete_story_partner(partner: any) {
    this.deleteConfirmation.displayModal = true;
    this.partner = partner;
  }
  confirmDelete(event: any) {
    this.httpService.delete("stories_partner/" + this.partner.id).subscribe(data => {
      if (data?.status === 204) {
        this.partners = this.partners.filter((element: any) => element.id != this.partner.id)
        this.deleteConfirmation.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Partenaire story' + this.partner.name + ' a été supprimé  avec succès' })
      }
    })
  }
  changeSectionOrder() {
    let order: any = []
    let form: any
    this.partners.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("stories_partners/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
}
