<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-3 pt-6 pb-3">
    <span class="avenirRegular f72 main-color fw85">Analytiques</span>
  </div>
  <div>
    <div class="d-flex align-items-center justify-content-between m-3">
      <div class="col-md-3">
        <p-dropdown [options]="regions" [(ngModel)]="regionOrder" optionValue="id" optionLabel="name"
          placeholder="Tout les régions" [showClear]="false" (onChange)="selectRegion('visitors')"></p-dropdown>
      </div>
      <div class="btn-group p-1  br20" role="group" aria-label="Basic example">
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background perDay"
          [ngClass]="{'activeBtn': selectedVisitor==='perDay'}" (click)="setUpVisitors('perDay')">Par jour</button>
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background"
          [ngClass]="{'activeBtn': selectedVisitor==='perWeek'}" (click)="setUpVisitors('perWeek')">Par semaine</button>
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background perMonth"
          [ngClass]="{'activeBtn': selectedVisitor==='perMonth'}" (click)="setUpVisitors('perMonth')">Par mois</button>
      </div>
    </div>
    <div class="white-back ">
      <p-chart type="bar" [data]="stackedData" [options]="stackedOptions" height="460"></p-chart>
    </div>
  </div>
  <div>
    <div class="d-flex align-items-center justify-content-between m-3">
      <div class="col-md-3">
        <p-dropdown [options]="regions" [(ngModel)]="regionVisitor" optionValue="id" optionLabel="name"
          placeholder="Tout les régions" [showClear]="false" (onChange)="selectRegion('orders')"></p-dropdown>
      </div>
      <div class="btn-group p-1  br20" role="group" aria-label="Basic example">
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background perDay"
          [ngClass]="{'activeBtn': selectedOrder==='perDay'}" (click)="setUpOrders('perDay')">Par jour</button>
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background"
          [ngClass]="{'activeBtn': selectedOrder==='perWeek'}" (click)="setUpOrders('perWeek')">Par semaine</button>
        <button type="button" class="btn deepGrey f18 avenirMedium grey-background perMonth"
          [ngClass]="{'activeBtn': selectedOrder==='perMonth'}" (click)="setUpOrders('perMonth')">Par mois</button>
      </div>
    </div>
    <div class="white-back ">
      <p-chart type="bar" [data]="stackedOrdersData" [options]="stackedOrdersOptions" height="460"></p-chart>
    </div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>