import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-cancelled-orders-stats',
  templateUrl: './cancelled-orders-stats.component.html',
  styleUrls: ['./cancelled-orders-stats.component.scss']
})
export class CancelledOrdersStatsComponent implements OnInit {
  isLoading: boolean = false;
  stats: any
  stats_duration: any = [{ label: "Aujourdh'ui", value: "today" }, { label: "Hier", value: "yesterday" }, { label: "Dernière semaine", value: "last_week" }, { label: "Dernier mois", value: "last_month" }]
  stat_duration!: string | null;
  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.route?.queryParamMap.subscribe(param => {
      this.stat_duration=null
      let url = 'orders?get_cancellations_stats=1'
      console.log(url);
      if (param.get('stats_duration')) {
        this.stat_duration = param.get('stats_duration')
        url = url + "&stats_duration=" + param.get('stats_duration')
      }
      this.get_cencelled_orders(url)
    })
  }
  get_cencelled_orders(url: any) {
    this.httpService.get(url).subscribe(res => {
      if (res?.status === 200) {
        this.isLoading = true;
        this.stats = Object.values(res.body.data)
      }
    })
  }
  filter() {
    this.router.navigate(['/orders/orders_stats'], { queryParams: { stats_duration: this.stat_duration }, queryParamsHandling: "merge" });
  }
  reset_filter() {
    this.stat_duration = null
    this.router.navigate(['/orders/orders_stats']);
  }
}
