<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Régions</span>
    <div class="d-flex justify-content-end align-items-center">
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="addRegion()">Ajoutez une
        région</button>
      <button class="btn" (click)="menu.toggle($event)">
        <img src="../../../assets/images/svg/more.svg" alt="">
      </button>
      <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </div>
  </div>
  <div *ngIf="regions.length>0;else noRegions" class="table-responsive">
    <table class="table table-borderless mt-4 mb-0 customTable">
      <thead>
        <tr>
          <th class="centerth"></th>
          <th class="centerth">Région</th>
          <th class="centerth">Status</th>
          <!-- <th class="centerth">Hubs</th> -->
          <th class="centerth">Opérations</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let region of regions">
          <td>
            <svg class="icon">
              <use xlink:href="../../../assets/images/svg/Icon-region.svg#Group_3475"></use>
            </svg>
          </td>
          <td class="centertd avenirMedium f18 black">{{region.name}}</td>
          <td class="centertd avenirMedium f18 black"><span
              [ngClass]="{'active': region.active,'inactive deepGrey': !region.active}"
              class="px-4 br20">{{region.active?"Active":"Inactive"}}</span></td>
          <!-- <td class="centertd avenirMedium f16 main-color"><a class="custom-anchor"
              href="https://web.facebook.com/?_rdc=1&_rdr">Voir les
              hubs</a></td> -->
          <td class="centertd avenirMedium f16 black">
            <button class="btn p-0 mr-2" (click)="editRegion(region)">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
          </td>
      </tbody>
    </table>
  </div>
</div>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="displayModal" [modal]="true" [draggable]="false"
  [resizable]="false" [breakpoints]="{ '124px': '100vw'}" [style]="{width: '1240px'}"
  (onShow)="setCenter(gmap.getMap())">
  <div class="row no-gutters">
    <div class="col-md-6">
      <p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}"
        (onOverlayDragEnd)="mapDRagEnd($event)"></p-gmap>
    </div>
    <div class="col-md-6 p-3">
      <form autocomplete="off" [formGroup]="regionForm">
        <p-divider align="center">
          <div class="inline-flex align-items-center">
              <b>Nom</b>
          </div>
      </p-divider>
      <div class="border border-top-0 p-1">
        <div class="form-group">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="name"
            placeholder="Région name" [ngClass]="{'is-invalid': f['name'].errors&&submit}">
        </div>
        <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
          <div class="form-group">
            <input type="text" class="form-control mdStl avenirMedium f18" id="name-{{lang.code}}" [placeholder]="lang.name"
                  dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}" 
                  [value]="regionForm.value?.translations | translationsPatchValue: 'name':lang.code" 
                  (change)="onTranslationsChange(regionForm, 'name', lang.code, $event)">
          </div>
      </div>
      </div>

        <div class="mb-4">
          <span class="main-color avenirRegular f20">Catégories: </span>
          <div class="d-flex align-items-center flex-wrap mt-3">
            <div *ngFor="let category of categories" class="field-checkbox mr-4 d-flex align-items-center pb-2">
              <label class="avenirRegular f20 black mr-3" for="{{category.value}}">{{category.name}}</label>
              <p-checkbox name="group1" [value]="category.value" inputId="{{category.value}}"
                [(ngModel)]="category.checked" [ngModelOptions]="{standalone:true}" [binary]="true"></p-checkbox>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <span class="main-color avenirRegular f20">Commission des catégories par défaut: </span>
          <div class="mt-3">
            <div class="row">
              <div *ngFor="let category of categories" class="col-md-5">
                <div class="row no-gutters align-items-center mb-4">
                  <div class="col-md-5">
                    <label class="avenirMedium f20 black" for="name">{{category.name}} </label>
                  </div>
                  <div class="col-md-5 position-relative d-flex align-items-center">
                    <input type="text" class="form-control mdStl avenirMedium f18" [(ngModel)]="category.fees"
                      [ngModelOptions]="{standalone:true}" id="name" placeholder="00">
                    <span class="avenirMedium f18 position-absolute right-0">DA</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <span class="main-color avenirRegular f20">Commission des services par défaut: </span>
          <div class="mt-3">
            <div class="row">
              <div class="col-md-5">
                <div class="row no-gutters align-items-center mb-4">
                  <div class="col-md-5">
                    <label class="avenirMedium f20 black" for="name">Général </label>
                  </div>
                  <div class="col-md-5 position-relative d-flex align-items-center">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name"
                      [ngClass]="{'is-invalid': f['default_order_rate'].errors&&submit}"
                      formControlName="default_order_rate" placeholder="00">
                    <span class="avenirMedium f18 position-absolute right-0">%</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row no-gutters align-items-center mb-4">
                  <div class="col-md-6">
                    <label class="avenirMedium f20 black" for="name">Crée par établissement</label>
                  </div>
                  <div class="col-md-5 position-relative d-flex align-items-center">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="00"
                      [ngClass]="{'is-invalid': f['default_store_order_rate'].errors&&submit}"
                      formControlName="default_store_order_rate">
                    <span class="avenirMedium f18 position-absolute right-0">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between  align-items-center px-3 py-2 bnr">
          <span class="avenirMedium f18 ursopct black"> Région non-active</span>
          <p-inputSwitch formControlName="active"></p-inputSwitch>
        </div>
        <div class="d-flex justify-content-end  align-items-center pt-6">
          <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5" (click)="hideDialog()">Annuler</button>
          <button class="btn green-back  avenirMedium f18 text-white px-5" (click)="manageRegion()">Ajouter</button>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
<p-sidebar [(visible)]="displaySidebar" appendTo="body" position="right" [style]="{width:'575px'}" [showCloseIcon]=false
  [baseZIndex]="10000">
  <div class="d-flex align-items-center p-3">
    <button class="btn p-0" (click)="displaySidebar=false">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">Statut des SMS</span>
  </div>
  <div>
    <table class="table table-borderless mt-4 mb-0">
      <thead>
        <tr class="head">
          <th>Téléphone</th>
          <th>Statut</th>
          <th>Opération</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let smsPhone of smsPhones">
          <td class="avenirMedium f20 black ">{{smsPhone.number}}</td>
          <td class="avenirMedium f20 black">Lorem, ipsum.</td>
          <td class="avenirMedium f20"><button class="btn p-0 text-white f16 green-back w-100 py-2"
              (click)="testSMS(smsPhone)">Tester</button></td>
      </tbody>
    </table>
  </div>
</p-sidebar>
<p-sidebar [(visible)]="display" appendTo="body" position="right" [style]="{width:'575px'}" [showCloseIcon]=false
  [baseZIndex]="10000">
  <div class="d-flex align-items-center p-3">
    <button class="btn p-0">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">Statut des SMS</span>
  </div>
  <div>
    <form autocomplete="off" [formGroup]="SMSForm">
      <div class="row no-gutters">
        <div class="col-md-6 pr-4">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" [attr.disabled]="true"
            formControlName="from" placeholder="Emitteur">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control mdStl avenirMedium f18" id="name" formControlName="to"
            placeholder="Destinataire">
        </div>
      </div>
      <div class="pt-5">
        <textarea class="form-control mdStl avenirMedium f18" placeholder="Write a message" formControlName="text"
          id="message" rows="2"></textarea>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-4" (click)="hideDialog()">Annuler</button>
        <button class="btn green-back  avenirMedium f18 text-white px-4" (click)="sendSMS()">Envoyer</button>

      </div>
    </form>
  </div>
</p-sidebar>
<ng-template #noRegions>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des régions appuyez sur "Ajoutez une région"
      pour le insérer une </p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>