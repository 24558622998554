import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm, FormArray, Form } from "@angular/forms";
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  loadingService: boolean = false;
  loadingSpecialities: boolean = false;
  loadingtypes: boolean = false;
  services: any
  specialities: any
  types: any
  display: boolean = false;
  displaySpeciality: boolean = false;
  displayType: boolean = false
  submit: boolean = false;
  submitSpeciality: boolean = false;
  submitTypes: boolean = false;
  service_form!: FormGroup;
  speciality_form!: FormGroup;
  types_form!: FormGroup;
  selectedFile: any = null;
  selctedFile: any = null;
  selctedFileType: any = null;
  imgUrl: any
  editSrvice: boolean = false;
  editSpeciality: boolean = false;
  editType: boolean = false
  service: any
  speciality: any
  type: any
  text: any
  item: any
  configLangs: any[] = [];

  categories: any = [
    { value: "food", label: "Food" }, 
    { value: "healthy", label: "Healthy" }, 
    { value: "fresh", label: "Fresh" }, 
    { value: "express", label: "Express" }, 
    { value: "more", label: "More" }, 
    { value: "wajeez", label: "Wajeez" }
  ]

  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;

  constructor(
    private httpService: HttpService, 
    private messageService: MessageService, 
    private fb: FormBuilder,
    private appConfig: AppConfigService,
    private sharedService: SharedService
    ) { }

  ngOnInit(): void {
    this.configLangs = this.appConfig.configLangs;

    this.getServices();
    this.getSpecialities();
    this.getTypes();

    this.service_form = this.fb.group({
      name: new FormControl("", [Validators.maxLength(32), Validators.required]),
      description: new FormControl("", Validators.maxLength(255)),
      translations: new FormArray([])
    });

    this.speciality_form = this.fb.group({
      name: new FormControl("", [Validators.maxLength(32), Validators.required]),
      category: new FormControl("", [Validators.maxLength(16), Validators.required]),
      description: new FormControl("", Validators.maxLength(255)),
      translations: new FormArray([])
    });

    this.types_form = this.fb.group({
      name: new FormControl("", [Validators.maxLength(32), Validators.required]),
      category: new FormControl("", [Validators.maxLength(16), Validators.required]),
      description: new FormControl("", Validators.maxLength(255)),
      translations: new FormArray([])
    });

  }

  getServices() {
    this.httpService.get('services?order_by=weight').subscribe(data => {
      if (data?.status === 200) {
        this.services = data.body.data;
        this.loadingService = true;
      }
    })
  }

  getSpecialities() {
    this.httpService.get('specialities?order_by=weight').subscribe(data => {
      if (data?.status === 200) {
        this.specialities = data.body.data;
        this.loadingSpecialities = true;
      }
    })
  }

  getTypes() {
    this.httpService.get('store_types?order_by=weight').subscribe(data => {
      if (data?.status === 200) {
        this.types = data.body.data;
        this.loadingtypes = true;
      }
    })
  }

  get f() { return this.service_form.controls; }
  get g() { return this.speciality_form.controls; }
  get k() { return this.types_form.controls; }

  changeOrder() {
    let order: any = []
    let form: any
    this.services.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("services/update_order/", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a été modifié avec succès' });
      }
    })

  }

  changeSpecialitiesOrder() {
    let order: any = []
    let form: any
    this.specialities.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("specialities/update_order/", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a été modifié avec succès' });
      }
    })

  }

  changetypesOrder() {
    let order: any = []
    let form: any
    this.types.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    this.httpService.post("store_types/update_order/", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a été modifié avec succès' });
      }
    })

  }

  showPreviewImage(event: any, icon: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      switch (icon) {
        case "service":
          this.selectedFile = <File>event.target.files[0];
          break;
        case "speciality":
          this.selctedFile = <File>event.target.files[0];
          break;
        case "type":
          this.selctedFileType = <File>event.target.files[0];
          break;
      }
    }
  }

  addService() {
    this.display = true;
    this.editSrvice = false;
    this.service_form.reset();
    this.imgUrl = null;
  }

  addSpeciality() {
    this.displaySpeciality = true;
    this.editSpeciality = false;
    this.speciality_form.reset();
    this.imgUrl = null;
  }

  addType() {
    this.displayType = true;
    this.editType = false;
    this.types_form.reset();
    this.imgUrl = null;
  }

  manageService() {
    this.submit = true;
    if (this.service_form.invalid) {
      return
    }
    const fd = new FormData();
    this.selectedFile ? fd.append('icon', this.selectedFile) : ""
    fd.append('name', this.service_form.value.name);
    fd.append('description', this.service_form.value.description);
    
    let translations: any = [...this.service_form.value.translations];
    translations?.map((element: any) => {
      Object.keys(element || {}).forEach((fst_key: any) => {
        Object.keys(element[fst_key] || {}).forEach((snd_key: any) => {
          fd.append("translations[" + fst_key + "][" + snd_key + "]", element[fst_key][snd_key]);
        })
      })
    })

    if (this.editSrvice) {
      this.httpService.post("service/" + this.service.id, fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Service a été modifié avec succès' });
          this.display = false;
          this.submit = false;
          Object.assign(this.service, data.body.data);
        }
      }
      )
    } else {
      this.httpService.post("service", fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Service a été ajouté avec succès' });
          this.display = false;
          this.submit = false;
          this.services.unshift(data.body.data);
        }
      }
      )
    }
  }

  manageSpeciality() {
    this.submitSpeciality = true;
    if (this.speciality_form.invalid) {
      return
    }
    const fd = new FormData();
    this.selctedFile ? fd.append('icon', this.selctedFile) : ""
    fd.append('name', this.speciality_form.value.name);
    fd.append('description', this.speciality_form.value.description);
    fd.append('category', this.speciality_form.value.category);

    let translations: any = [...this.speciality_form.value.translations];
    translations?.map((element: any) => {
      Object.keys(element).forEach((fst_key: any) => {
        Object.keys(element[fst_key]).forEach((snd_key: any) => {
          fd.append("translations[" + fst_key + "][" + snd_key + "]", element[fst_key][snd_key]);
        })
      })
    })
    if (this.editSpeciality) {
      this.httpService.post("speciality/" + this.speciality.id, fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Spécialité a été modifié avec succès' });
          this.displaySpeciality = false;
          this.submitSpeciality = false;
          Object.assign(this.speciality, data.body.data);
        }
      })
    } else {
      this.httpService.post("speciality", fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Spécialité a été ajouté avec succès' });
          this.displaySpeciality = false;
          this.submitSpeciality = false;
          this.specialities.unshift(data.body.data);
        }
      })
    }
  }

  manageType() {
    this.submitTypes = true;
    if (this.types_form.invalid) {
      return
    }
    const fd = new FormData();
    this.selctedFileType ? fd.append('icon', this.selctedFileType) : ""
    fd.append('name', this.types_form.value.name);
    fd.append('description', this.types_form.value.description);
    fd.append('category', this.types_form.value.category);

    let translations: any = [...this.types_form.value.translations];
    translations?.map((element: any) => {
      Object.keys(element || {}).forEach((fst_key: any) => {
        Object.keys(element[fst_key] || {}).forEach((snd_key: any) => {
          fd.append("translations[" + fst_key + "][" + snd_key + "]", element[fst_key][snd_key]);
        })
      })
    })
    if (this.editType) {
      this.httpService.post("store_type/" + this.type.id, fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'type a été modifié avec succès' });
          this.displayType = false;
          this.submitTypes = false;
          Object.assign(this.type, data.body.data);
        }
      })
    } else {
      this.httpService.post("store_type", fd).subscribe(data => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'type a été ajouté avec succès' });
          this.displayType = false;
          this.submitTypes = false;
          this.types.unshift(data.body.data);
        }
      })
    }
  }

  editService(service: any) {
    this.display = true;
    this.editSrvice = true;
    this.service = service;
    this.service_form.patchValue({
      name: service.name,
      description: service.description,
    })
    
    this.patchTranslationsData(this.service_form, service)
    this.imgUrl = service.icon.url
  }

  editSpciality(speciality: any) {
    this.displaySpeciality = true;
    this.editSpeciality = true;
    this.speciality = speciality;
    this.speciality_form.patchValue({
      name: speciality.name,
      category: speciality.category,
      description: speciality.description,
    })
    this.patchTranslationsData(this.speciality_form, speciality)
    this.imgUrl = speciality.icon.url
  }

  editTypes(type: any) {

    this.displayType = true;
    this.editType = true;
    this.type = type;
    this.types_form.patchValue({
      name: type.name,
      category: type.category,
      description: type.description,
    })
    this.patchTranslationsData(this.types_form, type)
    this.imgUrl = type.icon.url
  }

  deleteItem(item: any) {
    switch (item) {
      case "service":
        this.text = "le service " + this.service.name;
        this.item = "service";
        break;
      case "speciality":
        this.text = "la spécialité " + this.speciality.name;
        this.item = "speciality";
        break;
      case "store_type":
        this.text = "le type " + this.type.name;
        this.item = "store_type";
        break;
    }
    this.deleteModal.displayModal = true;
  }

  onConfirm(event: any) {
    switch (this.item) {
      case "service":
        this.httpService.delete("service/" + this.service.id).subscribe(data => {
          if (data?.status === 204) {
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Service a été supprimé avec succès' });
            this.services = this.services.filter((item: any) => item.id !== this.service.id);
            this.display = false;
            this.deleteModal.displayModal = false;
          }
        })
        break;
      case "speciality":
        this.httpService.delete("speciality/" + this.speciality.id).subscribe(data => {
          if (data?.status === 204) {
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Spécialité a été supprimé avec succès' });
            this.specialities = this.specialities.filter((item: any) => item.id !== this.speciality.id);
            this.displaySpeciality = false;
            this.deleteModal.displayModal = false;
          }
        })
        break;
      case "store_type":
        this.httpService.delete("store_type/" + this.type.id).subscribe(data => {
          if (data?.status === 204) {
            this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Type a été supprimé avec succès' });
            this.types = this.types.filter((item: any) => item.id !== this.type.id);
            this.displayType = false;
            this.deleteModal.displayModal = false;
          }
        })
        break;
    }
  }

  onTranslationsChange(form: FormGroup, key: string, lang: string, event: any){
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.onTranslationsChange(form, key, lang, event);
}

  patchTranslationsData(form: FormGroup, data: any){
    var element: FormArray = form.get('translations') as FormArray;
    element = this.sharedService.patchTranslationsData(form, data);
  }

}
