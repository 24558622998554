import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import * as FileSaver from 'file-saver';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DatePipe } from '@angular/common';
import { OrderDetailsComponent } from '../sharedComponents/order-details/order-details.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  currentPage: any;
  page: number = 1
  orders: any
  isLoading: boolean = false;
  first: number = 11;
  regions: any
  order: any
  displayFilter: boolean = false
  orderForm!: FormGroup;
  stores!: any
  clients!: any
  filteredStores: any = [];
  url: any
  data_to_export: any = []
  status: any = [{ name: "Accompli", value: "completed" }, { name: "Annuler", value: "canceled" }, { name: "Accepté", value: "accepted" }, { name: "Rejeté", value: "rejected" }, { name: "En livraison", value: "in-delivery" }, { name: "Prêt", value: "ready" }, { name: "Annulée ou Rejetée", value: "in:rejected,canceled" }]
  cancelation_reasons: any = [{ label: "Service", value: 0 }, { label: "Etablissement n'a pas répondu", value: 1 }, { label: "Etablissement fermé", value: 2 }, { label: "Non disponible (Menu)", value: 3 }, { label: "Client", value: 4 }, { label: "Other", value: 5 }]
  date: any = new Date()
  @ViewChild('order_details') order_details!: OrderDetailsComponent;
  constructor(private datePipe: DatePipe, public appconfig: AppConfigService, private fb: FormBuilder, private route: ActivatedRoute, private httpService: HttpService, private router: Router) { }
  async ngOnInit() {
    this.orderForm = this.fb.group({
      status: new FormControl("",),
      cancel_reason: new FormControl("",),
      region: new FormControl(""),
      client: new FormControl("",),
      order_number: new FormControl("",),
      gte: new FormControl("",),
      store: new FormControl(""),
      lte: new FormControl("",),
      stats_duration: new FormControl("",),
    });
    // await this.getStores()
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      this.url = 'orders?order_by=-created'
      if (param.get('status') || param.get('region') || param.get('client') || param.get('order_number') || param.get('gte') || param.get('lte') || param.get('store') || param.get('cancel_reason') || param.get('stats_duration')) {
        this.url += '&filters='
        this.orderForm.patchValue({
          status: param.get('status'),
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          cancel_reason: param?.get('cancel_reason') ? JSON.parse(param?.get('cancel_reason') || '') : "",
          client: param.get('client'),
          order_number: param.get('order_number'),
          gte: param.get('gte') ? new Date(param.get('gte') || '') : '',
          stats_duration: param.get('stats_duration') ? new Date(param.get('stats_duration') || '') : '',
          store: param?.get('store'),
          lte: param.get('lte') ? new Date(param.get('lte') || '') : '',
        })
      }
      if (param?.get('client')) {
        this.getClient(param.get('client'))
      }
      if (param?.get('store')) {
        this.getStore(param.get('store'))
      }
      if (param.get('gte') || param.get('lte')) {
        param.get('gte') ? this.url += 'created:gte:' + param.get('gte') + ";" : ""
        param.get('lte') ? this.url += 'created:lse:' + param.get('lte') + ";" : ""
      }
      if (param.get('stats_duration')) {
        switch (param.get('stats_duration')) {
          case "today":
            this.url += "created:gt:" + this.datePipe.transform(this.date, 'yyyy-MM-dd 00:00:00') + ";"
            this.orderForm.patchValue({ stats_duration: "today" })
            break;
          case "yesterday":
            const yesterday = new Date(this.date);
            yesterday.setDate(this.date.getDate() - 1);
            this.url += "created:gt:" + this.datePipe.transform(yesterday, 'yyyy-MM-dd 00:00:00') + ";created:ls:" + this.datePipe.transform(this.date, 'yyyy-MM-dd 00:00:00') + ";"
            this.orderForm.patchValue({ stats_duration: "yesterday" })
            break;
          case "last_week":
            const last_week = new Date(this.date);
            last_week.setDate(this.date.getDate() - 7);
            this.url += "created:gt:" + this.datePipe.transform(last_week, 'yyyy-MM-dd 00:00:00') + ";created:ls:" + this.datePipe.transform(this.date, 'yyyy-MM-dd 00:00:00') + ";"
            this.orderForm.patchValue({ stats_duration: "last_week" })
            break;
          case "last_month":
            const last_month = new Date(this.date);
            last_month.setDate(this.date.getDate() - 30);
            this.url += "created:gt:" + this.datePipe.transform(last_month, 'yyyy-MM-dd 00:00:00') + ";created:ls:" + this.datePipe.transform(this.date, 'yyyy-MM-dd 00:00:00') + ";"
            this.orderForm.patchValue({ stats_duration: "last_month" })
            break;
          default:
            break;
        }
      }
      param.get('status') ? this.url += 'status:' + param.get('status') + ";" : ""
      param.get('cancel_reason') ? this.url += 'cancel_reason:' + param.get('cancel_reason') + ";" : ""
      param.get('region') ? this.url += 'region:' + param.get('region') + ";" : ""
      param.get('client') ? this.url += 'client:' + param.get('client') + ";" : ""
      param.get('store') ? this.url += 'store:' + param.get('store') + ";" : ""
      param.get('order_number') ? this.url += 'order_number:sw:' + param.get('order_number') + ";" : ""
      this.getOrders()
      this.getRegions()
    })
  }
  getClient(id: any) {
    this.httpService.get('client/' + id).subscribe(res => {
      this.orderForm.patchValue({
        client: res.body.data

      })
    })
  }
  getStore(id: any) {
    this.httpService.get('store/' + id).subscribe(res => {
      this.orderForm.patchValue({
        store: res.body.data

      })
    })
  }
  adjust_phone(phone: any) {
    if (phone.lastIndexOf(this.appconfig.config.phone_int_prefix, 0) === 0) {
      return phone.replace(this.appconfig.config.phone_int_prefix, this.appconfig.config.phone_local_prefix)
    } else {
      return phone
    }
  }
  getOrders() {
    this.httpService.get(this.url + "&page=" + this.page).subscribe((res: any) => {
      if (res?.status == 200) {
        this.isLoading = true
        this.orders = res.body
        this.orders.data.forEach((order: any) => {
          let average = 0;
          if (order.delivery) {
            order.delivery.client.phone = this.adjust_phone(order.delivery?.client.phone)
          }
          // order.delivery.client.phone = this.adjust_phone(order.delivery?.client.phone)
          if (order.delivery?.staff?.phone) {
            order.delivery.staff.phone = this.adjust_phone(order.delivery.staff.phone)
          }
          order?.ratings?.forEach((element: any) => {
            average += element.rating
          });
          order.rating = average !== 0 ? average : ""
        });
      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/orders'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  getRegions() {
    this.httpService.get('regions').subscribe((data: any) => {
      if (data?.status == 200) {
        this.regions = data.body.data
      }
    })
  }
  collapse() {
    this.displayFilter = !this.displayFilter
    let doc = document.getElementById('filter') as HTMLHeadElement
    doc.style.paddingBottom = '1.5rem'
    this.displayFilter === true ? doc.style.paddingBottom = "1.5rem " : doc.style.paddingBottom = "0.375rem "
    this.displayFilter === false ? doc.style.borderBottomLeftRadius = "0.25rem " : doc.style.borderBottomLeftRadius = "0"
    this.displayFilter === false ? doc.style.borderBottomRightRadius = "0.25rem " : doc.style.borderBottomRightRadius = "0"
  }
  showSidbar(order: any) {
    this.order_details.display = true
    this.order = order
    this.order.items.forEach((element: any) => {
      element.data.options = Object.values(element.data.options)
    });
  }

  filter() {
    let search = this.orderForm.value
    search.store = search.store?.id
    search.client = search.client?.id
    let gte: any
    let lte: any
    search?.gte ? gte = new Date(search?.gte) : ""
    search?.lte ? lte = new Date(search?.lte) : ""
    let monthg = gte ? gte.getMonth() + 1 : ""
    let monthl = lte ? lte.getMonth() + 1 : ""
    gte ? gte = gte.getFullYear() + "-" + monthg + "-" + gte.getDate() : ""
    lte ? lte = lte.getFullYear() + "-" + monthl + "-" + lte.getDate() : ""
    search.gte = gte
    search.lte = lte
    search = { ...search, page: 1 }
    this.isLoading = false
    console.log(this.orderForm.value);

    this.router.navigate(['/orders'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.orderForm.reset()
    this.router.navigate(['/orders']);
  }
  search(event: any) {
    const filtered: string[] = [];
    let query = event.query;
    for (let i = 0; i < this.stores.length; i++) {
      let store: any = this.stores[i]
      if (store.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(store);
      }
    }
    this.filteredStores = filtered;
    console.log(this.filteredStores);
    return this.filteredStores
  }
  searchClients(event: any) {
    this.httpService.get('clients?filters=phone:sw:' + event.query).subscribe((data: any) => {
      if (data?.status == 200) {
        this.clients = data.body.data
      }
    })
  }
  searchStores(event: any) {
    this.httpService.get('stores?filters=name:sw:' + event.query).subscribe((data: any) => {
      if (data?.status == 200) {
        this.stores = data.body.data
      }
    })
  }
  exportExcel(page: any) {

    this.httpService.get(this.url + "&page=" + page).subscribe((res: any) => {
      if (res?.status == 200) {
        res.body.data.forEach((element: any) => {
          this.data_to_export.push(element)
        });
        if (page < res.body.meta.pagination.total_pages) {
          page++
          this.exportExcel(page)
        }
      }
      if (page === res.body.meta.pagination.total_pages) {
        if (this.data_to_export.length > 0) {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.data_to_export);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "ExportExcel");
          });
        }
      }
    })
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
