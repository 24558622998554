<div *ngIf="loading;else notYetLoaded">
    <div class="d-flex">
        <div class="pr-3">
            <p-calendar inputId="basic" placeholder="JJ/MM/AA" appendTo="body" class="avenirMedium greyBorder"
                [(ngModel)]="gte" dateFormat="dd/mm/yy">
            </p-calendar>
        </div>
        <div class="pr-3">
            <p-calendar inputId="basic" placeholder="JJ/MM/AA" appendTo="body" class="avenirMedium whiteBorder"
                [(ngModel)]="lte" dateFormat="dd/mm/yy">
            </p-calendar>
        </div>
        <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4 mr-3" (click)="filter()">Filtrer
        </button>
        <button class="btn avenirRegular f20 br25 white-back f16 main-color px-4 mb-4"
            (click)="resetFilter()">Réintianiliser </button>
        <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4 ml-auto"
            (click)="add_transaction()">Ajouter une transaction </button>
    </div>
    <div *ngIf="transactions.data.length>0;else noPromos" class="table-responsive">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Établissement</th>
                    <th>Staff</th>
                    <th>Montant</th>
                    <th>Solde</th>
                    <th>Type</th>
                    <th>Commentaire</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of transactions.data">
                    <td class="avenirRegular f18 black">{{transaction.store?.name}}</td>
                    <td class="avenirRegular f18 black">{{transaction.staff?.name}}</td>
                    <td class="avenirRegular f18 blue">{{transaction.amount}} {{home.config.langs[0].currency_symbol}}
                    </td>
                    <td class="avenirRegular f18 blue">{{transaction.balance}} {{home.config.langs[0].currency_symbol}}
                    </td>
                    <td class="avenirRegular f18 black">{{transaction.type}}</td>
                    <td class="avenirRegular f18  d-block lineHeight">
                        <span class="black">
                            {{transaction.comment}}
                        </span>
                        <div>
                            Prix de commande: <span class="blue">{{transaction?.data?.order_price}} {{home.config.langs[0].currency_symbol}}</span>
                        </div>
                    </td>
                    <td class="avenirRegular f18 black">{{transaction.created?.date | date:"dd/MM/yyyy"}}</td>
                </tr>
            </tbody>
        </table>
        <div class=" w-100 ">
            <div class="d-flex justify-content-end">
                <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
                    [showFirstLastIcon]="true" [totalRecords]="transactions.meta.pagination.total_pages" #paginator
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
   <div class="row no-gutters px-5 py-3 justify-content-center">
    <div class="col-md-4 pr-4 d-flex text-center">
        <div class="crd br5 d-flex flex-column avenirRegular fw85 p-3 white-back w-100">
            <span class="greyColor f18">Solde :</span>
            <span class="main-color f35">{{store?.money?.toLocaleString()}} {{appconfig.config.langs[0].currency_symbol}}</span>
        </div>
    </div>
   </div>
</div>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<ng-template #noPromos>
    <div class="pt-3">
        <p class="text-center black  avenirBlack f18">Cet établissement n'a pas encore des transactions</p>
    </div>
</ng-template>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="display" [modal]="true" [draggable]="false"
    [resizable]="false" [breakpoints]="{ '800px': '100vw'}" [style]="{width: '800px'}">
    <div class="pt-2">
        <form autocomplete="off" [formGroup]="TransactionForm" class="pt-3">
            <div class="row no-gutters mb-3 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Montant </span> <span
                            class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Ex. 500"
                        formControlName="amount" [ngClass]="{'is-invalid': f['amount'].errors&&submit}">
                </div>
            </div>
            <div class="row no-gutters mb-3 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Type </span> <span class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9">
                    <p-dropdown [options]="types" formControlName="type" placeholder="Choisir le type"
                        [showClear]="false" [ngClass]="{'is-invalid': f['type'].errors && submit}"></p-dropdown>
                    <!-- <input type="text" class="form-control mdStl avenirMedium f18"[ngClass]="{'is-invalid': f['text_fr'].errors && submit}" formControlName="text_fr" id="name" placeholder="Descritpion"> -->
                </div>
            </div>
            <div class="row no-gutters mb-3 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Commentaie </span> </label>
                </div>
                <div class="col-md-9">
                    <textarea type="text" rows="4" class="form-control mdStl avenirMedium f18"
                        [ngClass]="{'is-invalid': f['comment'].errors && submit}" formControlName="comment" id="name"
                        placeholder="Description">
                    </textarea>
                </div>
            </div>
            <div class="d-flex justify-content-end  align-items-center mt-auto">
                <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
                    (click)="display=false">Annuler</button>
                <button class="btn green-back px-5 avenirMedium f18 text-white"
                    (click)="addTransaction()">Envoyer</button>
            </div>
        </form>
    </div>
</p-dialog>
