import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  display: boolean = false;
  @Input() order: any;
  // @Input() display: boolean = false;
  constructor(public appconfig: AppConfigService) { }

  ngOnInit(): void {
  }

}
