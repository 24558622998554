<div class="d-flex justify-content-end">
  <button class="btn avenirRegular f20 br25 green-back text-white px-4 " (click)="addTransactionPointCategory()">Ajouter
    une
    catégorie</button>
</div>
<div class="table-responsive mt-3" *ngIf="points_rewards_categorys?.data?.length>0;else noOrders">
  <p-table [value]="points_rewards_categorys?.data" [reorderableColumns]="true" responsiveLayout="scroll">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th></th>
        <th class="text-center">Nom</th>
        <th class="text-center">Opération</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <img src="../../../../../assets/images/svg/Group380.svg" [pReorderableRowHandle]="index" alt=""
            (dragend)="changeOrder()">
        </td>
        <td class="avenirRegular f16 black text-center">{{rowData?.name}}</td>
        <td class="avenirRegular f18 text-center">
          <button class="btn p-0 mr-2" (click)="editTransactionPointCategory(rowData)">
            <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
          </button>
          <button class="btn p-0 btnIcon delete" (click)="delete_category(rowData)">
            <img src="../../../../assets/images/svg/delete..svg" alt="" />
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<ng-template #noOrders>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des catégories de points de transactions </p>
  </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="point_category_modal" [modal]="true" [style]="{width: '592px'}"
  [draggable]="false" [resizable]="false">
  <form [formGroup]="categoryForm" autocomplete="off">
    <div class="mb-4">
      <span class="avenirMedium f35 fw85 main-color">Gérer les catégories des points de transactions</span>
    </div>
    <input type="text" class="form-control mdStl avenirMedium f18 mb-3" id="name" placeholder="Titre de la catégorie"
      formControlName="name" [ngClass]="{'is-invalid': submit&&f['name'].errors}">
    <ng-container *ngFor="let lang of configLangs; let i=index" formArrayName="translations">
      <input type="text" class="form-control mdStl avenirMedium f16 pr-4 mb-3" id="title-{{lang.code}}"
        placeholder="Titre en {{lang.name}}" dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
        [value]="categoryForm.value?.translations | translationsPatchValue: 'name':lang.code"
        (change)="onTranslationsChange(categoryForm, 'name', lang.code, $event)">
    </ng-container>
    <div class="d-flex justify-content-end pt-5">
      <button class="btn avenirMedium f16  text-white dp-grey-back mr-3 px-4"
        (click)="point_category_modal=false">Annuler </button>
      <button class="btn green-back avenirMedium f16  text-white px-4" (click)="manage_category()">Enregistrer</button>
    </div>
  </form>
</p-dialog>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="category?.name"></app-confirmation>
