<div class="back-pattern">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="loginCard">
      <div class="px-4 py-5 d-flex align-items-center flex-column white-back loginCrd">
        <img src="../../../assets/images/svg/FoodBeeper logo.svg" alt="">
        <span class="avenirRegular f25 black pt-2 font-weight-bold">Wajeez</span>
        <div class="avenirRegular f20 main-color pb-3 font-weight-bold">{{app_config.appConfig.country_name}} </div>
        <form [formGroup]="userForm" class="w-100 mt-3" autocomplete="off" autocapitalize="off">
          <div class="form-group">
            <input type="text" formControlName="username" [ngClass]="{'is-invalid':submit && f['username'].errors}"  class="form-control customFC f16 avenirMedium" placeholder="Nom d’utilisateur"  >
          </div>
          <div class="form-group">
            <input type="password" [ngClass]="{'is-invalid':submit && f['password'].errors}" formControlName="password" class="form-control customFC f16 avenirMedium" placeholder="Mot de passe">
          </div>
          <div class="mt-4 mb-6">
            <button type="button" class="btn w-100 text-white f20 avenirRegular green-back loginBtn"(click)="login()" >Se connecter</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
