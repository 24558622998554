<div class="container h-100">
  <div class="row no-gutters h-100 justify-content-center align-items-center">
    <div class="col-md-8 col-lg-7 col-lg-6 ">
      <span class="avenirRegular f72 main-color font-weight-bold">Sélectionner le pays </span>
      <p-dropdown [options]="countries" optionLabel="country_name" placeholder="Tout les régions" [showClear]="false"
        [(ngModel)]="country">
      </p-dropdown>
      <div class="d-flex justify-content-end pt-3">
        <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="cancel()">Annuler </button>
        <button class="btn green-back avenirRegular f16 text-white px-4" (click)="select_country()">Envoyer</button>
      </div>
    </div>
  </div>
</div>
