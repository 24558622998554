<div class="white-back" *ngIf="isLoading;else notYetLoaded ">
  <div class="pt-6 pb-4 d-flex align-items-center px-3">
    <button class="btn p-0" [routerLink]="['/stores/details/'+store_id+'/menu']">
      <img src="../../../../../../assets/images/svg/Icon-arrow.svg" class="mr-2 backArrow" alt="">
    </button>
    <span class="avenirArabic f30 fw85 black">Statistiques</span>
  </div>
  <div>
    <p-table *ngIf="section_items!.length>0;else nosection_items" [value]="section_items" [reorderableColumns]="true"
      responsiveLayout="scroll" [resizableColumns]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width:2%"></th>
          <th>Nom</th>
          <th style="max-width: 40%;width: 40%;">Description</th>
          <th>Prix</th>
          <th>Disponibilité</th>
          <th>Section</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td><img *ngIf="rowData.image.url;else noImg" src="{{rowData.image.url}}" class="img" alt="">
          </td>
          <td class="f16 avenirArabic black">
            {{rowData.name}}
            <div class="d-flex mb-1">
              <div class="bar_stats red" [ngStyle]="{width: rowData.availability_stats.stats.not_available*100/50+'%'}"
                pTooltip='{{rowData.availability_stats.stats.not_available}}' tooltipPosition="top"></div>
              <div class="bar_stats green" [ngStyle]="{width: rowData.availability_stats.stats.available*2+'%'}"
                pTooltip='{{rowData.availability_stats.stats.available}}' tooltipPosition="top">
              </div>
            </div>
            <div class="d-flex">
              <div *ngFor="let item of rowData.availability_stats.last_orders" class="pieShape" [ngClass]="{'green-back': item.available===1,'red-back': item.available===0}"
                pTooltip='{{item.order_number}}
               {{ item.available===1?"Disponible":"Non disponible"}}
              {{item.created_at|date:"dd/MM/yyyy HH:mm"}}' tooltipPosition="top">
              </div>
            </div>
          </td>
          <td class="f16 avenirArabic greyColor normalWhiteSpace">{{rowData.description}}</td>
          <td class="f18 avenirArabic"><span class="main-color">{{rowData.price}}</span><span
              class="greyColor">{{appconfig.config.langs[0].currency_symbol}}</span></td>
          <td>
            <p-inputSwitch [(ngModel)]="rowData.available" (onChange)="change_availibility(rowData)">
            </p-inputSwitch>
          </td>
          <td class="">
            <a class="f16 avenirArabic main-color text-capitalize" [routerLink]="['/stores/details/'+rowData.store_id+'/section/'+rowData.section_id]"><u>{{rowData?.section?.name}}</u></a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
<app-manage-items #manage_items [item]="item" [storeId]="store_id"></app-manage-items>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #nosection_items>
  <div class="text-center black  avenirBlack f18 p-3">Cet section n'a pas encore des produits appuyez sur "Ajouter"
    pour en ajouter un</div>
</ng-template>
<ng-template #noImg>
  <img src="../../../../../../assets/images/png/Frame 1322.png" alt="">
</ng-template>

