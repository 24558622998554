<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-3">
    <span class="avenirRegular f72 main-color font-weight-bold">Utilisateurs</span>
    <div class="d-flex justify-content-end">
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" (click)="addUser()">Ajouter un
        utilisateur</button>
    </div>
    <div class="col-md-4 d-flex align-items-center my-4">
      <button class="btn btn-sel avenirMedium f18 black d-flex align-items-center" (click)="toggleBtn()">{{value ===true ?
        "Nom" : "Téléphone"}}<img src="../../../assets/images/svg/arrow_drop_down_black_24dp 1.svg" alt="">
      </button>
      <div class="d-flex position-relative mr-3">
        <input type="text" class="form-control avenirMedium f18 black custom-form-control"[(ngModel)]="searchFilter" placeholder="Rechercher">
        <i class="pi pi-search customIcon"></i>
      </div>
      <p-dropdown [options]="status" [(ngModel)]="active" placeholder="Statut" [showClear]="false" (onChange)="select_status()"></p-dropdown>
    </div>
  </div>
  <table *ngIf="Users.length>0;else noUsers" class="table table-borderless">
    <thead>
      <tr>
        <th>Nom</th>
        <th>Téléphone</th>
        <th>Nom d'utilisateur</th>
        <th>Région</th>
        <th>Permissions</th>
        <th>Opérations</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of Users| searchFilter: searchFilter">
        <td class="avenirMedium f18 black">{{user.name}}</td>
        <td class="avenirMedium f18 black">{{user.phone}}</td>
        <td class="avenirMedium f16 black">{{user.username}}</td>
        <td class="avenirMedium f18 black">
          <ul>
            <li *ngFor="let region of user.regions">{{region.name}}</li>
          </ul>
        </td>
        <td class="avenirMedium f18 black">
          <ul>
            <li *ngFor="let permission of user.permisionss">
              <ng-container *ngIf="permission==='can_view_analytics'">Analytics</ng-container>
              <ng-container *ngIf="permission==='can_manage_stores'">Gestion des établissments</ng-container>
              <ng-container *ngIf="permission==='can_manage_delivery_fees'">Gestion Frais de livraison</ng-container>
              <ng-container *ngIf="permission==='can_manage_orders'">Gestion des commandes</ng-container>
              <ng-container *ngIf="permission==='can_manage_offers_promos'">Gestion des offres et promo</ng-container>
              <ng-container *ngIf="permission==='can_manage_hubs'">Gestion des hubs</ng-container>
            </li>
          </ul>
        </td>
        <td class="avenirMedium f18 black">
          <button class="btn p-0 mr-2"(click)="editUsr(user)">
            <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<p-dialog [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [modal]="true" [style]="{width: '800px'}"
  [showHeader]="false">
  <form autocomplete="off" [formGroup]="userForm">
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Nom</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control mdStl avenirMedium f18" formControlName="name" id="name" [ngClass]="{'is-invalid': submit&&f['name'].errors}" placeholder="Ex. Lokmane">
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="name"><span class="black">Nom d'utilisateur</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control mdStl avenirMedium f18"   [attr.disabled]="editUser" id="name"formControlName="username"[ngClass]="{'is-invalid': submit&&f['username'].errors}" placeholder="Ex. Lokmane">
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="phone"><span class="black">Téléphone</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control mdStl avenirMedium f18" id="phone"formControlName="phone" [ngClass]="{'is-invalid': submit&&f['phone'].errors}"placeholder="Ex . 0657889125">
      </div>
    </div>
    <div *ngIf="!editUser" class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="password"><span class="black">Mot de passe</span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <input type="password" class="form-control mdStl avenirMedium f18" id="name"formControlName="password"[ngClass]="{'is-invalid': submit&&f['password'].errors}" placeholder="Compte">
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="regions"><span class="black">Toutes les régions </span> </label>
      </div>
      <div class="col-md-9">
        <p-inputSwitch formControlName="all_regions"></p-inputSwitch>
      </div>
    </div>
    <div *ngIf="userForm.get('all_regions')?.value===false" class="row no-gutters align-items-center mb-4">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="regions"><span class="black">Région </span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <p-multiSelect [options]="regions" appendTo="body" formControlName="regions" [showHeader]="false" defaultLabel="Régions"
          optionLabel="name" display="chip" optionValue="id" [(ngModel)]="selectedRegions" ></p-multiSelect>
      </div>
    </div>
    <div class="row no-gutters align-items-center mb-5">
      <div class="col-md-3">
        <label class="avenirMedium f20" for="permissions"><span class="black">Permissions </span> <span class="red-color">
            *</span></label>
      </div>
      <div class="col-md-9">
        <p-multiSelect [options]="permissions" optionValue="id" appendTo="body" formControlName="permissions" [showHeader]="false" defaultLabel="Permissions"
          optionLabel="name" display="chip" optionValue="value" inputId="permisionss" formControlName="permissions" [(ngModel)]="selectedPermissions"></p-multiSelect>
      </div>
    </div>
    <div class="d-flex justify-content-between  align-items-center px-3 py-2 bnr">
      <span class="avenirMedium f18 ursopct black"> Utilisateur non-active</span>
      <p-inputSwitch  formControlName="active"></p-inputSwitch>

    </div>
    <div class="d-flex justify-content-end  align-items-center mt-4">
      <button class="btn avenirMedium f18 text-white dp-grey-back mr-3" (click)="hideDialog()">Annuler</button>
      <button class="btn green-back  avenirMedium f18 text-white" (click)="manageUser()">Envoyer</button>
    </div>
  </form>
</p-dialog>
<ng-template #noUsers>
  <div class="pt-3">
      <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des utilisateurs appuyez sur "Ajouter un utilisateur" pour le insérer un </p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
      <app-loading></app-loading>
  </div>
</ng-template>
