import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sales-promotion',
  templateUrl: './sales-promotion.component.html',
  styleUrls: ['./sales-promotion.component.scss']
})
export class SalesPromotionComponent implements OnInit {
  promotions: any
  loading: boolean = false
  currentPage: any;
  page: number = 1
  displayModal: boolean = false
  promoForm!: FormGroup;
  edit: boolean = false
  submit:boolean  = false
  promo:any
  store_id:any
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;

  constructor(private datePipe :DatePipe,private httpService: HttpService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private messageService: MessageService
  ) { }
  ngOnInit(): void {
    this.route.parent?.params.subscribe((params: any) => {
      this.store_id = params.id
      this.route.queryParamMap.subscribe(param => {
        if (Number(param.get('page'))) {
          this.page = Number(param.get('page'))
        } else {
          this.page = 1;
        }

        this.getStoresPromos( this.page)
      })
    })
    this.promoForm = this.fb.group({
      title: new FormControl("", [Validators.required]),
      text_fr: new FormControl("", [Validators.required]),
      text_ar: new FormControl("", [Validators.required]),
      start_at: new FormControl("", [Validators.required]),
      end_at: new FormControl("", [Validators.required])
    });
  }
  get f() { return this.promoForm.controls }

  getStoresPromos( page: number) {
    this.httpService.get('store_promos/' +this.store_id+ '?page=' + page).subscribe(res => {
      if (res?.status == 200) {
        this.promotions = res.body
        this.loading = true
      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['../sales-promotions'], { relativeTo: this.route, queryParams: { page: this.page } });
  }
  editPromo(promo: any) {
    this.promoForm.reset();
    this.displayModal = true
    this.submit=false
    this.edit = true
    this.promo = promo
    this.promoForm.patchValue({
      title: promo.title,
      text_fr: promo.text_fr,
      text_ar: promo.text_ar,
      start_at: promo.start_at?.date ? new Date(promo.start_at?.date) : null,
      end_at: promo.end_at?.date ? new Date(promo.end_at?.date) : null
    })
  }
  addPromo() {
    this.promoForm.reset();
    this.submit=false
    this.displayModal = true
    this.edit = false
  }
  delete_promo(promo: any) {
    this.deleteModal.displayModal = true;
    this.promo = promo
  }
  managePromo() {
    this.submit = true
    if (this.promoForm.invalid) {
      return
    }
    this.promoForm.value. start_at=this.datePipe.transform(this.promoForm.value. start_at, 'dd-MM-yyyy 00:00:00')
    this.promoForm.value. end_at=this.datePipe.transform(this.promoForm.value. end_at, 'dd-MM-yyyy 00:00:00')
    if (this.edit) {
      this.httpService.post('store_promo/'+this.store_id+'/'+this.promo.id, this.promoForm.value).subscribe(res => {
        if (res?.status == 200) {
          this.displayModal = false
          Object.assign(this.promo, res.body?.data)
          this.messageService.add({severity:'success', summary:'Opération Terminée', detail:'Promotion modifiée avec succès'});
        }
      })
    }
    else {
      this.httpService.post('store_promos/'+this.store_id+"/add", this.promoForm.value).subscribe(res => {
        if (res?.status == 200) {
          this.displayModal = false
          this.promotions.data.push(res.body?.data)
          this.messageService.add({severity:'success', summary:'Opération Terminée', detail:'Promotion ajoutée avec succès'});
        }
      })
    }
  }
  onConfirm(event: any) {
    this.httpService.delete('store_promo/'+this.store_id+'/'+this.promo.id).subscribe(data => {
      if (data?.status === 204) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Promotion a été supprimé avec succès' });
        this.promotions.data = this.promotions.data.filter((item: any) => item.id !== this.promo.id);
        this.deleteModal.displayModal = false;
      }
    })
  }
}
