<div class=" white-back p-3" *ngIf="loading">
  <div class="position-relative coverPhoto">
    <div class="customUpload flex-column" style="background-image:url('{{imgUrl}}')"
      [ngStyle]="{'background-color:#ffffff': !reward}">
      <label class="d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <div class="clickable addImg mr-3">
            <input type="file" (change)="showPreviewImage($event)" />
            <img src="../../../../../assets/images/svg/camera.svg" class="cameraImg" alt="">
          </div>
        </div>
        <span class="f16 avenirRglr deepGrey mt-2">Ajouter une photo de récompense</span>
      </label>
    </div>
  </div>
  <form [formGroup]="reward_form" autocomplete="off">
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Titre</b>
        </div>
      </p-divider>
      <div class="form-group d-flex align-items-center">
        <label class="avenirMedium f18 white-space" for="name"><span class="black">Nom de récompense</span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16 pr-4" formControlName="name"
          [ngClass]="{'is-invalid': f['name'].errors&&submit}" id="name" placeholder="Nom de reward">
      </div>
      <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations"
        class="form-group d-flex align-items-center">
        <label class="avenirMedium f18 white-space" for="title-{{lang.code}}"><span class="black">Nom en
            {{lang.name}}</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16 pr-4" id="title-{{lang.code}}"
          [placeholder]="lang.name" dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
          [value]="reward_form.value?.translations | translationsPatchValue: 'name':lang.code"
          (change)="onTranslationsChange(reward_form, 'name', lang.code, $event)">
      </div>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Sous Titre</b>
        </div>
      </p-divider>
      <div class="form-group d-flex align-items-center">
        <label class="avenirMedium f18 white-space" for="subtitle"><span class="black">Sous Titre</span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16 pr-4" formControlName="subtitle"
          [ngClass]="{'is-invalid': f['subtitle'].errors&&submit}" id="subtitle" placeholder="Sous Titre">
      </div>
      <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations"
        class="form-group d-flex align-items-center">
        <label class="avenirMedium f18 white-space" for="subtitle-{{lang.code}}"><span class="black">Sous Titre en
            {{lang.name}}</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16 pr-4" id="subtitle-{{lang.code}}"
          [placeholder]="lang.name" dir="auto" [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
          [value]="reward_form.value?.translations | translationsPatchValue: 'subtitle':lang.code"
          (change)="onTranslationsChange(reward_form, 'subtitle', lang.code, $event)">
      </div>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Mini Description</b>
        </div>
      </p-divider>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="mini_description"><span class="black">Mini
              Description</span>
          </label>
          <div class="border_ w-100">
            <app-markdown-editor controlId="mini_description"></app-markdown-editor>
            <textarea type="text" class="form-control mdStl avenirMedium f16 pr-4 text-editor"
              formControlName="mini_description" [ngClass]="{'is-invalid': f['mini_description'].errors&&submit}"
              rows="4" id="mini_description" placeholder="Mini Description">
        </textarea>
          </div>
        </div>
        <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations" class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="mini_description{{lang.code}}"><span
              class="black">Mini
              Description
              en
              {{lang.name}}</span></label>
          <div class="border_ w-100">
            <app-markdown-editor controlId="mini_description{{lang.code}}"></app-markdown-editor>
            <textarea type="text" class="form-control mdStl avenirMedium f16 pr-4 text-editor" rows="4"
              id="mini_description{{lang.code}}" [placeholder]="lang.name" dir="auto"
              [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
              [value]="reward_form.value?.translations | translationsPatchValue: 'mini_description':lang.code"
              (change)="onTranslationsChange(reward_form, 'mini_description', lang.code, $event)">
        </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Description</b>
        </div>
      </p-divider>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="description"><span class="black">Description</span>
          </label>
          <div class="border_ w-100">
            <app-markdown-editor controlId="description"></app-markdown-editor>
            <textarea id="description" class="text-editor form-control mdStl avenirMedium f16 pr-4"
              formControlName="description" [ngClass]="{'is-invalid': f['description'].errors&&submit}"
              placeholder="Description" rows="4"></textarea>
          </div>
        </div>
        <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations" class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="description{{lang.code}}"><span
              class="black">Description
              en
              {{lang.name}}</span></label>
          <div class="border_ w-100">
            <app-markdown-editor controlId="description{{lang.code}}"></app-markdown-editor>
            <textarea type="text" class="form-control mdStl avenirMedium f16 pr-4 text-editor" rows="4"
              id="description{{lang.code}}" [placeholder]="lang.name" dir="auto"
              [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
              [value]="reward_form.value?.translations | translationsPatchValue: 'description':lang.code"
              (change)="onTranslationsChange(reward_form, 'description', lang.code, $event)">
        </textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Message d'activation</b>
        </div>
      </p-divider>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="activation_message"><span class="black">Message
              d'activation</span>
          </label>
          <div class="w-100 border_">
            <app-markdown-editor controlId="activation_message"></app-markdown-editor>
            <textarea type="text" class="form-control mdStl avenirMedium f16 pr-4 text-editor"
              formControlName="activation_message" [ngClass]="{'is-invalid': f['activation_message'].errors&&submit}"
              rows="4" id="activation_message" placeholder="Message d'activation">
      </textarea>
          </div>
        </div>
        <div *ngFor="let lang of configLangs; let i=index" formArrayName="translations" class="form-group col-md-4">
          <label class="avenirMedium f18 white-space pr-4 mb-2" for="activation_message{{lang.code}}"><span
              class="black">Message
              d'activation en
              {{lang.name}}</span></label>
          <div class="border_ w-100">
            <app-markdown-editor controlId="activation_message{{lang.code}}"></app-markdown-editor>
            <textarea type="text" class="form-control mdStl avenirMedium f16 pr-4 text-editor" rows="4"
              id="activation_message{{lang.code}}" [placeholder]="lang.name" dir="auto"
              [ngClass]="{'is-invalid': submit&&f['translations'].errors}"
              [value]="reward_form.value?.translations | translationsPatchValue: 'activation_message':lang.code"
              (change)="onTranslationsChange(reward_form, 'activation_message', lang.code, $event)">
          </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class="avenirMedium f20 black">Points et Remises</b>
        </div>
      </p-divider>
      <div class="border border-top-0 p-1">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label class="avenirMedium f18 white-space" for="base_points">Points
            </label>
            <input type="number" formControlName="base_points" class="form-control mdStl avenirArabic f18 "
              id="base_points" placeholder="Points" [ngClass]="{'is-invalid':submit&& f['base_points'].errors}"
              (mousewheel)="$event.preventDefault()">
          </div>
          <div class="form-group col-md-3">
            <label class="avenirMedium f18 white-space" for="base_points">Vente Flash
            </label>
            <p-inputSwitch [(ngModel)]="temporel_reward" [ngModelOptions]="{standalone:true}"
              (onChange)="temporel_rewarding($event)"></p-inputSwitch>
          </div>
          <ng-container *ngIf="temporel_reward">
            <div class="form-group col-md-3">
              <label class="avenirMedium f18 white-space" for="base_points">Points de Vente flash
              </label>
              <input type="number" formControlName="points" class="form-control mdStl avenirArabic f18 "
                id="description" placeholder="Points" [ngClass]="{'is-invalid':submit&& f['points'].errors}"
                (mousewheel)="$event.preventDefault()">
            </div>
            <div class="form-group col-md-3 d-flex flex-column">
              <label class="avenirMedium f18 white-space" for="base_points">Date d'expiration
              </label>
              <p-calendar inputId="promo_until" formControlName="promo_until" [showTime]="false" dateFormat="dd/mm/yy"
                [ngClass]="{'is-invalid': f['promo_until'].errors&&submit}" appendTo="body" class="avenirMedium"
                placeholder="promo jusqu'à">
              </p-calendar>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class="avenirMedium f20 black">Liens</b>
        </div>
      </p-divider>
      <div formArrayName="links" *ngFor="let fg of formgroup.controls; let i = index;let isLast=last">
        <div [formGroupName]="i" class="row no-gutters mt-4 align-items-center">
            <div class="col-md-3 pr-3">
              <p-dropdown optionValue="id" formControlName="media"
              [ngClass]="{'is-invalid': f['region_id'].errors&&submit}" [options]="social_media" optionValue="value"  optionLabel="label"
              placeholder="Media" class="w-50" [showClear]="false">
            </p-dropdown>
            </div>
            <div class="col-md-3 pr-2">
                <input type="text" class="form-control mdStl avenirMedium f18" id="name" placeholder="Value"
                    formControlName="value" >
            </div>
            <div class="col-md-2 d-flex justify-content-center">
              <button class="btn p-0" (click)="deleteProduct(i)">
                  <img src="../../../../../assets/images/svg/delete..svg" alt="">
              </button>
          </div>
        </div>
    </div>
    <button class="btn main-color f20 avenirMedium mt-3" (click)="addLink()">Ajouter un lien (+)</button>
    </div>
    <div class="pt-4">
      <p-divider align="center">
        <div class="inline-flex align-items-center">
          <b class='avenirMedium f20 black'>Plus d'information</b>
        </div>
      </p-divider>
      <div class="form-row">
        <div class="form-group col-md-3 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="regions">Toutes les régions</label>
          <p-inputSwitch formControlName="all_regions"></p-inputSwitch>
        </div>
        <div class="form-group col-md-4 d-flex align-items-center"
          *ngIf="reward_form.get('all_regions')?.value===false">
          <label class="avenirMedium f18 white-space pr-3" for="region_id"><span class="black">Région</span>
          </label>
          <p-dropdown optionValue="id" formControlName="region_id"
            [ngClass]="{'is-invalid': f['region_id'].errors&&submit}" [options]="regions" optionLabel="name"
            placeholder="Régions" class="w-50" [showClear]="false">
          </p-dropdown>
        </div>
        <div class="form-group col-md-4 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="reward_category_id"><span class="black">Catégorie</span>
          </label>
          <p-dropdown optionValue="id" formControlName="reward_category_id"
            [ngClass]="{'is-invalid': f['reward_category_id'].errors&&submit}" [options]="categories" optionLabel="name"
            placeholder="Catégorie" class="w-50" [showClear]="false">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row pt-3">
        <div class="form-group col-md-4 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="type"><span class="black">Type de récompense</span>
          </label>
          <p-dropdown [ngClass]="{'is-invalid':submit&& f['type'].errors}" appendTo="body" formControlName="type"
            [options]='type' optionValue="value" optionLabel="label" placeholder="Type de récompense" [disabled]="edit"
            [showClear]="false">
          </p-dropdown>
        </div>
        <div class="form-group col-md-2">
          <div class="d-flex pt-4"
            *ngIf="reward_form.get('type')?.value==='percent_promo'||reward_form.get('type')?.value==='value_promo'">
            <input formControlName="value" class="form-control mdStl avenirRegular f18 " id="description"
              [placeholder]="reward_form.get('type')?.value==='percent_promo'?'Pourcentage de remise':'Valeur de remise'"
              [ngClass]="{'is-invalid':submit&& f['value'].errors}" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <span class="input-group-text fw85 avenirRegular f18 main-color"
                id="basic-addon2">{{reward_form.get('type')?.value==='percent_promo'?"%":home.config.langs[0].currency_symbol}}</span>
            </div>
          </div>
          <div class="pt-4">
            <input type="email" *ngIf="reward_form?.get('type')?.value==='partner_promo'"
              formControlName="partner_email" class="form-control mdStl avenirRegular f18 " id="partner_email"
              placeholder="E-mail du partenaire" [ngClass]="{'is-invalid':submit&& f['partner_email'].errors}">
          </div>
        </div>
      </div>
      <div class="form-row pt-3">
        <div class="form-group col-md-4 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="starts_at"><span class="black">Date de début</span>
          </label>
          <p-calendar inputId="starts_at" formControlName="starts_at" [showTime]="false" dateFormat="dd/mm/yy"
            [ngClass]="{'is-invalid': f['starts_at'].errors&&submit}" appendTo="body" class="avenirMedium"
            placeholder="Commence à">
          </p-calendar>
        </div>
        <div class="form-group col-md-4 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="expires_at"><span class="black">Date de fin</span>
          </label>
          <p-calendar inputId="expires_at" formControlName="expires_at" [showTime]="false" dateFormat="dd/mm/yy"
            [ngClass]="{'is-invalid': f['expires_at'].errors&&submit}" appendTo="body" class="avenirMedium"
            placeholder="Expire à">
          </p-calendar>
        </div>
        <div class="form-group col-md-4 d-flex align-items-center">
          <label class="avenirMedium f18 white-space pr-3" for="active"><span class="black">Active</span>
          </label>
          <p-inputSwitch formControlName="active"></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pt-5">
      <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4" (click)="cancel()">Annuler </button>
      <button class="btn green-back avenirMedium f18 text-white px-4"
        (click)="manageReward()">{{edit?"Modifier":"Ajouter"}}</button>
    </div>
  </form>
</div>
