<div class="" *ngIf="loading;else loadingtemplate">
    <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'380px'}" #gmap id="map">
    </p-gmap>
    <div class="d-flex justify-content-end py-4">
        <button *ngIf="delivery_zone" class="btn green-back br25 text-white avenirRegular fw85 f20 px-4 mr-3"
            (click)="changeStatus(delivery_zone)">Sauvegarder</button>
        <button class="btn red-back br25 text-white avenirRegular fw85 f20 px-4"
            (click)="delete_all_delivery_zones()">Suprimer tout les zones</button>
    </div>
    <div class="row no-gutters">
        <div *ngFor="let item of store_delivery_zone" class="col-md-4 d-flex">
            <div class="crd m-2">
                <div class="p-3 crd-header">
                    <div class="d-flex justify-content-between">
                        <button *ngIf="item.active" class="btn p-0" (click)="edit_delivery_zone(item)">
                            <img src="../../../../../assets/images/svg/Icon - pen.svg" alt="">
                        </button>
                        <span class="avenirMedium f18 main-color">Zone de {{item.delivery_time}} min</span>
                        <p-inputSwitch [(ngModel)]="item.active" (onChange)="changeStatus(item)"></p-inputSwitch>
                    </div>
                </div>
                <div class="p-4 text-center">
                    <span class="avenirMedium f20 deepGrey">Zone de La livraison de {{item.delivery_time}} min</span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingtemplate>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="'toutes les zones de livraison'">
</app-confirmation>