import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
// ************************************************************************PrimeNg Components************************************************************************
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { GMapModule } from 'primeng/gmap';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { RatingModule } from 'primeng/rating';
import { InputNumberModule } from 'primeng/inputnumber';
import { FieldsetModule } from 'primeng/fieldset';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SliderModule } from 'primeng/slider';
import {RadioButtonModule} from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { OrderListModule } from 'primeng/orderlist';
import { AccordionModule } from 'primeng/accordion';

import { TimelineModule } from 'primeng/timeline';

// ************************************************************************ Guards************************************************************************
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuardGuard } from './guards/permission-guard.guard';
// ************************************************************************ Services************************************************************************
import { SplashScreenStateService } from './services/splash-screen-state.service';
import { HomepageResolver } from './resolvers/homepage.resolver';
import { AppConfigService } from './services/app-config.service';
// ************************************************************************ Lottie************************************************************************
import { LottieModule } from 'ngx-lottie';
// ************************************************************************Components************************************************************************
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HomeComponent } from './components/home/home.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { GlobalAnalyticsComponent } from './components/global-analytics/global-analytics.component';
import { StoresComponent } from './components/stores/stores.component';
import { UsersComponent } from './components/users/users.component';
import { DeliveryFeesComponent } from './components/delivery-fees/delivery-fees.component';
import { RegionsComponent } from './components/regions/regions.component';
import { HubsComponent } from './components/hubs/hubs.component';
import { TestComponent } from './components/test/test.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PhoneListComponent } from './components/regions/phone-list/phone-list.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { SearchPipe } from './pipes/search.pipe';
import { StoreDetailsComponent } from './components/stores/store-details/store-details.component';
import { ProfileComponent } from './components/stores/store-details/profile/profile.component';
import { UsrsComponent } from './components/stores/store-details/usrs/usrs.component';
import { GalleryComponent } from './components/stores/store-details/gallery/gallery.component';
import { DeliveryZoneComponent } from './components/stores/store-details/delivery-zone/delivery-zone.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { MenuComponent } from './components/stores/store-details/menu/menu.component';
import { SectionsComponent } from './components/stores/store-details/menu/sections/sections.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OpeningHoursComponent } from './components/stores/store-details/opening-hours/opening-hours.component';
import { ContactComponent } from './components/stores/store-details/contact/contact.component';
import { SalesPromotionComponent } from './components/stores/store-details/sales-promotion/sales-promotion.component';
import { OrdersComponent } from './components/orders/orders.component';
import { BannerComponent } from './components/banner/banner.component';
import { RewardsComponent } from './components/rewards/rewards.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ZoneComponent } from './components/hubs/zone/zone.component';
import { RewardsChildComponent } from './components/rewards/rewards-child/rewards-child.component';
import { PointsProgramsComponent } from './components/rewards/points-programs/points-programs.component';
import { TransactionPointsComponent } from './components/rewards/transaction-points/transaction-points.component';
import { ManageRewardComponent } from './components/rewards/manage-reward/manage-reward.component';
import { ManagePromoCodeComponent } from './components/promo-code/manage-promo-code/manage-promo-code.component';
import { AssistanceComponent } from './components/assistance/assistance.component';
import { ManageNotificationComponent } from './components/notifications/manage-notification/manage-notification.component';
import { PushComponent } from './components/notifications/manage-notification/push/push.component';
import { EmailComponent } from './components/notifications/manage-notification/email/email.component';
import { SMSComponent } from './components/notifications/manage-notification/sms/sms.component';
import { SpecialOpeningHoursComponent } from './components/stores/store-details/opening-hours/special-opening-hours/special-opening-hours.component';
import { PageNotFoundComponent } from './components/errors/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './components/errors/permission-denied/permission-denied.component';
import { StoreTicketsComponent } from './components/stores/store-details/store-tickets/store-tickets.component';
import { StoresTicketsComponent } from './components/tickets/stores-tickets/stores-tickets.component';
import { StaffComponent } from './components/hubs/staff/staff.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { StoreTransactionsComponent } from './components/stores/store-details/store-transactions/store-transactions.component';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { StatisticsComponent } from './components/stores/store-details/menu/statistics/statistics.component';
import { ManageItemsComponent } from './components/stores/store-details/menu/manage-items/manage-items.component';
import { CancelledOrdersStatsComponent } from './components/orders/cancelled-orders-stats/cancelled-orders-stats.component';
import { StoriesManagementComponent } from './components/stories-management/stories-management.component';
import { PartnersManagementComponent } from './components/stories-management/partners-management/partners-management.component';
import { TranslationsPatchValuePipe } from './pipes/translations-patch-value.pipe';
import { ManageCategoriesComponent } from './components/rewards/manage-categories/manage-categories.component';
import { ManageFilesComponent } from './components/rewards/manage-files/manage-files.component';
import { MarkdownEditorModule } from "./components/sharedComponents/markdown-editor/markdown-editor.module";
import { ClientTicketsComponent } from './components/tickets/client-tickets/client-tickets.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { OrderDetailsComponent } from './components/sharedComponents/order-details/order-details.component';

export function playerFactory(): any {
  return import('lottie-web');
}
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  }
};
@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    AnalyticsComponent,
    GlobalAnalyticsComponent,
    StoresComponent,
    UsersComponent,
    DeliveryFeesComponent,
    RegionsComponent,
    HubsComponent,
    TestComponent,
    LoadingComponent,
    PhoneListComponent,
    SplashScreenComponent,
    SearchPipe,
    StoreDetailsComponent,
    ProfileComponent,
    UsrsComponent,
    GalleryComponent,
    DeliveryZoneComponent,
    ConfirmationComponent,
    MenuComponent,
    SectionsComponent,
    NotificationsComponent,
    OpeningHoursComponent,
    ContactComponent,
    SalesPromotionComponent,
    OrdersComponent,
    BannerComponent,
    RewardsComponent,
    PromoCodeComponent,
    TransactionsComponent,
    SettingsComponent,
    ZoneComponent,
    RewardsChildComponent,
    PointsProgramsComponent,
    TransactionPointsComponent,
    ManageRewardComponent,
    ManagePromoCodeComponent,
    AssistanceComponent,
    ManageNotificationComponent,
    PushComponent,
    EmailComponent,
    SMSComponent,
    SpecialOpeningHoursComponent,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    StoreTicketsComponent,
    StoresTicketsComponent,
    StaffComponent,
    CountrySelectorComponent,
    StoreTransactionsComponent,
    BlankPageComponent,
    StatisticsComponent,
    ManageItemsComponent,
    CancelledOrdersStatsComponent,
    StoriesManagementComponent,
    PartnersManagementComponent,
    TranslationsPatchValuePipe,
    ManageCategoriesComponent,
    ManageFilesComponent,
    ClientTicketsComponent,
    TicketsComponent,
    OrderDetailsComponent,
  ],
  imports: [
    BrowserModule,
    MarkdownEditorModule,
    AppRoutingModule,
    DropdownModule,
    MenuModule,
    ToggleButtonModule,
    BrowserAnimationsModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    MultiSelectModule,
    CalendarModule,
    GMapModule,
    CheckboxModule,
    PaginatorModule,
    HttpClientModule,
    ReactiveFormsModule,
    SidebarModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    TabMenuModule,
    ChartModule,
    ToastModule,
    TableModule,
    SliderModule,
    MenuModule ,
    RatingModule,
    RadioButtonModule,
    LottieModule.forRoot({ player: playerFactory }),
    InputNumberModule,
    FieldsetModule,
    AutoCompleteModule,
    DividerModule,
    OrderListModule,
    AccordionModule,
    TimelineModule,

  ],

  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    DatePipe, AuthGuard, PermissionGuardGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }, ConfirmationService, MessageService, SplashScreenStateService, HomepageResolver],
  bootstrap: [AppComponent]
})
export class AppModule { }
