<div *ngIf="loading;else notYetLoaded">
    <div class="d-flex justify-content-end">
        <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4"(click)="addPromo()">Ajouter une promotion</button>
    </div>
    <div *ngIf="promotions.data.length>0;else noPromos" class="table-responsive">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>Titre</th>
                    <th>Description FR</th>
                    <th>Description AR</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Opérations</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let promo of promotions.data">
                    <td class="avenirRegular f18 black">{{promo.title}}</td>
                    <td class="avenirRegular f18 black">{{promo.text_fr}}</td>
                    <td class="avenirRegular f18 black">{{promo.text_ar}}</td>
                    <td class="avenirRegular f18 black">{{promo.start_at.date|date:'dd/MM/yyyy'}}</td>
                    <td class="avenirRegular f18 black">{{promo.end_at.date|date:'dd/MM/yyyy'}}</td>
                    <td class="avenirRegular f18 black">
                        <button class="btn p-0 mr-2" (click)="editPromo(promo)">
                            <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                        </button>
                        <button class="btn p-0" (click)="delete_promo(promo)">
                            <img src="../../../assets/images/svg/delete..svg" alt="">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class=" w-100 ">
            <div class="d-flex justify-content-end">
                <p-paginator [rows]="promotions.meta.pagination.per_page"
                    [totalRecords]="promotions.meta.pagination.total" [showFirstLastIcon]="false"
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
</div>
<ng-template #noPromos>
    <div class="pt-3">
        <p class="text-center black  avenirBlack f18">Cet établissement n'a pas encore des promotions appuyez sur
            "Ajouter une promotion" pour en insérer une</p>
    </div>
</ng-template>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-dialog [showHeader]="false" class="customModal" [(visible)]="displayModal" [modal]="true" [draggable]="false"
    [resizable]="false" [breakpoints]="{ '800px': '100vw'}" [style]="{width: '800px'}">
    <div class="pt-2">
        <form autocomplete="off" [formGroup]="promoForm" class="pt-3">
            <div class="row no-gutters mb-5 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Titre </span> <span
                            class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9">
                    <input type="text" class="form-control mdStl avenirMedium f18" id="name"
                        placeholder="Ex. promo exclusive"formControlName="title" [ngClass]="{'is-invalid': f['title'].errors&&submit}">
                </div>
            </div>
            <div class="row no-gutters mb-5 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Description FR </span> <span
                            class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9">
                    <input type="text" class="form-control mdStl avenirMedium f18"[ngClass]="{'is-invalid': f['text_fr'].errors && submit}" formControlName="text_fr" id="name" placeholder="Descritpion">
                </div>
            </div>
            <div class="row no-gutters mb-5 align-items-center">
                <div class="col-md-3">
                    <label class="avenirMedium f20" for="name"><span class="black">Description AR </span> <span
                            class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9">
                    <input type="text" class="form-control mdStl avenirMedium f18"[ngClass]="{'is-invalid': f['text_ar'].errors && submit}" formControlName="text_ar"id="name" placeholder="Description">
                </div>
            </div>
            <div class="row no-gutters mb-5 align-items-center">
                <div class="col-md-6" >
                    <p-calendar inputId="basic" dateFormat="dd/mm/yy" appendTo="body" placeholder="JJ/MM/AA"[ngClass]="{'is-invalid': f['start_at'].errors && submit}"formControlName="start_at" class="avenirMedium"></p-calendar>
                </div>
                <div class="col-md-6">
                    <p-calendar inputId="basic" dateFormat="dd/mm/yy" id="" appendTo="body" class="avenirMedium"[ngClass]="{'is-invalid': f['end_at'].errors && submit}"formControlName="end_at" placeholder="JJ/MM/AA"></p-calendar>
                </div>
            </div>
            <div class="d-flex justify-content-end  align-items-center mt-auto">
                <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
                    (click)="displayModal=false">Annuler</button>
                <button class="btn green-back px-5 avenirMedium f18 text-white"(click)="managePromo()">Envoyer</button>
            </div>
        </form>
    </div>
</p-dialog>
<app-confirmation  #deleteModal (onConfirm)="onConfirm($event)" [name]="promo?.title"></app-confirmation>
