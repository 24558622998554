import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { options } from '../../_helpers/chart_options';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  stackedData: any;
  stackedOptions: any;
  stackedOrdersData: any;
  stackedOrdersOptions: any;
  selectedVisitor: any = 'perDay'
  selectedOrder: any = 'perDay'
  data: any
  regionOrder!: string
  regionVisitor!: string
  regions: any
  isLoading: boolean = false
  constructor(private httppService: HttpService) { }
  ngOnInit(): void {
    this.getStats()
    this.getRegions()
    this.stackedOptions = options.options
    this.stackedOrdersOptions = options.options
  }
  getStats() {
    this.httppService.get("stats/order_stats/").subscribe(
      (data: any) => {
        if (data?.status === 200) {
          this.isLoading = true
          this.data = data.body
          this.setUpVisitors("perDay")
          this.setUpOrders("perDay")
        }
      })
  }
  setUpVisitorsChart(firstValue: any, secondValue: any, date: any) {
    const borderRadius = 5;
    const borderRadiusAllCorners = { bottomLeft: borderRadius, bottomRight: borderRadius, topRight: borderRadius, topLeft: borderRadius }
    this.stackedData = {
      labels: date,
      datasets: [{
        type: 'bar',
        label: 'Visiteurs',
        backgroundColor: '#02A0FC',
        borderRadius: borderRadiusAllCorners,
        borderSkipped: false,
        data: firstValue
      }, {
        type: 'bar',
        label: 'Nouveaux visiteurs',
        backgroundColor: '#34B53A',
        borderRadius: borderRadiusAllCorners,
        borderSkipped: false,
        data: secondValue
      },]
    };
  }
  setUpVisitors(selection: string) {
    this.selectedVisitor = selection
    let visitors: any[] = []
    let newVisitors: any[] = []
    let date: any[] = []
    switch (selection) {
      case "perDay":
        this.data.visitors_day.forEach((element: any) => {
          visitors.push(element.visitors)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          newVisitors.push(element.new)
        });
        break;
      case "perWeek":
        this.data.visitors_week.forEach((element: any) => {
          visitors.push(element.visitors)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          newVisitors.push(element.new)
        });
        break;
      case "perMonth":
        this.data.visitors_month.forEach((element: any) => {
          visitors.push(element.visitors)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          newVisitors.push(element.new)
        });
        break;

    }
    this.setUpVisitorsChart(visitors.reverse(), newVisitors.reverse(), date.reverse())
  }
  setUpOrdersChart(firstValue: any, secondValue: any, date: any) {
    const borderRadius = 5;
    const borderRadiusAllCorners = { bottomLeft: borderRadius, bottomRight: borderRadius, topRight: borderRadius, topLeft: borderRadius }
    this.stackedOrdersData = {
      labels: date,
      datasets: [{
        type: 'bar',
        label: 'Commandes',
        backgroundColor: '#02A0FC',
        borderRadius: borderRadiusAllCorners,
        borderSkipped: false,
        data: firstValue
      }, {
        type: 'bar',
        label: 'Nouveaux Commandes',
        backgroundColor: '#34B53A',
        data: secondValue,
        borderRadius: borderRadiusAllCorners,
        borderSkipped: false,
      },]
    };
  }
  setUpOrders(selection: string) {
    this.selectedOrder = selection
    let orders: any[] = []
    let neworders: any[] = []
    let date: any[] = []
    switch (selection) {
      case "perDay":
        this.data.orders_day.forEach((element: any) => {
          orders.push(element.orders)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          neworders.push(element.new)
        });
        break;
      case "perWeek":
        this.data.orders_week.forEach((element: any) => {
          orders.push(element.orders)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          neworders.push(element.new)
        });
        break;
      case "perMonth":
        this.data.orders_month.forEach((element: any) => {
          orders.push(element.orders)
          let dte = new Date(element.date)
          let day = dte.getMonth() + 1
          element.dates = dte.getDate() + "/" + day
          date.push(element.dates)
          neworders.push(element.new)
        });
        break;

    }
    this.setUpOrdersChart(orders.reverse(), neworders.reverse(), date.reverse())
  }
  getRegions() {
    this.httppService.get('regions').subscribe((data: any) => {
      if (data?.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  selectRegion(event: any) {
    let selecctedRegion = this.regionVisitor
    if (event === "orders") {
      this.httppService.get('stats/order_stats?region=' + selecctedRegion).subscribe((data: any) => {
        if (data?.status === 200) {
          this.data = data.body
          if (event === "visitors") {
            this.setUpVisitors("perDay")
          }
          if (event === "orders") {
            this.setUpOrders("perDay")
          }
        }
      })
    }
  }
}
