<div *ngIf="loading;else notYetLoaded">
    <div class="px-4 pt-6 pb-3">
        <span class="avenirRegular f72 main-color font-weight-bold">Transactions</span>
        <div class="d-flex justify-content-end">
            <button class="btn avenirRegular f20 br25 green-back text-white px-4 mb-4" (click)="openModal()">Créer une
                transaction</button>
        </div>
        <form [formGroup]="searchForm">
            <div class="row no-gutters ">
                <div class="col-md-2 pr-3">
                    <p-dropdown [options]="regions" optionValue="id" optionLabel="name" placeholder="Région"
                        [showClear]="false" formControlName="region"></p-dropdown>
                </div>
                <div class="col-md-2 pr-3">
                    <p-dropdown optionValue="id" formControlName="hub" [options]="hubs" optionLabel="name"
                        placeholder="Hub" [showClear]="false">
                    </p-dropdown>
                </div>
                <div class="col-md-2 pr-3">
                    <p-dropdown optionValue="value" formControlName="type" [options]="transactionTypes"
                        optionLabel="label" placeholder="Type de paiement" [showClear]="false"></p-dropdown>
                </div>
                <div class="col-md-2 pr-2">
                    <p-calendar inputId="basic"dateFormat="dd/mm/yy" formControlName="gte" placeholder="JJ/MM/AA" appendTo="body"
                        class="avenirMedium whiteBorder">
                    </p-calendar>
                </div>
                <div class="col-md-2 pr-2">
                    <p-calendar inputId="basic"dateFormat="dd/mm/yy" formControlName="lte" placeholder="JJ/MM/AA" appendTo="body"
                        class="avenirMedium whiteBorder">
                    </p-calendar>
                </div>
                <div class="col-md-1 pr-1">
                    <button class="text-white btn avenirRegular f18 green-back w-100 filterBtn"
                        (click)="filter()">Filtrer</button>
                </div>
                <div class="col-md-1 ">
                    <button class="w-100 text-white btn avenirRegular f16 main-color white-back filterBtn"
                        (click)="reset()">Réintianiliser</button>
                </div>
            </div>
        </form>
    </div>
    <div class="table-responsive">
        <table *ngIf="transactions.data.length>0;else notransactions" class="table table-borderless mt-4">
            <thead>
                <tr>
                    <th>N° Transaction</th>
                    <th>Hub</th>
                    <th>Hub Admin</th>
                    <th>Montant</th>
                    <th>Type</th>
                    <th>Date</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of transactions.data">

                    <td class="avenirRegular f18 black">{{transaction.id}}</td>
                    <td class="avenirRegular f18 black">{{transaction.hub.name}}</td>
                    <td class="avenirRegular f18 black">{{transaction.hub.name}}</td>
                    <td class="avenirRegular f18 fw85">
                        <span class="main-color">{{transaction.amount}}</span>
                        <span class=" greyColor"> {{appconfig.config.langs[0].currency_symbol}}</span>
                    </td>
                    <td class="avenirRegular f18 black">
                        <div [ngSwitch]="transaction.type">
                            <div *ngSwitchCase="'service_fee'">Frais de service</div>
                            <div *ngSwitchCase="'collected_order'">Commande collectée</div>
                            <div *ngSwitchCase="'hub_payment'">Paiement du Hub</div>
                            <div *ngSwitchCase="'order_commission'">Commission</div>
                        </div>
                    </td>
                    <td class="avenirRegular f18 black">{{transaction.created.date |date:"dd/MM/yyyy" }}</td>
                </tr>
            </tbody>
        </table>
        <div class=" w-100 ">
            <div class="d-flex justify-content-end">
                <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
                    [showFirstLastIcon]="true" [totalRecords]="transactions?.meta.pagination.total_pages" #paginator
                    (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </div>
    </div>
    <div class="row no-gutters px-5 py-3 justify-content-center">
        <ng-container *ngFor="let item of transactions?.meta?.types">
            <div *ngIf="item.type==='hub_payment'" class="col-md-4 pr-4 d-flex">
                <div class="crd br5 d-flex flex-column avenirRegular fw85 p-3 white-back w-100">
                    <span class="greyColor f18">Paiement du Hub Totale:</span>
                    <span class="main-color f35">{{item.total}} {{appconfig.config.langs[0].currency_symbol}}</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngFor="let item of transactions?.meta?.types">
            <div *ngIf="item.type==='order_commission'" class="col-md-4 pr-4 d-flex">
                <div class="crd br5 d-flex flex-column avenirRegular fw85 p-3 white-back w-100">
                    <span class="greyColor f18">Paiement de La commission Totale :</span>
                    <span class="main-color f35">{{item.total}} {{appconfig.config.langs[0].currency_symbol}}</span>
                </div>
            </div>
        </ng-container>
        <div class="col-md-4 pr-4 d-flex">
            <div class="crd br5 d-flex flex-column avenirRegular fw85 p-3 white-back w-100">
                <span class="greyColor f18">Totale :</span>
                <span class="main-color f35">{{transactions?.meta?.total?.toLocaleString()}} {{appconfig.config.langs[0].currency_symbol}}</span>
            </div>
        </div>
    </div>
</div>
<ng-template #notransactions>
    <div class="pt-3">
        <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des transactions appuyez sur "Ajouter une
            transaction" pour en insérer une </p>
    </div>
</ng-template>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [style]="{width: '656px'}" [draggable]="false"
    [resizable]="false">
    <div class="pb-5">
        <span class="main-color f35 fw85 avenirRegular">Ajouter une transaction</span>
    </div>
    <form autocomplete="off" [formGroup]="transaction_form">
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirRegular f20 black" for="name">Hub</label>
            </div>
            <div class="col-md-9">
                <p-dropdown [ngClass]="{'is-invalid': f['hub_id'].errors&&submit}" optionValue="id" [options]="hubs"
                    formControlName="hub_id" optionLabel="name" placeholder="Choisir un hub" [showClear]="false">
                </p-dropdown>
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4">
            <div class="col-md-3">
                <label class="avenirRegular f20 black" for="name">Montant</label>
            </div>
            <div class="col-md-9">
                <input type="number" formControlName="amount" [ngClass]="{'is-invalid': f['amount'].errors&&submit}"
                    class="form-control mdStl avenirRegular f18" id="name" placeholder="Montant">
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-5">
            <div class="col-md-3">
                <label class="avenirRegular f20 black" for="name">Type</label>
            </div>
            <div class="col-md-9">
                <p-dropdown optionValue="value" formControlName="type"
                    [ngClass]="{'is-invalid': f['type'].errors&&submit}" [options]="transactionTypes"
                    optionLabel="label" placeholder="Type de transaction" [showClear]="false"></p-dropdown>
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-5">
            <div class="col-md-3">
                <label class="avenirRegular f20 black" for="name">Commentaire</label>
            </div>
            <div class="col-md-9">
                <textarea class="form-control mdStl avenirRegular f18"
                    [ngClass]="{'is-invalid': f['comment'].errors&&submit}" formControlName="comment"
                    placeholder="Commantaire" id="message" rows="4"></textarea>
            </div>
        </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">
            <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="closeModal()">Annuler
            </button>
            <button class="btn green-back  avenirRegular f16 text-white px-4"
                (click)="addTransaction()">Ajouter</button>
        </div>
    </form>
</p-dialog>
