<p-dialog [showHeader]="false" [(visible)]="displayModal" [modal]="true" [style]="{width: '675px'}" [draggable]="false"
    [resizable]="false">
    <div class="">
        <span class="avenirRegular f35 fw85 main-color">Ajouter une date spécial </span>
        <form [formGroup]="sceduleForm" class="mt-4">
            <div class="row no-gutters align-items-center mb-2">
                <div class="col-md-2">
                    <label class="avenirMedium f20" for="name"><span class="black">Date</span> <span class="red-color">
                            *</span></label>
                </div>
                <div class="col-md-9 ">
                    <p-calendar dateFormat="dd/mm/yy" [showTime]="false" class="pr-3" appendTo="body" hourFormat="24" formControlName="date" [ngClass]="{'is-invalid': submit&& controler['date'].errors}"> </p-calendar>
                </div>
                <div class="col-md-1 d-flex justify-content-center">
                    <button class="btn p-0 btnIcon plus" (click)="addCourseClick()">
                        <img src="../../../../../assets/images/svg/Icon - add.svg" alt="">
                    </button>
                </div>
            </div>
            <div formArrayName="scheduls" *ngFor="let skill of f.controls; let i = index;let isLast=last"
                class="d-flex justiy-content-center w-100">
                <div class="row no-gutters align-items-center w-100 mb-2" [formGroupName]="i">
                    <div class="col-md-2">
                        <label class="avenirMedium f20" for="name"><span class="black">De </span>
                            <span class="red-color">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <p-calendar dateFormat="dd/mm/yy" [showTime]="true" [timeOnly]="true" appendTo="body" formControlName="start_at"(onSelect)="selectHour($event,i,'start_at')" [ngClass]="{'is-invalid': submit&& skill.get('start_at')?.errors}"
                            hourFormat="24">
                        </p-calendar>
                    </div>
                    <div class="col-md-1 d-flex justify-content-center">
                        <label class="avenirMedium f20" for="name">
                            <span class="black">À </span> <span class="red-color">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <p-calendar dateFormat="dd/mm/yy" [showTime]="true" [timeOnly]="true" appendTo="body" formControlName="finish_at"(onSelect)="selectHour($event,i,'finish_at')"[ngClass]="{'is-invalid': submit&& skill.get('finish_at')?.errors}"
                            hourFormat="24">
                        </p-calendar>
                    </div>
                    <div class="col-md-1 d-flex justify-content-center">
                        <button class="btn p-0"(click)="deleteDate(i)">
                            <img src="../../../assets/images/svg/delete..svg"
                            alt="">
                        </button>
                    </div>
                </div>
            </div>
        </form>
       <div *ngIf="opening_hours.length > 0">
        <div class="mt-3 mb-2">
            <span class="avenirRegular f30 fw85 main-color">Les dates spéciales précédentes </span>
        </div>
        <form [formGroup]="old_dates" action="">
            <div formArrayName="dates"
            *ngFor="let skill of g.controls; let i = index;let isLast=last">
            <div [formGroupName]="i">
                <div class="row no-gutters align-items-center mb-2">
                    <div class="col-md-2">
                        <label class="avenirMedium f20" for="name"><span class="black">Date</span> <span class="red-color">
                                *</span></label>
                    </div>
                    <div class="col-md-9 ">
                        <p-calendar dateFormat="dd/mm/yy" [showTime]="false" class="pr-3" appendTo="body" hourFormat="24" formControlName="dates"> </p-calendar>
                    </div>
                    <div class="col-md-1 d-flex justify-content-center">
                        <button class="btn p-0">
                            <img src="../../../assets/images/svg/delete..svg"
                            alt="">
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </form>
       </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">

            <button class="btn avenirRegular f16 text-white dp-grey-back mr-3 px-4" (click)="displayModal=false">Annuler
            </button>
            <button class="btn green-back  avenirRegular f16 text-white px-4"(click)="manageDate()">Modifier</button>
        </div>
    </div>
</p-dialog>
