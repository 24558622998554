import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'app-manage-promo-code',
  templateUrl: './manage-promo-code.component.html',
  styleUrls: ['./manage-promo-code.component.scss']
})
export class ManagePromoCodeComponent implements OnInit {
  promo_types: any = [{ value: "percentage", name: 'Poucentage ' }, { value: "value", name: 'Valeur' }, { value: "free_delivery", name: 'Gratuit' }]
  stores: any
  promoCodeForm!: FormGroup;
  regions!: any[]
  selectedRegions!: any[]
  selectedStores: string[]=[]
  promo_code: any
  edit: boolean = false
  submit: boolean = false
  constructor(
    public appconfig: AppConfigService,
    private location: Location,
    private httpService: HttpService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private router: Router) { }
  async ngOnInit() {
    await this.getRegions()
    this.promoCodeForm = this.fb.group({
      code: new FormControl("", [Validators.required, Validators.maxLength(16), Validators.pattern("^[a-zA-Z0-9]*$")]),
      description: new FormControl("", [Validators.required, Validators.maxLength(64)]),
      active: new FormControl(true,),
      uses: new FormControl("",),
      type: new FormControl("", Validators.required),
      value: new FormControl(0, Validators.required),
      limit_regions: new FormControl("",),
      limit_stores: new FormControl([]),
      min_price: new FormControl(""),
      expires_at: new FormControl(""),
      validity_days: new FormControl(""),
    });
    this.route.params.subscribe((params: any) => {
      if (params.code === "create_promo_code") {
        this.edit = false
        this.route?.params.subscribe((param: any) => {
          if (param.store_id) {
            this.httpService.get('store/' + param.store_id).subscribe(data => {
              if (data?.status == 200) {
                this.promoCodeForm.patchValue({
                  limit_regions: [{ id: data.body.data.region.id, name: data.body.data.region.name }],
                  limit_stores: [data.body.data.id]
                })
                let rr: any = []
                rr.push({ id: data.body.data.region.id, name: data.body.data.region.name })
                this.selectedRegions = rr
                this.getStores()
              }
            })
          }
        })
      } else {
        this.getPromoCode(params.code)
      }
    })
  }
  get f() { return this.promoCodeForm.controls }
  back() {
    this.location.back()
  }
  async getRegions() {
    await this.httpService.get("regions").toPromise().then(data => {
      if (data?.status == 200) {
        let regions: any = []
        data.body.data.forEach((element: any) => {
          regions.push({ name: element.name, id: element.id })
        });
        this.regions = regions;
      }
    })
  }
  async getStores() {
    let stores:any = []
    await this.selectedRegions.forEach(element => {
      this.httpService.get('stores?filters=region:' + element.id).toPromise().then(data => {
        if (data.status == 200) {
          data.body.data.forEach((region: any) => {
            stores.push(region)
          });
        }
      })
      this.stores = stores
    });

  }
   async getPromoCode(code: any) {
   await  this.httpService.get('promo_codes?filters=code:' + code).toPromise().then(data => {
      if (data?.status == 200) {
        this.handleRegion(data.body.data[0])
        this.promo_code = data.body.data[0]
        this.edit = true
        let rr: any = []
        data.body.data[0].limit_regions.forEach((element: any) => {
          rr.push({ name: element.name, id: element.id })
        });
        this.selectedRegions = rr
        this.getStores()
        console.log(this.stores);
        let selectedStores: string[] = []
        data.body.data[0].limit_stores.forEach((element:any) => {
          selectedStores.push(JSON.parse(element))
        });
        this.selectedStores = selectedStores
        console.log(this.selectedStores);

        this.promoCodeForm.patchValue({
          code: data.body.data[0].code,
          description: data.body.data[0].description,
          active: data.body.data[0].active === 1 ? true : false,
          uses: data.body.data[0].uses,
          type: data.body.data[0].type,
          value: data.body.data[0].value,
          limit_regions: data.body.data[0].limit_regions,
          limit_stores: data.body.data[0].limit_stores.length > 0 ?data.body.data[0].limit_stores : "",
          min_price: data.body.data[0].min_price,
          expires_at: data.body.data[0].expires_at?.date ? new Date(data.body.data[0].expires_at?.date) : null,
          validity_days: data.body.data[0].validity_days,
        })
      }
    })
  }
  manage_promo_code() {
    this.submit = true
    if (this.promoCodeForm.invalid) {
      return
    }
    let form = this.promoCodeForm.value
    let regions: any = []
    this.selectedRegions?.forEach((element: any) => {
      regions.push(element.id)
    });
    form.limit_regions = regions
    form.expires_at=== null ? delete form.expires_at:form.expires_at= this.datePipe.transform(form.expires_at, 'yyyy-MM-dd HH:mm:ss');
    if (this.edit) {
      this.httpService.post("promo_code/" + this.promo_code.id, form).subscribe(res => {
        if (res?.status == 200) {
          Object.assign(this.promo_code, res.body.data)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Code promo est modifiée avec succès', });

        }
      })
    } else {
      // form.expires_at.setHours(new Date(form.expires_at).getHours() + 1)
      console.log(form);
      this.httpService.post("promo_code/", form).subscribe(res => {
        if (res?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Code promo est ajouté avec succès', });
          this.router.navigate(['/promo_code'])
        }
      })
    }
  }
  handleRegion(promo: any) {
    const keys = Object.keys(promo.limit_regions);
    const regions: any = []
    keys.forEach(key => {
      const name = promo.limit_regions[key];
      regions.push({ id: JSON.parse(key), name: name });
    })
    promo.limit_stores = Object.keys(promo.limit_stores)
    promo.limit_regions = regions;
    return promo;
  }
}
