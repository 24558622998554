import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { style } from '../../_helpers/map_style';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { ZoneComponent } from './zone/zone.component';
import { environment } from 'src/environments/environment.staging';
import { HomeComponent } from '../home/home.component';
declare var google: any;
@Component({
  selector: 'app-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.scss']
})
export class HubsComponent implements OnInit {
  displayModal: boolean = false;
  options: any;
  infoWindow: any;
  overlays!: any[];
  items!: MenuItem[];
  hubs: any;
  regions: any;
  iconUrl = "../../../assets/images/svg/Group378.svg"
  selectedCities: string[] = [];
  hubForm!: FormGroup;
  cords: any = { lat: 36.9055073, lng: 7.7544866 }
  myMap: any;
  hub: any;
  edit: boolean = false;
  submit: boolean = false;
  @ViewChild('zone') zone!: ZoneComponent;

  constructor(public home: HomeComponent, private httpService: HttpService, private fb: FormBuilder, private messageService: MessageService) { }
  ngOnInit(): void {
    this.inialiseForm()
    this.options = {
      center: this.cords,
      zoom: 14,
      styles: style.style
    };
    this.getHubs()
    this.getRegions()
    this.infoWindow = new google.maps.InfoWindow();
    this.items = [{
      items: [{
        label: 'Liste des SMS globale',
        routerLink: '/fileupload'
      }, {
        label: 'Status des SMS',
      }
      ]
    },];
    this.setUpMap()
  }
  get f() { return this.hubForm.controls }
  getHubs() {
    this.httpService.get('hubs').subscribe(data => {
      if (data?.status == 200) {
        this.hubs = data.body.data
      }
    })
  }
  setUpMap() {
    this.overlays = [
      new google.maps.Marker(
        {
          position: this.cords, icon: this.iconUrl,
          title: "Annaba", draggable: true,
        },
      ),
    ];

  }
  getRegions() {
    this.httpService.get('regions').subscribe(data => {
      if (data?.status == 200) {
        this.regions = data.body.data

      }
    })
  }
  dispalyZone(hub: any) {
    this.hub = hub
    this.zone.displayModal = true
    this.zone.getDeliveryZones(hub)
    this.zone.getStores(hub)
  }
  mapDRagEnd(event: any) {
    this.hubForm.patchValue({
      location: "POINT(" + event.overlay.position.lat() + " " + event.overlay.position.lng() + ")"
    })
  }
  addHub() {
    this.edit = false
    this.inialiseForm()
    this.hubForm.addControl('password', new FormControl('', Validators.required))
    this.hubForm.addControl('username', new FormControl('', Validators.required))
    this.displayModal = true
    this.hubForm.updateValueAndValidity()
  }
  editHub(hub: any) {
    this.displayModal = true
    this.edit = true
    this.hubForm.removeControl('password')
    this.hubForm.removeControl('username')
    this.hubForm.updateValueAndValidity()
    this.hub = hub
    let cordsText: string = hub.location.substring(6, hub.location.length - 1);
    let cords = cordsText.split(" ");
    this.options = {
      center: { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) },
      zoom: 14,
      styles: style.style
    };

    this.overlays = [
      new google.maps.Marker(
        {
          position: { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) },
          title: hub.name, draggable: true, icon: this.iconUrl
        }
      ),
    ];
    this.hubForm.patchValue({
      name: hub.name,
      contact_phone: hub.contact_phone,
      contact_name: hub.contact_name,
      base_price_reduction: hub.base_price_reduction,
      address: hub.address,
      region_id: hub.region.id,
      delivery_commission: hub.delivery_commission,
      delivery: hub.delivery,
      location: hub.location
    })

  }
  manageHub() {
    this.submit = true
    if (this.hubForm.invalid) {
      console.log(this.hubForm.value);

      return;
    }
    if (this.edit) {
      this.httpService.post('hub/' + this.hub.id, this.hubForm.value).subscribe(data => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Hub modifié avec succès' });
          Object.assign(this.hub, data.body.data)
          this.displayModal = false
        }
      })
    }
    else {
      this.httpService.post('hub', this.hubForm.value).subscribe(data => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Hub ajouté avec succès' });
          this.hubs.push(data.body.data)
          this.displayModal = false
          this.submit = false
        }
      })
    }
  }
  changeAvailability(hub: any) {
    this.httpService.post('hub/' + hub.id, { active: hub.active }).subscribe(data => {
      if (data?.status == 200) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Hub modifié avec succès' });
        Object.assign(hub, data.body.data)
      }
    })
  }
  inialiseForm() {
    this.hubForm = this.fb.group({
      name: new FormControl("", Validators.required),
      username: new FormControl("", [Validators.required, Validators.pattern("^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._-]+(?<![_.])$")]),
      password: new FormControl("", [Validators.required, Validators.minLength(8)]),
      address: new FormControl("", Validators.required),
      contact_name: new FormControl("", Validators.required),
      base_price_reduction: new FormControl("", [Validators.required, Validators.pattern("^[0-9]*$")]),
      location: new FormControl("POINT(36.9055073 7.7544866)", Validators.required),
      contact_phone: new FormControl("", [Validators.required, Validators.pattern("^(0)(5|6|7)(4|5|6|7|8|9)[0-9]{7}$")]),
      region_id: new FormControl("", Validators.required),
      delivery: new FormControl(false, Validators.required),
      delivery_commission: new FormControl(false, Validators.required),
    });
  }
  setCenter(map: any) {
    let cordsText: string = this.hub?.location.substring(6, this.hub.location.length - 1)
    let cords = cordsText?.split(" ") || ["36.9055073", "7.7544866"]
    map.setCenter(new google.maps.LatLng(parseFloat(cords[0]), parseFloat(cords[1])));
  }
  go_to_hub(hub: any) {
    window.open(environment.hub_url + hub.id + "/dashboard")
    // this.router.navigate(["https://hub-staging.foodbeeper.com/#/app;hub="+hub.id+";screen="+hub.id+"/dashboard"])
  }
}
