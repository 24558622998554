import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { options } from '../../_helpers/chart_options';
@Component({
  selector: 'app-global-analytics',
  templateUrl: './global-analytics.component.html',
  styleUrls: ['./global-analytics.component.scss']
})
export class GlobalAnalyticsComponent implements OnInit {
  regions: any = []
  stackedData: any;
  stackedOptions: any;
  analytics: any = []
  filters: any = [{ "title": "Nouveaux clients", "value": "new_clients" }, { "title": "Nouveaux stores", "value": "new_stores" }, { "title": "Nouveaux livreurs", "value": "new_staff" }, { "title": "Nombre total des commandes", "value": "total_orders" }, { "title": "Total commissions", "value": "total_commissions" }, { "title": "Clients uniques", "value": "unique_clients" }, { "title": "Retard moyen de livraison", "value": "avg_delay" }, { "title": "Pourcentage de commandes livrés en retard", "value": "delay_percent" }, { "title": "Stores actifs", "value": "active_stores" }, { "title": "Livreurs actifs", "value": "active_staff" }]
  region: any
  type: any
  isLoading: boolean = false
  constructor(private httpService: HttpService) { }
  ngOnInit(): void {
    this.getGLobalAnalytics("stats/global_stats")
    this.getRegions()
  }
  getRegions() {
    this.httpService.get("regions/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  filterByRegion() {
    this.type = null
    this.getGLobalAnalytics("stats/global_stats?region=" + this.region)
  }
  filterByType() {
    this.region = null
    this.getGLobalAnalytics("stats/global_stats?type=" + this.type)
  }
 
  getGLobalAnalytics(url: any) {
    this.httpService.get(url).subscribe((res: any) => {
      if (res?.status === 200) {
        this.isLoading=true
        this.analytics.length = 0
        var json_data: any = res.body.data;
        for (var i in json_data) {
          this.analytics.push({ "data": json_data[i] })
        }
        this.analytics.forEach((element: any) => {
          let data: any = []
          let date: any = []
          element.data.stats.forEach((elme: any) => {
            let dte = new Date(elme.date)
            let day = dte.getMonth() + 1
            elme.dates = dte.getDate() + "/" + day
            date.push(elme.dates)
            data.push(elme[Object.keys(elme)[0]])
          });
          this.stackedOptions = options.options
          const borderRadius = 8;
          const borderRadiusAllCorners = { bottomLeft: borderRadius, bottomRight: borderRadius, topRight: borderRadius, topLeft: borderRadius }
          element.chart = {
            labels: date.reverse(),
            datasets: [{
              type: 'bar',
              label: element.data.title,
              borderRadius: borderRadiusAllCorners,
              backgroundColor: '#34B53A',
              borderSkipped: false,
              data: data.reverse()
            }],
          };
        });
      }
    })
  }
}
