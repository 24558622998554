<div *ngIf="isLoading;else notYetLoaded">
  <div class="px-4 pt-6 pb-5">
    <span class="avenirRegular f72 main-color font-weight-bold">Commandes</span>
  </div>
  <form [formGroup]="orderForm">
    <div class="row no-gutters px-4 align-items-center">
      <div class="col-md-2 pr-3">
        <p-dropdown [options]="regions" optionValue="id" optionLabel="name" placeholder="Région" [showClear]="true"
          formControlName="region"></p-dropdown>
      </div>
      <div class="col-md-2 pr-2">
        <p-dropdown [options]="status" optionValue="value" optionLabel="name" formControlName="status"
          placeholder="Statut" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-md-2 pr-2">
        <p-dropdown [options]="cancelation_reasons" formControlName="cancel_reason"
          placeholder="Raisons d'annulation" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-md-2 pr-2">
        <input type="text" class="form-control mdStl greyBorder avenirMedium f18" formControlName="order_number"
          placeholder="N° Commande">
      </div>
      <div class="col-md-1 pr-2">
        <button class="w-100 text-white btn avenirRegular f16 green-back"
          (click)="filter()">Filtrer</button>
      </div>
      <div class="col-md-1 pr-2">
        <button class="w-100 text-white btn avenirRegular f16 main-color white-back"
          (click)="reset()">Réintianiliser</button>
      </div>
      <div class=" pr-2">
        <button class="btn green-back h-100 " (click)="collapse()" id="filter">
          <img src="../../../assets/images/svg/Vector 10.svg" alt="">
          <span class="ml-2 f18 text-white avenirRegular">Autre filter</span>
        </button>
      </div>
      <button [routerLink]="['orders_stats']" [queryParams]="{stats_duration:'last_week'}" class="avenirRegular f18 main-color ml-auto stats_btn">
        <i class="pi pi-chart-line"></i>
      </button>

    </div>
    <div class="green-back text-white" *ngIf="displayFilter">
      <div class="row no-gutters align-items-center p-3">
        <div class="col-md-2 pr-2">
          <div class="filter">
            <p-autoComplete class="greyBorder text-white" placeholder="N° téléphone" formControlName="client"
              field="phone" [suggestions]="clients" (completeMethod)="searchClients($event)"></p-autoComplete>
          </div>
        </div>
        <div class="col-md-2 pr-2">
          <div class="filter">
            <p-autoComplete placeholder="Saisir le nom d'établissments" formControlName="store" field="name"
            [suggestions]="stores" (completeMethod)="searchStores($event)"></p-autoComplete>
          </div>
        </div>
        <div class="col-md-2 pr-2">
          <p-calendar inputId="basic" dateFormat="dd/mm/yy" placeholder="JJ/MM/AA" appendTo="body"
            class="avenirMedium whiteBorder" formControlName="gte">
          </p-calendar>
        </div>
        <div class="col-md-2 pr-2">
          <p-calendar inputId="basic" dateFormat="dd/mm/yy" placeholder="JJ/MM/AA" appendTo="body"
            class="avenirMedium whiteBorder" formControlName="lte">
          </p-calendar>
        </div>
        <div class="col-md-2 pr-1">
          <button class="w-100 text-white btn avenirRegular f16 exportBtn" (click)="exportExcel(1)">Exporter
            XLS</button>
        </div>

      </div>
    </div>
  </form>
  <div class="table-responsive mt-5">
    <table *ngIf="orders?.data?.length>0;else noOrders" class="table table-borderless">
      <thead>
        <tr>
          <th>Commande</th>
          <th>Etablissement</th>
          <th>Client</th>
          <th>Mobile</th>
          <th>Status de commande</th>
          <th>Total</th>
          <th>Livreur</th>
          <th>Rating</th>
          <th>Status de livraison</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders.data">
          <td class="avenirRegular f16 main-color clickable">
            <u (click)="showSidbar(order)">{{order.order_number}}</u>
          </td>
          <td>
            <a class="avenirRegular f16 main-color " [routerLink]="['/stores']"[queryParams]="{ id : order?.store_id }" target="_blank">{{order.store?.name}}</a>
          </td>
          <td class="avenirRegular f16 black">{{order.delivery?.client?.name}}</td>
          <td class="avenirRegular f16 black">{{order.delivery?.client?.phone}}</td>
          <td class="avenirRegular f18">
            <div class="status"
              [ngClass]="{'yellow_back': order.status==='in-delivery','light_green_back': order.status==='completed','light_red_back': order.status==='rejected'||order.status==='canceled','light_blue_back': order.status==='ready','green_back':order.status==='accepted'}">
              <span
                [ngClass]="{'yellow': order.status==='in-delivery','main-color': order.status==='completed'||order.status==='accepted','red-color': order.status==='rejected'||order.status==='canceled','blue': order.status==='ready'}">{{order.status}}</span>
            </div>
          </td>
          <td class="avenirRegular f18">
            <span class="main-color fw85">{{order.price}} </span>
            <span class="deepGrey">{{appconfig.config.langs[0].currency_symbol}} </span>
          </td>
          <td class="avenirRegular f18 black">{{order.delivery?.staff?.name}}</td>
          <td class="avenirRegular f16 main-color">
            <u>{{order?.rating?order?.rating+'/5':""}}</u>
          </td>
          <td></td>
          <td class="avenirRegular f18">
            <div class="d-flex flex-column">
              <span class="black">{{order.created.date | date:'dd/MM/yyyy'}}</span>
              <span class="deepGrey">{{order.created.date | date:'HH:mm '}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class=" w-100 ">
    <div class="d-flex justify-content-end">
      <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
        [showFirstLastIcon]="true" [totalRecords]="orders.meta.pagination.total_pages" #paginator
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<ng-template #noOrders>
  <div class="pt-3">
    <p class="text-center black  avenirBlack f18">Vous n'avez pas encore des commandes </p>
  </div>
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100 ">
    <app-loading></app-loading>
  </div>
</ng-template>
<app-order-details #order_details [order]="order"></app-order-details>
