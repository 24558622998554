<div class="h-100" *ngIf="showSplash" class="h-100 w-100 d-flex justify-content-center align-items-center" >
  <div class="lottie-animation mt-10">
    <ng-lottie
      [options]="options" 
      (animationCreated)="onAnimate($event)">
    </ng-lottie>
  </div>
</div>
<!-- <div *ngIf="showSplash" class="app-splash-screen" [ngStyle]="{'opacity': opacityChange, 'transition': splashTransition}">
  <div class="app-splash-inner">
    <div data-text="Loading ..." class="loading-text">Loading ...</div>
  </div>
</div> -->