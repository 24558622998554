<div *ngIf="is_loaded;else notYetLoaded">
  <div class="px-4 py-3">
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="searchForm">
      <div class="d-flex align-items-center my-4 justify-content-between">
        <div class="d-flex align-items-center">
          <p-dropdown class="mr-3" formControlName="store" [options]="stores" placeholder="Établissments"
            [filter]="true" filterBy="label" [showClear]="false"></p-dropdown>
          <p-dropdown class="mr-3" [options]="types_array" formControlName="type" placeholder="Type"
            [showClear]="false">
          </p-dropdown>
          <p-dropdown class="mr-3" [options]="status" formControlName="backoffice" placeholder="Statut"
            [showClear]="false">
          </p-dropdown>
        </div>
        <div class="d-flex">
          <button class="text-white btn avenirRegular f18 green-back w-100 filterBtn mr-3"
            (click)="filter()">Filtrer</button>
          <button class="w-100 text-white btn avenirRegular f16 main-color white-back filterBtn"
            (click)="reset()">Réintianiliser</button>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless mt-4">
      <thead>
        <tr>
          <th>Région</th>
          <th>Établissments</th>
          <th>Type</th>
          <th class="reason">Raison</th>
          <th>Commande</th>
          <th>Date de création</th>
          <th>Opérations</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let client_ticket of client_tickets?.data">
          <td class="avenirRegular f18 black">
            {{client_ticket?.region?.name}}
          </td>
          <td class="avenirRegular f18 ">
            <u class="main-color clickable"  [routerLink]="['/stores']"[queryParams]="{ id : client_ticket?.store?.id }" target="_blank">{{client_ticket?.store?.name}}</u>
          </td>
          <td class="avenirRegular f18 text-white">
            <div [ngStyle]="{'background-color': client_ticket?.type.color}" class="br20">
              {{client_ticket?.type.name}}
            </div>
          </td>
          <td class="avenirRegular f18 black">
            {{client_ticket?.reason_text}}
          </td>
          <td class="avenirRegular f18">
            <u class="main-color clickable" (click)="showSidbar(client_ticket)">{{client_ticket?.order?.order_number}}</u>
          </td>
          <td class="avenirRegular f18 black">
            {{client_ticket?.created_at?.date | date:"YYYY-MM-dd HH:mm"}}
          </td>
          <td class="avenirRegular f18 black">
            <button *ngIf="client_ticket?.notify_backoffice===1" class="btn p-0 mr-2" (click)="edit_client_ticket(client_ticket)">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class=" w-100 ">
    <div class="d-flex justify-content-end">
      <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
        [showFirstLastIcon]="true" [totalRecords]="client_tickets?.meta?.pagination?.total_pages" #paginator
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<p-sidebar #sidebar [(visible)]="display" [appendTo]="sidebar" position="right" [style]="{width:'575px'}"
  [showCloseIcon]=false [baseZIndex]="10000" styleClass="disable-scroll">
  <div class="d-flex align-items-center p-3">
    <button class="btn p-0" (click)="display=false">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">Détails de la requête</span>
  </div>
  <div class="p-3">
    <div class="mb-3">
      <span class=" main-color f25 fw85 avenirArabic">Suivi de la requête </span>
    </div>
    <div class="deepGrey f20 avenirArabic mb-3">
      <div class="d-flex justify-content-between pt-4">
        <span>Crée le:{{client_ticket?.created_at?.date | date:"YYYY-MM-dd HH:mm"}} </span>
      </div>
      <div class="pt-4">
        <span class="f20 avenirArabic">Description: </span>
        <div *ngIf="client_ticket?.description" [innerHTML]="client_ticket?.description" class="pb-2 custom_html px-3">
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Établissment:
          <a [routerLink]="['/stores/details/'+client_ticket?.store?.id+'/profile']"
            class="main-color"><u>{{client_ticket?.store?.name}}</u>
          </a>
        </div>
        <button class="btn p-0" [routerLink]="['/stores/details/'+client_ticket?.store?.id+'/profile']">
          <img src="{{client_ticket?.store?.logo}}" alt="" class="img-store">
        </button>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Client:
          <span class="main-color">{{client_ticket?.client?.name}}</span>
        </div>
        <span class="black f20 avenirArabic">{{client_ticket?.client?.comment}} </span>
      </div>
      <div class="pt-4">
        <div class="card flex justify-content-center">
          <p-accordion class="w-full" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                  <span class="main-color f20  avenirArabic">
                    Suivi des actions
                  </span>
                </span>
              </ng-template>
              <div class="f18 avenirArabic">
                <div class="py-2 ">
                  <p-timeline [value]="client_ticket?.actions">
                    <ng-template pTemplate="content" let-event>
                      <small class="avenirArabic f16 text-capitalize">{{ event.created_at }}</small>
                      <div *ngIf="event?.comment" [innerHTML]="event?.comment" class="avenirArabic f16 black my-3">
                      </div>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-event>
                      <div class="d-flex flex-column">
                        <span [ngStyle]="{color: event?.color}" class="avenirArabic f16 text-capitalize"> {{
                          event.action
                          }}</span>
                        <span class="avenirArabic f16 text-capitalize black"> {{ event?.created_by?.name
                          }}</span>
                      </div>
                    </ng-template>
                  </p-timeline>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Numéro de commande:
          <span class="avenirRegular f16 main-color clickable"> <u
              (click)="showSidbar(client_ticket)">{{client_ticket?.order?.order_number}}</u></span>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Type de la requête
          <span class="main-color">{{client_ticket?.type?.name}}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Raison:
          <span class="main-color">{{client_ticket?.type?.reasons?.name?.text}}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4 align-items-center">
        <div>Remise suggérée :
          <span class="main-color">{{client_ticket?.type.reasons.suggested_discount}}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4">
        <span>Région: {{client_ticket?.region?.name}}</span>
      </div>
      <form [formGroup]="client_ticket_form">
        <div>
          <div class="mt-4">
            <span class=" main-color f25 fw85 avenirArabic">Mise à jour de la requête </span>
          </div>
          <div class="form-row my-3">
            <div class="form-group col-12"[ngClass]="{'col-md-6': client_ticket_form.get('discount')?.value==='value'}">
              <label class="avenirMedium f20" for="password"><span class="deepGrey">Remise</span> </label>
              <p-dropdown optionValue="value" formControlName="discount" [options]="discounts" optionLabel="label"
                placeholder="Type de remise" [showClear]="false"
                [ngClass]="{'is-invalid': submit &&f['discount'].errors}">
              </p-dropdown>
            </div>
            <div class="form-group col-12" [ngClass]="{'col-md-6': client_ticket_form.get('discount')?.value==='value'}" *ngIf="client_ticket_form.get('discount')?.value==='value'">
              <label class="avenirMedium f20" for="password"><span class="deepGrey">Valuer de la remise</span></label>
              <input type="number" class="form-control mdStl avenirMedium f18" formControlName="discount_value"
                [ngClass]="{'is-invalid':submit&& f['discount_value'].errors}" id="name"
                placeholder="Valeur de la remise ">
            </div>
          </div>
          <div class="form-group">
            <label class="avenirMedium f20" for="update"><span class="deepGrey">Mise à jour</span></label>
            <textarea [ngClass]="{'is-invalid': submit &&f['update'].errors}"
              class="form-control mdStl deepGrey avenirArabic f18" formControlName="update"
              placeholder="Description de la mise à jour" id="message" rows="5"></textarea>
          </div>
          <div class=" d-flex justify-content-between  align-items-center px-3 py-2 bnr">
            <span class="avenirMedium f18 ursopct black"> Ouverte</span>
            <p-inputSwitch formControlName="backoffice_close"
              [disabled]="client_ticket?.notify_backoffice===0?true:false"></p-inputSwitch>
          </div>
        </div>
        <div class="d-flex justify-content-end  align-items-center mt-4">
          <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
            (click)="display=false">Annuler</button>
          <button class="btn green-back px-5 avenirMedium f18 text-white"
            (click)="manage_client_ticket()">Enregistrer</button>
        </div>
      </form>
    </div>
  </div>
</p-sidebar>
<app-order-details #order_details [order]="client_ticket?.order"></app-order-details>
