import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { style } from '../../../../_helpers/map_style';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HomeComponent } from 'src/app/components/home/home.component';
declare var google: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  store: any
  types: any
  slectedTypes: any
  services: any
  categories: any = [{ name: "Healthy", label: "healthy" }, { name: "Food", label: "food" }, { name: "Fresh", label: "fresh" }, { name: "Express", label: "express" }]
  specialities: any
  regions: any
  options: any;
  menus: any;
  overlays!: any[];
  cords: any = { lat: 36.9055073, lng: 7.7544866 }
  iconUrl = "../../../assets/images/svg/Group1378.svg"
  storeForm!: FormGroup;
  selectedSpecilities: any[] = []
  selectedTypes: any[] = []
  selectedServices: any[] = []
  bannerUrl: any;
  logoUrl!: any[];
  selectedBanner!: any;
  selectedlogo!: File;
  submit: boolean = false
  loading: boolean = false;
  constructor(public home: HomeComponent, private app_config: AppConfigService, private router: Router, private route: ActivatedRoute, private httpService: HttpService, private fb: FormBuilder, private messageService: MessageService) { }
  ngOnInit(): void {
    this.storeForm = this.fb.group({
      name: new FormControl("", Validators.required),
      abbreviation: new FormControl("", [Validators.required, Validators.maxLength(4), Validators.pattern("^[A-Za-z]+$")]),
      category: new FormControl("", Validators.required),
      tag_line: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      phone: new FormControl("", [Validators.required]),
      type_id: new FormControl("", Validators.required),
      region_id: new FormControl("", Validators.required),
      location: new FormControl("", Validators.required),
      visible: new FormControl(false),
      exclusive: new FormControl(false),
      hidden: new FormControl(false),
      delivery: new FormControl(false),
      pick_up: new FormControl(false),
      nocollect: new FormControl(false),
      can_create_orders: new FormControl(false),
      menu_display: new FormControl(false),
      closed: new FormControl(false),
      is_default_order_rate: new FormControl(false),
      closed_until: new FormControl("2000-01-01", this.ConditionallyRequiredValidator),
      free_delivery: new FormControl(false),
      free_delivery_until: new FormControl("2000-01-01", this.ConditionallyRequiredValidator),
      free_delivery_from: new FormControl(0),
      accept_delivery: new FormControl(false),
      accept_delivery_at: new FormControl("2000-01-01", this.ConditionallyRequiredValidator),
      global_menu: new FormControl("",),
      specialities: new FormControl([],),
      types: new FormControl([],),
      services: new FormControl([],),
      global_store: new FormControl(false),
      default_order_rate: new FormControl(false),
      order_rate: new FormControl("",),
      default_store_order_rate: new FormControl(false),
      store_order_rate: new FormControl("",),
      default_service_fee: new FormControl(false),
      service_fee: new FormControl("",),
    }, {
      validators: [this.ConditionallyRequiredValidator]
    });
    this.storeForm.get('free_delivery')?.valueChanges.subscribe(value => {
      this.storeForm.get('free_delivery_until')?.updateValueAndValidity();
    });
    this.storeForm.get('accept_delivery')?.valueChanges.subscribe(value => {
      this.storeForm.get('accept_delivery_at')?.updateValueAndValidity();
    });
    this.storeForm.get('closed')?.valueChanges.subscribe(value => {
      this.storeForm.get('closed_until')?.updateValueAndValidity();
    });
    this.route.parent?.params.subscribe((params: any) => {
      if (params.id !== '0') {
        this.getstore(params.id)
      } else {
        this.options = {
          center: this.cords,
          zoom: 12,
          styles: style.style
        };
        this.overlays = [
          new google.maps.Marker(
            {
              position: this.cords, icon: this.iconUrl,
              title: this.store?.name, draggable: true,
            },
          ),
        ];
        this.loading = true
      }
    })
    this.getStoreTypes()
    this.getStoreSErvices()
    this.getStoreSpecilities()
    this.getMenuTemplates()
    this.getRegions()
  }
  patch_values() {
    this.storeForm.patchValue({
      name: this.store.name,
      abbreviation: this.store.abbreviation,
      category: this.store.category,
      tag_line: this.store.tag_line,
      description: this.store.description,
      address: this.store.address,
      phone: this.store.phone.lastIndexOf(this.app_config.config.phone_int_prefix, 0) === 0 ? this.store.phone.replace(this.app_config.config.phone_int_prefix, this.app_config.config.phone_local_prefix) : this.store.phone,
      type_id: this.store.type.id,
      region_id: this.store.region.id,
      location: this.store.location,
      visible: this.store.visible,
      exclusive: this.store.exclusive,
      hidden: this.store.hidden,
      delivery: this.store.delivery,
      closed: this.store.closed_until?.date ? true : false,
      closed_until: this.store.closed_until?.date ? new Date(this.store.closed_until.date) : null,
      free_delivery: this.store.free_delivery,
      free_delivery_from: this.store.free_delivery_from,
      accept_delivery: !this.store.accept_delivery,
      accept_delivery_at: this.store.accept_delivery_at?.date ? new Date(this.store.accept_delivery_at.date) : null,
      free_delivery_until: this.store.free_delivery_until?.date ? new Date(this.store.free_delivery_until.date) : null,
      pick_up: this.store.pick_up,
      can_create_orders: this.store.can_create_orders === 1 ? true : false,
      nocollect: this.store.nocollect === 1 ? true : false,
      menu_display: this.store.menu_display === 1 ? true : false,
      default_order_rate: this.store.is_default_order_rate === 1 ? true : false,
      order_rate: (this.store.order_rate * 100),
      default_store_order_rate: this.store.is_default_store_order_rate === 1 ? true : false,
      store_order_rate: (this.store.store_order_rate * 100),
      default_service_fee: this.store.is_default_service_fee === 1 ? true : false,
      service_fee: this.store.service_fee,
      global_menu: this.store.global_menu,
      specialities: this.store.specialities,
      types: this.store.types,
      services: this.store.services,
      global_store: this.store.global_store === 1 ? true : false,
    })
  }
  get f() { return this.storeForm.controls }
  getstore(storeId: any) {
    this.httpService.get("store/" + storeId).subscribe((data: any) => {
      if (data?.status === 200) {
        this.store = data.body.data
        this.patch_values()
        let cordsText: string = this.store.location.substring(6, this.store.location.length - 1);
        let cords = cordsText.split(" ");
        this.cords = { lat: parseFloat(cords[0]), lng: parseFloat(cords[1]) }
        this.options = {
          center: this.cords,
          zoom: 12,
          styles: style.style
        };
        this.overlays = [
          new google.maps.Marker(
            {
              position: this.cords, icon: this.iconUrl,
              title: this.store.name, draggable: true,
            },
          ),
        ];
        this.store.hub_delivery_zones.forEach((element: any) => {
          this.setUpMap(element)
        });
        this.loading = true;
      }
      this.bannerUrl = this.store.banner.url
      this.logoUrl = this.store.logo.url
      this.selectedServices = []
      this.selectedSpecilities = []
      this.selectedTypes = []
      this.store.specialities.forEach((element: any) => {
        this.selectedSpecilities.push(element.id)
      });
      this.store.store_types.forEach((element: any) => {
        this.selectedTypes.push(element.id)
      });
      this.store.services.forEach((element: any) => {
        this.selectedServices.push(element.id)
      });
    })
  }
  getStoreTypes() {
    this.httpService.get("store_types/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.types = data.body.data
      }
    })
  }
  select_category() {
    // display only the types with the selected category
    this.slectedTypes = this.types.filter((element: any) => element.category === this.storeForm.value.category)
    console.log(this.slectedTypes);
  }
  getStoreSpecilities() {
    this.httpService.get("specialities/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.specialities = data.body.data
      }
    })
  }
  getRegions() {
    this.httpService.get("regions/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  getStoreSErvices() {
    this.httpService.get("services/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.services = data.body.data
      }
    })
  }
  getMenuTemplates() {
    this.httpService.get("stores?filters=global_store:1&all=1/").subscribe((data: any) => {
      if (data?.status === 200) {
        this.menus = data.body.data
      }
    })
  }
  showPreviewImage(event: any, type: string) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      switch (type) {
        case "banner":
          reader.onload = (event: any) => {
            this.bannerUrl = event.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
          this.selectedBanner = <File>event.target.files[0];
          break;
        case "logo":
          reader.onload = (event: any) => {
            this.logoUrl = event.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
          this.selectedlogo = <File>event.target.files[0];
          break;
      }
    }
  }
  manageStore(storeForm: any) {
    this.submit = true
    this.storeForm.patchValue({
      free_delivery_until: this.storeForm.value.free_delivery_until ? this.storeForm.value.free_delivery_until : "2000-01-01",
      accept_delivery_at: this.storeForm.value.accept_delivery_at ? this.storeForm.value.accept_delivery_at : "2000-01-01",
      closed_until: this.storeForm.value.closed_until ? this.storeForm.value.closed_until : "2000-01-01",
    })
    if (this.storeForm.invalid) {
      const invalid = [];
      const controls = this.storeForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      return
    }
    const fd = new FormData();
    if (this.selectedBanner) {
      fd.append('banner', this.selectedBanner);
    }
    if (this.selectedlogo) {
      fd.append('logo', this.selectedlogo);
    }
    let date = new Date(this.storeForm.value.free_delivery_until)
    let accept_delivery_at = new Date(this.storeForm.value.accept_delivery_at)
    let closed_until = new Date(this.storeForm.value.closed_until)
    fd.append('name', storeForm.name);
    fd.append('abbreviation', storeForm.abbreviation);
    fd.append('category', storeForm.category);
    fd.append('tag_line', storeForm.tag_line);
    fd.append('description', storeForm.description);
    fd.append('address', storeForm.address);
    if (this.storeForm.value.phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
      storeForm.phone = this.storeForm.value.phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)
    } else {
      this.storeForm.value.phone = this.app_config.config.phone_int_prefix + this.storeForm.value.phone
    }
    fd.append('phone', storeForm.phone);
    fd.append('type_id', storeForm.type_id);
    fd.append('region_id', storeForm.region_id);
    storeForm.global_menu ? fd.append('global_menu', storeForm.global_menu) : fd.append('global_menu', "0");
    fd.append('location', storeForm.location);
    fd.append('visible', storeForm.visible === true ? "1" : "0");
    fd.append('exclusive', storeForm.exclusive === true ? "1" : "0");
    fd.append('hidden', storeForm.hidden === true ? "1" : "0");
    fd.append('delivery', storeForm.delivery === true ? "1" : "0");
    fd.append('free_delivery', storeForm.free_delivery === true ? "1" : "0");
    fd.append('free_delivery_from', storeForm.free_delivery_from);
    fd.append('closed', storeForm.closed === true ? "0" : "1");
    fd.append('accept_delivery', storeForm.accept_delivery === true ? "0" : "1");
    storeForm.free_delivery === true ? fd.append('free_delivery_until', date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()) : fd.append('free_delivery_until', "2000-01-01")
    storeForm.closed === true ? fd.append('closed_until', closed_until.getFullYear() + "-" + (closed_until.getMonth() + 1) + "-" + closed_until.getDate()) : fd.append('closed_until', "2000-01-01")
    storeForm.accept_delivery === true ? fd.append('accept_delivery_at', accept_delivery_at.getFullYear() + "-" + (accept_delivery_at.getMonth() + 1) + "-" + accept_delivery_at.getDate()) : fd.append('accept_delivery_at', "2000-01-01")
    fd.append('pick_up', storeForm.pick_up === true ? "1" : "0");
    fd.append('nocollect', storeForm.nocollect === true ? "1" : "0");
    fd.append('can_create_orders', storeForm.can_create_orders === true ? "1" : "0");
    fd.append('menu_display', storeForm.menu_display === true ? "1" : "0");
    fd.append('global_store', storeForm.global_store === true ? "1" : "0");
    fd.append('default_order_rate', storeForm.default_order_rate === true ? "1" : "0");
    fd.append('order_rate', JSON.stringify(storeForm.order_rate / 100));
    fd.append('default_store_order_rate', storeForm.default_store_order_rate === true ? "1" : "0");
    fd.append('store_order_rate', JSON.stringify(storeForm.store_order_rate / 100));
    fd.append('default_service_fee', storeForm.default_service_fee === true ? "1" : "0");
    fd.append('service_fee', storeForm.service_fee);
    storeForm.specialities.forEach((element: any) => {
      fd.append("specialities[]", element);
    });
    storeForm.types.forEach((element: any) => {
      fd.append("types[]", element);
    });
    storeForm.services.forEach((element: any) => {
      fd.append("services[]", element);
    });
    if (this.store) {
      this.httpService.post('store/' + this.store.id, fd).subscribe((data: any) => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Établissement a été modifié avec succès' });
          Object.assign(this.store, data.body.data)
          this.storeForm.patchValue({
            order_rate: (this.store.order_rate * 100),
            service_fee: this.store.service_fee,
            store_order_rate: (this.store.store_order_rate * 100),
          })
        }
      })
    } else {
      this.httpService.post('store', fd).subscribe((data: any) => {
        if (data?.status === 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Établissement a été créé avec succès' });
          this.router.navigate(['/stores/details/' + data.body.data.id])
        }
      })
    }
  }
  add_marker(event: any) {
    this.overlays = [
      new google.maps.Marker(
        {
          position: { lat: event.latLng.lat(), lng: event.latLng.lng() }, icon: this.iconUrl,
          title: this.store?.name, draggable: true,
        },
      ),
    ];
    this.storeForm.patchValue({
      location: "POINT(" + event.latLng.lat() + " " + event.latLng.lng() + ")"
    })
  }
  mapDRagEnd(event: any) {
    this.storeForm.patchValue({
      location: "POINT(" + event.overlay.position.lat() + " " + event.overlay.position.lng() + ")"
    })
  }
  ConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('free_delivery').value === true) {
      return Validators.required(formControl);
    }
    if (formControl.parent.get('accept_delivery').value === true) {
      return Validators.required(formControl);
    }
    if (formControl.parent.get('closed').value === true) {
      return Validators.required(formControl);
    }
    return null;
  }
  setUpMap(element: any,) {
    element.color = this.generateColor(element.name);
    let cordsPolygon = element.delivery_zone.substring(9, element.delivery_zone.length - 2)
    let cordPolygon = cordsPolygon.split(",");
    let array: any[] = [];
    cordPolygon.forEach((element: any) => {
      let crd = element.split(" ")
      array.push({ lat: parseFloat(crd[0]), lng: parseFloat(crd[1]) })
    });
    let polygon = new google.maps.Polygon({
      paths: array, strokeOpacity: 0.5, strokeWeight: 0, fillColor: element.color, fillOpacity: 0.5, editable: false,
      draggable: false,

    })
    this.overlays.push(polygon)
  }
  handlePolygonChange(shape: any) {
    var len = shape.getLength();
    var points
    var paths = new Array
    for (var i = 0; i < len; i++) {
      points = shape.getAt(i).toUrlValue(5).split(',')
      paths.push({
        lat: Number(points[0]),
        lng: Number(points[1])
      })
    }
    var result = [];
    for (var i = 0; i < paths.length; i += 1) {
      result.push(paths[i].lat + ' ' + paths[i].lng,);
    }
    if (paths[0].lat !== paths[length].lat || paths[0].lng !== paths[length].lng) {
      result.push(paths[0].lat + ' ' + paths[0].lng);
    }
  }

  changeMarker(event: any, map: any) {
    let region = this.regions.find((element: any) => element.id === event.value)
    let cordsText: string = region?.location.substring(6, region.location.length - 1)
    let cords = cordsText?.split(" ")
    this.overlays = [
      new google.maps.Marker(
        {
          position: { lat: JSON.parse(cords[0]), lng: JSON.parse(cords[1]) }, icon: this.iconUrl,
          title: this.store?.name, draggable: true,
        },
      ),
    ];
    map.setCenter(new google.maps.LatLng(parseFloat(cords[0]), parseFloat(cords[1])));
    this.storeForm.patchValue({
      location: "POINT(" + JSON.parse(cords[0]) + " " + JSON.parse(cords[1]) + ")"
    })
  }
  generateColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i);
    }
    hash = Math.pow((hash % 799 + 97) * 13, 3);
    const r = hash % 90 + 110;
    hash = Math.floor(hash / 100);
    const g = hash % 90 + 110;
    hash = Math.floor(hash / 100);
    const b = hash % 90 + 110;
    return "rgb(" + r + ',' + g + ',' + b + ')';
  }
}
