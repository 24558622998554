import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.staging';
import { PrimeNGConfig } from 'primeng/api';
const endpoint = environment

@Injectable()
export class AppConfigService {
   appConfig: any;
   appConfigLangs: any[] = [];

  constructor(private injector: Injector,private confg:PrimeNGConfig) { }

  loadAppConfig() {
    this.confg.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      dayNamesShort	: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNames:['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembere','Décembre'],
      monthNamesShort:[ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  });
    let http = this.injector.get(HttpClient);
    if (localStorage.getItem('country_code')) {
      return http.get(endpoint.region_selector + '&c=' + localStorage.getItem('country_code'), { observe: "response" })
        .toPromise()
        .then((data: any) => {
          if (data.status === 200) {
            this.appConfig = data.body;
            this.appConfigLangs = data.body.langs

          }
        })
    } else {
      return http.get(endpoint.region_selector, { observe: "response" })
        .toPromise()
        .then((data: any) => {
          if (data.status === 200) {
            localStorage.setItem("country_code", data.body.country_code)
            this.appConfig = data.body;
          }
        })
    }

  }

  get config() {
    this.appConfig.langs = this.appConfig.langs.filter((lang: any) => lang.code=== "fr")

    return this.appConfig;
  }

  get configLangs() {
    this.appConfigLangs = this.appConfigLangs.filter((lang: any) => lang.code !== "fr")
    return this.appConfigLangs;
  }
}
