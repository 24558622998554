import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from "@angular/forms";
declare var google: any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  options: any;

  overlays!: any[];
  constructor(private _formBuilder: FormBuilder, private httpService: HttpService) { }
  ngOnInit() {
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12
    };
    this.overlays = [
      new google.maps.Marker({ position: { lat: 36.879466, lng: 30.667648 }, title: "Konyaalti" }),
      new google.maps.Marker({ position: { lat: 36.883707, lng: 30.689216 }, title: "Ataturk Park" }),
      new google.maps.Marker({ position: { lat: 36.885233, lng: 30.702323 }, title: "Oldtown" }),
      new google.maps.Polygon({
        paths: [{"lat":5.7384061759148,"lng":34.746492118242},{"lat":5.7381472567198,"lng":34.748579320252},{"lat":5.7373804499439,"lng":34.750586306758},{"lat":5.7361352255054,"lng":34.752435945731},{"lat":5.7344594396191,"lng":34.754057153471},{"lat":5.732417495261,"lng":34.755387626784},{"lat":5.7300878666694,"lng":34.756376237429},{"lat":5.7275600830968,"lng":34.756984996773},{"lat":5.7249312878043,"lng":34.757190515199},{"lat":5.7223025046033,"lng":34.756984900245},{"lat":5.7197747554648,"lng":34.756376059069},{"lat":5.7174451784075,"lng":34.755387393746},{"lat":5.7154032948382,"lng":34.754056901231},{"lat":5.7137275697407,"lng":34.752435712692},{"lat":5.7124823968364,"lng":34.750586128398},{"lat":5.7117156244946,"lng":34.748579223724},{"lat":5.7114567173912,"lng":34.746492118242},{"lat":5.7117156244946,"lng":34.74440501276},{"lat":5.7124823968364,"lng":34.742398108087},{"lat":5.7137275697407,"lng":34.740548523793},{"lat":5.7154032948382,"lng":34.738927335253},{"lat":5.7174451784075,"lng":34.737596842739},{"lat":5.7197747554648,"lng":34.736608177416},{"lat":5.7223025046033,"lng":34.73599933624},{"lat":5.7249312878043,"lng":34.735793721286},{"lat":5.7275600830968,"lng":34.735999239712},{"lat":5.7300878666694,"lng":34.736607999056},{"lat":5.732417495261,"lng":34.737596609701},{"lat":5.7344594396191,"lng":34.738927083014},{"lat":5.7361352255054,"lng":34.740548290754},{"lat":5.7373804499439,"lng":34.742397929727},{"lat":5.7381472567198,"lng":34.744404916233},{"lat":5.7384061759148,"lng":34.746492118242}], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
      }),
      new google.maps.Circle({ center: { lat: 36.90707, lng: 30.56533 }, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500 }),
      new google.maps.Polyline({ path: [{ lat: 36.86149, lng: 30.63743 }, { lat: 36.86341, lng: 30.72463 }], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2 })
    ];
  }



}
