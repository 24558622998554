import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';

@Component({
  selector: 'app-manage-files',
  templateUrl: './manage-files.component.html',
  styleUrls: ['./manage-files.component.scss']
})
export class ManageFilesComponent implements OnInit {
  displayModal: boolean = false
  selectedFiles: any = [];
  imgUrls: any = []
  file: any
  disabled: boolean = false
  @Input() reward: any
  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent

  constructor(private httpService: HttpService, private messageService: MessageService) { }

  ngOnInit(): void {
    // console.log(this.reward);

  }
  showPreviewImage(event: any,) {
    var imgurls: any = [];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      var reader = new FileReader();
      reader.onload = (function (file) {
        return function (event: any) {
          if (file.type.startsWith('image/')) {
            imgurls.push({ url: event.target.result, type: 'image' });
          }
          if (file.type.startsWith('video/')) {
            imgurls.push({ url: event.target.result, type: 'video' });
          }
        };
      })(files[i]);
      reader.readAsDataURL(files[i]);
      this.selectedFiles.push(<File>files[i])
    }
    this.imgUrls = imgurls
    console.log(this.selectedFiles);

  }
  manage_files() {
    const fd = new FormData();
    this.disabled = true
    this.selectedFiles.forEach((element: any) => {
      fd.append("media", element);
      this.httpService.post("points_rewards_file/" + this.reward.id, fd).subscribe((data: any) => {
        if (data?.status == 200) {
          this.disabled = false
          console.log(data);
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'IMages ont été téléchargé avec succès' });
          this.displayModal = false;
          this.reward.files.push(data.body.data)
        }
      })
    });

    console.log(this.reward);

  }
  deleteFile(file: any) {
    console.log(file);
    this.file = file
    this.deleteModal.displayModal = true;
  }
  updateOrder() {
    let order: any = []
    let form: any
    this.reward?.files.forEach((element: any) => {
      order.push(element.id)
    });
    form = { order: order, start: 1 }
    console.log(form);

    this.httpService.post("points_rewards_files/update_order", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a modifié avec succès' });
      }
    })
  }
  onConfirm(event: any) {
    this.httpService.delete("points_rewards_file/" + this.file.id).subscribe((data: any) => {
      if (data?.status === 204) {
        console.log("dfdfdfdf");
        this.deleteModal.displayModal = false;
        this.reward.files = this.reward.files.filter((item: any) => item.id !== this.file.id)
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Média supprimé avec succès' });
      }
    })
  }
}
