import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { SharedService } from 'src/app/services/shared.service';
import { HomeComponent } from '../home/home.component';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  stores: any
  store: any
  loading: boolean = false;
  regions: any
  store_types: any
  search_form!: FormGroup;
  value: boolean = true
  stackedBarOptions: any;
  stackedPieOptions: any;
  page: number = 1
  orders_modal: boolean = false
  isCollapsed = true;
  visibility: any = [{ label: "Visible", value: "1" }, { label: "Non visible", value: "0" }]
  Orderables: any = [{ label: "Date de création", value: "-created" }, { label: "Dernière mise à jour", value: "-updated" }, { label: "Visites 7 jours", value: "-visits" }, { label: "Solde", value: "-money" }, { label: "Commandes 7 jours", value: "-orders" }, { label: "Annulations 7 jours", value: "-cancellations" }]
  categories: any = [{ label: "Food", value: "food" }, { label: "Healthy", value: "healthy" }, { label: "Fresh", value: "fresh" }, { label: "Express", value: "express" }]
  constructor(private messageService: MessageService, public home: HomeComponent, private sharedService: SharedService, private router: Router, private fb: FormBuilder, private httpService: HttpService, private route: ActivatedRoute) {
    this.search_form = this.fb.group({
      id: new FormControl("",),
      name: new FormControl("",),
      phone: new FormControl(""),
      region: new FormControl("",),
      type: new FormControl("",),
      category: new FormControl("",),
      visible: new FormControl("",),
      order_by: new FormControl("",),
    });
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      let url: any = 'stores?page=' + this.page
      if (param.get('name') || param.get('phone') || param.get('region') || param.get('type') || param.get('category') || param.get('visible') || param.get('id')) {
        url += '&filters='
        this.search_form.patchValue({
          id: param.get('id'),
          name: param.get('name'),
          phone: param.get('phone'),
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          type: param?.get('type') ? JSON.parse(param?.get('type') || '') : "",
          visible: param?.get('visible'),
          category: param.get('category'),
        })
      }
      param.get('id') ? url += 'id:' + param.get('id') + ";" : ""
      param.get('name') ? url += 'name:lk:' + param.get('name') + ";" : ""
      param.get('phone') ? url += 'phone:lk:' + param.get('phone') + ";" : ""
      param.get('region') ? url += 'region:' + param.get('region') + ";" : ""
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""
      param.get('visible') ? url += 'visible:' + param.get('visible') + ";" : ""
      param.get('category') ? url += 'category:' + param.get('category') + ";" : ""
      if (param.get('order_by')) {
        this.search_form.patchValue({
          order_by: param.get('order_by')})
        url += '&order_by=' + param.get('order_by') + ";"
      }
      this.getStores(url)
      this.getRgions()
      this.getStoreTypes()
    })
  }
  ngOnInit(): void {
    this.stackedBarOptions = {
      indexAxis: 'x',
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#495057'
          }
        },
        tooltip: {
          titleFont: {
            size: 10
          },
          bodyFont: {
            size: 12
          },
        }
      },
      scales: {
        x: {
          display: true,
          ticks: {
            display: false,
            color: '#495057'
          },
          grid: {
            dispaly: false,
            color: '#ebedef'
          }
        },
        y: {
          min: 0,
          max: 200,
          display: true,
          ticks: {
            display: false,
            color: '#495057'
          },
          grid: {
            dispaly: false,
            color: '#ebedef'
          }
        }
      }
    };
    this.stackedPieOptions = {
      indexAxis: 'x',
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#495057'
          }
        },
        tooltip: {
          titleFont: {
            size: 10
          },
          bodyFont: {
            size: 12
          },
        }
      },
      scales: {
        x: {
          display: false,
          ticks: {
            display: false,
            color: '#495057'
          },
          grid: {
            dispaly: false,
            color: '#ebedef'
          }
        },
        y: {
          display: false,
          ticks: {
            display: false,
            color: '#495057'
          },
          grid: {
            dispaly: false,
            color: '#ebedef'
          }
        }
      }
    };
  }
  getStores(url: any) {
    const borderRadius = 5;
    const borderRadiusAllCorners = { bottomLeft: borderRadius, bottomRight: borderRadius, topRight: borderRadius, topLeft: borderRadius }
    this.httpService.get(url).subscribe((data: any) => {
      if (data?.status === 200) {
        this.loading = true
        this.stores = data.body
        this.stores.data.forEach((element: any) => {
          if (element.phone.lastIndexOf(this.home.config.phone_int_prefix, 0) === 0) {
            element.phone = element.phone.replace(this.home.config.phone_int_prefix, this.home.config.phone_local_prefix)
          }
          let values: any = []
          let labels: any = []
          let date: any = []
          let infos: any = []
          let colors: any = []
          element.visits_stats.reverse().forEach((visit_stat: any) => {
            date.push(visit_stat.date)
            infos.push(visit_stat.visits)
          });
          this.set_labels_and_colors(element.orders_stats.data)
          let data_array = Object.values(element.orders_stats.data)
          const order = ["closed", "no_answer", "not_available", "other", "completed"]
          data_array = order.map((item: any) => data_array.find((t: any) => t.lbl === item));
          data_array.forEach((element: any) => {
            labels.push(element.label)
            values.push(element.value)
            colors.push(element.color)
          });
          element.stackedOrdersData = {
            labels: labels,
            datasets: [{
              type: 'pie',
              label: element.label,
              backgroundColor: colors,
              borderRadius: borderRadiusAllCorners,
              borderSkipped: false,
              data: values
            }]
          };
          element.stackedvisitsData = {
            labels: date,
            datasets: [{
              type: 'bar',
              label: 'Visiteurs',
              backgroundColor: ["#00aa31"],
              borderRadius: borderRadiusAllCorners,
              borderSkipped: false,
              data: infos
            }]
          };
        });
      }
    },
      (error) => {
        console.log(error);
      }
    )
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/stores'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  changeVisibility(store: any, event: any) {
    this.httpService.post("store/" + store.id, { visible: event.checked }).subscribe(res => {
      if (res?.status === 200) {
        Object.assign(store, res.body.data);
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Établissments a été modifié avec succès' });
      }
    })
  }
  getRgions() {
    this.httpService.get("regions").subscribe((data: any) => {
      if (data.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  getStoreTypes() {
    this.httpService.get("store_types").subscribe((data: any) => {
      if (data.status === 200) {
        this.store_types = data.body.data
      }
    })
  }
  reset_filter() {
    this.search_form.reset()
    this.router.navigate(['/stores'])
    this.loading = false
  }
  filter() {
    this.loading = false
    if (this.search_form.value?.phone?.lastIndexOf(this.home.config.phone_local_prefix, 0) === 0) {
      if (this.home.config.phone_local_prefix === "") {
        this.search_form.value.phone = this.home.config.phone_int_prefix + this.search_form.value.phone
      } else {
        this.search_form.value.phone = this.search_form.value.phone.replace(this.home.config.phone_local_prefix, this.home.config.phone_int_prefix)
      }
    }
    this.router.navigate(['/stores'], { queryParams: this.search_form.value, queryParamsHandling: 'merge' });
  }
  toggleBtn() {
    this.value = !this.value
    this.value ? this.search_form.patchValue({ phone: "" }) : this.search_form.patchValue({ name: "" })
  }
  set_labels_and_colors(element: any) {
    element.closed.color = "#000000"
    element.closed.lbl = "closed"
    element.no_answer.color = "#e14c4c"
    element.no_answer.lbl = "no_answer"
    element.not_available.color = "#f7b203"
    element.not_available.lbl = "not_available"
    element.other.color = "#acacac"
    element.other.lbl = "other"
    element.completed.color = "#00aa31"
    element.completed.lbl = "completed"
  }
  Order_by() {

  }
}
