<div *ngIf="is_loaded;else notYetLoaded">
    <div class="px-4 py-3">
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="searchForm">
            <div class="d-flex align-items-center my-4 justify-content-between">
                <div class="d-flex align-items-center">
                    <p-dropdown optionValue="id" class="mr-3" formControlName="region" [options]="regions"
                        optionLabel="name" placeholder="Regions" [showClear]="false"></p-dropdown>
                    <p-dropdown optionValue="value" class="mr-3" [options]="status" formControlName="status"
                        optionLabel="label" placeholder="Statut" [showClear]="false">
                    </p-dropdown>
                    <p-dropdown class="mr-3" optionValue="value" optionLabel="label" [options]="types"
                        placeholder="Type" formControlName="type" [showClear]="false">
                    </p-dropdown>
                </div>
                <div class="d-flex">
                    <button class="text-white btn avenirRegular f18 green-back w-100 filterBtn mr-3"
                        (click)="filter()">Filtrer</button>
                    <button class="w-100 text-white btn avenirRegular f16 main-color white-back filterBtn"
                        (click)="reset()">Réintianiliser</button>
                </div>
            </div>
        </form>
    </div>
    <div class="table-responsive">
        <table class="table table-borderless mt-4">
            <thead>
                <tr>
                    <th>Établissments</th>
                    <th>Région</th>
                    <th>Type</th>
                    <th style="width: 20rem">Description</th>
                    <th>Statut</th>
                    <th>Crée par:</th>
                    <th>Date de création</th>
                    <th>Date de modification</th>
                    <th>Opérations</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let store_ticket of store_tickets?.data">
                    <td class="avenirArabic f18 black">
                        {{store_ticket?.store?.name}}
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.region?.name}}
                    </td>
                    <td class="avenirRegular f18 text-white">
                        <div *ngFor="let type of types">
                            <div *ngIf="type.value == store_ticket?.type">
                                <span [style.background-color]="type.color"
                                    class="store-ticket-type p-2 br30 white-space ">{{type.label}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.description}}
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.status}}
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.created_by?.name}}|{{store_ticket?.created_by?.type}}
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.created_at?.date | date:"YYYY-MM-dd HH:mm"}}
                    </td>
                    <td class="avenirRegular f18 black">
                        {{store_ticket?.updated_at?.date | date:"YYYY-MM-dd HH:mm"}}
                    </td>
                    <td class="avenirRegular f18 black">
                        <button class="btn p-0 mr-2" (click)="open_side_bar(store_ticket)">
                            <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class=" w-100 ">
        <div class="d-flex justify-content-end">
            <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
                [showFirstLastIcon]="true" [totalRecords]="store_tickets?.meta?.pagination?.total_pages" #paginator
                (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
</div>
<ng-template #notYetLoaded>
    <div class="d-flex align-items-center justify-content-center h-100">
        <app-loading></app-loading>
    </div>
</ng-template>
<p-sidebar #sidebar [(visible)]="display" [appendTo]="sidebar" position="right" [style]="{width:'575px'}"
    [showCloseIcon]=false [baseZIndex]="10000" styleClass="disable-scroll">
    <div class="d-flex align-items-center p-3">
        <button class="btn p-0" (click)="display=false">
            <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
        </button>
        <span class="f40 avenirMedium main-color fw85">Détails de la requête</span>
    </div>
    <div class="p-3">
        <div class="mb-3">
            <span class=" main-color f25 fw85 avenirArabic">Suivi de la requête </span>
        </div>
        <!-- {{store_ticket |json}} -->
        <div *ngIf="store_ticket?.updates" [innerHTML]="store_ticket?.updates" class="pb-5 custom_html px-3"></div>
        <div class="deepGrey f20 avenirArabic mb-3">
            <div class="d-flex justify-content-between pt-4">
                <span>Crée par:{{store_ticket?.created_by?.name}} </span>
                <span>Le: {{store_ticket?.created_at?.date | date:"YYYY-MM-dd HH:mm"}}</span>
            </div>
            <div class="pt-4">
              <span class="f20 avenirArabic">Description: </span>
              <span class="black f20 avenirArabic">{{store_ticket?.description}} </span>
          </div>
            <div class="d-flex justify-content-between pt-4 align-items-center">
                <div>Établissment:
                    <a [routerLink]="['/stores/details/'+store_ticket?.store_id+'/profile']"
                        class="main-color"><u>{{store_ticket?.store?.name}}</u>
                    </a>
                </div>
                <button class="btn p-0" [routerLink]="['/stores/details/'+store_ticket?.store_id+'/profile']">
                    <img src="{{store_ticket?.store?.logo?.url}}" alt="" class="img-store">
                </button>
                <!-- <div>Type: <span *ngFor="let type of types">
                        <span *ngIf="type.value == store_ticket?.type"> {{type.label}}</span>
                    </span>
                </div> -->
            </div>
            <div class="d-flex justify-content-between pt-4">
                <div>Type: <span *ngFor="let type of types">
                        <span *ngIf="type.value == store_ticket?.type"> {{type.label}}</span>
                    </span>
                </div>
                <span>Région: {{store_ticket?.region?.name}}</span>
            </div>
            <form [formGroup]="store_ticket_form">
                <div>
                    <div class="mt-4">
                        <span class=" main-color f25 fw85 avenirArabic">Mise à jour de la requête </span>
                    </div>
                    <div class="mt-3">
                        <label class="avenirMedium f20" for="password"><span class="deepGrey">Mise à jour</span> <span
                                class="red-color">*</span></label>
                        <div class="form-group mt-2">
                            <textarea [ngClass]="{'is-invalid': submit &&f['updates'].errors}"
                                class="form-control mdStl deepGrey avenirArabic f18" formControlName="updates"
                                placeholder="Description de la mise à jour" id="message" rows="5"></textarea>
                        </div>
                    </div>
                    <div class=" d-flex justify-content-between  align-items-center px-3 py-2 bnr">
                        <span class="avenirMedium f18 ursopct black"> Réglé</span>
                        <p-inputSwitch formControlName="status"></p-inputSwitch>
                    </div>
                </div>
                <div class="d-flex justify-content-end  align-items-center mt-4">
                    <button class="btn avenirMedium f18 text-white dp-grey-back mr-3 px-5"
                        (click)="display=false">Annuler</button>
                    <button class="btn green-back px-5 avenirMedium f18 text-white"
                        (click)="edit_store_ticket()">Envoyer</button>
                </div>
            </form>
        </div>
    </div>
</p-sidebar>
