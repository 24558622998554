import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  hub: any
  staff: any
  submit: boolean = false
  staff_form!: FormGroup
  permission_form!: FormGroup
  display: boolean = false;
  staf: any
  imgUrl: any;
  selectedFile: any = null;
  edit: boolean = false
  permission_dialog: boolean = false
  hub_id: any
  is_loading: boolean = false
  active: any
  status: any = [{ label: 'Actif', value: 1 }, { label: 'Inactif', value: 0 }]
  search: any
  type: any
  hubs: any
  selected_hubs: any[] = []
  types: any = [{ label: 'Delivery', value: 'delivery' }, { label: 'Hub Agent', value: 'hub_agent' }, { label: 'Hub manager', value: 'hub_manager' }, { label: 'Supervisor', value: 'supervisor' }]
  vehicles: any = [{ label: 'Voiture', value: 'voiture' }, { label: 'Moto', value: 'moto' }, { label: 'Vélo', value: 'velo' }]
  constructor(private location: Location, private app_config: AppConfigService, private router: Router, private fb: FormBuilder, private route: ActivatedRoute, private httpService: HttpService, private messageService: MessageService) { }
  emailConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('type').value === 'delivery') {
      return Validators.required(formControl);
    }
    return null;
  }
  ngOnInit(): void {
    this.staff_form = this.fb.group({
      name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      hubs: new FormControl([],),
      vehicle: new FormControl("", this.emailConditionallyRequiredValidator),
    }, {
      validators: [this.emailConditionallyRequiredValidator, this.password.bind(this)],
    });
    this.permission_form = this.fb.group({
      can_create_delivery_staff: new FormControl(false),
      can_create_delivery_transactions: new FormControl(false),
      can_edit_delivery_zones: new FormControl(false),
    })
    this.staff_form.get('type')?.valueChanges
      .subscribe(value => {
        this.staff_form.get('vehicle')?.updateValueAndValidity();
      });

    this.getStaff()
    this.get_hubs()
  }
  get f() { return this.staff_form.controls }
  getStaff() {
    this.route.params.subscribe((res: any) => {
      this.hub_id = res.id
      this.httpService.get('hub/' + res.id).subscribe((result: any) => {
        if (result?.status == 200) {
          let url = 'staff?filters=hub:' + res.id
          this.route.queryParams.subscribe((params: any) => {
            params.type ? url += ';type:' + params.type : null
            if (params.active) {
              url = url + ';active:' + params.active
              this.active = JSON.parse(params.active)
            }
            else {
              this.active = 1
              url = url + ';active:1'
            }

          })
          this.hub = result.body.data
          this.httpService.get(url).subscribe((res: any) => {
            if (res?.status == 200) {
              this.staff = res.body
              res.body.data.forEach((element: any) => {
                this.adjustphone_number(element)
              });
              this.is_loading = true
            }
          })

        }
      })
    })
  }
  get_hubs() {
    this.httpService.get('hubs').subscribe((res: any) => {
      if (res?.status == 200) {
        this.hubs = res.body.data
      }
    })
  }
  adjustphone_number(element: any) {
    if (element?.phone?.lastIndexOf(this.app_config.config.phone_int_prefix, 0) === 0) {
      element.phone = element.phone.replace(this.app_config.config.phone_int_prefix, this.app_config.config.phone_local_prefix)
    }
  }
  select_status() {
    this.router.navigate(['/hubs/1'], { queryParams: { status: this.active } });
  }
  add_staff() {
    this.edit = false
    this.submit = false
    this.selectedFile = null
    this.staff_form.reset()
    this.imgUrl = null
    this.display = true
    this.selected_hubs = []
  }
  edit_staff(staff: any) {
    this.selected_hubs = []
    this.staff_form.reset()
    this.staff_form.get('password')?.clearValidators()
    this.staff_form.get('confirmpassword')?.clearValidators()
    this.staff_form.get('password')?.updateValueAndValidity()
    this.staff_form.get('confirmpassword')?.updateValueAndValidity()
    this.selectedFile = null
    this.imgUrl = null
    this.staf = staff
    this.display = true
    this.edit = true
    this.edit_supervisor(staff)
    this.staff_form.patchValue({
      name: staff.name,
      username: staff.username,
      phone: staff.phone,
      type: staff.type,
      regions: staff.regions,
      vehicle: staff.vehicle,
    })
    this.imgUrl = staff.image
  }
  changeStatus(staff: any) {
    let form = {
      active: staff.active,
      collector: staff.collector,
    }
    this.httpService.post('staff/' + staff.id, form).subscribe((res: any) => {
      if (res?.status == 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Livreur  a été modifié avec succès' });
      }
    })
  }
  manage_staff() {
    this.submit = true
    if (this.staff_form.invalid) {
      return
    }
    const fd = new FormData();
    fd.append('name', this.staff_form.value.name)
    fd.append('username', this.staff_form.value.username)
    this.staff_form.value.password ? fd.append('password', this.staff_form.value.password) : null
    fd.append('type', this.staff_form.value.type)
    fd.append('hub_id', this.hub_id)
    fd.append("data[]", JSON.stringify({ "hubs": this.selected_hubs.toString() }));

    this.staff_form.value.type === 'hub_manager' || this.staff_form.value.type === 'hub_agent' || this.staff_form.value.type === 'supervisor' ? fd.append('vehicle', "voiture") : fd.append('vehicle', this.staff_form.value.vehicle)
    this.selectedFile ? fd.append('image', this.selectedFile, this.selectedFile.name) : null
    if (this.staff_form.value.phone !== this.staf?.phone) {
      if (this.staff_form.value.phone.lastIndexOf(this.app_config.config.phone_local_prefix, 0) === 0) {
        if (this.app_config.config.phone_local_prefix === "") {
          this.staff_form.value.phone = this.app_config.config.phone_int_prefix + this.staff_form.value.phone
        } else {
          this.staff_form.value.phone = this.staff_form.value.phone.replace(this.app_config.config.phone_local_prefix, this.app_config.config.phone_int_prefix)

        }
      } else {
        this.staff_form.value.phone = this.app_config.config.phone_int_prefix + this.staff_form.value.phone
      }
    }
    fd.append('phone', this.staff_form.value.phone)
    if (this.edit) {
      this.httpService.post('staff/' + this.staf.id, fd).subscribe((res: any) => {
        if (res?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Livreur  a été modifié avec succès' });
          this.display = false
          this.edit_hubs(this.staf.id)
          Object.assign(this.staf, res.body.data)
          this.adjustphone_number(this.staf)
        }
      })
    }
    else {
      fd.append('all_regions', "0")
      fd.append('regions[]', this.hub.region.id)
      fd.append('permissions', "-")
      this.httpService.post('staff/', fd).subscribe((res: any) => {
        if (res?.status == 200) {
          this.edit_hubs(res.body.data.id)
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Livreur  a été ajouté avec succès' });
          this.display = false
          this.adjustphone_number(res.body.data)
          this.staff.data.push(res.body.data)
        }
      })
    }
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
    }
  }
  filter() {
    let search = { type: this.type, active: this.active }
    this.router.navigate(['/hubs/' + this.hub_id,], { queryParams: search, queryParamsHandling: "merge" });
    this.is_loading = false
    this.getStaff()

  }
  reset() {
    this.router.navigate(['/hubs/' + this.hub_id]);
    this.is_loading = false
    this.getStaff()
  }
  editHubAgent(staff: any) {
    this.staf = staff
    this.permission_dialog = true
    this.permission_form.patchValue({
      can_create_delivery_staff: staff.permissions.can_create_delivery_staff === 1 ? true : false,
      can_create_delivery_transactions: staff.permissions.can_create_delivery_transactions === 1 ? true : false,
      can_edit_delivery_zones: staff.permissions.can_edit_delivery_zones === 1 ? true : false,
    })
  }
  manage_permissions() {
    this.httpService.post('staff/' + this.staf.id, this.permission_form.value).subscribe((res: any) => {
      if (res?.status == 200) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Staff a été modifié avec succès' });
        this.permission_dialog = false
        Object.assign(this.staf, res.body.data)
        this.adjustphone_number(this.staf)
      }
    })
  }
  edit_supervisor(supervisor: any) {
    this.selected_hubs = []
    if (supervisor.hubs) {
      Object.keys(supervisor.hubs).forEach((element: any) => {
        this.selected_hubs.push(JSON.parse(element))
      });
    }
  }
  edit_hubs(staff_id: any) {
    this.httpService.post('staff/' + staff_id + "/update_data/?key=hubs&value=" + this.selected_hubs.toString(), '').subscribe((res: any) => {
      if (res?.status == 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Staff a été modifié avec succès' });
        this.httpService.get('staff/' + staff_id).subscribe((res: any) => {
          if (res?.status == 200) {
            Object.assign(this.staf, res.body.data)
            this.adjustphone_number(this.staf)
          }
        })
      }
    })

  }
  back() {
    this.location.back()
  }
  password(formGroup: FormGroup) {
    const { value: password }: any = formGroup.get('password');
    const { value: confirmPassword }: any = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : this.f['confirmpassword'].setErrors({ notSame: true });
  }
}
