import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translationsPatchValue'
})
export class TranslationsPatchValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const index = (value || []).findIndex((x: any) => Object.keys(x || {})[0] === args[0]);
    if(index !== -1){
      return value[index][args[0]][args[1]] || null;
    }
    return null;
  }

}
