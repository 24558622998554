<div class=" white-back p-3" *ngIf="loading">
  <div class="position-relative coverPhoto">
    <div class="customUpload flex-column" style="background-image:url('{{bannerUrl}}')"
      [ngStyle]="{'background-color:#ffffff': !store}">
      <label class="d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <div class="clickable addImg mr-3">
            <input type="file" (change)="showPreviewImage($event,'banner')" />
            <img src="../../../../../assets/images/svg/camera.svg" class="cameraImg" alt="">
          </div>
        </div>
        <span class="f16 avenirRglr deepGrey mt-2">Ajouter une photo de couverture</span>
      </label>
    </div>
    <label class="custom-file-upload " style="background-image:url('{{logoUrl}}')">
      <input type="file" (change)="showPreviewImage($event,'logo')" />
      <img src="../../../../../assets/images/svg/camera.svg" class="cameraImg" alt="">
    </label>
  </div>
  <form class="pt-5" autocomplete="off" [formGroup]="storeForm">
    <div class="form-group d-flex align-items-center">
      <label class="avenirMedium f20 white-space" for="name"><span class="black">Nom de l'établissement</span>
        <span class="red-color">
          *</span></label>
      <input type="text" class="form-control mdStl avenirMedium f16 pr-4" formControlName="name"
        [ngClass]="{'is-invalid': f['name'].errors&&submit}" id="name" placeholder="Ex. Gouda">
    </div>
    <div class="form-row pt-4">
      <div class="form-group col-md-6 d-flex align-items-center">
        <label class="avenirMedium f20 white-space" for="name"><span class="black">Abbréviation</span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16"
          [ngClass]="{'is-invalid': f['abbreviation'].errors&&submit}" formControlName="abbreviation" id="name"
          placeholder="ex. gouda">
      </div>
      <div class="form-group col-md-6 d-flex align-items-center">
        <label class="avenirMedium f20 white-space" for="name"><span class="black">Tagline </span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16"
          [ngClass]="{'is-invalid': f['tag_line'].errors&&submit}" formControlName="tag_line" id="name"
          placeholder="ex. gouda">
      </div>
    </div>
    <div class="form-group d-flex align-items-center pt-4">
      <label class="avenirMedium f20 white-space" for="name"><span class="black">Descritiption </span>
        <span class="red-color">
          *</span></label>
      <input type="text" class="form-control mdStl avenirMedium f16 pr-4"
        [ngClass]="{'is-invalid': f['description'].errors&&submit}" formControlName="description" id="name"
        placeholder="Description de l'établissement">
    </div>
    <div class="form-row pt-4">
      <div class="form-group col-md-6 d-flex align-items-center">
        <label class="avenirMedium f20 white-space" for="name"><span class="black">Addresse </span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16"
          [ngClass]="{'is-invalid': f['address'].errors&&submit}" formControlName="address" id="name"
          placeholder="Ex Rue Saouli Abdelkader">
      </div>
      <div class="form-group col-md-6 d-flex align-items-center">
        <label class="avenirMedium f20 white-space" for="name"><span class="black">Téléphone </span>
          <span class="red-color">
            *</span></label>
        <input type="text" class="form-control mdStl avenirMedium f16"
          [ngClass]="{'is-invalid': f['phone'].errors&&submit}" formControlName="phone" id="name"
          placeholder="Ex. 06 555555">
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <p-dropdown [options]="regions" optionValue="id" optionLabel="name" placeholder="Région"
          (onChange)="changeMarker($event,gmap.getMap())" ks [ngClass]="{'is-invalid': f['region_id'].errors&&submit}"
          [showClear]="false" formControlName="region_id"></p-dropdown>
        <p-dropdown [options]="categories" [options]="categories" optionValue="label" optionLabel="name"
          [ngClass]="{'is-invalid': f['category'].errors&&submit}" placeholder="Catégories d'établissement"
          formControlName="category" [showClear]="false" [disabled]="store?true:false" (onChange)="select_category()">
        </p-dropdown>
        <p-dropdown [options]="slectedTypes" optionValue="id" optionLabel="name" placeholder="Choisir un type"
          [ngClass]="{'is-invalid': f['type_id'].errors&&submit}" [showClear]="false" formControlName="type_id">
        </p-dropdown>
        <p-dropdown [options]="menus" optionValue="id" optionLabel="name" formControlName="global_menu"
          [ngClass]="{'is-invalid': f['global_menu'].errors&&submit}" placeholder="Chosir un menu template"
          [showClear]="true"></p-dropdown>
        <p-multiSelect *ngIf="specialities" [options]="specialities" appendTo="body" [filter]="true" filterBy="name"
          formControlName="specialities" placeholder="Specialité" optionLabel="name"
          [ngClass]="{'is-invalid': f['specialities'].errors&&submit}" display="chip" optionValue="id"
          [(ngModel)]="selectedSpecilities"></p-multiSelect>
        <p-multiSelect *ngIf="types" [options]="types" appendTo="body" formControlName="types" [showHeader]="false"
          placeholder="Autre type" optionLabel="name" [ngClass]="{'is-invalid': f['types'].errors&&submit}"
          display="chip" optionValue="id" [(ngModel)]="selectedTypes"></p-multiSelect>
        <p-multiSelect *ngIf="services" [options]="services" appendTo="body" formControlName="services"
          [ngClass]="{'is-invalid': f['services'].errors&&submit}" [showHeader]="false"
          placeholder="Etablissement équipé de: " optionLabel="name" display="chip" [(ngModel)]="selectedServices"
          optionValue="id"></p-multiSelect>
      </div>
      <div class="col-md-6">
        <p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}"
          (onOverlayDragEnd)="mapDRagEnd($event)" (onMapClick)="add_marker($event)"></p-gmap>
      </div>
    </div>
    <div class="mt-4">
      <span class="main-color f30 avenirHeavy">Statut</span>
      <div class="row no-gutters mt-3">
        <div class="col-md-6 br">
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Visible</span>
            <p-inputSwitch formControlName="visible"></p-inputSwitch>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">En Pause</span>
            <p-inputSwitch formControlName="accept_delivery"></p-inputSwitch>
            <p-calendar *ngIf="storeForm.get('accept_delivery')?.value" inputId="basic"
              formControlName="accept_delivery_at" [showTime]="false" dateFormat="dd/mm/yy"
              [ngClass]="{'is-invalid': f['accept_delivery_at'].errors&&submit}" appendTo="body" class="avenirMedium"
              placeholder="En pause jusqu'à"></p-calendar>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Exclusive</span>
            <p-inputSwitch formControlName="exclusive"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-6 pl-4">
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Cachez-le dans la recherche</span>
            <p-inputSwitch formControlName="hidden"></p-inputSwitch>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Fermé</span>
            <p-inputSwitch formControlName="closed"></p-inputSwitch>
            <p-calendar *ngIf="storeForm.get('closed')?.value" inputId="basic" formControlName="closed_until"
              [showTime]="false" dateFormat="dd/mm/yy" [ngClass]="{'is-invalid': f['closed_until'].errors&&submit}"
              appendTo="body" class="avenirMedium" placeholder="Fermé jusqu'à"></p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <span class="main-color f30 avenirHeavy">Services</span>
      <div class="row no-gutters mt-3">
        <div class="col-md-6 br">
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Livraison</span>
            <p-inputSwitch formControlName="delivery"></p-inputSwitch>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Création des commandes</span>
            <p-inputSwitch formControlName="can_create_orders"></p-inputSwitch>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Activer comme store global</span>
            <p-inputSwitch formControlName="global_store"></p-inputSwitch>
          </div>
        </div>
        <div class="col-md-6 pl-4">
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Vente à l’emporter</span>
            <p-inputSwitch formControlName="pick_up"></p-inputSwitch>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="avenirRglr f18 black pr-3">Affichage du menu en grille</span>
            <p-inputSwitch formControlName="menu_display"></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <span class="main-color f30 avenirHeavy">Promotions</span>
      <div class="row no-gutters mt-3 align-items-center">
        <div class="col-md-5">
          <div class="d-flex align-items-center">
            <span class="avenirRglr f18 black pr-3">Livraison gratuite</span>
            <p-inputSwitch formControlName="free_delivery"></p-inputSwitch>
            <p-calendar *ngIf="storeForm.get('free_delivery')?.value" inputId="basic"
              formControlName="free_delivery_until" [showTime]="false" dateFormat="dd/mm/yy"
              [ngClass]="{'is-invalid': f['free_delivery_until'].errors&&submit}" appendTo="body" class="avenirMedium"
              placeholder="Livraison gratuite jusqu'à"></p-calendar>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <span class="avenirRglr f18 black pr-3">à partir de</span>
        </div>
        <div class="col-md-5">
          <div class="d-flex align-items-center position-relative">
            <input type="number" class="form-control mdStl avenirMedium f16"
              [ngClass]="{'is-invalid': f['free_delivery_from'].errors&&submit}" formControlName="free_delivery_from"
              id="name" placeholder="Livraison gratuite a partir de ">
            <span class="avenirRglr f18 black percountage">
              {{home.config.langs[0].currency_symbol}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <span class="main-color f30 avenirHeavy">Commission et Frais de Services</span>
      <div class="row no-gutters mt-3">
        <div class="col-md-6 br">
          <div class="mb-5">
            <span class="avenirBlck f18 black fw85"> Commission:</span>
            <div class="d-flex align-items-center mt-3">
              <p-checkbox name="group1" inputId="default_order_rate" class="mr-2" formControlName="default_order_rate"
                [binary]="true"></p-checkbox>
              <label class="black f18 avenirRglr" for="default_order_rate">Commission par défaut</label>
              <div class="position-relative d-flex align-items-center">
                <input type="number" class="form-control mdStl avenirMedium f16"
                  [readonly]="storeForm.get('default_order_rate')?.value"
                  [ngClass]="{'is-invalid': f['order_rate'].errors&&submit}" formControlName="order_rate" id="name"
                  placeholder="Commission">
                <span class="avenirRglr f18 black percountage">
                  %
                </span>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <span class="avenirBlck f18 black fw85"> Commandes créés par l'établissement:</span>
            <div class="d-flex align-items-center mt-3">
              <p-checkbox name="group1" inputId="default_store_order_rate" class="mr-2"
                formControlName="default_store_order_rate" [binary]="true"></p-checkbox>
              <label class="black f18 avenirRglr" for="default_store_order_rate">Commission par
                défaut</label>
              <div class="position-relative d-flex align-items-center">
                <input type="number" class="form-control mdStl avenirMedium f16"
                  [readonly]="storeForm.get('default_store_order_rate')?.value"
                  [ngClass]="{'is-invalid': f['store_order_rate'].errors&&submit}" formControlName="store_order_rate"
                  id="name" placeholder="Commission">
                <span class="avenirRglr f18 black percountage">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pl-4">
          <div class="mb-5">
            <span class="avenirBlck f18 black fw85">Frais de services:</span>
            <div class="d-flex align-items-center mt-3">
              <p-checkbox name="group1" inputId="default_service_fee" class="mr-2" formControlName="default_service_fee"
                [binary]="true"></p-checkbox>
              <label class="black f18 avenirRglr" for="default_service_fee">Frais de services par
                défaut</label>
              <div class="position-relative d-flex align-items-center">
                <input type="number" class="form-control mdStl avenirMedium f16"
                  [ngClass]="{'is-invalid': f['service_fee'].errors&&submit}" formControlName="service_fee"
                  [readonly]="storeForm.get('default_service_fee')?.value" id="name" placeholder="Commission">
                <span class="avenirRglr f18 black percountage">
                  DA
                </span>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <span class="avenirBlck f18 black fw85">Commission à l'enlèvement:</span>
            <div class="d-flex align-items-center mt-3">
              <p-checkbox name="group1" inputId="nocollect" class="mr-2" formControlName="nocollect"
                [binary]="true"></p-checkbox>
              <label class="black f18 avenirRglr" for="nocollect">Commission à l'enlèvement</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="home?.user?.profile?.permissions.includes('can_manage_stores')||home?.user?.type==='superadmin'"
      class="d-flex justify-content-end mt-5">
      <button class="btn avenirMedium f16 text-white dp-grey-back mr-3 px-4">Annuler </button>
      <button class="btn green-back  avenirMedium f16 text-white px-4"
        (click)="manageStore(storeForm.value)">Valider</button>
    </div>
  </form>
</div>
