<div>
  <div class="px-4 pt-6 pb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center">

      <form [formGroup]="search_form" (keydown.enter)="$event.preventDefault()">
        <div class="d-flex align-items-center">
          <p-dropdown optionValue="id" class="mr-3" [options]="regions" formControlName="region" optionLabel="name"
            [filter]="true" placeholder="Région" [showClear]="false"></p-dropdown>
          <p-dropdown class="mr-3" [options]="stores" formControlName="store" placeholder="Store" [showClear]="false"
            [filter]="true" optionLabel="label" optionValue="value"></p-dropdown>
          <p-dropdown class="mr-3" [options]="stories_partners" formControlName="partner" placeholder="Partenaire"
            [filter]="true" optionLabel="name" optionValue="id" [showClear]="false"></p-dropdown>
          <p-dropdown optionValue="id" class="mr-3" [options]="visibility" formControlName="active" optionLabel="label"
            optionValue="value" placeholder="Visibilité" [showClear]="false"></p-dropdown>
          <button type="button" class="text-white btn avenirRegular br25 mr-3 f16 green-back px-4 filterBtn"
            style="height:48px" (click)="filter()">Filtrer</button>
          <button type="button" class="text-white btn avenirRegular br25  f16 main-color white-back filterBtn"
            (click)="reset_filter()" style="height:48px">Réintianiliser</button>
        </div>
      </form>
    </div>
    <div>
      <button class="btn avenirRegular f20 br25 green-back text-white px-4 mr-2" type="button"
        (click)="add_story()">Ajouter un Round</button>
      <button class="btn avenirRegular f20 br25 green-back text-white px-4" type="button"
        [routerLink]="['partners-management']" routerLinkActive="router-link-active">Gestion des
        partenaires</button>
    </div>
  </div>
  <div *ngIf="isLoading;else notYetLoaded">
    <p-table *ngIf="stories?.data.length>0;else noItems" [value]="stories?.data" [reorderableColumns]="true"
      responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>Id</th>
          <th>Nom</th>
          <th>Propriétaire</th>
          <th>Date de début</th>
          <th>Text</th>
          <th>Action</th>
          <th>Type</th>
          <th>Date de création</th>
          <th>Date d'expiration</th>
          <th>Opérations</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td class="f16 avenirRegular black">
            {{ rowData?.id }}
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center flex-column">
              <img *ngIf="rowData?.type=='image'; else video_media" src="{{ rowData?.media }}" class="img mr-2"
                alt="" />
              <span class="f18 avenirRegular black">{{ rowData.name }}</span>
              <ng-template #video_media>
                <button class="btn py-0" (click)="play_video(rowData.media,rowData.type)">
                  <img src="../../../assets/images/svg/play_button.svg" alt="">
                </button>
              </ng-template>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center flex-column">
              <img *ngIf="rowData?.owner.logo; " src="{{ rowData?.owner.logo }}" class="img mr-2 d-block" alt="" />
              <span class="f18 avenirRegular black">{{ rowData.owner.name }}</span>
            </div>
          </td>
          <td class="f16 avenirRegular black">
            {{ rowData?.starts_at?.date |date :'dd/MM/yyyy' }}
          </td>
          <td class="f16 avenirRegular black">
            {{ rowData.text }}
          </td>
          <td class="f16 avenirRegular black">
            {{ rowData.action }}
          </td>
          <td class="f16 avenirRegular black">
            {{ rowData.owner.type }}
          </td>
          <td class="f16 avenirRegular black">
            {{rowData.created?.date|date :'dd/MM/yyyy' }}
          </td>
          <td class="f16 avenirRegular black">
            {{rowData.expires_at?.date|date :'dd/MM/yyyy' }}
          </td>
          <td>
            <button class="btn p-0 mr-2" (click)="edit_story(rowData)">
              <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="" />
            </button>
            <button class="btn p-0" (click)="delete_story(rowData)">
              <img src="../../../../../assets/images/svg/delete..svg" alt="">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class=" w-100 ">
      <div class="d-flex justify-content-end">
        <p-paginator [rows]="1" [first]="page-1" [alwaysShow]="false" [showCurrentPageReport]="false"
          [showFirstLastIcon]="true" [totalRecords]="stories.meta.pagination.total_pages" #paginator
          (onPageChange)="paginate($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>

<p-dialog [showHeader]="false" [(visible)]="display" [modal]="true" [style]="{width: '620px'}" [draggable]="false"
  [resizable]="false">
  <form [formGroup]="story_form" autocomplete="off">
    <div class="mb-4 d-flex align-items-baseline">
      <span class="avenirMedium f35 fw85 main-color">{{edit?'Modifier ':'Ajouter '}} </span>
      <img src="../../../assets/images/svg/Layer_1.svg" alt="" class="ml-2">
      <div *ngIf="story">
        <span class="avenirMedium f18">{{story?.action}} </span>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <p-dropdown formControlName="type" appendTo="body" [disabled]="edit" [options]='types' optionValue="value"
          optionLabel="label" placeholder="Type du Round" [showClear]="false"
          [ngClass]="{'is-invalid': submit&&f['type'].errors}">
        </p-dropdown>
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control mdStl avenirMedium f18 customHeight" id="nom" placeholder="Nom du Round"
          formControlName="name" [ngClass]="{'is-invalid': submit&&f['name'].errors}">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <div *ngIf="story_form.get('type')?.value==='partner'" class="d-flex">
          <p-checkbox value="all_regions" inputId="all_regions" [(ngModel)]="all_regions"
            [ngModelOptions]="{standalone:true}" (onChange)="select_regions($event)" class="mr-2"></p-checkbox>
          <p-multiSelect [options]="regions" appendTo="body" [filter]="true" filterBy="name" formControlName="regions"
            [maxSelectedLabels]="1" selectedItemsLabel="{0} région selectionnée" placeholder="Régions"
            optionLabel="name" [ngClass]="{'is-invalid': f['regions'].errors&&submit}" optionValue="id"
            [(ngModel)]="selectedRegions" [disabled]="selectedRegions?.length===regions?.length"
            class="w-100"></p-multiSelect>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div *ngIf="story_form.get('type')?.value==='partner'">
          <p-dropdown formControlName="stories_partner_id" appendTo="body" [options]='stories_partners' optionValue="id"
            [filter]="true" optionLabel="name" placeholder="Partenaire" [showClear]="false"
            [ngClass]="{'is-invalid': submit&&f['stories_partner_id'].errors}">
          </p-dropdown>
        </div>
        <div *ngIf="story_form.get('type')?.value==='store'">
          <p-autoComplete formControlName="store_id" [(ngModel)]="store_id" [suggestions]="stores" class="w-100"
            appendTo="body" field="name" (onSelect)="get_section_menu($event.id)" placeholder="Store"
            (completeMethod)="search($event)">
            <ng-template let-store pTemplate="item">
              {{store.name}}, {{store.region}}
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="form-row grey_back mb-3">
      <input type="text" class="form-control mdStl avenirMedium f18 customHeight" id="title"
        placeholder="Titre du Round" formControlName="title" [ngClass]="{'is-invalid': submit&&f['title'].errors}">
      <div class="d-flex justify-content-center w-100 ">
        <div class="seperator">
          <hr class="my-0">
        </div>
      </div>
      <textarea class="form-control mdStl black avenirRegular f18 mb-3" formControlName="description"
        placeholder="Description du Round (3 lignes maximum)" id="message" rows="3"
        [ngClass]="{'is-invalid': submit&&f['description'].errors}"></textarea>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input type="text" class="form-control mdStl avenirMedium f18  customHeight" id="text"
          placeholder="Texte du bouton" formControlName="text" [ngClass]="{'is-invalid': submit&&f['text'].errors}">
      </div>
      <div class="from-group col-md-6">
        <p-dropdown [(ngModel)]="urlType" [ngModelOptions]="{standalone:true}" appendTo="body" [options]='urlSelector'
          optionValue="value" optionLabel="label" placeholder="Action du bouton" [showClear]="false">
        </p-dropdown>
        <div [ngSwitch]="urlType" class="mt-2">
          <div *ngSwitchCase="'externLink'">
            <input type="text" class="form-control mdStl avenirMedium f18 mb-3 customHeight" id="externLink"
              placeholder="Action" [(ngModel)]="url" [ngModelOptions]="{standalone:true}"
              [ngClass]="{'is-invalid': submit&&f['action'].errors}" value="https://">
          </div>
          <div *ngSwitchCase="'rewards'">
            <div class="form-group mb-3">
              <p-dropdown [options]="rewards" [(ngModel)]="url" [ngModelOptions]="{standalone:true}" optionValue="id"
                optionLabel="label" id="rewards" #reward [ngClass]="{'is-invalid': submit &&f['action'].errors}"
                [filter]="true" placeholder="Tout les Rewards" appendTo="body" [showClear]="true"></p-dropdown>
            </div>
          </div>
          <div *ngSwitchCase="'section_menu'">
            <div class="form-group mb-3">
              <p-dropdown [options]="sections" [(ngModel)]="url" [ngModelOptions]="{standalone:true}" optionValue="id"
                optionLabel="name" id="section_menu" appendTo="body" #section_menu
                [ngClass]="{'is-invalid': submit &&f['action'].errors}" [filter]="true" placeholder="Tout les Sections"
                [showClear]="true"></p-dropdown>
            </div>
          </div>
          <div *ngSwitchCase="'create_order'">
            <div class="form-group mb-3">
              <p-dropdown [options]="sections" [(ngModel)]="section" (onChange)="create_order()"
                [ngModelOptions]="{standalone:true}" optionValue="id" optionLabel="name" id="section_menu"
                appendTo="body" #section_menu [ngClass]="{'is-invalid': submit &&f['action'].errors}" [filter]="true"
                placeholder="Tout les Sections" [showClear]="true"></p-dropdown>
            </div>
            <div class="form-group mb-3">
              <p-multiSelect *ngIf="items" class="w-100" [options]="items" appendTo="body" [filter]="true"
                filterBy="name" placeholder="Selectionner les produits" optionLabel="name" display="chip"
                optionValue="id" [(ngModel)]="selected_items" [ngModelOptions]="{standalone:true}"></p-multiSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <p-calendar inputId="basic" formControlName="starts_at"
          [ngClass]="{'is-invalid': submit &&f['starts_at'].errors}" dateFormat="dd/mm/yy" appendTo="body"
          class="avenirRegular" placeholder="Date de Début"></p-calendar>
      </div>
      <div class="form-group col-md-6">
        <p-calendar inputId="basic" formControlName="expires_at"
          [ngClass]="{'is-invalid': submit &&f['expires_at'].errors}" dateFormat="dd/mm/yy" appendTo="body"
          class="avenirRegular" placeholder="Date de fin "></p-calendar>
      </div>
    </div>
    <div *ngIf="!edit" class="d-flex align-items-center ">
      <label class="mr-3 clickable" [ngClass]="{'is-invalid': submit&&f['media'].errors}">
        <input type="file" (change)="showPreviewImage($event)" accept=".jpg,.jpeg,.mp4" />
        <img src="../../../../../assets/images/svg/Component_1.svg" class="cameraImg" alt="">
      </label>
      <span class="f18 avenirRegular black mr-4"><u>Télécharger media</u> </span>
      <svg class="icon_play" *ngIf="!imgUrl;else play" [ngClass]="{'is-invalid': submit &&f['media'].errors}">
        <use xlink:href="../../../../../assets/images/svg/Component_2.svg#Group_3475"></use>
      </svg>
      <ng-template #play>
        <button class="btn py-0" (click)="play_video(imgUrl,selectedFile.file_type)">
          <img src="../../../assets/images/svg/play_button.svg" alt="">
        </button>
      </ng-template>
    </div>
    <div class="d-flex justify-content-end pt-3">
      <button class="avenirMedium f16  btn text-white dp-grey-back mr-3 br10" (click)="display=false">Annuler </button>
      <p-button class="green-back avenirMedium f16  text-white p-0 br10 validateBtn" [loading]="loading" size="small"
        (click)="manage_story()">{{edit?"Modifier":"Ajouter"}}</p-button>
    </div>
  </form>
</p-dialog>
<p-dialog [showHeader]="false" [(visible)]="display_video" [modal]="true" [style]="{width: '325px'}" [draggable]="false"
  [resizable]="false" class="modalcontent">
  <div class="d-flex justify-content-between px-3 py-2">
    <button class="btn p-0" (click)="delete_media()">
      <img src="../../../assets/images/svg/delete button.svg" alt="">
    </button>
    <button class="btn p-0" (click)="display_video=false">
      <img src="../../../assets/images/svg/confirm button.svg" alt="">
    </button>
  </div>
  <video *ngIf="media_type==='video'else image" width="100%" height="590px" controls [src]="media">
  </video>
  <ng-template #image>
    <img [src]="imgUrl" alt="" class="w-100">
  </ng-template>
</p-dialog>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template #noItems>
  <div class="text-center black avenirBlack f18 p-3">
    Cette section n'a pas encore des rounds appuyez sur "Ajouter un Round" pour en
    ajouter une
  </div>
</ng-template>
<app-confirmation #deleteConfirmation (onConfirm)="confirmDelete($event)" [name]="'Round '+this.story?.name">
</app-confirmation>
