import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  loading: boolean = false;
  transactions: any
  hubs: any
  currentPage: any;
  displayModal: boolean = false
  page: number = 1
  transaction_form!: FormGroup;
  submit: boolean = false;
  transactionTypes: any = [{ label: 'paiement du Hub', value: "hub_payment" }, { label: 'Commission', value: "order_commission" }, { label: 'Commande collectée', value: "collected_order" }, { label: 'Frais de service', value: "service_fee" }]
  regions: any
  searchForm!: FormGroup;
  constructor(public appconfig: AppConfigService,private httpService: HttpService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) { }
  ngOnInit(): void {
    this.transaction_form = this.fb.group({
      hub_id: new FormControl("", Validators.required),
      key: new FormControl("", Validators.required),
      amount: ["", Validators.required],
      comment: ["", Validators.maxLength(256)],
      type: ["", Validators.maxLength(32)],
    });
    this.searchForm = this.fb.group({
      region: new FormControl(""),
      hub: new FormControl("",),
      type: new FormControl("",),
      gte: new FormControl("",),
      lte: new FormControl(""),
    });
    this.route.queryParamMap.subscribe(param => {
      param.get('page') ? this.page = Number(param.get('page')) : this.page = 1;
      let url: any = 'hub_transactions?order_by=-created&page=' + this.page
      if (param.get('hub') || param.get('region') || param.get('type') || param.get('gte') || param.get('lte')) {
        url += '&filters='
        this.searchForm.patchValue({
          region: param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          hub: param?.get('hub') ? JSON.parse(param?.get('hub') || '') : "",
          type: param.get('type'),
          gte: param.get('gte') ? new Date(param.get('gte') || '') : '',
          lte: param.get('lte') ? new Date(param.get('lte') || '') : '',
        })
      }
      if (param.get('gte') || param.get('lte')) {
        param.get('gte') ? url += 'created:gte:' + param.get('gte') + ";" : ""
        param.get('lte') ? url += 'created:lse:' + param.get('lte') + ";" : ""
      }
      param.get('region') ? url += 'region:' + param.get('region') + ";" : ""
      param.get('hub') ? url += 'hub:' + param.get('hub') + ";" : ""
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""

      this.get_transactions(url)
      this.getHUbs()
      this.getRegions()
    })
  }
  get f() { return this.transaction_form.controls; }
  get_transactions(url: number) {
    this.httpService.get(url).subscribe((res: any) => {
      if (res?.status == 200) {
        this.loading = true
        this.transactions = res.body
      }
    })
  }
  getHUbs() {
    this.httpService.get('hubs').subscribe((res: any) => {
      if (res?.status == 200) {
        this.hubs = res.body.data
      }
    })
  }

  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/transactions'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  addTransaction() {
    this.transaction_form.patchValue({
      key: this.generateRandomKey(18)
    })
    console.log(this.transaction_form);

    this.submit = true;
    if (this.transaction_form.invalid) {
      return
    }
    this.httpService.post('hub_transaction', this.transaction_form.value).subscribe((res: any) => {
      if (res?.status == 200) {
        this.displayModal = false
        this.transaction_form.reset()
        this.transactions.data.unshift(res.body.data)
      }
    })
  }
  openModal() {
    this.displayModal = true
    this.transaction_form.reset()
  }
  closeModal() {
    this.displayModal = false
    this.transaction_form.reset()

  }

  generateRandomKey(length: any) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }
  getRegions() {
    this.httpService.get('regions').subscribe((data: any) => {
      if (data?.status === 200) {
        this.regions = data.body.data
      }
    })
  }
  filter() {
    let search = this.searchForm.value
    let gte: any
    let lte: any
    search?.gte ? gte = new Date(search?.gte) : ""
    search?.lte ? lte = new Date(search?.lte) : ""
    let monthg = gte ? gte.getMonth() + 1 : ""
    let monthl = lte ? lte.getMonth() + 1 : ""
    gte ? gte = gte.getFullYear() + "-" + monthg + "-" + gte.getDate() : ""
    lte ? lte = lte.getFullYear() + "-" + monthl + "-" + lte.getDate() : ""
    search.gte = gte
    search.lte = lte
    search = { ...search, page: 1 }
    console.log(search);

    this.router.navigate(['/transactions'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.searchForm.reset()
    this.router.navigate(['/transactions']);
  }
}
