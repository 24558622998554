import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transaction-points',
  templateUrl: './transaction-points.component.html',
  styleUrls: ['./transaction-points.component.scss']
})
export class TransactionPointsComponent implements OnInit {
  currentPage: any;
  isLoading: boolean = false;
  page: number = 1
  transactionPoints: any
  displayModal: boolean = false
  transactionPoint: any
  modificationModal: boolean = false
  tpDeliveryStatus!: FormGroup;
  submit: boolean = false
  deliveryStatus = [{ name: "Livrée", value: 1 }, { name: "Non livrée", value: 0 }]
  type: any = [{ label: "Tous les types", value: "" }, { label: "Bon d'achat", value: "value_promo" }, { label: "Remise pourcentage", value: "percent_promo" }, { label: "Livraison gratuite", value: "free_delivery" }, { label: "Objet physique", value: "physical_object" }, { label: "Cadeau par téléphone", value: "gift_by_phone" }, { label: "Cadeau par email", value: "gift_by_email" }, { label: "Partenariat", value: "partner_promo" }]
  searchForm!: FormGroup;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private httpService: HttpService, private router: Router,private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      delivered: new FormControl(""),
      type: new FormControl(""),
      created: new FormControl("")
    })
    this.tpDeliveryStatus = this.fb.group({
      delivered: new FormControl("", Validators.required),
      comment: new FormControl("", [Validators.required, Validators.minLength(10)]),

    });
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      let url = 'points_transactions?order_by:-created&page=' + this.page
      if (param.get('delivered')||param.get('type')||param.get('created')){
        url += '&filters='
        this.searchForm.patchValue({
          delivered:  param?.get('delivered') ? JSON.parse(param?.get('delivered') || '') : "",
          type:  param.get('type'),
          created: param.get('created') ? new Date(param.get('created') || '') : '',
        })
      }
      param?.get('delivered') ? url += 'delivered:' + param.get('delivered') + ";" : ""
      param.get('type') ? url += 'type:' + param.get('type') + ";" : ""
      param?.get('created') ? url += 'created:gte:' + this.datePipe.transform(param?.get('created'), 'yyyy-MM-dd')+";lse:"+this.datePipe.transform(param?.get('created'), 'yyyy-MM-dd 2024-05-03 23:59:59') : ""
      this.getTransactionPoints(url)
    })
  }
  get f() { return this.tpDeliveryStatus.controls }
  getTransactionPoints(url: any) {
    this.httpService.get(url).subscribe((res: any) => {
      if (res?.status == 200) {
        this.transactionPoints = res.body
        console.log(this.transactionPoints)
        this.isLoading = true

      }
    })
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/rewards/transaction-points'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  showModal(transactionPoint: any) {
    this.displayModal = true
    this.transactionPoint = transactionPoint
  }
  editTp(transactionPoint: any) {
    this.modificationModal = true
    this.transactionPoint = transactionPoint
    this.tpDeliveryStatus.patchValue({
      delivered: transactionPoint.delivered,
      comment: transactionPoint.comment
    })
  }
  editTransactionPoint() {
    this.submit = true
    if (this.tpDeliveryStatus.invalid) {
      return
    }
    console.log(this.tpDeliveryStatus.value);
    this.httpService.post('points_transaction/' + this.transactionPoint.id, this.tpDeliveryStatus.value).subscribe((res: any) => {
      if (res.status === 200) {
        console.log(res);

        this.modificationModal = false
        Object.assign(this.transactionPoint, res.body.data)
      }
    })
  }
  filter() {
    let created: any
    let search = this.searchForm.value
    search?.created ? created = new Date(search?.created) : ""
    let monthg = created ? created.getMonth() + 1 : ""
    created ? created = created.getFullYear() + "-" + monthg + "-" + created.getDate() : ""
    search.created = created
    search = { ...search, page: 1 }
    this.router.navigate(['/rewards/transaction-points'], { queryParams: search, queryParamsHandling: "merge" });
  }
  reset() {
    this.searchForm.reset()
    this.router.navigate(['/rewards/transaction-points'], { queryParams: { page: 1 } });
  }
}
