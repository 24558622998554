<div class="white-back h-100">
  <div class="d-flex align-items-center pt-5 pb-4 pl-3">
    <button class="btn p-0" (click)="back()">
      <img src="../../../../assets/images/svg/Icon-arrow.svg" class="mr-2" alt="">
    </button>
    <span class="f40 avenirMedium main-color fw85">List téléphonique globale</span>
  </div>
  <div class="table-responsive">
    <table class="table table-borderless mt-4">
      <thead>
        <tr>
          <th style="width:1%">
            <svg class="icon headerIcon">
              <use xlink:href="../../../assets/images/svg/Icon-region.svg#Group_3475"></use>
            </svg>
          </th>
          <th class="regionHeader">
            Région
          </th>
          <th>Etablissement</th>
          <th>Livreurs</th>
          <th>Clients</th>
          <th>SMS</th>
          <th>Opérations</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let region of regions">
          <td><svg class="icon bodyIcon mr-2">
            <use xlink:href="../../../assets/images/svg/Icon-region.svg#Group_3475"></use>
          </svg></td>
          <td class="avenirMedium f20 black">
            {{region.name}}
          </td>
          <td class="avenirMedium f20 black">
            <div class="d-flex flex-column" *ngIf="region.store_phones.length>0">
              <span *ngFor="let phone of region.store_phoness">{{phone}}</span>
            </div>
          </td>
          <td class="avenirMedium f20 black">
            <div class="d-flex flex-column" *ngIf="region.delivery_phones.length>0">
              <span *ngFor="let phone of region.delivery_phoness">{{phone}}</span>
            </div>
          </td>
          <td class="avenirMedium f20 black">
            <div class="d-flex flex-column" *ngIf="region.client_phones.length>0">
              <span *ngFor="let phone of region.client_phoness">{{phone}}</span>
            </div>
          </td>
          <td class="avenirMedium f20 black">
            <div class="d-flex flex-column" *ngIf="region.sms_phones.length>0">
              <span *ngFor="let phone of region.sms_phoness">{{phone}}</span>
            </div>
          </td>
          <td class="avenirMedium f20 black">
            <button class="btn p-0 mr-2">
              <img src="../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, nobis. -->
